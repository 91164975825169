export const enum LoadingIds {
	ACCOMMODATIONS = 'ACCOMMODATIONS',
	OCCUPANCY = 'OCCUPANCY',
	PRICES = 'PRICES',
	CALCULATED_QUOTATIONS = 'CALCULATED_QUOTATIONS',
	VISUALIZATIONS = 'VISUALIZATIONS',
	VISUALIZATION_DATA = 'VISUALIZATION_DATA',
	EVENTS = 'EVENTS',
	NONE = 'NONE',
	UPDATES_LIST = 'UPDATES_LIST',
	LAST_UPDATE = 'LAST_UPDATE',
	ACCOMMODATIONS_BASE_PRICES = 'ACCOMMODATIONS_BASE_PRICES',
	GET_PREDICTION_PREVIEW = 'GET_PREDICTION_PREVIEW',
	GET_BILLING_PROFILES = 'GET_BILLING_PROFILES',
	GET_INVOICES = 'GET_INVOICES',
	GET_FIXED_DATES = 'GET_FIXED_DATES',

	// Strategy
	STRATEGY_ADVANCED_EXPERT_USER_PARAMS_MAPPING = 'STRATEGY_ADVANCED_EXPERT_USER_PARAMS_MAPPING',
	STRATEGY_LOAD = 'STRATEGY_LOAD',

	// AutoOnboarding
	AUTO_ONBOARDING_PARTNER_SCHEMA = 'AUTO_ONBOARDING_PARTNER_SCHEMA',
	AUTO_ONBOARDING_GET_PROGRESS = 'AUTO_ONBOARDING_GET_PROGRESS',

	GET_MINIMUM_STAY_RULES = 'GET_MINIMUM_STAY_RULES',
}
