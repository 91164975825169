import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { NumericVariationType } from '../submodules/sharedTypes/common/NumericVariationType'
import { Price } from '../submodules/sharedTypes/common/Price'
import { KeyVal } from '../types/KeyVal'
import { modifiersConfig } from './../config/Modifiers'
import { Currency } from './../submodules/sharedTypes/common/Currency'
import { Modifiers, ModifierType, TweakModifierType } from './../submodules/sharedTypes/common/Modifiers'
import { utilNumber } from './UtilNumber'
class UtilModifiers {
	public toReadableArray(modifiers: Modifiers, keys: ModifierType[], currency: Currency): KeyVal<string>[] {
		return keys.map((key) => ({
			key: this.modifierText(key),
			val: this.modifierToString(key, modifiers[key]!, currency),
		}))
	}

	public getErrors(
		modifiers: Modifiers,
		associatedModifiers: Modifiers[] = []
	): {
		[k in ModifierType]: TranslationKey | undefined
	} {
		return {
			frozen: undefined,
			max: this.hasMaxModifierError(modifiers, associatedModifiers)
				? TranslationKeys.MODIFIERS_MAX_MIN_ERROR
				: undefined,
			min: this.hasMinModifierError(modifiers, associatedModifiers)
				? TranslationKeys.MODIFIERS_MIN_MAX_ERROR
				: undefined,
			tweak: undefined,
		}
	}

	public hasErrors(modifiers: Modifiers, associatedModifiers: Modifiers[] = []): boolean {
		const errors = this.getErrors(modifiers, associatedModifiers)

		return Object.values(errors).some((el) => el != undefined)
	}

	private hasMinModifierError(modifiers: Modifiers, associatedModifiers: Modifiers[] = []) {
		const allModifiers = [...associatedModifiers, modifiers]
		const lowestMaxModifier = allModifiers.reduce((acc: number, el: Modifiers) => {
			const currentVal = el[ModifierType.Max] || Number.MAX_VALUE
			return currentVal < acc ? currentVal : acc
		}, Number.MAX_VALUE)
		return lowestMaxModifier <= (modifiers.min || Number.MIN_VALUE)
	}

	private hasMaxModifierError(modifiers: Modifiers, associatedModifiers: Modifiers[] = []) {
		const allModifiers = [...associatedModifiers, modifiers]
		const highestMinModifier = allModifiers.reduce((acc: number, el: Modifiers) => {
			const currentVal = el[ModifierType.Min] || Number.MIN_VALUE
			return currentVal > acc ? currentVal : acc
		}, Number.MIN_VALUE)
		return highestMinModifier >= (modifiers.max || Number.MAX_VALUE)
	}

	public modifierToString(
		modifier: ModifierType,
		value: number | TweakModifierType,
		currency: Currency,
		withCents: boolean = false
	): string {
		const functionToUse = withCents ? utilNumber.toCurrencyWithCents : utilNumber.toCurrency
		if (modifier === ModifierType.Tweak) {
			const castedVal = value as TweakModifierType
			const sign = castedVal.variationValue > 0 ? '+ ' : '- '
			const absVariationValue = Math.abs(castedVal.variationValue)

			if (castedVal.variationType === NumericVariationType.Percentage) {
				return `${sign}${utilNumber.toPercentageWithCap(absVariationValue)}`
			}

			return `${sign}${functionToUse(absVariationValue, currency)}`
		}

		return functionToUse(Math.abs(value as number), currency)
	}

	tweakToTrackingType(tweak?: TweakModifierType) {
		if (tweak == undefined) {
			return ''
		}

		let tweakMessage = tweak!.variationValue > 0 ? 'raise_' : 'lower_'
		tweakMessage += tweak!.variationType === NumericVariationType.Percentage ? 'rate' : 'absolute'

		return tweakMessage
	}

	activeModifiers(price: Price): ModifierType[] {
		const activeModifiers = price?.activeModifiers || []
		const setModifiers = price?.modifiers || {}

		return activeModifiers.filter((el) => setModifiers[el] != undefined)
	}

	modifierTextColor(modifier: ModifierType) {
		return modifiersConfig[modifier].textColor
	}
	modifierIconColor(modifier: ModifierType) {
		return modifiersConfig[modifier].iconColor
	}
	modifierIcon(modifier: ModifierType) {
		return modifiersConfig[modifier].icon
	}
	modifierText(modifier: ModifierType) {
		return modifiersConfig[modifier].text
	}
}
export const utilModifiers = new UtilModifiers()
