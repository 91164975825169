import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { getISODate } from '../../utils/dates';
import {
  UpdateModifiersRequest,
  UpdateModifiersRequestPayload,
} from './UpdateModifiersRequest';

export class UpdateModifiersNetworkObject extends WebSocketNetworkObject<
  UpdateModifiersRequest,
  UpdateModifiersRequestPayload
> {
  constructor(params: UpdateModifiersRequest) {
    super(params, WSMessageEvent.UpdateModifiers);
  }

  override getParams(): UpdateModifiersRequestPayload {
    const { roomTypeId, date, modifiers } = this.params;
    return {
      roomTypeId,
      date: getISODate(date),
      modifiers,
    };
  }
}
