import {
	ChannelManagerName,
	type CompetitorId,
	type GetCompetitorsResponse,
	UpdateCompsetNetworkObject,
	UpdateCompsetOperation,
	GetSummaryResponse,
} from '@dev.smartpricing/sp-shared-network-freemium-types'
import { CompetitorGHT, AccommodationGHT } from '~/types/Competitor'
import { defineStore } from 'pinia'
import { useAccommodationsStore } from './accommodations'
import { TrackingMessages } from '~/constants/trackingMessages'
import { StorageKeys } from '~/constants/storageKeys'
import { AnonymizationStructure, SalesModesConfig } from '~/config/SalesModesConfig'
import { SalesModes } from '~/constants/salesMode'
import _ from 'lodash'
import { FeedbackId } from '../constants/FeedbackId'
import { Core } from '~/config/Core'
import { SpRange } from '#imports'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { stringDateToStartOfDay } from '~/utils/utilParsing'

export type MarkerPriceTrendData = {
	accommodation: { time: Date | undefined; value: number | undefined }[]
	smartpricingStrategy: { time: Date | undefined; value: number | undefined }[]
	market: { time: Date | undefined; value: number | undefined }[]
	marketMinMax: { time: Date | undefined; min: number | undefined; max: number | undefined }[]
	competitors: Record<string, { time: Date | undefined; value: number | undefined }[]>
}

export const useCompetitorsStore = defineStore('⚙️ competitors', () => {
	// state
	const selectedAccommodationId = ref<string>(
		useAccommodationsStore().accommodations.find((accommodation) =>
			isMarketIntelligenceEnabledOnAccommodation(accommodation)
		)!.id
	)
	const currentAccommodation = ref<AccommodationGHT | undefined>(undefined)
	const competitors = ref<CompetitorGHT[]>([])
	const marketPriceTrendDataRaw = shallowRef<GetSummaryResponse['marketPriceTrend'] | undefined>(undefined)

	const idToActiveLegendMap = ref<Record<string, boolean>>({
		['currentAccommodation']: true,
		['meanMarketTrend']: true,
		['smartpricingStrategy']: true,
		['minMaxMarketTrend']: true,
	})
	const selectedRange = ref<SpRange<Date>>({
		from: new Date(),
		to: addDays(new Date(), 364),
	})

	// loading
	const isLoading = ref<boolean>(false)
	const competitorsBeingRestored = ref<Map<CompetitorId, boolean>>(new Map())

	// actions for the websocket handler
	function setCurrentAccommodationFromResponse(response: AccommodationGHT) {
		currentAccommodation.value = response
	}

	function setCompetitorsFromResponse(response: GetCompetitorsResponse) {
		const alreadySelectedCompetitors = competitors.value
			.filter((competitor) => competitor.isSelected)
			.map((competitor) => competitor.id)

		const newCompetitors = response
			.map((competitor) => {
				const thumbnailURL = !!competitor?.thumbnailURL?.length
					? competitor.thumbnailURL
					: 'https://placehold.co/270x200'

				competitorsBeingRestored.value.has(competitor.id) && competitorsBeingRestored.value.delete(competitor.id)

				return {
					...competitor,
					thumbnailURL,
					_name: undefined,
					isSelected: alreadySelectedCompetitors.includes(competitor.id),
				} as CompetitorGHT
			})
			.sort((a, b) => {
				if (a.isDisabledByUser === b.isDisabledByUser) {
					return 0
				}

				return a.isDisabledByUser ? 1 : -1
			})

		competitors.value = newCompetitors
	}

	const setChartDataFromResponse = (res: GetSummaryResponse) => {
		marketPriceTrendDataRaw.value = res.marketPriceTrend
	}

	const marketPriceTrendData = computed(() => {
		if (marketPriceTrendDataRaw.value === undefined) return

		const generalData = marketPriceTrendDataRaw.value?.dates.reduce(
			(acc, date, index) => {
				if (
					!date ||
					!(
						endOfDay(stringDateToStartOfDay(date)) >= new Date(selectedRange.value.from) &&
						startOfDay(stringDateToStartOfDay(date)) <= new Date(selectedRange.value.to)
					)
				)
					return acc

				acc.accommodation.push({
					time: stringDateToStartOfDay(date),
					value: marketPriceTrendDataRaw.value?.accommodation[index],
				})

				acc.market.push({ time: stringDateToStartOfDay(date), value: marketPriceTrendDataRaw.value?.market[index] })

				acc.marketMinMax.push({
					time: stringDateToStartOfDay(date),
					max: marketPriceTrendDataRaw.value?.marketMax[index],
					min: marketPriceTrendDataRaw.value?.marketMin[index],
				})

				acc.smartpricingStrategy.push({
					time: stringDateToStartOfDay(date),
					value: marketPriceTrendDataRaw.value?.smartpricingStrategy[index],
				})

				return acc
			},
			{
				accommodation: [],
				smartpricingStrategy: [],
				market: [],
				marketMinMax: [],
			} as Omit<MarkerPriceTrendData, 'competitors'>
		)

		const competitorsParsed: Record<string, { time: Date | undefined; value: number | undefined }[]> = {}
		if (marketPriceTrendDataRaw.value?.competitors) {
			const competitorIdToData = Object.entries(marketPriceTrendDataRaw.value.competitors)

			const compIds = Object.keys(marketPriceTrendDataRaw.value.competitors)
			compIds.forEach((id) => (competitorsParsed[id] = []))

			competitorIdToData.forEach(([compId, data]) => {
				data.forEach((value, index) => {
					const date = marketPriceTrendDataRaw.value?.dates[index]
						? stringDateToStartOfDay(marketPriceTrendDataRaw.value.dates[index])
						: undefined

					if (
						!date ||
						!(
							endOfDay(date) >= new Date(selectedRange.value.from) &&
							startOfDay(date) <= new Date(selectedRange.value.to)
						)
					)
						return

					competitorsParsed[compId].push({
						time: date,
						value: value,
					})
				})
			})
		}

		return {
			competitors: competitorsParsed,
			...generalData,
		} as MarkerPriceTrendData
	})

	// generic actions
	function setSelectedAccommodation(id: string) {
		selectedAccommodationId.value = id
	}

	function toggleSelectedCompetitor(competitorId: CompetitorId) {
		const index = competitors.value.findIndex((competitor) => competitor.id === competitorId)

		if (index > -1) {
			competitors.value[index].isSelected = !competitors.value[index].isSelected
			idToActiveLegendMap.value[competitorId] = !idToActiveLegendMap.value[competitorId]
		}
	}

	async function addCompetitor(competitor: AccommodationGHT) {
		useFeedbackStore().requestFeedback(FeedbackId.Add3OrMoreCompetitors)

		// add for loading feedback
		competitors.value.unshift({
			...competitor,
			isAddedByUser: true,
			smartScore: 0,
			marketTrendChartData: undefined,
			relevance: 0,
			isSelected: true,
			isLoading: true,
		})

		utilTracking.track(TrackingMessages.COMPETITOR_ADD, {
			competitor_name: competitor.name,
			competitor_url: competitor.channelManagerURLs[ChannelManagerName.Booking],
			competitor_score: competitor.score,
			competitor_relevance: competitor.rating,
		})

		if (competitor.channelManagerURLs?.[ChannelManagerName.Booking]) {
			await utilGHT.simplRequest(
				new UpdateCompsetNetworkObject({
					accommodationId: selectedAccommodationId.value as unknown as number,
					operationType: UpdateCompsetOperation.Add,
					bookingId: competitor.id as number,
				})
			)
		}
	}

	async function removeCompetitor(index: number) {
		const competitorToRemove = competitors.value[index]

		competitorToRemove.isLoading = true

		if (competitorToRemove.id) {
			await utilGHT.simplRequest(
				new UpdateCompsetNetworkObject({
					accommodationId: selectedAccommodationId.value as unknown as number,
					operationType: UpdateCompsetOperation.Remove,
					bookingId: competitorToRemove.id as number,
				})
			)
		}
	}

	async function restoreCompetitor(index: number) {
		const competitorToRestore = competitors.value[index]

		competitorsBeingRestored.value.set(competitorToRestore.id, true)

		if (competitorToRestore.channelManagerURLs?.[ChannelManagerName.Booking]) {
			await utilGHT.simplRequest(
				new UpdateCompsetNetworkObject({
					accommodationId: selectedAccommodationId.value as unknown as number,
					operationType: UpdateCompsetOperation.Add,
					bookingId: competitorToRestore.id as number,
				})
			)
		}
	}

	async function resetCompetitors() {
		await utilGHT.simplRequest(
			new UpdateCompsetNetworkObject({
				accommodationId: selectedAccommodationId.value as unknown as number,
				operationType: UpdateCompsetOperation.Restore,
			})
		)
	}

	function setLoading(loading: boolean) {
		isLoading.value = loading
	}

	function $reset() {
		selectedAccommodationId.value = ''
		currentAccommodation.value = undefined
		competitors.value = []
		marketPriceTrendDataRaw.value = undefined
		idToActiveLegendMap.value = {
			['currentAccommodation']: true,
			['meanMarketTrend']: true,
			['smartpricingStrategy']: true,
			['minMaxMarketTrend']: true,
		}
		selectedRange.value = { from: new Date(), to: addDays(new Date(), 364) }
		isLoading.value = false
	}

	// computed
	const competitorHasData = computed(() =>
		competitors.value.reduce(
			(acc, competitor) => {
				acc[competitor.id] = !!marketPriceTrendData.value?.competitors[competitor.id]?.length
				return acc
			},
			{} as Record<string, boolean>
		)
	)

	const idToNameMap = computed<Record<string, string | undefined>>(() => ({
		['currentAccommodation']: currentAccommodation?.value?.name,
		['meanMarketTrend']: useLocale().translate(TranslationKeys.MEAN_MARKET_TREND),
		['smartpricingStrategy']: useLocale().translate(TranslationKeys.SMARTPRICING_STRATEGY),
		['minMaxMarketTrend']: useLocale().translate(TranslationKeys.MIN_MAX_MARKET),
		...competitors.value.reduce(
			(acc, competitor) => ({
				...acc,
				[competitor.id]: competitor.name,
			}),
			{}
		),
	}))

	const colorData = computed(() => {
		const competitorsColors: TailwindColorKeys[] = [
			'dark-red',
			'lavander',
			'water-blue',
			'main-blue',
			'light-blue',
			'light-orange',
			'light-yellow',
			'light-green',
			'gem-green',
		]

		const filtersOptions: { colors: TailwindColorKeys[]; weights: TailwindColorWeight[] }[] = [
			{ colors: competitorsColors, weights: [400] },
			{ colors: competitorsColors, weights: [600] },
			{ colors: competitorsColors, weights: [200] },
		]

		const seriesColor = getManyColorConfigWithContrast(ComponentColorConfig.ChartLegend, filtersOptions)
		const legendColors = getManyColorConfigWithContrast(ComponentColorConfig.ChartSerie, filtersOptions)
		const tooltipColors = getManyColorConfigWithContrast(ComponentColorConfig.ChartSerieTooltip, filtersOptions)

		return seriesColor.map((serieColor, index) => ({
			legend: legendColors[index],
			serie: serieColor,
			tooltip: tooltipColors[index],
		}))
	})

	const marketChartData = computed(() => [
		{
			id: 'currentAccommodation',
			data: marketPriceTrendData.value?.accommodation.map((point) => [point.time, point.value]),
			name: currentAccommodation?.value?.name ?? useLocale().translate(TranslationKeys.YOUR_ACCOMMODATION),
			showSymbol: false,
			type: 'line',
			color: Core.colors.charts.line.accommodation,
		},
		{
			id: 'meanMarketTrend',
			data: marketPriceTrendData.value?.market.map((point) => [point.time, point.value]),
			name: useLocale().translate(TranslationKeys.MEAN_MARKET_TREND),
			showSymbol: false,
			type: 'line',
			color: Core.colors.charts.line.marketTrend,
		},
		/* {
				id: 'smartpricingStrategy',
				data: marketPriceTrendData.value?.smartpricingStrategy.map((point) => [point.time, point.value]),
				name: useLocale().translate(TranslationKeys.SMARTPRICING_STRATEGY),
				showSymbol: false,
				type: 'line',
				color: Core.colors.charts.line.smartpricing,
			}, */
		{
			id: 'minMaxMarketTrend',
			data: marketPriceTrendData.value?.marketMinMax.map((point) => [point.time, point.min, point.max]),
			name: useLocale().translate(TranslationKeys.MIN_MAX_MARKET),
			type: 'custom',
			color: Core.colors.charts.line.marketThreshold,
			renderItem: function (params: any, api: any) {
				const points = marketPriceTrendData.value?.marketMinMax.map((dataPoint) => {
					const date = dataPoint.time
					const min = dataPoint.min
					const max = dataPoint.max

					const yMin = api.coord([date, min])
					const yMax = api.coord([date, max])

					return [yMin, yMax]
				})

				const polygonPoints: any[] = []
				points!.forEach((pointPair, index) => {
					polygonPoints.push(pointPair[0])
				})

				for (let i = points!.length - 1; i >= 0; i--) {
					polygonPoints.push(points![i][1])
				}

				return {
					type: 'group',
					children: [
						{
							type: 'polygon',
							shape: {
								points: polygonPoints,
							},
							style: api.style({
								fill: Core.colors.charts.line.marketThreshold.serie.hex,
								stroke: null,
							}),
						},
					],
				}
			},
		},
		...competitors.value
			.filter((competitor) => competitor.isSelected)
			.map((competitor, index) => ({
				id: competitor.id,
				name: competitor.name,
				type: 'line',
				showSymbol: false,
				color: colorData.value[index],
				data: marketPriceTrendData.value?.competitors[competitor.id]?.map((point) => [point.time, point.value]),
			})),
	])

	const legendChips = computed(() =>
		marketChartData.value.map((chartSerie) => ({
			legendActive: idToActiveLegendMap.value[chartSerie.id!],
			name: chartSerie.name,
			id: chartSerie.id,
			colorData: chartSerie.color,
		}))
	)

	const serieIdToColorData = computed(() =>
		marketChartData.value.reduce(
			(acc, serie) => {
				acc[serie.id as string] = serie.color
				return acc
			},
			{} as Record<
				string,
				{
					legend: globalThis.ColorConfig
					serie: globalThis.ColorConfig
					tooltip: globalThis.ColorConfig
				}
			>
		)
	)

	const maxDateRange = computed<SpRange<Date>>(() => ({
		from: new Date(),
		to: addDays(new Date(), 364),
	}))

	// utility private functions
	async function anonymizeDeanonymizeCurrentAccommodation(mode: 'anon' | 'deanon', config?: AnonymizationStructure) {
		if (!currentAccommodation.value) return
		const term = config ? useLocale().translate(config.accommodation) : ''

		const tempCurrentAccommodation = _.cloneDeep(toRaw(currentAccommodation.value))

		// @ts-ignore
		if (mode === 'anon') {
			// @ts-ignore
			if (!tempCurrentAccommodation?.demoMode) {
				//@ts-ignore
				tempCurrentAccommodation._name = tempCurrentAccommodation?.name
			}
			// @ts-ignore
			tempCurrentAccommodation.name = term
			// @ts-ignore
			tempCurrentAccommodation.demoMode = true
			// @ts-ignore
		} else {
			// @ts-ignore
			tempCurrentAccommodation.name = tempCurrentAccommodation._name
			// @ts-ignore
			tempCurrentAccommodation.demoMode = false
		}

		currentAccommodation.value = tempCurrentAccommodation
	}

	watch(
		useLocale().currentLocale,
		() => {
			if (UtilSales.isSalesMode.value) {
				const selectedMode = localStorage.getItem(StorageKeys.SalesMode)! as SalesModes
				const config = SalesModesConfig[selectedMode]

				anonymizeDeanonymizeCurrentAccommodation('anon', config)
			}
		},
		{ immediate: true }
	)

	return {
		// state
		selectedAccommodationId,
		currentAccommodation,
		competitors,
		marketPriceTrendData,
		idToActiveLegendMap,
		selectedRange,

		isLoading,
		competitorsBeingRestored,

		// actions
		setCurrentAccommodationFromResponse,
		setCompetitorsFromResponse,
		setChartDataFromResponse,

		setSelectedAccommodation,
		toggleSelectedCompetitor,

		addCompetitor,
		removeCompetitor,
		restoreCompetitor,
		resetCompetitors,

		anonymizeDeanonymizeCurrentAccommodation,
		setLoading,

		// computed
		marketChartData,
		competitorHasData,
		colorData,
		idToNameMap,
		legendChips,
		serieIdToColorData,
		maxDateRange,

		// pinia
		$reset,
	}
})
