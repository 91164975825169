import axios from 'axios'
import { useStorage } from '@vueuse/core'

export function useMaintenanceMode() {
	// forced disable maintenance mode
	const ignoreMaintenanceMode = useStorage('ignoreMaintenanceMode', false)
	const maintenanceMode = useStorage('maintenanceMode', false)

	function disableMaintenanceMode() {
		ignoreMaintenanceMode.value = true
		navigateTo('/')
		checkForMaintenance()
	}

	function enableMaintenanceMode() {
		ignoreMaintenanceMode.value = false
		checkForMaintenance()
	}

	// @ts-ignore
	if (!window?.disableMaintenanceMode) window.disableMaintenanceMode = disableMaintenanceMode
	// @ts-ignore
	if (!window?.enableMaintenanceMode) window.enableMaintenanceMode = enableMaintenanceMode

	// maintenance mode

	const checkForMaintenance = async () => {
		try {
			if (ignoreMaintenanceMode.value) return

			const { data } = await axios.get(useRuntimeConfig().public.API_BASE_URL + '/maintenance')
			maintenanceMode.value = data.data.status

			const isAuthRoute = useRouter().currentRoute.value.path.includes('auth')
			const isMaintenancePage = useRouter().currentRoute.value.path.includes('maintenance')
			if (maintenanceMode.value && !isAuthRoute && !isMaintenancePage) {
				await utilLogout.logout()
			}
			if (maintenanceMode.value && !isMaintenancePage) {
				await navigateTo('/maintenance')
			}

			if (!maintenanceMode.value && isMaintenancePage) {
				await navigateTo('/calendar')
			}
		} catch (e) {}
	}

	onMounted(async () => {
		if (import.meta.client) {
			await checkForMaintenance()
			setInterval(async () => {
				await checkForMaintenance()
			}, 60000)
		}
	})
}
