import { NumericVariationType } from './NumericVariationType';

export enum ModifierType {
  Frozen = 'frozen',
  Max = 'max',
  Min = 'min',
  Tweak = 'tweak',
}

export enum ModifierKey {
  Tweak = 'customer-adjustments',
  ThresholdPrevent = 'threshold-prevent',
  FrozenPrice = 'frozen-price',
  IndexedPrice = 'indexed-price',
}

export type TweakModifierType = {
  // variation value, could positive or negative, if variationType is percentage is a value between 0 and 1
  variationValue: number;
  // type of variation
  variationType: NumericVariationType;
  // the final price after the variation
  variationPrice?: number;
  // starting price for the variation
  targetPrice?: number;
};

export type Modifiers = {
  [ModifierType.Frozen]?: number;
  [ModifierType.Min]?: number;
  [ModifierType.Max]?: number;
  [ModifierType.Tweak]?: TweakModifierType;
};
