import { UrlParams } from '~/constants/urlParams'
import { StorageKeys } from '../constants/storageKeys'
import { handleAccountingToken, handleGhtDemoToken } from '@/utils/utilToken'
import { useStorage } from '@vueuse/core'

export default defineNuxtRouteMiddleware(async (to, from) => {
	const { path, query } = from
	let queryChanged = false
	const maintenanceMode = useStorage('maintenanceMode', false)

	const accountingToken = query[UrlParams.accountingToken]
	if (accountingToken != undefined) {
		// keep in mind using toString automatically applies url.decode()
		await handleAccountingToken(accountingToken.toString().replace(`${UrlParams.accountingToken}=`, ''))

		delete query[UrlParams.accountingToken]
		queryChanged = true
	}

	const ghtDemoToken = query[UrlParams.ghtDemoToken]

	if (ghtDemoToken != undefined) {
		await handleGhtDemoToken(ghtDemoToken.toString().replace(`${UrlParams.ghtDemoToken}=`, ''))

		delete query[UrlParams.ghtDemoToken]
		queryChanged = true
	}

	// TODO: move to router-params once it is fixed
	const paramsToCheck = [StorageKeys.SessionToken, StorageKeys.RefreshToken]

	for (let key of paramsToCheck) {
		if (query[key]) {
			localStorage.setItem(key, query[key]!.toString())
			delete query[key]
			queryChanged = true
		}
	}
	// TODO: validateUser and logout if not valid

	// TODO: remove in router-params
	if (queryChanged) {
		if (!maintenanceMode.value) {
			return navigateTo({ path, query })
		}
	}
})
