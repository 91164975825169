import { ECElementEvent, ElementEvent } from 'echarts'
import {
	Strategy as StrategySharedType,
	StrategyPeriod as StrategyPeriodSharedType,
	OccupancyInterval,
	BookDaysInterval,
	ExpectedChangeType,
} from './../submodules/sharedTypes/common/Strategy'

export enum StrategyChartClickType {
	BLANK = 'blank',
	MARK_AREA = 'markArea',
}

export type StrategyPeriod = StrategyPeriodSharedType & {
	id: string
}

export type Strategy = Omit<StrategySharedType, 'periods'> & {
	periods: StrategyPeriod[]
}

export type StrategyChartLineDataPoint = {
	time: Date
	value: number
}

export type StrategyChartMarkAreaDataPoint = {
	from: Date
	to: Date
}

export type StrategyChartLineData = {
	id: string
	label: string
	colorConfig: {
		serie: ColorConfig
		legend: ColorConfig
		tooltip: ColorConfig
	}
	lineConfig?: {
		type: 'solid' | 'dotted'
	}
	visible?: boolean
	data: StrategyChartLineDataPoint[]
}

export type StrategyChartMarkAreaData = {
	id: string
	colorConfig: {
		markArea: string
	}
	visible?: boolean
	data: StrategyChartMarkAreaDataPoint
}

export type ClickToEventType = { blank: ElementEvent; markArea: ECElementEvent }
export type StrategyChartInteractiveTooltipArgsType = {
	[K in keyof ClickToEventType]: [clickType: K, event: ClickToEventType[K]]
}[keyof ClickToEventType]

export enum AdvancedStrategySections {
	DropPickup = 'Drop & Pickup',
	DayOfTheWeek = 'Day of the week',
	EventsHolidays = 'Events & Holidays',
	StartingPrice = 'Starting Price',
	MarketDemand = 'Market Demand',
}

export type OccupancyIntervalWithErrors = Omit<OccupancyInterval, 'from' | 'to' | 'expectedChange'> & {
	id: string

	from?: string
	fromError: boolean
	fromDirty: boolean

	to?: string
	toError: boolean
	toDirty: boolean

	expectedChange: {
		type: ExpectedChangeType
		value?: string
		valueDirty: boolean
	}
}

export type BookDaysIntervalWithErrors = Omit<BookDaysInterval, 'from' | 'to' | 'occupancyInterval'> & {
	from?: string
	fromError: boolean
	fromDirty: boolean

	to?: string
	toError: boolean
	toDirty: boolean

	occupancyInterval: OccupancyIntervalWithErrors[]
}

export type DropPickupRuleWithErrors = {
	id: string
	bookDaysInterval: BookDaysIntervalWithErrors
}
