import { io, Socket } from 'socket.io-client'
import { Environments } from '../constants/environments'
import { LoadingIds } from '../constants/loadingIds'
import { StorageKeys } from '../constants/storageKeys'
import { WSMessageEvent } from '../submodules/sharedTypes/common/WebSocket'
import { StandardWebSocketObject } from '../submodules/sharedTypes/communication/standardWebSocketObject'
import { ISocketHandler } from './../networkListeners/socketListenersMap'
import { useLoadingStore } from './../store/loading'
import { TokenExpiredErrorOnMessageResponse } from './../submodules/sharedTypes/communication/common/TokenExpiredErrorOnMessageResponse'
import { UtilSales } from './UtilSales'
import mixpanel from 'mixpanel-browser'

interface IQueuedWSMessage {
	message: WSMessageEvent
	payload: any
	connectionString: string
	loadingId: LoadingIds
}

class UtilWebSocket {
	private activeConnections = new Map<String, Socket>()
	private messageHandlers = new Map<string, Map<WSMessageEvent, ISocketHandler>>()
	private tokenValid = true
	private requestQueue: IQueuedWSMessage[] = []

	private async getConnectionOrCreate(connectionString: string): Promise<Socket> {
		if (!this.activeConnections.has(connectionString)) {
			const environment = useRuntimeConfig().public.ENVIRONMENT
			const socket = io(connectionString, {
				transports: ['websocket'],
				auth: {
					token: localStorage.getItem(StorageKeys.SessionToken),
				},
				withCredentials: environment === Environments.Production || environment === Environments.Playground,
			})

			socket.on('connect_error', async (err) => {
				const cookieList = document.cookie.split('; ')
				const newMixpanelCookies = cookieList
					.filter((c) => !c.startsWith('mp_'))
					.map((c) => c + '=; Max-Age=0')
					.join('; ')
				document.cookie = newMixpanelCookies
			})

			socket.on('message', ({ event, data }) => {
				// convert dates
				parseDatesFromBackend(data)

				const socketHandler = this.messageHandlers.get(connectionString)?.get(event)

				if (environment === Environments.Development || environment === Environments.Staging) {
					log(['Event received', event, data], 'Socket')
				}
				if (socketHandler == undefined || socketHandler.handler == undefined) {
					log(['Warning! Message ', event, ' was not handled since a listener is not configured!'], 'Socket')
				} else {
					socketHandler.handler(data, connectionString)
					if (socketHandler.loadingId) {
						useLoadingStore().removeLoading(socketHandler.loadingId)
					}
				}
			})

			socket.on('connect', () => {
				this.dequeueRequests()
			})

			socket.connect()

			this.activeConnections.set(connectionString, socket)

			// without this, the first send is lost
			await setTimeout(() => {}, 100)
		}

		return this.activeConnections.get(connectionString)!
	}

	public async setMessageHandlers(
		events: Map<WSMessageEvent, ISocketHandler>,
		connectionString: string = useRuntimeConfig().public.WS_BASE_URL
	) {
		// pre-emptively start a connection if it is not available
		await this.getConnectionOrCreate(connectionString)
		this.messageHandlers.set(connectionString, events)
	}

	public async sendEvent(
		event: WSMessageEvent,
		data: any,
		loadingId: LoadingIds,
		connectionString: string = useRuntimeConfig().public.WS_BASE_URL
	) {
		const environment = useRuntimeConfig().public.ENVIRONMENT

		if (this.tokenValid == false) {
			if (environment === Environments.Development || environment === Environments.Staging) {
				log('Token expired', 'Socket')
			}

			this.requestQueue.push({
				connectionString,
				loadingId,
				message: event,
				payload: data,
			})
		}

		useLoadingStore().addLoading(loadingId)

		if (environment === Environments.Development || environment === Environments.Staging) {
			log(['Sending event:  ', event, ' with data: ', data], 'Socket')
		}

		const connection = await this.getConnectionOrCreate(connectionString)
		const message: StandardWebSocketObject = {
			event,
			data,
			smartpricingParams: {
				SalesMode: UtilSales.isSalesMode.value,
			},
		}
		connection.send(message)
	}

	public killConnection(connectionString: string = useRuntimeConfig().public.WS_BASE_URL) {
		if (this.activeConnections.has(connectionString)) {
			this.activeConnections.get(connectionString)!.close()
			this.activeConnections.delete(connectionString)
		}
	}

	public invalidateToken(
		connectionString: string = useRuntimeConfig().public.WS_BASE_URL,
		requestToRepeat: TokenExpiredErrorOnMessageResponse | undefined = undefined
	) {
		this.tokenValid = false

		if (requestToRepeat != undefined) {
			this.requestQueue.push({
				connectionString,
				payload: requestToRepeat.data,
				message: requestToRepeat.event,
				loadingId: LoadingIds.NONE,
			})
		}

		// this prevents random reconnects
		this.activeConnections.get(connectionString)?.disconnect()
		this.activeConnections.delete(connectionString)
	}

	public confirmToken(connectionString: string = useRuntimeConfig().public.WS_BASE_URL) {
		this.tokenValid = true
		this.getConnectionOrCreate(connectionString)
	}

	public dequeueRequests() {
		const queuedCalls = [...this.requestQueue]
		this.requestQueue = []
		queuedCalls.forEach((request) => {
			this.sendEvent(request.message, request.payload, request.loadingId, request.connectionString)
		})
	}
}

export const utilWebSocket = new UtilWebSocket()
