export enum FeedbackId {
	StrategyChange = 'strategy-change',
	BulkPriceCustomization = 'bulk-price-customization',
	EventConfirm = 'event-confirm',
	Add3OrMoreCompetitors = 'add-3-or-more-competitors',
	CompetitorsMapZoomInOrOut = 'competitors-map-zoom-in-or-out',
	CompetitorsChartInteraction = 'competitors-chart-interaction',
	PriceListSave = 'price-list-save',
	DashboardKPISCompared = 'dashboard-kpis-compared',
	ChartsHover = 'charts-hover',
	StrategyAdvancedChange = 'strategy-advanced-change',
	MinStayChange = 'min-stay-change',
	DropPickupCustomRule = 'drop-pickup-custom-rule',
}
