import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../utils/dates';
import {
  GetOrphanNightsFixedDatesRequest,
  GetOrphanNightsFixedDatesRequestPayload,
} from './GetOrphanNightsFixedDatesRequest';

export class GetOrphanNightsFixedDatesNetworkObject extends WebSocketNetworkObject<
  GetOrphanNightsFixedDatesRequest,
  GetOrphanNightsFixedDatesRequestPayload
> {
  constructor(params: GetOrphanNightsFixedDatesRequest) {
    super(params, WSMessageEvent.GetOrphanNightsFixedDates);
  }

  override getParams(): GetOrphanNightsFixedDatesRequestPayload {
    const { accommodationIds, dateRange } = this.params;
    return {
      accommodationIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
