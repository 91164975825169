import { Currency } from '../submodules/sharedTypes/common/Currency'
import { Range } from '../submodules/sharedTypes/common/Range'
import { utilCurrency } from './utilCurrency'

// TODO: find a way to standardize toLocaleString currency param value
class UtilNumber {
	toCurrency(val: number | undefined, currency: Currency) {
		if (val == undefined) {
			return this.emptyCurrency(currency)
		}
		return val.toLocaleString(currency.locale, {
			maximumFractionDigits: 0,
			style: 'currency',
			currency: currency.code,
		})
	}

	toCurrencyWithoutSymbol(val: number | undefined, currency: Currency) {
		return this.toCurrency(val, currency).replaceAll(utilCurrency.currentCurrency(currency), '').trim()
	}

	shortenNumber(val: number) {
		if (val <= 99999) return val
		if (val <= 99999999) return Math.floor(val / 1000)
		return Math.floor(val / 1000000)
	}

	getShortenedSymbol(val: number) {
		if (val <= 99999) return ''
		if (val <= 99999999) return 'K'
		return 'M'
	}

	toCurrencyWithCents(val: number | undefined, currency: Currency) {
		if (val == undefined) {
			return this.emptyCurrency(currency)
		}
		return val.toLocaleString(currency.locale, {
			maximumFractionDigits: 5,
			style: 'currency',
			currency: currency.code,
		})
	}

	private emptyCurrency(currency: Currency) {
		const temp = 0
		return temp
			.toLocaleString(currency.locale, {
				maximumFractionDigits: 0,
				style: 'currency',
				currency: currency.code,
			})
			.replace('0', '-')
	}

	toPercentage(val: number, decimals = 0, addPlusSign = false) {
		const perc = val.toLocaleString(useLocale().currentLocale.value, {
			style: 'percent',
			maximumFractionDigits: decimals,
		})

		return val > 0 && addPlusSign ? `+${perc}` : perc
	}

	toPercentageWithCap(val: number, cap: number = 1) {
		return this.toPercentage(Math.min(val, cap))
	}

	comparisonPercentage(source: number, comparison: number, maxDecimals: number = 2) {
		const decimalAdjust = Math.pow(10, maxDecimals)

		const difference = comparison - source
		let percentageDifference = (difference * 100) / source
		percentageDifference *= decimalAdjust
		percentageDifference = Math.round(percentageDifference)

		return percentageDifference / decimalAdjust
	}

	clamp(num: number, range: Range<number>) {
		return Math.max(range.from, Math.min(num, range.to))
	}

	remapValue(value: number, fromRange: readonly [number, number], toRange: readonly [number, number]) {
		let valueToRemap = value
		const [minInitialRange, maxInitialRange] = fromRange
		const [minNewRange, maxNewRange] = toRange

		// Ensure the value is within the source range
		valueToRemap = Math.min(Math.max(value, minInitialRange), maxInitialRange)

		// Calculate the normalized value (between 0 and 1) in the source range
		const normalizedValue = (valueToRemap - minInitialRange) / (maxInitialRange - minInitialRange)

		// Map the normalized value to the target range
		const mappedValue = normalizedValue * (maxNewRange - minNewRange) + minNewRange

		return mappedValue
	}

	// Remap symmetric differs from remapValue because uses the 0 as the center of the range.
	// Example: Input range:[-90, 500] to output range: [-1, 1]
	// With remapValue, the output range would with 0 as input would be something like -0,37
	// But we want to remap the values symmetrically, so the output range with 0 as input would be 0
	remapSymmetric(
		value: number,
		negativeFromRange: readonly [number, number],
		positiveFromRange: readonly [number, number],
		negativeToRange: readonly [number, number],
		positiveToRange: readonly [number, number]
	) {
		if (value < 0) {
			return (
				((value - negativeFromRange[1]) * (negativeToRange[1] - negativeToRange[0])) /
					(negativeFromRange[1] - negativeFromRange[0]) +
				0
			)
		} else {
			return (
				((value - positiveFromRange[0]) * (positiveToRange[1] - positiveToRange[0])) /
					(positiveFromRange[1] - positiveFromRange[0]) +
				0
			)
		}
	}
}

export const utilNumber = new UtilNumber()
