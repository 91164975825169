import { defineStore } from 'pinia'
import {
	BasePrice,
	BasePricePredictionPreviewType,
	BasePriceVariation,
} from '../submodules/sharedTypes/common/BasePrice'
import { basePricesConfiguration } from '../config/BasePrices'
import { GetAccommodationBasePricesResponse } from '../submodules/sharedTypes/communication/base-prices/get/GetAccommodationBasePricesResponse'
import { useAccommodationsStore } from './accommodations'
import { Range } from '../submodules/sharedTypes/common/Range'
import { GetAccommodationBasePricesNetworkObject } from '../submodules/sharedTypes/communication/base-prices/get/GetAccommodationBasePricesNetworkObject'
import { GetAccommodationBasePricesPredictionPreviewResponse } from '../submodules/sharedTypes/communication/base-prices/preview/GetAccommodationBasePricesPredictionPreviewResponse'
import { GetAccommodationBasePricesPredictionPreviewNetworkObject } from '../submodules/sharedTypes/communication/base-prices/preview/GetAccommodationBasePricesPredictionPreviewNetworkObject'
import { UpdateAccommodationBasePricesNetworkObject } from '../submodules/sharedTypes/communication/base-prices/update/UpdateAccommodationBasePricesNetworkObject'
import { useNotificationsStore } from './notifications'
import { CardTypes } from '../constants/cardTypes'
import { TrackingMessages } from '../constants/trackingMessages'
import {
	addDays,
	differenceInCalendarDays,
	eachDayOfInterval,
	formatISO,
	getISODay,
	isAfter,
	isBefore,
	isPast,
	isToday,
} from 'date-fns'
import {
	BasePriceVariationSettings,
	BasePriceEdited,
	BasePricePreviewList,
	BasePriceSourceType,
	BasePrices,
	BasePricesEdited,
	BasePricesVariations,
	CurrentlyEditingBasePrice,
} from '../types/StartingPrices'
import { computeValidBasePricesFromEdited } from '../utils/utilBasePriceNew'
import { LineChartDataPoint } from '../types/Chart'
import { NumericVariationType } from '../submodules/sharedTypes/common/NumericVariationType'
import { cloneDeep, isEqual } from 'lodash'
import { FeedbackId } from '../constants/FeedbackId'

export const defaultEditingBasePrice: CurrentlyEditingBasePrice = {
	accommodationId: -1,
	roomTypeId: -1,
	basePrice: {} as BasePriceEdited,
}

export const defaultVariationSettings: BasePriceVariationSettings = {
	variationType: undefined,
	variationValue: undefined,
	targetRoomTypeId: undefined,
}

export const useBasePricesStore = defineStore('💵 Base prices new', () => {
	// Store
	const basePrices = ref<BasePrices>(new Map())
	const basePricesEdited = ref<BasePricesEdited>(new Map())

	const basePricesVariations = ref<BasePricesVariations>(new Map())
	const basePricesVariationsEdited = ref<BasePricesVariations>(new Map())

	const selectedAccommodationId = computed(() => useSettingsStore().selectedAccommodation?.id || -1)
	const selectedRoomTypeId = computed(() => useSettingsStore().selectedRoomType?.id || -1)
	const selectedBasePrices = ref<Range<Date>[]>([])

	const currentlyEditingBasePrice = ref<CurrentlyEditingBasePrice>(defaultEditingBasePrice)

	const basePricePreviewPeriods = ref<number[]>([0])
	const basePricePreviewList = ref<BasePricePreviewList>({
		[BasePricePredictionPreviewType.Regular]: new Map(),
		[BasePricePredictionPreviewType.Temporary]: new Map(),
	})

	const isLoadingRegularPreview = ref(false)
	const isLoadingTemporaryPreview = ref(false)

	const minimumDaysInRange = ref(basePricesConfiguration.minimumDaysInRange)

	const currentBasePriceSource = ref<BasePriceSourceType>(BasePriceSourceType.StartingPrices)

	// Actions
	const fetchBasePrices = (accommodationId: number) => {
		if (accommodationId === undefined || accommodationId <= 0) {
			return
		}

		setIsLoadingRegularPreview(true)
		utilNetwork.simpleRequest(new GetAccommodationBasePricesNetworkObject({ accommodationId }))
	}
	const fetchBasePricesPreview = (currentlyEditingBasePrice?: BasePriceEdited) => {
		let basePricesToPreview: BasePriceEdited[] =
			currentlyEditingBasePrice !== undefined && !isEqual(currentlyEditingBasePrice, defaultEditingBasePrice)
				? handleNewBasePriceInsertion(currentlyEditingBasePrice)
				: getSelectedRoomTypeEditedBasePrices.value || []

		if (!hasBasePriceGaps(basePricesToPreview)) {
			if (
				getCurrentVariationEditedSettings.value !== undefined &&
				!isEqual(getCurrentVariationEditedSettings.value, defaultVariationSettings) &&
				!isEqual(getCurrentVariationEditedSettings.value, getCurrentVariationSettings.value)
			) {
				basePricesToPreview =
					basePricesEdited.value
						?.get(selectedAccommodationId.value)
						?.get(getCurrentVariationEditedSettings.value.targetRoomTypeId!)
						?.map((basePrice) => {
							let basePricePrice = basePrice.price || undefined

							if (
								basePricePrice !== undefined &&
								getCurrentVariationEditedSettings.value &&
								!isPast(basePrice.dateRange.to)
							) {
								switch (getCurrentVariationEditedSettings.value.variationType) {
									case NumericVariationType.Percentage:
										basePricePrice =
											basePricePrice * (1 + (getCurrentVariationEditedSettings.value.variationValue || 0))
										break
									case NumericVariationType.Amount:
										basePricePrice = basePricePrice + (getCurrentVariationEditedSettings.value.variationValue || 0)
										break
									default:
										break
								}
							}

							return {
								dateRange: basePrice.dateRange,
								price: basePricePrice,
								isGap: basePrice.isGap,
							}
						}) || []
			}

			const basePricesToPreviewFormatted = {
				id: selectedRoomTypeId.value,
				basePrices: computeValidBasePricesFromEdited(basePricesToPreview),
			}

			if (basePricesToPreviewFormatted.basePrices.length > 0) {
				setIsLoadingTemporaryPreview(true)
				utilNetwork.simpleRequest(
					new GetAccommodationBasePricesPredictionPreviewNetworkObject({
						accommodationId: selectedAccommodationId.value,
						previewType: BasePricePredictionPreviewType.Temporary,
						roomTypes: [basePricesToPreviewFormatted],
					})
				)
			}
		}
	}

	const addBasePrice = (newBasePrice: BasePrice) => {
		const basePricesAfterInsertion = handleNewBasePriceInsertion(newBasePrice)

		basePricesEdited.value.get(selectedAccommodationId.value)!.set(selectedRoomTypeId.value, basePricesAfterInsertion)

		useNotificationsStore().addNotification({
			canClose: true,
			cardType: CardTypes.SUCCESS,
			title: TranslationKeys.BASE_PRICES_ADDED,
			message: TranslationKeys.BASE_PRICES_ADDED_TEXT,
			messageReplacements: [
				utilNumber.toCurrency(
					newBasePrice.price,
					useAccommodationsStore().getAccommodationById(selectedAccommodationId.value)?.currency || { code: 'eur' }
				),
				utilDate.formatDate(newBasePrice.dateRange.from),
				utilDate.formatDate(newBasePrice.dateRange.to),
				useAccommodationsStore().getRoomTypeById(selectedRoomTypeId.value)?.name || '',
			],
		})

		utilTracking.track(TrackingMessages.PRICE_PERIOD_SAVE, {
			days_num: utilDate.daysDifference(newBasePrice.dateRange),
		})
	}
	const setBasePrices = (res: GetAccommodationBasePricesResponse) => {
		const { accommodationId, roomTypes: basePricesByRoomTypeInRes, minDaysInRange: minimumDaysInRangeFromRes } = res

		let accommodationRoomTypesWithBasePrices = getSelectedAccommodationRoomTypesWithBasePrices.value
		let accommodationRoomTypesWithEditedBasePrices = getSelectedAccommodationRoomTypesWithEditedBasePrices.value

		if (!accommodationRoomTypesWithBasePrices) {
			accommodationRoomTypesWithBasePrices = new Map()
			accommodationRoomTypesWithBasePrices.set(selectedAccommodationId.value, [])
		}

		if (!accommodationRoomTypesWithEditedBasePrices) {
			accommodationRoomTypesWithEditedBasePrices = new Map()
			accommodationRoomTypesWithEditedBasePrices.set(selectedAccommodationId.value, [])
		}

		const accommodationEditableRoomTypes = getBasePricesEditableRoomTypes.value?.map((roomType) => roomType.id)
		const accommodationReceivedRoomTypes = basePricesByRoomTypeInRes.filter((roomType) =>
			accommodationEditableRoomTypes?.includes(roomType.id)
		)

		minimumDaysInRange.value = minimumDaysInRangeFromRes || basePricesConfiguration.minimumDaysInRange

		basePrices.value.set(accommodationId, new Map())
		basePricesEdited.value.set(accommodationId, new Map())

		accommodationReceivedRoomTypes.forEach((roomType) => {
			accommodationRoomTypesWithBasePrices!.set(roomType.id, roomType.basePrices)
			accommodationRoomTypesWithEditedBasePrices!.set(roomType.id, roomType.basePrices)

			basePricesVariations.value.set(roomType.id, roomType.variation)
			basePricesVariationsEdited.value.set(roomType.id, roomType.variation)
		})

		basePrices.value.set(accommodationId, cloneDeep(accommodationRoomTypesWithBasePrices))
		basePricesEdited.value.set(accommodationId, cloneDeep(accommodationRoomTypesWithEditedBasePrices))
	}
	const setBasePricesPreview = (res: GetAccommodationBasePricesPredictionPreviewResponse) => {
		res.roomTypes.forEach((data) => {
			basePricePreviewList.value[res.previewType].set(data.id, data.dataPoints)
		})

		if (res.previewType === BasePricePredictionPreviewType.Regular) {
			setIsLoadingRegularPreview(false)
		}

		if (res.previewType === BasePricePredictionPreviewType.Temporary) {
			setIsLoadingTemporaryPreview(false)
		}
	}
	const setBasePricesPreviewPeriods = (newPeriods: number[]) => {
		basePricePreviewPeriods.value = newPeriods
	}

	const setBasePricesModifiers = (newBasePricesModifiers: BasePricesVariations) => {
		basePricesVariations.value = newBasePricesModifiers
	}

	const setSelectedBasePrices = (basePrices: Range<Date>[]) => {
		selectedBasePrices.value = basePrices
	}

	const setEditableBasePrice = (dateRange: Range<Date>) => {
		const selectedRoomTypeEditedBasePrices = getSelectedRoomTypeEditedBasePrices.value
		const selectedEditedBasePrices = selectedRoomTypeEditedBasePrices?.find(
			(basePrice) => basePrice.dateRange === dateRange
		)

		currentlyEditingBasePrice.value = {
			accommodationId: selectedRoomTypeId.value,
			roomTypeId: selectedRoomTypeId.value,
			basePrice: {
				dateRange: dateRange,
				price: selectedEditedBasePrices ? selectedEditedBasePrices.price : basePricesConfiguration.defaultBasePrice,
				isGap: selectedEditedBasePrices ? selectedEditedBasePrices.isGap : false,
			},
		}
	}
	const addEditableBasePrice = (newBasePrice: BasePriceEdited) => {
		const basePricesAfterInsertion = handleNewBasePriceInsertion(newBasePrice)
		basePricesEdited.value.get(selectedAccommodationId.value)!.set(selectedRoomTypeId.value, basePricesAfterInsertion)
	}
	const resetEditableBasePrice = () => {
		currentlyEditingBasePrice.value = defaultEditingBasePrice
	}

	const setCurrentBasePriceSource = (newBasePriceSource: BasePriceSourceType) => {
		currentBasePriceSource.value = newBasePriceSource
	}

	const setCurrentVariationSettings = (newVariationSettings: BasePriceVariationSettings | undefined) => {
		basePricesVariationsEdited.value.set(selectedRoomTypeId.value, newVariationSettings)
	}

	const deleteRoomTypeBasePrices = () => {
		let currentDate = new Date()
		let currentBasePrices = getSelectedRoomTypeEditedBasePrices.value

		if (!currentBasePrices) {
			return
		}

		const selectedBasePricesInThePast = selectedBasePrices.value.filter((basePrice) =>
			isBefore(basePrice.from, currentDate)
		)
		const selectedBasePricesInTheFuture = selectedBasePrices.value.filter(
			(basePrice) => isAfter(basePrice.from, currentDate) || isToday(basePrice.from)
		)

		selectedBasePricesInThePast.forEach((basePriceDateRange) => {
			currentBasePrices = handleNewBasePriceInsertion({
				dateRange: { from: currentDate, to: basePriceDateRange.to },
				price: undefined,
				isGap: true,
			})

			basePricesEdited.value.get(selectedAccommodationId.value)!.set(selectedRoomTypeId.value, currentBasePrices)
		})

		selectedBasePricesInTheFuture.forEach((basePriceDateRange) => {
			const selectedBasePriceIndex = currentBasePrices.findIndex(
				(basePrice) =>
					basePrice.dateRange.from === basePriceDateRange.from && basePrice.dateRange.to === basePriceDateRange.to
			)

			if (selectedBasePriceIndex !== -1) {
				if (selectedBasePriceIndex === currentBasePrices.length - 1) {
					const firstFutureBasePrice = currentBasePrices.find(
						(basePrice) => isAfter(basePrice.dateRange.to, currentDate) || isToday(basePrice.dateRange.to)
					)

					if (
						differenceInCalendarDays(
							currentBasePrices[currentBasePrices.length - 2].dateRange.to,
							isPast(firstFutureBasePrice?.dateRange.from!) ? currentDate : firstFutureBasePrice?.dateRange.from!
						) +
							1 >=
						basePricesConfiguration.minimumDaysToFillWithPrices
					) {
						currentBasePrices.pop()
					} else {
						const lastBasePriceDatesInRange = eachDayOfInterval({
							start: currentBasePrices[currentBasePrices.length - 1].dateRange.from,
							end: currentBasePrices[currentBasePrices.length - 1].dateRange.to,
						})

						let splitDate: Date | null = null

						lastBasePriceDatesInRange.forEach((date) => {
							if (
								differenceInCalendarDays(
									date,
									isPast(firstFutureBasePrice?.dateRange.from!) ? currentDate : firstFutureBasePrice?.dateRange.from!
								) +
									1 >=
									basePricesConfiguration.minimumDaysToFillWithPrices &&
								splitDate === null
							) {
								splitDate = date
							}
						})

						if (splitDate !== null) {
							currentBasePrices[currentBasePrices.length - 1].dateRange.to = splitDate

							if (splitDate === currentBasePrices[currentBasePrices.length - 1].dateRange.to) {
								currentBasePrices[currentBasePrices.length - 1].isGap = true
								currentBasePrices[currentBasePrices.length - 1].price = undefined
							} else {
								currentBasePrices.push({
									dateRange: {
										from: addDays(splitDate, 1),
										to: basePriceDateRange.to,
									},
									price: undefined,
									isGap: true,
								})
							}
						}
					}
				} else {
					currentBasePrices[selectedBasePriceIndex] = {
						dateRange: basePriceDateRange,
						price: undefined,
						isGap: true,
					}
				}
			}
		})

		basePricesEdited.value.get(selectedAccommodationId.value)!.set(selectedRoomTypeId.value, currentBasePrices)
	}
	const save = () => {
		if (!checkForChanges.value) {
			return
		}

		const currentAccommodationId = selectedAccommodationId.value
		const currentAccommodationRoomTypesWithEditedBasePrices = Array.from(
			getSelectedAccommodationRoomTypesWithEditedBasePrices.value || [],
			([roomTypeId, roomTypeBasePrices]) => {
				if (
					!isEqual(basePricesVariationsEdited.value.get(roomTypeId), defaultVariationSettings) &&
					basePricesVariationsEdited.value.get(roomTypeId)?.variationType !== undefined &&
					basePricesVariationsEdited.value.get(roomTypeId)?.variationValue !== undefined &&
					basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId !== undefined
				) {
					return {
						id: roomTypeId,
						basePrices: [],
						variation: basePricesVariationsEdited.value.get(roomTypeId) as BasePriceVariation,
					}
				} else {
					return {
						id: roomTypeId,
						basePrices: computeValidBasePricesFromEdited(roomTypeBasePrices),
					}
				}
			}
		)

		utilNetwork.simpleRequest(
			new UpdateAccommodationBasePricesNetworkObject({
				accommodationId: currentAccommodationId,
				roomTypes: currentAccommodationRoomTypesWithEditedBasePrices.filter(
					(roomType) => roomType.basePrices.length > 0 || roomType.variation !== undefined
				),
			})
		)

		useNotificationsStore().addNotification({
			canClose: true,
			cardType: CardTypes.SUCCESS,
			title: TranslationKeys.BASE_PRICES_SAVED,
			message: TranslationKeys.BASE_PRICES_SAVED_TEXT,
		})
		utilTracking.track(TrackingMessages.PRICE_LIST_SAVE, {
			is_successfull: getAccommodationHasErrors.value,
			is_derived: !!currentAccommodationRoomTypesWithEditedBasePrices.find(
				(test) => test.variation?.targetRoomTypeId !== undefined
			),
		})

		const roomTypeIds = getBasePricesEditableRoomTypes.value?.map((roomType) => roomType.id) || []
		for (const roomTypeId of roomTypeIds) {
			if (
				basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId !== undefined &&
				!isEqual(basePricesVariationsEdited.value.get(roomTypeId), defaultVariationSettings)
			) {
				const currentBasePrices =
					basePricesEdited.value
						.get(currentAccommodationId)
						?.get(basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId!) || []
				const basePricesToSave: BasePriceEdited[] = []

				for (const basePrice of currentBasePrices) {
					let basePricePrice =
						basePricesEdited.value
							.get(currentAccommodationId)
							?.get(basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId!)
							?.find((el) => isEqual(el.dateRange, basePrice.dateRange))?.price || undefined

					if (basePricePrice !== undefined && basePricesVariationsEdited.value.get(roomTypeId)) {
						switch (basePricesVariationsEdited.value.get(roomTypeId)?.variationType) {
							case NumericVariationType.Percentage:
								basePricePrice =
									basePricePrice * (1 + (basePricesVariationsEdited.value.get(roomTypeId)?.variationValue || 0))
								break
							case NumericVariationType.Amount:
								basePricePrice =
									basePricePrice + (basePricesVariationsEdited.value.get(roomTypeId)?.variationValue || 0)
								break
							default:
								break
						}
					}

					basePricesToSave.push({
						dateRange: basePrice.dateRange,
						price: basePricePrice,
					})
				}

				basePricesEdited.value.get(currentAccommodationId)?.set(roomTypeId, basePricesToSave)
			}

			basePrices.value
				.get(currentAccommodationId)
				?.set(
					roomTypeId,
					cloneDeep(
						computeValidBasePricesFromEdited(basePricesEdited.value.get(currentAccommodationId)?.get(roomTypeId) || [])
					)
				)
			basePricesVariations.value.set(roomTypeId, basePricesVariationsEdited.value.get(roomTypeId))
		}

		useFeedbackStore().requestFeedback(FeedbackId.PriceListSave)
	}

	const resetChanges = () => {
		const roomTypeIds = getBasePricesEditableRoomTypes.value?.map((roomType) => roomType.id) || []

		for (const roomTypeId of roomTypeIds) {
			basePricesEdited.value
				.get(selectedAccommodationId.value)
				?.set(roomTypeId, basePrices.value.get(selectedAccommodationId.value)?.get(roomTypeId) || [])

			basePricesVariationsEdited.value.set(roomTypeId, basePricesVariations.value.get(roomTypeId))
		}
	}

	const setIsLoadingRegularPreview = (isLoading: boolean) => {
		isLoadingRegularPreview.value = isLoading
	}
	const setIsLoadingTemporaryPreview = (isLoading: boolean) => {
		isLoadingTemporaryPreview.value = isLoading
	}

	// Getters
	const getSelectedAccommodationId = computed(() => selectedAccommodationId.value)
	const getSelectedRoomTypeId = computed(() => selectedRoomTypeId.value)
	const getSelectedBasePrices = computed(() => selectedBasePrices.value)

	const getSelectedAccommodationRoomTypesWithBasePrices = computed(() => {
		return basePrices.value.get(selectedAccommodationId.value)
	})
	const getSelectedRoomTypeBasePrices = computed(() => {
		return getSelectedAccommodationRoomTypesWithBasePrices.value?.get(selectedRoomTypeId.value) || []
	})
	const getSelectedAccommodationRoomTypesWithEditedBasePrices = computed(() => {
		return basePricesEdited.value.get(selectedAccommodationId.value)
	})
	const getSelectedRoomTypeEditedBasePrices = computed(() => {
		return getSelectedAccommodationRoomTypesWithEditedBasePrices.value?.get(selectedRoomTypeId.value) || []
	})

	const getBasePricesEditableRoomTypes = computed(() => {
		const accommodationsStore = useAccommodationsStore()
		const accommodation = accommodationsStore.accommodations.find(
			(accommodation) => accommodation.id === selectedAccommodationId.value
		)

		if (accommodation) {
			return accommodation.roomTypes
				?.filter((roomType) => !roomType.isIndexed && roomType.isPredictionEnabled)
				?.map((roomType) => {
					return { id: roomType.id, name: roomType.name }
				})
		}
		return []
	})

	const getAccommodationHasErrors = computed(() => {
		const selectedAccommodationRoomTypesWithEditedBasePrices =
			getSelectedAccommodationRoomTypesWithEditedBasePrices.value

		if (!selectedAccommodationRoomTypesWithEditedBasePrices) {
			return true
		}

		return Array.from(selectedAccommodationRoomTypesWithEditedBasePrices.keys()).some(
			(roomTypeId) =>
				getAccommodationRoomTypesWithErrors.value?.includes(roomTypeId) ||
				getAccommodationRoomTypesWithNegativePrices.value?.includes(roomTypeId)
		)
	})
	const getAccommodationRoomTypesWithErrors = computed(() => {
		if (!basePrices.value.has(selectedAccommodationId.value)) {
			return []
		}

		const roomTypeIds = getBasePricesEditableRoomTypes.value?.map((roomType) => roomType.id) || []
		const roomTypesWithErrors: number[] = []

		for (const roomTypeId of roomTypeIds) {
			const roomTypeBasePrices = getSelectedAccommodationRoomTypesWithEditedBasePrices.value!.get(roomTypeId)

			if (!roomTypeBasePrices) {
				continue
			}

			if (
				!isBasePricesRangeValid(roomTypeBasePrices) ||
				getAccommodationRoomTypesWithNegativePrices.value.includes(roomTypeId)
			) {
				roomTypesWithErrors.push(roomTypeId)
			}
		}

		return roomTypesWithErrors
	})
	const getAccommodationRoomTypesWithNegativePrices = computed(() => {
		const roomTypeIds = getBasePricesEditableRoomTypes.value?.map((roomType) => roomType.id) || []
		const roomTypesWithNegativePrices: number[] = []

		for (const roomTypeId of roomTypeIds) {
			const roomTypeBasePrices = getSelectedAccommodationRoomTypesWithEditedBasePrices.value!.get(roomTypeId)

			if (!roomTypeBasePrices) {
				continue
			}

			if (
				basePricesVariationsEdited.value.get(roomTypeId)?.variationType !== undefined &&
				!isEqual(basePricesVariationsEdited.value.get(roomTypeId), defaultVariationSettings)
			) {
				const currentBasePrices =
					basePricesEdited.value
						.get(getSelectedAccommodationId.value)
						?.get(basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId!)
						?.filter((basePrice) => !isPast(basePrice.dateRange.to)) || []

				for (const basePrice of currentBasePrices) {
					let basePricePrice =
						basePricesEdited.value
							.get(getSelectedAccommodationId.value)
							?.get(basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId!)
							?.find((el) => isEqual(el.dateRange, basePrice.dateRange))?.price || undefined

					if (basePricePrice !== undefined && basePricesVariationsEdited.value.get(roomTypeId)) {
						switch (basePricesVariationsEdited.value.get(roomTypeId)?.variationType) {
							case NumericVariationType.Percentage:
								basePricePrice =
									basePricePrice * (1 + (basePricesVariationsEdited.value.get(roomTypeId)?.variationValue || 0))
								break
							case NumericVariationType.Amount:
								basePricePrice =
									basePricePrice + (basePricesVariationsEdited.value.get(roomTypeId)?.variationValue || 0)
								break
							default:
								break
						}
					}

					if (basePricePrice !== undefined && basePricePrice <= 0) {
						roomTypesWithNegativePrices.push(roomTypeId)
						continue
					}
				}
			}
		}

		return roomTypesWithNegativePrices
	})

	const getBasePricesChartData = computed(() => (currentlyEditingBasePrice?: BasePriceEdited) => {
		return (roomTypeId: number, withVariation?: boolean) => {
			const basePricesToShow: BasePriceEdited[] =
				currentlyEditingBasePrice !== undefined
					? handleNewBasePriceInsertion(currentlyEditingBasePrice)
					: getSelectedAccommodationRoomTypesWithEditedBasePrices.value?.get(roomTypeId) || []
			const basePricesToShowFormatted: LineChartDataPoint[] = []

			for (const basePrice of basePricesToShow) {
				const datesInRange = eachDayOfInterval({
					start: basePrice.dateRange.from,
					end: basePrice.dateRange.to,
				})

				datesInRange.forEach((basePriceDate) => {
					let basePricePrice = basePrice.price || null

					if (basePricePrice !== null && withVariation && getCurrentVariationEditedSettings.value) {
						switch (getCurrentVariationEditedSettings.value.variationType) {
							case NumericVariationType.Percentage:
								basePricePrice = basePricePrice * (1 + (getCurrentVariationEditedSettings.value.variationValue || 0))
								break
							case NumericVariationType.Amount:
								basePricePrice = basePricePrice + (getCurrentVariationEditedSettings.value.variationValue || 0)
								break
							default:
								break
						}
					}

					basePricesToShowFormatted.push({
						time: basePriceDate,
						value: basePricePrice,
					})
				})
			}

			return basePricesToShowFormatted
		}
	})
	const getBasePricesGapsChartData = computed(() => {
		return (currentlyEditingBasePrice: BasePriceEdited) => {
			return handleNewBasePriceInsertion(currentlyEditingBasePrice)
				.filter((basePrice) => basePrice.isGap)
				.map((basePrice) => {
					return {
						from: basePrice.dateRange.from,
						to: basePrice.dateRange.to,
					}
				})
		}
	})
	const getBasePricesPreviewChartData = computed(() => {
		const previewTypeToShow = basePricePreviewList.value[BasePricePredictionPreviewType.Temporary].has(
			getSelectedRoomTypeId.value
		)
			? BasePricePredictionPreviewType.Temporary
			: BasePricePredictionPreviewType.Regular

		const previewBasePricesToShow = basePricePreviewList.value[previewTypeToShow]
			.get(getSelectedRoomTypeId.value)
			?.map((dataPoint) => {
				return {
					time: dataPoint.x,
					value: dataPoint.y,
				}
			})

		const dateRangeToCover = eachDayOfInterval({
			start: new Date(),
			end: getSelectedRoomTypeEditedBasePrices.value.at(-1)?.dateRange.to || addDays(new Date(), 500),
		})

		dateRangeToCover.forEach((date) => {
			if (!previewBasePricesToShow?.find((dataPoint) => isEqual(dataPoint.time, date))) {
				previewBasePricesToShow?.push({
					time: date,
					value: null,
				})
			}
		})

		return previewBasePricesToShow
	})

	const getCurrentBasePriceSource = computed(() => currentBasePriceSource.value)
	const getCurrentVariationSettings = computed(() => basePricesVariations.value.get(selectedRoomTypeId.value))
	const getCurrentVariationEditedSettings = computed(() =>
		basePricesVariationsEdited.value.get(selectedRoomTypeId.value)
	)

	const areAllBasePricesSelected = computed(() => {
		return (
			getSelectedRoomTypeEditedBasePrices.value.filter(
				(basePrice) => !basePrice.isGap && !isPast(basePrice.dateRange.to)
			).length === getSelectedBasePrices.value.length && getSelectedBasePrices.value.length > 0
		)
	})
	const isAtLeastOneBasePriceSelected = computed(() => {
		return !areAllBasePricesSelected.value && getSelectedBasePrices.value.length > 0
	})

	const isDeleteBasePriceDisabled = computed(
		() =>
			getSelectedBasePrices.value.length !== 1 ||
			getSelectedRoomTypeEditedBasePrices.value.some(
				(basePrice) => getSelectedBasePrices.value.includes(basePrice.dateRange) && basePrice.isGap
			)
	)
	const isDuplicateBasePriceDisabled = computed(
		() =>
			(!isAtLeastOneBasePriceSelected.value && !areAllBasePricesSelected.value) ||
			getSelectedRoomTypeEditedBasePrices.value.some(
				(basePrice) => getSelectedBasePrices.value.includes(basePrice.dateRange) && basePrice.isGap
			)
	)

	const getIsLoadingRegularPreview = computed(() => isLoadingRegularPreview.value)
	const getIsLoadingTemporaryPreview = computed(() => isLoadingTemporaryPreview.value)

	const checkForChanges = computed(() => {
		const roomTypeIds = getBasePricesEditableRoomTypes.value?.map((roomType) => roomType.id) || []

		for (const roomTypeId of roomTypeIds) {
			if (
				!isEqual(
					getSelectedAccommodationRoomTypesWithBasePrices.value?.get(roomTypeId),
					computeValidBasePricesFromEdited(
						getSelectedAccommodationRoomTypesWithEditedBasePrices.value?.get(roomTypeId) || []
					)
				)
			) {
				return true
			}

			if (
				(basePricesVariations.value.get(roomTypeId) === undefined &&
					basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId !== undefined) ||
				(basePricesVariations.value.get(roomTypeId) !== undefined &&
					(basePricesVariationsEdited.value.get(roomTypeId) === undefined ||
						(basePricesVariationsEdited.value.get(roomTypeId)?.targetRoomTypeId !== undefined &&
							!isEqual(basePricesVariations.value.get(roomTypeId), basePricesVariationsEdited.value.get(roomTypeId)))))
			) {
				return true
			}
		}

		return false
	})

	const checkForErrors = computed(() => {
		return getAccommodationHasErrors.value
	})

	const $reset = () => {
		basePrices.value.clear()
		basePricesEdited.value.clear()
		basePricesVariations.value.clear()
		basePricesVariationsEdited.value.clear()
		selectedBasePrices.value = []
		currentlyEditingBasePrice.value = defaultEditingBasePrice
		basePricePreviewPeriods.value = [0]
		basePricePreviewList.value = {
			[BasePricePredictionPreviewType.Regular]: new Map(),
			[BasePricePredictionPreviewType.Temporary]: new Map(),
		}
		isLoadingRegularPreview.value = false
		isLoadingTemporaryPreview.value = false
		minimumDaysInRange.value = basePricesConfiguration.minimumDaysInRange
		currentBasePriceSource.value = BasePriceSourceType.StartingPrices
	}

	return {
		basePrices,
		basePricesEdited,
		basePricesVariations,
		basePricesVariationsEdited,
		selectedAccommodationId,
		selectedRoomTypeId,
		selectedBasePrices,
		minimumDaysInRange,
		currentlyEditingBasePrice,

		basePricePreviewPeriods,
		basePricePreviewList,

		fetchBasePrices,
		fetchBasePricesPreview,

		addBasePrice,
		setBasePrices,

		setBasePricesPreviewPeriods,
		setBasePricesPreview,

		setBasePricesModifiers,

		setSelectedBasePrices,

		setEditableBasePrice,
		addEditableBasePrice,
		resetEditableBasePrice,

		setCurrentBasePriceSource,
		setCurrentVariationSettings,

		deleteRoomTypeBasePrices,

		getSelectedAccommodationId,
		getSelectedRoomTypeId,
		getSelectedBasePrices,

		getSelectedAccommodationRoomTypesWithBasePrices,
		getSelectedRoomTypeBasePrices,
		getSelectedAccommodationRoomTypesWithEditedBasePrices,
		getSelectedRoomTypeEditedBasePrices,

		getBasePricesEditableRoomTypes,

		getAccommodationHasErrors,
		getAccommodationRoomTypesWithErrors,
		getAccommodationRoomTypesWithNegativePrices,

		getBasePricesChartData,
		getBasePricesGapsChartData,
		getBasePricesPreviewChartData,

		getCurrentBasePriceSource,
		getCurrentVariationSettings,
		getCurrentVariationEditedSettings,

		areAllBasePricesSelected,
		isAtLeastOneBasePriceSelected,

		isDeleteBasePriceDisabled,
		isDuplicateBasePriceDisabled,

		getIsLoadingRegularPreview,
		getIsLoadingTemporaryPreview,

		save,
		resetChanges,
		checkForChanges,
		checkForErrors,

		setIsLoadingRegularPreview,
		setIsLoadingTemporaryPreview,

		$reset,
	}
})
