export const TranslationKeys = {
	'ORPHAN_NIGHTS_SETTINGS_EXPLANATION': 'ORPHAN_NIGHTS_SETTINGS_EXPLANATION',
	'AUTO_ONBOARDING_FIND_PROPERTY_INTRO_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_INTRO_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_INTRO_DESCRIPTION': 'AUTO_ONBOARDING_FIND_PROPERTY_INTRO_DESCRIPTION',
	'AUTO_ONBOARDING_FIND_PROPERTY_INTRO_START_BUTTON': 'AUTO_ONBOARDING_FIND_PROPERTY_INTRO_START_BUTTON',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_TYPE_OPTIONS': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_TYPE_OPTIONS',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_NO_FACILITIES': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_NO_FACILITIES',
	'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_TITLE': 'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_TITLE',
	'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_CONTEXT': 'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_CONTEXT',
	'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_DESCRIPTION': 'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_DESCRIPTION',
	'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_CONTENT': 'AUTO_ONBOARDING_ROOM_MAPPING_DOCUMENTATION_PANEL_CONTENT',
	'STRATEGY_DOCUMENTATION_PANEL_SUMMARY': 'STRATEGY_DOCUMENTATION_PANEL_SUMMARY',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_1_CONTENT': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_1_CONTENT',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_2_CONTENT': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_2_CONTENT',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_3_CONTENT': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_3_CONTENT',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_4_CONTENT': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_4_CONTENT',
	'STRATEGY_CONFIGURATION_EDIT_CUSTOM_PERIOD_MODAL_CALENDAR_SUBTITLE': 'STRATEGY_CONFIGURATION_EDIT_CUSTOM_PERIOD_MODAL_CALENDAR_SUBTITLE',
	'STRATEGY_CONFIGURATION_RESET_STRATEGY_CHANGES_MODAL_HINT': 'STRATEGY_CONFIGURATION_RESET_STRATEGY_CHANGES_MODAL_HINT',
	'STRATEGY_CONFIGURATION_MODIFIERS_STRATEGY_SETTINGS_HINT': 'STRATEGY_CONFIGURATION_MODIFIERS_STRATEGY_SETTINGS_HINT',
	'STRATEGY_CONFIGURATION_MODIFIERS_STARTING_PRICE_SETTINGS_HINT': 'STRATEGY_CONFIGURATION_MODIFIERS_STARTING_PRICE_SETTINGS_HINT',
	'STRATEGY_CONFIGURATION_DERIVED_STRATEGY_WARNING_BODY': 'STRATEGY_CONFIGURATION_DERIVED_STRATEGY_WARNING_BODY',
	'STRATEGY_CHART_PUBLISHED_PRICES': 'STRATEGY_CHART_PUBLISHED_PRICES',
	'STRATEGY_CHART_PUBLISHED_PRICES_PREVIEW': 'STRATEGY_CHART_PUBLISHED_PRICES_PREVIEW',
	'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_TITLE': 'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_TITLE',
	'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_BUTTON': 'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_BUTTON',
	'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_MOVE_ALL': 'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_MOVE_ALL',
	'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_MOVE_ALL_TITLE': 'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_MOVE_ALL_TITLE',
	'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_MOVE_ALL_DESCRIPTION': 'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_MOVE_ALL_DESCRIPTION',
	'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_TITLE': 'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_TITLE',
	'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_LEFT': 'AUTO_ONBOARDING_ROOMS_MAPPING_YOUR_ROOMS_LEFT',
	'AUTO_ONBOARDING_ROOMS_MAPPING_ROOMS_TO_CONNECT_TITLE': 'AUTO_ONBOARDING_ROOMS_MAPPING_ROOMS_TO_CONNECT_TITLE',
	'AUTO_ONBOARDING_ROOMS_MAPPING_ROOMS_TO_CONNECT': 'AUTO_ONBOARDING_ROOMS_MAPPING_ROOMS_TO_CONNECT',
	'AUTO_ONBOARDING_ROOMS_MAPPING_ROOMS_TO_CONNECT_PLACEHOLDER': 'AUTO_ONBOARDING_ROOMS_MAPPING_ROOMS_TO_CONNECT_PLACEHOLDER',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_LOADER_TITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_LOADER_TITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_SUCCESSFUL_TITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_SUCCESSFUL_TITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_SUCCESSFUL_SUBTITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_SUCCESSFUL_SUBTITLE',
	'AUTO_ONBOARDING_PMS_SLOW_CONNECTION_LOADER_TITLE': 'AUTO_ONBOARDING_PMS_SLOW_CONNECTION_LOADER_TITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_BUTTON': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_BUTTON',
	'AUTOONBOARDING_STEPPER_SUBTITLE': 'AUTOONBOARDING_STEPPER_SUBTITLE',
	'AUTOONBOARDING_STEPPER_STEP1': 'AUTOONBOARDING_STEPPER_STEP1',
	'AUTOONBOARDING_STEPPER_STEP2': 'AUTOONBOARDING_STEPPER_STEP2',
	'AUTOONBOARDING_STEPPER_BUTTON_DONE': 'AUTOONBOARDING_STEPPER_BUTTON_DONE',
	'AUTOONBOARDING_STEPPER_BUTTON_MAPPING': 'AUTOONBOARDING_STEPPER_BUTTON_MAPPING',
	'SMARTPAYING_PAYMENTS_ALL_RESERVATION_STATUS': 'SMARTPAYING_PAYMENTS_ALL_RESERVATION_STATUS',
	'MIN_OCCUPANCY': 'MIN_OCCUPANCY',
	'MAX_OCCUPANCY': 'MAX_OCCUPANCY',
	'MASTER_OCCUPANCY': 'MASTER_OCCUPANCY',
	'TOTAL_UNITS': 'TOTAL_UNITS',
	'VIRTUAL_ROOM': 'VIRTUAL_ROOM',
	'PARENT_ROOM': 'PARENT_ROOM',
	'AUTOONBOARDING_VIRTUAL_ROOM_SETUP_NOTE': 'AUTOONBOARDING_VIRTUAL_ROOM_SETUP_NOTE',
	'AUTOONBOARDING_VIRTUAL_ROOM_ADD_NOTE': 'AUTOONBOARDING_VIRTUAL_ROOM_ADD_NOTE',
	'AUTOONBOARDING_VIRTUAL_ROOM_CUSTOMER_SUPPORT_NOTE': 'AUTOONBOARDING_VIRTUAL_ROOM_CUSTOMER_SUPPORT_NOTE',
	'AUTOONBOARDING_VIRTUAL_ROOM_COMPLEX_NOTE': 'AUTOONBOARDING_VIRTUAL_ROOM_COMPLEX_NOTE',
	'MASTER_RATE': 'MASTER_RATE',
	'ALL_RATES': 'ALL_RATES',
	'MASTER_BOARD_TYPE': 'MASTER_BOARD_TYPE',
	'AUTOONBOARDING_ROOM_SETTINGS': 'AUTOONBOARDING_ROOM_SETTINGS',
	'AUTOONBOARDING_MASTER_RATE_SETTINGS': 'AUTOONBOARDING_MASTER_RATE_SETTINGS',
	'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_TITLE': 'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_TITLE',
	'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_SUBTITLE': 'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_SUBTITLE',
	'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_DESCRIPTION': 'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_DESCRIPTION',
	'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_VIRTUAL_ROOMS': 'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_VIRTUAL_ROOMS',
	'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_PHYSICAL_ROOMS': 'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_PHYSICAL_ROOMS',
	'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_DESCRIPTION_BOTTOM': 'AUTOONBOARDING_ROOM_MAPPING_SUMMARY_STEP_DESCRIPTION_BOTTOM',
	'SMARTPAYING_RESERVATIONS_FOUND': 'SMARTPAYING_RESERVATIONS_FOUND',
	'SMARTPAYING_NO_RESERVATIONS_FOUND': 'SMARTPAYING_NO_RESERVATIONS_FOUND',
	'AUTOONBOARDING_ROOM_TYPE_STANDARD_DOUBLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_STANDARD_DOUBLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_STANDARD_TWIN_ROOM': 'AUTOONBOARDING_ROOM_TYPE_STANDARD_TWIN_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_STANDARD_SINGLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_STANDARD_SINGLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_STANDARD_TRIPLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_STANDARD_TRIPLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_STANDARD_QUADRUPLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_STANDARD_QUADRUPLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_DELUXE_DOUBLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_DELUXE_DOUBLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_DELUXE_TWIN_ROOM': 'AUTOONBOARDING_ROOM_TYPE_DELUXE_TWIN_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_DELUXE_SINGLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_DELUXE_SINGLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_DELUXE_TRIPLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_DELUXE_TRIPLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_DELUXE_QUADRUPLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_DELUXE_QUADRUPLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_FAMILY_ROOM': 'AUTOONBOARDING_ROOM_TYPE_FAMILY_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_SUITE': 'AUTOONBOARDING_ROOM_TYPE_SUITE',
	'AUTOONBOARDING_ROOM_TYPE_JUNIOR_SUITE': 'AUTOONBOARDING_ROOM_TYPE_JUNIOR_SUITE',
	'AUTOONBOARDING_ROOM_TYPE_EXECUTIVE_SUITE': 'AUTOONBOARDING_ROOM_TYPE_EXECUTIVE_SUITE',
	'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_DOUBLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_DOUBLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_TWIN_ROOM': 'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_TWIN_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_SINGLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_SINGLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_TRIPLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_TRIPLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_QUADRUPLE_ROOM': 'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_QUADRUPLE_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_KING_ROOM': 'AUTOONBOARDING_ROOM_TYPE_KING_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_QUEEN_ROOM': 'AUTOONBOARDING_ROOM_TYPE_QUEEN_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_DOUBLE_OR_TWIN_ROOM': 'AUTOONBOARDING_ROOM_TYPE_DOUBLE_OR_TWIN_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_DOUBLE_OR_TWIN_ROOM': 'AUTOONBOARDING_ROOM_TYPE_SUPERIOR_DOUBLE_OR_TWIN_ROOM',
	'AUTOONBOARDING_ROOM_TYPE_TWIN_ROOM_WITH_EXTRA_BED': 'AUTOONBOARDING_ROOM_TYPE_TWIN_ROOM_WITH_EXTRA_BED',
	'AUTOONBOARDING_ROOM_TYPE_TWO_BEDROOM_SUITE': 'AUTOONBOARDING_ROOM_TYPE_TWO_BEDROOM_SUITE',
	'AUTOONBOARDING_ROOM_TYPE_STUDIO': 'AUTOONBOARDING_ROOM_TYPE_STUDIO',
	'AUTOONBOARDING_ROOM_TYPE_APARTMENT': 'AUTOONBOARDING_ROOM_TYPE_APARTMENT',
	'AUTOONBOARDING_ROOM_TYPE_LOFT': 'AUTOONBOARDING_ROOM_TYPE_LOFT',
	'AUTOONBOARDING_ROOM_TYPE_BUNGALOW': 'AUTOONBOARDING_ROOM_TYPE_BUNGALOW',
	'AUTOONBOARDING_ROOM_TYPE_VILLA': 'AUTOONBOARDING_ROOM_TYPE_VILLA',
	'AUTOONBOARDING_ROOM_TYPE_CHALET': 'AUTOONBOARDING_ROOM_TYPE_CHALET',
	'AUTOONBOARDING_ROOM_TYPE_COTTAGE': 'AUTOONBOARDING_ROOM_TYPE_COTTAGE',
	'AUTOONBOARDING_ROOM_TYPE_CABIN': 'AUTOONBOARDING_ROOM_TYPE_CABIN',
	'AUTOONBOARDING_ROOM_TYPE_MOBILE_HOME': 'AUTOONBOARDING_ROOM_TYPE_MOBILE_HOME',
	'AUTOONBOARDING_ROOM_TYPE_TENT': 'AUTOONBOARDING_ROOM_TYPE_TENT',
	'AUTOONBOARDING_ROOM_TYPE_YURT': 'AUTOONBOARDING_ROOM_TYPE_YURT',
	'AUTOONBOARDING_ROOM_TYPE_HOUSEBOAT': 'AUTOONBOARDING_ROOM_TYPE_HOUSEBOAT',
	'AUTOONBOARDING_ROOM_TYPE_TREEHOUSE': 'AUTOONBOARDING_ROOM_TYPE_TREEHOUSE',
	'AUTOONBOARDING_ROOM_TYPE_IGLOO': 'AUTOONBOARDING_ROOM_TYPE_IGLOO',
	'AUTOONBOARDING_ROOM_TYPE_SELECTOR_PLACEHOLDER': 'AUTOONBOARDING_ROOM_TYPE_SELECTOR_PLACEHOLDER',
	'AUTOONBOARDING_VIRTUAL_ROOM_PARENT_SELECTOR_PLACEHOLDER': 'AUTOONBOARDING_VIRTUAL_ROOM_PARENT_SELECTOR_PLACEHOLDER',
	'AUTOONBOARDING_BOARD_TYPE_ROOM_ONLY': 'AUTOONBOARDING_BOARD_TYPE_ROOM_ONLY',
	'AUTOONBOARDING_BOARD_TYPE_BED_AND_BREAKFAST': 'AUTOONBOARDING_BOARD_TYPE_BED_AND_BREAKFAST',
	'AUTOONBOARDING_BOARD_TYPE_HALF_BOARD': 'AUTOONBOARDING_BOARD_TYPE_HALF_BOARD',
	'AUTOONBOARDING_BOARD_TYPE_FULL_BOARD': 'AUTOONBOARDING_BOARD_TYPE_FULL_BOARD',
	'AUTOONBOARDING_BOARD_TYPE_ALL_INCLUSIVE': 'AUTOONBOARDING_BOARD_TYPE_ALL_INCLUSIVE',
	'AUTOONBOARDING_BOARD_TYPE_SELECTOR_PLACEHOLDER': 'AUTOONBOARDING_BOARD_TYPE_SELECTOR_PLACEHOLDER',
	'AUTOONBOARDING_RATE_EDITOR_RULE': 'AUTOONBOARDING_RATE_EDITOR_RULE',
	'AUTOONBOARDING_MASTER_RATE_SELECTOR_PLACEHOLDER': 'AUTOONBOARDING_MASTER_RATE_SELECTOR_PLACEHOLDER',
	'AUTOONBOARDING_VERIFY_ALL_FIELDS': 'AUTOONBOARDING_VERIFY_ALL_FIELDS',
	'SMARTPAYING_PAYMENTS_CANNOT_REFUND_VALUE_HIGHER_THAN_PRICE': 'SMARTPAYING_PAYMENTS_CANNOT_REFUND_VALUE_HIGHER_THAN_PRICE',
	'SETTINGS_NOTIFICATIONS_PERFORMANCE_COMPARISON_LABEL': 'SETTINGS_NOTIFICATIONS_PERFORMANCE_COMPARISON_LABEL',
	'SETTINGS_NOTIFICATIONS_PERFORMANCE_COMPARISON_PLACEHOLDER': 'SETTINGS_NOTIFICATIONS_PERFORMANCE_COMPARISON_PLACEHOLDER',
	'SETTINGS_NOTIFICATIONS_PERFORMANCE_COMPARISON_HELP': 'SETTINGS_NOTIFICATIONS_PERFORMANCE_COMPARISON_HELP',
	'SETTINGS_NOTIFICATIONS_PERFORMANCE_SMLY': 'SETTINGS_NOTIFICATIONS_PERFORMANCE_SMLY',
	'SETTINGS_NOTIFICATIONS_PERFORMANCE_WOW': 'SETTINGS_NOTIFICATIONS_PERFORMANCE_WOW',
	'SETTINGS_NOTIFICATIONS': 'SETTINGS_NOTIFICATIONS',
	'SETTINGS_NOTIFICATIONS_PERFORMANCE_TITLE': 'SETTINGS_NOTIFICATIONS_PERFORMANCE_TITLE',
	'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_TITLE': 'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_TITLE',
	'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_DESCRIPTION': 'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_DESCRIPTION',
	'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_CANCEL_BUTTON': 'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_CANCEL_BUTTON',
	'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_CONFIRM_BUTTON': 'AUTOONBOARDING_ROOM_MAPPING_REMOVE_PARENT_DIALOG_CONFIRM_BUTTON',
	'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_TITLE': 'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_TITLE',
	'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_DESCRIPTION': 'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_DESCRIPTION',
	'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_CANCEL_BUTTON': 'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_CANCEL_BUTTON',
	'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_CONFIRM_BUTTON': 'AUTOONBOARDING_ROOM_MAPPING_CONNECT_PARENT_DIALOG_CONFIRM_BUTTON',
	'AUTOONBOARDING_ROOM_MAPPING_LOADER_TITLE': 'AUTOONBOARDING_ROOM_MAPPING_LOADER_TITLE',
	'AUTOONBOARDING_TOTAL_UNITS_PLACEHOLDER': 'AUTOONBOARDING_TOTAL_UNITS_PLACEHOLDER',
	'AUTOONBOARDING_MIN_OCCUPANCY_PLACEHOLDER': 'AUTOONBOARDING_MIN_OCCUPANCY_PLACEHOLDER',
	'AUTOONBOARDING_MIN_OCCUPANCY_ERROR': 'AUTOONBOARDING_MIN_OCCUPANCY_ERROR',
	'AUTOONBOARDING_MAX_OCCUPANCY_PLACEHOLDER': 'AUTOONBOARDING_MAX_OCCUPANCY_PLACEHOLDER',
	'AUTOONBOARDING_MAX_OCCUPANCY_ERROR': 'AUTOONBOARDING_MAX_OCCUPANCY_ERROR',
	'AUTOONBOARDING_MASTER_OCCUPANCY_PLACEHOLDER': 'AUTOONBOARDING_MASTER_OCCUPANCY_PLACEHOLDER',
	'ACCOMMODATIONS_SEARCH_ROOM_PLACEHOLDER': 'ACCOMMODATIONS_SEARCH_ROOM_PLACEHOLDER',
	'SMARTPAYING_PAYMENTS_SEARCH_PLACEHOLDERS': 'SMARTPAYING_PAYMENTS_SEARCH_PLACEHOLDERS',
	'MAIL': 'MAIL',
	'SMARTPAYING_MEX_KIND_orderCreatedGuest': 'SMARTPAYING_MEX_KIND_orderCreatedGuest',
	'SMARTPAYING_MEX_KIND_orderAcceptedGuest': 'SMARTPAYING_MEX_KIND_orderAcceptedGuest',
	'SMARTPAYING_MEX_KIND_orderRejectedGuest': 'SMARTPAYING_MEX_KIND_orderRejectedGuest',
	'SMARTPAYING_RESERVATION_FOUND': 'SMARTPAYING_RESERVATION_FOUND',
	'SMARTPAYING_PAYMENTS_RESERVATION': 'SMARTPAYING_PAYMENTS_RESERVATION',
	'SMARTPAYING_SEND_MARKETPLACE_VIA_WA': 'SMARTPAYING_SEND_MARKETPLACE_VIA_WA',
	'SMARTPAYING_SEND_MARKETPLACE_VIA_MAIL': 'SMARTPAYING_SEND_MARKETPLACE_VIA_MAIL',
	'SMARTPAYING_SEND_MARKETPLACE_VIA_LINK': 'SMARTPAYING_SEND_MARKETPLACE_VIA_LINK',
	'SMARTPAYING_SEND_MARKETPLACE': 'SMARTPAYING_SEND_MARKETPLACE',
	'SMARTPAYING_PAYMENTS_MARKETPLACE_SENT': 'SMARTPAYING_PAYMENTS_MARKETPLACE_SENT',
	'SMARTPAYING_PAYMENTS_MARKETPLACE_SENT_TEXT': 'SMARTPAYING_PAYMENTS_MARKETPLACE_SENT_TEXT',
	'WHATS': 'WHATS',
	'ACCOMMODATION_CLOSURES': 'ACCOMMODATION_CLOSURES',
	'ADD_CLOSURE': 'ADD_CLOSURE',
	'ADD_COMPARISON': 'ADD_COMPARISON',
	'ADDED_CLOSING_PERIOD': 'ADDED_CLOSING_PERIOD',
	'ADDED_CLOSING_PERIOD_TEXT': 'ADDED_CLOSING_PERIOD_TEXT',
	'ADDED_ROOM_CLOSING_PERIOD_TEXT': 'ADDED_ROOM_CLOSING_PERIOD_TEXT',
	'ARE_YOUR_PAYMENT_DATA_UPDATED': 'ARE_YOUR_PAYMENT_DATA_UPDATED',
	'ARE_YOUR_PAYMENT_DATA_UPDATED_TEXT': 'ARE_YOUR_PAYMENT_DATA_UPDATED_TEXT',
	'AVERAGE_NIGHT_PRICE': 'AVERAGE_NIGHT_PRICE',
	'AVERAGE_OCCUPANCY': 'AVERAGE_OCCUPANCY',
	'BASE_PRICES': 'BASE_PRICES',
	'BASE_PRICES_AND_PRICE_RANGES': 'BASE_PRICES_AND_PRICE_RANGES',
	'CALCULATE': 'CALCULATE',
	'CALCULATE_PRICE_MULTIPLE_DATES': 'CALCULATE_PRICE_MULTIPLE_DATES',
	'CALCULATED_PRICE': 'CALCULATED_PRICE',
	'CALENDAR': 'CALENDAR',
	'CANCEL': 'CANCEL',
	'CHARTS': 'CHARTS',
	'CHOSE_AN_OPTION': 'CHOSE_AN_OPTION',
	'CLIPBOARD_PRICE_SAVED': 'CLIPBOARD_PRICE_SAVED',
	'CLIPBOARD_TEXT_SAVED': 'CLIPBOARD_TEXT_SAVED',
	'CLOSE': 'CLOSE',
	'CLOSING_DATES': 'CLOSING_DATES',
	'CLOSING_PERIOD_OVERLAP': 'CLOSING_PERIOD_OVERLAP',
	'COMPARE': 'COMPARE',
	'COMPARISON_DIFFERENCE_WARNING': 'COMPARISON_DIFFERENCE_WARNING',
	'CONFIRM': 'CONFIRM',
	'CONTACT_US': 'CONTACT_US',
	'CREATION_DATE': 'CREATION_DATE',
	'CUSTOMIZE_PRICES': 'CUSTOMIZE_PRICES',
	'DASHBOARD': 'DASHBOARD',
	'DAY': 'DAY',
	'DAYS': 'DAYS',
	'DE': 'DE',
	'DE_WITH_FLAG': 'DE_WITH_FLAG',
	'DELETE_CLOSING_PERIOD_TEXT': 'DELETE_CLOSING_PERIOD_TEXT',
	'DELETE_CLOSURE': 'DELETE_CLOSURE',
	'DELETE_ROOM_CLOSING_PERIOD_TEXT': 'DELETE_ROOM_CLOSING_PERIOD_TEXT',
	'DELETE_VISUALIZATION': 'DELETE_VISUALIZATION',
	'DELETE_VISUALIZATION_CONFIRMATION': 'DELETE_VISUALIZATION_CONFIRMATION',
	'EN': 'EN',
	'EN_WITH_FLAG': 'EN_WITH_FLAG',
	'ERROR': 'ERROR',
	'EVENTS': 'EVENTS',
	'FESTIVITIES': 'FESTIVITIES',
	'GENERAL_SETTING_DESCRIPTION': 'GENERAL_SETTING_DESCRIPTION',
	'GENERICS': 'GENERICS',
	'GUIDED_TOUR': 'GUIDED_TOUR',
	'HOME': 'HOME',
	'INSERT_NAME': 'INSERT_NAME',
	'IT': 'IT',
	'IT_WITH_FLAG': 'IT_WITH_FLAG',
	'LOGOUT': 'LOGOUT',
	'MONTHS': 'MONTHS',
	'NEW_VISUALIZATION': 'NEW_VISUALIZATION',
	'NIGHT': 'NIGHT',
	'NIGHTS': 'NIGHTS',
	'NO_CLOSING_DATES_AVAILABLE': 'NO_CLOSING_DATES_AVAILABLE',
	'OCCUPANCY': 'OCCUPANCY',
	'PAYMENT_ERROR_BANNER_TEXT': 'PAYMENT_ERROR_BANNER_TEXT',
	'PAYMENTS': 'PAYMENTS',
	'PERSONAL_VISUALIZATION': 'PERSONAL_VISUALIZATION',
	'PLAYGROUND_PAYMENT_DATA_ERROR': 'PLAYGROUND_PAYMENT_DATA_ERROR',
	'PROFILE': 'PROFILE',
	'REFERRAL': 'REFERRAL',
	'REMOVED_CLOSING_PERIOD': 'REMOVED_CLOSING_PERIOD',
	'REMOVED_CLOSING_PERIOD_TEXT': 'REMOVED_CLOSING_PERIOD_TEXT',
	'REMOVED_ROOM_CLOSING_PERIOD_TEXT': 'REMOVED_ROOM_CLOSING_PERIOD_TEXT',
	'REV_PAR': 'REV_PAR',
	'REVENUE': 'REVENUE',
	'ROOM_TYPE': 'ROOM_TYPE',
	'ROOM_TYPE_CLOSURES': 'ROOM_TYPE_CLOSURES',
	'SAVE': 'SAVE',
	'SAVE_AS_NEW': 'SAVE_AS_NEW',
	'SAVE_VISUALIZATION': 'SAVE_VISUALIZATION',
	'SAVE_VISUALIZATION_DESCRIPTION': 'SAVE_VISUALIZATION_DESCRIPTION',
	'SETTING': 'SETTING',
	'SETTINGS': 'SETTINGS',
	'SOMETHING_WENT_WRONG': 'SOMETHING_WENT_WRONG',
	'SOMETHING_WENT_WRONG_TEXT': 'SOMETHING_WENT_WRONG_TEXT',
	'STAY_DATE': 'STAY_DATE',
	'STRATEGIES': 'STRATEGIES',
	'STRUCTURE': 'STRUCTURE',
	'TODAY': 'TODAY',
	'TRIAL_PERIOD': 'TRIAL_PERIOD',
	'TRIAL_PERIOD_MESSAGE': 'TRIAL_PERIOD_MESSAGE',
	'UNPAID_INVOICES_DETECTED': 'UNPAID_INVOICES_DETECTED',
	'UPDATE_YOUR_DATA': 'UPDATE_YOUR_DATA',
	'UPDATED_CLOSING_PERIOD': 'UPDATED_CLOSING_PERIOD',
	'UPDATED_CLOSING_PERIOD_TEXT': 'UPDATED_CLOSING_PERIOD_TEXT',
	'UPDATED_ROOM_CLOSING_PERIOD_TEXT': 'UPDATED_ROOM_CLOSING_PERIOD_TEXT',
	'VISUALIZATION': 'VISUALIZATION',
	'VISUALIZATION_ALREADY_EXISTS': 'VISUALIZATION_ALREADY_EXISTS',
	'VISUALIZATION_NAME': 'VISUALIZATION_NAME',
	'WEEK': 'WEEK',
	'WEEKS': 'WEEKS',
	'WHOLE_STRUCTURE': 'WHOLE_STRUCTURE',
	'YEARS': 'YEARS',
	'CUSTOMIZE_PRICES_MULTIPLE_DATES': 'CUSTOMIZE_PRICES_MULTIPLE_DATES',
	'DEFAULT_VIEW': 'DEFAULT_VIEW',
	'ON_SINGLE_ROOMS': 'ON_SINGLE_ROOMS',
	'ON_MULTIPLE_ROOMS': 'ON_MULTIPLE_ROOMS',
	'MONDAY': 'MONDAY',
	'TUESDAY': 'TUESDAY',
	'WEDNESDAY': 'WEDNESDAY',
	'THURSDAY': 'THURSDAY',
	'FRIDAY': 'FRIDAY',
	'SATURDAY': 'SATURDAY',
	'SUNDAY': 'SUNDAY',
	'WEEKEND': 'WEEKEND',
	'WEEK_DAYS': 'WEEK_DAYS',
	'MONDAY_SHORT': 'MONDAY_SHORT',
	'TUESDAY_SHORT': 'TUESDAY_SHORT',
	'WEDNESDAY_SHORT': 'WEDNESDAY_SHORT',
	'THURSDAY_SHORT': 'THURSDAY_SHORT',
	'FRIDAY_SHORT': 'FRIDAY_SHORT',
	'SATURDAY_SHORT': 'SATURDAY_SHORT',
	'SUNDAY_SHORT': 'SUNDAY_SHORT',
	'REMOVE_MODIFIERS': 'REMOVE_MODIFIERS',
	'MODIFIER_FROZEN': 'MODIFIER_FROZEN',
	'MODIFIER_MIN': 'MODIFIER_MIN',
	'MODIFIER_MAX': 'MODIFIER_MAX',
	'MODIFIER_TWEAK': 'MODIFIER_TWEAK',
	'PUBLISHED': 'PUBLISHED',
	'PRICES_JUSTIFIER': 'PRICES_JUSTIFIER',
	'BASE_PRICE': 'BASE_PRICE',
	'SMARTPRICING_STRATEGY': 'SMARTPRICING_STRATEGY',
	'USER_STRATEGY': 'USER_STRATEGY',
	'LAST_YEAR': 'LAST_YEAR',
	'ADR': 'ADR',
	'SHOW_MORE': 'SHOW_MORE',
	'SHOW_LESS': 'SHOW_LESS',
	'MODIFIERS': 'MODIFIERS',
	'RESET': 'RESET',
	'MODIFIERS_HELP_TEXT': 'MODIFIERS_HELP_TEXT',
	'MODIFIERS_HELP_BASE_PRICE_TEXT': 'MODIFIERS_HELP_BASE_PRICE_TEXT',
	'MODIFIERS_HELP_MARKET_TREND_TEXT': 'MODIFIERS_HELP_MARKET_TREND_TEXT',
	'MODIFIERS_HELP_STRUCTURE_TREND_TEXT': 'MODIFIERS_HELP_STRUCTURE_TREND_TEXT',
	'MODIFIERS_HELP_DATE_DISTANCE_TEXT': 'MODIFIERS_HELP_DATE_DISTANCE_TEXT',
	'MODIFIERS_HELP_EVENTS_TEXT': 'MODIFIERS_HELP_EVENTS_TEXT',
	'MODIFIERS_HELP_DAY_OF_THE_WEEK': 'MODIFIERS_HELP_DAY_OF_THE_WEEK',
	'MODIFIERS_HELP_HISTORICAL_CORRECTION': 'MODIFIERS_HELP_HISTORICAL_CORRECTION',
	'HISTORICAL_CORRECTION': 'HISTORICAL_CORRECTION',
	'DAY_OF_THE_WEEK': 'DAY_OF_THE_WEEK',
	'MARKET_TREND': 'MARKET_TREND',
	'STRUCTURE_TREND': 'STRUCTURE_TREND',
	'DATE_DISTANCE': 'DATE_DISTANCE',
	'CREATION_DATE_EXPLAINATION': 'CREATION_DATE_EXPLAINATION',
	'MODIFIER_REMOVE_CONFIRMATION': 'MODIFIER_REMOVE_CONFIRMATION',
	'RESERVATIONS': 'RESERVATIONS',
	'LOST_RESERVATIONS': 'LOST_RESERVATIONS',
	'PRICE': 'PRICE',
	'PRICES_UPDATED_CONFIRMATION': 'PRICES_UPDATED_CONFIRMATION',
	'SINGLE_PRICE_UPDATE': 'SINGLE_PRICE_UPDATE',
	'PRICE_UPDATED_ON_DATE': 'PRICE_UPDATED_ON_DATE',
	'BOOKING_CONFIRMED': 'BOOKING_CONFIRMED',
	'BOOKING_CANCELED': 'BOOKING_CANCELED',
	'PICKUP_COMPACT': 'PICKUP_COMPACT',
	'CANCELLED_F_MULTIPLE': 'CANCELLED_F_MULTIPLE',
	'PRICE_TREND': 'PRICE_TREND',
	'INFO': 'INFO',
	'DERIVED_ROOM': 'DERIVED_ROOM',
	'DERIVED_ROOM_MODIFIER_NOT_SETTABLE': 'DERIVED_ROOM_MODIFIER_NOT_SETTABLE',
	'ROOM_DERIVED_FROM': 'ROOM_DERIVED_FROM',
	'WORKING_DAYS': 'WORKING_DAYS',
	'ACTIVE_MODIFIER': 'ACTIVE_MODIFIER',
	'SET_MODIFIER': 'SET_MODIFIER',
	'LEGEND': 'LEGEND',
	'CUSTOMIZE_PRICES_ON_MULTIPLE_DATES': 'CUSTOMIZE_PRICES_ON_MULTIPLE_DATES',
	'DAY_MAP': 'DAY_MAP',
	'PICKUP': 'PICKUP',
	'DROP': 'DROP',
	'PREDICTED_PRICE': 'PREDICTED_PRICE',
	'GIORNO_DELLA_SETTIMANA': 'GIORNO_DELLA_SETTIMANA',
	'DISTANZA_DALLA_DATA': 'DISTANZA_DALLA_DATA',
	'ANDAMENTO_DI_MERCATO': 'ANDAMENTO_DI_MERCATO',
	'ANDAMENTO_STRUTTURA': 'ANDAMENTO_STRUTTURA',
	'EVENTO': 'EVENTO',
	'AGGRESSIVITÀ_STRATEGIA': 'AGGRESSIVITÀ_STRATEGIA',
	'USER_BEHAVIOR': 'USER_BEHAVIOR',
	'INSUFFICIENT_DATA_FOR_CHART': 'INSUFFICIENT_DATA_FOR_CHART',
	'BOOKINGS_UNAVAILABLE': 'BOOKINGS_UNAVAILABLE',
	'CHART_PLEASE_ENABLE_FILTERS': 'CHART_PLEASE_ENABLE_FILTERS',
	'CLOSING_DAYS_WARNING': 'CLOSING_DAYS_WARNING',
	'SIMULATED_DATA': 'SIMULATED_DATA',
	'SIMULATED_DATA_EXPLAINATION': 'SIMULATED_DATA_EXPLAINATION',
	'PLAYGROUND': 'PLAYGROUND',
	'OK': 'OK',
	'PREVIEW': 'PREVIEW',
	'PARAMETERS': 'PARAMETERS',
	'INCREASING_PRICE': 'INCREASING_PRICE',
	'INCREASING_PRICE_TEXT': 'INCREASING_PRICE_TEXT',
	'DIMINISHING_PRICE': 'DIMINISHING_PRICE',
	'DIMINISHING_PRICE_TEXT': 'DIMINISHING_PRICE_TEXT',
	'AVERAGE_PRICE': 'AVERAGE_PRICE',
	'AVERAGE_PRICE_TEXT': 'AVERAGE_PRICE_TEXT',
	'PERIOD': 'PERIOD',
	'ADD_PERIOD': 'ADD_PERIOD',
	'BASE_STRATEGY': 'BASE_STRATEGY',
	'SUGGESTED_STRATEGY': 'SUGGESTED_STRATEGY',
	'CANCEL_STRATEGIES': 'CANCEL_STRATEGIES',
	'CANCEL_ALL_STRATEGIES': 'CANCEL_ALL_STRATEGIES',
	'CANCEL_ALL_STRATEGIES_TEXT': 'CANCEL_ALL_STRATEGIES_TEXT',
	'SAVE_STRATEGIES': 'SAVE_STRATEGIES',
	'MODIFIERS_DETECTED': 'MODIFIERS_DETECTED',
	'MODIFIERS_DETECTED_TEXT': 'MODIFIERS_DETECTED_TEXT',
	'SAVE_ANYWAY': 'SAVE_ANYWAY',
	'REMOVE_AND_SAVE': 'REMOVE_AND_SAVE',
	'STRATEGY_NOT_SAVED': 'STRATEGY_NOT_SAVED',
	'STRATEGY_NOT_SAVED_TEXT': 'STRATEGY_NOT_SAVED_TEXT',
	'FAILED_PREVIEW': 'FAILED_PREVIEW',
	'FAILED_PREVIEW_TEXT': 'FAILED_PREVIEW_TEXT',
	'EVERY_DATE': 'EVERY_DATE',
	'LAST_MONTH': 'LAST_MONTH',
	'CALCULATED_PRICES': 'CALCULATED_PRICES',
	'UPDATES': 'UPDATES',
	'NEWS_AND_UPDATES': 'NEWS_AND_UPDATES',
	'NEWS_AND_UPDATES_TEXT': 'NEWS_AND_UPDATES_TEXT',
	'BADGE_FEATURE': 'BADGE_FEATURE',
	'BADGE_LIGHT_FEATURE': 'BADGE_LIGHT_FEATURE',
	'BADGE_UPDATE': 'BADGE_UPDATE',
	'BADGE_IMPROVEMENT': 'BADGE_IMPROVEMENT',
	'READ_MORE_ARROW': 'READ_MORE_ARROW',
	'MAILS_AND_NOTIFICATIONS': 'MAILS_AND_NOTIFICATIONS',
	'EMAIL_REPORT': 'EMAIL_REPORT',
	'PERIODIC_REPORT': 'PERIODIC_REPORT',
	'PERIODIC_REPORT_TEXT': 'PERIODIC_REPORT_TEXT',
	'ON': 'ON',
	'OFF': 'OFF',
	'DELETED': 'DELETED',
	'DELETE': 'DELETE',
	'RESTORE': 'RESTORE',
	'EDIT': 'EDIT',
	'CALCULATE_PRICE': 'CALCULATE_PRICE',
	'CANCEL_REMOVE': 'CANCEL_REMOVE',
	'MODIFIERS_MIN_MAX_ERROR': 'MODIFIERS_MIN_MAX_ERROR',
	'MODIFIERS_MAX_MIN_ERROR': 'MODIFIERS_MAX_MIN_ERROR',
	'INCOMPATIBLE_MODIFIERS': 'INCOMPATIBLE_MODIFIERS',
	'INCOMPATIBLE_MODIFIERS_TEXT': 'INCOMPATIBLE_MODIFIERS_TEXT',
	'SELECT_ALL': 'SELECT_ALL',
	'FEEDBACK_EVALUATE': 'FEEDBACK_EVALUATE',
	'ADD_MORE_POSITIVE': 'ADD_MORE_POSITIVE',
	'ADD_MORE_MILD': 'ADD_MORE_MILD',
	'ADD_MORE_NEGATIVE': 'ADD_MORE_NEGATIVE',
	'FEEDBACK_CONTACT_ME': 'FEEDBACK_CONTACT_ME',
	'CONTACT_TO_IMPROVE_SMARTPRICING': 'CONTACT_TO_IMPROVE_SMARTPRICING',
	'SUGGESTIONS_HERE': 'SUGGESTIONS_HERE',
	'PRIVACY_POLICY': 'PRIVACY_POLICY',
	'SEND': 'SEND',
	'CUSTOMIZED_PRICES_IN_RANGE_UPDATED': 'CUSTOMIZED_PRICES_IN_RANGE_UPDATED',
	'FEEDBACK_CATEGORY_SINGLE_PRICE_CUST': 'FEEDBACK_CATEGORY_SINGLE_PRICE_CUST',
	'FEEDBACK_TITLE_SINGLE_PRICE_CUST': 'FEEDBACK_TITLE_SINGLE_PRICE_CUST',
	'FEEDBACK_DESCR_SINGLE_PRICE_CUSTOM': 'FEEDBACK_DESCR_SINGLE_PRICE_CUSTOM',
	'FEEDBACK_CATEGORY_BULK_PRICE_CUST': 'FEEDBACK_CATEGORY_BULK_PRICE_CUST',
	'FEEDBACK_TITLE_BULK_PRICE_CUST': 'FEEDBACK_TITLE_BULK_PRICE_CUST',
	'FEEDBACK_DESCR_BULK_PRICE_CUST': 'FEEDBACK_DESCR_BULK_PRICE_CUST',
	'FEEDBACK_CATEGORY_STRATEGY_CHANGE': 'FEEDBACK_CATEGORY_STRATEGY_CHANGE',
	'FEEDBACK_TITLE_STRATEGY_CHANGE': 'FEEDBACK_TITLE_STRATEGY_CHANGE',
	'FEEDBACK_DESCR_STRATEGY_CHANGE': 'FEEDBACK_DESCR_STRATEGY_CHANGE',
	'ADD_NEW': 'ADD_NEW',
	'CONFRONTATION': 'CONFRONTATION',
	'DETAIL': 'DETAIL',
	'SUGGESTED': 'SUGGESTED',
	'ENTER': 'ENTER',
	'EMAIL_PLACEHOLDER': 'EMAIL_PLACEHOLDER',
	'PASSWORD': 'PASSWORD',
	'WELCOME_BACK': 'WELCOME_BACK',
	'FORGOT_PASSWORD': 'FORGOT_PASSWORD',
	'PASSWORD_RECOVERY': 'PASSWORD_RECOVERY',
	'DONT_HAVE_ACCOUNT': 'DONT_HAVE_ACCOUNT',
	'TRY_SMARTPRICING': 'TRY_SMARTPRICING',
	'ENTER_AND_TRY_DYNAMIC_PRICES': 'ENTER_AND_TRY_DYNAMIC_PRICES',
	'ALREADY_A_USER': 'ALREADY_A_USER',
	'LOG_IN_YOUR_ACCOUNT': 'LOG_IN_YOUR_ACCOUNT',
	'DERIVED_STRATEGY': 'DERIVED_STRATEGY',
	'DERIVED_STRATEGY_WARNING_BANNER_TEXT': 'DERIVED_STRATEGY_WARNING_BANNER_TEXT',
	'DERIVED_STRATEGY_OTHER_ACCOMMODATION': 'DERIVED_STRATEGY_OTHER_ACCOMMODATION',
	'INSERT_VALID_MAIL': 'INSERT_VALID_MAIL',
	'INSERT_VALID_PASSWORD': 'INSERT_VALID_PASSWORD',
	'CHECK_MAIL': 'CHECK_MAIL',
	'CHECK_PASSWORD': 'CHECK_PASSWORD',
	'WRONG_MAIL_OR_PASS': 'WRONG_MAIL_OR_PASS',
	'CHECK_CREDENTIALS': 'CHECK_CREDENTIALS',
	'SUSPENDED_ACCOUNT': 'SUSPENDED_ACCOUNT',
	'SUSPENDED_ACCOUNT_TEXT': 'SUSPENDED_ACCOUNT_TEXT',
	'CONTACT': 'CONTACT',
	'PASSWORD_FORGOTTEN': 'PASSWORD_FORGOTTEN',
	'PASSWORD_FORGOTTEN_TEXT': 'PASSWORD_FORGOTTEN_TEXT',
	'EMAIL_ADDRESS': 'EMAIL_ADDRESS',
	'EMAIL': 'EMAIL',
	'PASSWORD_RECOVERY_ACTION': 'PASSWORD_RECOVERY_ACTION',
	'PASSWORD_RECOVERY_SEND_SUCCESS': 'PASSWORD_RECOVERY_SEND_SUCCESS',
	'SET_NEW_PASSWORD': 'SET_NEW_PASSWORD',
	'ACTIVATE_ACCOUNT': 'ACTIVATE_ACCOUNT',
	'NEW_PASSWORD': 'NEW_PASSWORD',
	'CONFIRM_PASSWORD': 'CONFIRM_PASSWORD',
	'NEW_PASSWORD_SET': 'NEW_PASSWORD_SET',
	'NEW_PASSWORD_SET_TEXT': 'NEW_PASSWORD_SET_TEXT',
	'CREATE_NEW_EVENT': 'CREATE_NEW_EVENT',
	'EDIT_EVENT': 'EDIT_EVENT',
	'IMPACT': 'IMPACT',
	'NEXT': 'NEXT',
	'PREVIOUS': 'PREVIOUS',
	'INSERT_HOLIDAY_NAME': 'INSERT_HOLIDAY_NAME',
	'INSERT_EVENT_NAME': 'INSERT_EVENT_NAME',
	'ACCOMMODATIONS': 'ACCOMMODATIONS',
	'ALL_ACCOMMODATIONS': 'ALL_ACCOMMODATIONS',
	'ANNUAL_REPETITION': 'ANNUAL_REPETITION',
	'ANNUAL_REPETITION_TEXT': 'ANNUAL_REPETITION_TEXT',
	'ANNUAL_REPETITION_DIFFERENT_DATES': 'ANNUAL_REPETITION_DIFFERENT_DATES',
	'ANNUAL_REPETITION_DIFFERENT_DATES_TEXT': 'ANNUAL_REPETITION_DIFFERENT_DATES_TEXT',
	'EVERY_YEAR': 'EVERY_YEAR',
	'EVERY_YEAR_DIFFERENT_DATES': 'EVERY_YEAR_DIFFERENT_DATES',
	'NAME': 'NAME',
	'DATES': 'DATES',
	'TYPE': 'TYPE',
	'REPETITION': 'REPETITION',
	'NO_REPETITION': 'NO_REPETITION',
	'PASSWORD_MISMATCH': 'PASSWORD_MISMATCH',
	'SMARTPRICING': 'SMARTPRICING',
	'PRICE_PREVIEW': 'PRICE_PREVIEW',
	'IMPACT_EXPLAINATION': 'IMPACT_EXPLAINATION',
	'PRICE_WITH_EVENT': 'PRICE_WITH_EVENT',
	'PRICE_WITHOUT_EVENT': 'PRICE_WITHOUT_EVENT',
	'INSERT_VALID_PASSWORD_TEMP': 'INSERT_VALID_PASSWORD_TEMP',
	'USER_GENERATED_EVENT': 'USER_GENERATED_EVENT',
	'USER_GENERATED_FESTIVITY': 'USER_GENERATED_FESTIVITY',
	'USER_EDITED_EVENT': 'USER_EDITED_EVENT',
	'USER_EDITED_FESTIVITY': 'USER_EDITED_FESTIVITY',
	'DISABLED_EVENT': 'DISABLED_EVENT',
	'DISABLED_FESTIVITY': 'DISABLED_FESTIVITY',
	'MODIFIERS_DETECTED_CUSTOM_EVENTS_TEXT': 'MODIFIERS_DETECTED_CUSTOM_EVENTS_TEXT',
	'INVALID_VALUE': 'INVALID_VALUE',
	'EVENT_ADDED_SUCCESSFULLY': 'EVENT_ADDED_SUCCESSFULLY',
	'FESTIVITY_ADDED_SUCCESSFULLY': 'FESTIVITY_ADDED_SUCCESSFULLY',
	'EVENT_EDITED_SUCCESSFULLY': 'EVENT_EDITED_SUCCESSFULLY',
	'FESTIVITY_EDITED_SUCCESSFULLY': 'FESTIVITY_EDITED_SUCCESSFULLY',
	'ADD_EVENT_NAME': 'ADD_EVENT_NAME',
	'ADD_FESTIVITY_NAME': 'ADD_FESTIVITY_NAME',
	'MARKET_OCCUPANCY': 'MARKET_OCCUPANCY',
	'LOW_DEMAND': 'LOW_DEMAND',
	'MEDIUM_DEMAND': 'MEDIUM_DEMAND',
	'HIGH_DEMAND': 'HIGH_DEMAND',
	'MKT_OCC_DESCR': 'MKT_OCC_DESCR',
	'PRICE_DERIVED_FROM': 'PRICE_DERIVED_FROM',
	'CURRENT_STRATEGY': 'CURRENT_STRATEGY',
	'PREVIEW_PLUS_MODIFIERS': 'PREVIEW_PLUS_MODIFIERS',
	'EVENT_LOADING_NOTIFICATION_TITLE': 'EVENT_LOADING_NOTIFICATION_TITLE',
	'EVENT_LOADING_NOTIFICATION_DESCRIPTION': 'EVENT_LOADING_NOTIFICATION_DESCRIPTION',
	'MULTIPLE_EVENTS_LOADING_NOTIFICATION_TITLE': 'MULTIPLE_EVENTS_LOADING_NOTIFICATION_TITLE',
	'MULTIPLE_EVENTS_LOADING_NOTIFICATION_DESCRIPTION': 'MULTIPLE_EVENTS_LOADING_NOTIFICATION_DESCRIPTION',
	'SMARTPRICING_INVOICE_SUBJECT': 'SMARTPRICING_INVOICE_SUBJECT',
	'INVOICE_SPAN': 'INVOICE_SPAN',
	'SMARTPAYING_INVOICE_SUBJECT': 'SMARTPAYING_INVOICE_SUBJECT',
	'SMARTPAYING_VARIABLE_FEE_INVOICE_SUBJECT': 'SMARTPAYING_VARIABLE_FEE_INVOICE_SUBJECT',
	'WARNING_MODIFIERS_AVAILABLE': 'WARNING_MODIFIERS_AVAILABLE',
	'REMOVE': 'REMOVE',
	'REMOVE_EVENT_MODIFIERS_CONFIRMATION': 'REMOVE_EVENT_MODIFIERS_CONFIRMATION',
	'INDEXED_ROOMS_DETECTED': 'INDEXED_ROOMS_DETECTED',
	'MODIFIERS_DELETE_MAY_IMPACT_THE_FOLLOWING': 'MODIFIERS_DELETE_MAY_IMPACT_THE_FOLLOWING',
	'MODIFIERS_DELETE_ON_EVENT_EDIT': 'MODIFIERS_DELETE_ON_EVENT_EDIT',
	'CUSTOM_REPETITION': 'CUSTOM_REPETITION',
	'EVENT_SENT': 'EVENT_SENT',
	'FESTIVITY_SENT': 'FESTIVITY_SENT',
	'EVENT_SENT_TEXT': 'EVENT_SENT_TEXT',
	'FESTIVITY_SENT_TEXT': 'FESTIVITY_SENT_TEXT',
	'EVENT_LOADING': 'EVENT_LOADING',
	'FESTIVITY_LOADING': 'FESTIVITY_LOADING',
	'EVENT_LOADING_TEXT': 'EVENT_LOADING_TEXT',
	'FESTIVITY_LOADING_TEXT': 'FESTIVITY_LOADING_TEXT',
	'MULTIPLE_EVENTS_LOADING': 'MULTIPLE_EVENTS_LOADING',
	'MULTIPLE_FESTIVITIES_LOADING': 'MULTIPLE_FESTIVITIES_LOADING',
	'MULTIPLE_EVENTS_LOADING_TEXT': 'MULTIPLE_EVENTS_LOADING_TEXT',
	'MULTIPLE_FESTIVITIES_LOADING_TEXT': 'MULTIPLE_FESTIVITIES_LOADING_TEXT',
	'SALES_MODE_HOTEL': 'SALES_MODE_HOTEL',
	'SALES_MODE_ROOM_TYPE': 'SALES_MODE_ROOM_TYPE',
	'SALES_MODE_BNB': 'SALES_MODE_BNB',
	'SALES_MODE_APARTMENT': 'SALES_MODE_APARTMENT',
	'SALES_MODE_MULTI_PROPERTY': 'SALES_MODE_MULTI_PROPERTY',
	'SALES_MODE_HOTEL_MODE': 'SALES_MODE_HOTEL_MODE',
	'SALES_MODE_BNB_MODE': 'SALES_MODE_BNB_MODE',
	'SALES_MODE_MULTI_PROPERTY_MODE': 'SALES_MODE_MULTI_PROPERTY_MODE',
	'SALES_MODE_TURN_OFF': 'SALES_MODE_TURN_OFF',
	'DOWNLOAD': 'DOWNLOAD',
	'GUIDES': 'GUIDES',
	'CSV_FILE_PRICE_REPORT': 'CSV_FILE_PRICE_REPORT',
	'IMPACT_FIELD_HELPER_TEXT': 'IMPACT_FIELD_HELPER_TEXT',
	'LOGIN': 'LOGIN',
	'BASE_PRICES_EXPLAINATION': 'BASE_PRICES_EXPLAINATION',
	'MISSING_BASE_PRICES_EXPLAINATION': 'MISSING_BASE_PRICES_EXPLAINATION',
	'COMPARISON_ONLY_CONFIRMED_BOOKING': 'COMPARISON_ONLY_CONFIRMED_BOOKING',
	'PAST_PRICES': 'PAST_PRICES',
	'FUTURE_PRICES': 'FUTURE_PRICES',
	'DUPLICATE': 'DUPLICATE',
	'DUPLICATE_BASE_PRICES_DIALOG_BUTTON': 'DUPLICATE_BASE_PRICES_DIALOG_BUTTON',
	'BASE_PRICES_EDIT_PERIOD': 'BASE_PRICES_EDIT_PERIOD',
	'SELECTED_PERIOD_MIN_DAYS': 'SELECTED_PERIOD_MIN_DAYS',
	'NO_BASE_PRICE_AVAILABLE': 'NO_BASE_PRICE_AVAILABLE',
	'YOU_NEED_MIN_X_BASE_PRICES': 'YOU_NEED_MIN_X_BASE_PRICES',
	'BASE_PRICES_SAVED': 'BASE_PRICES_SAVED',
	'BASE_PRICES_SAVED_TEXT': 'BASE_PRICES_SAVED_TEXT',
	'SUGGESTED_PRICE': 'SUGGESTED_PRICE',
	'REMOVE_DATE': 'REMOVE_DATE',
	'SELECT_A_DATE_TO_EDIT_IMPACTS': 'SELECT_A_DATE_TO_EDIT_IMPACTS',
	'OTHER_ROOM': 'OTHER_ROOM',
	'OTHER_APARTMENT': 'OTHER_APARTMENT',
	'MONDAY_MOBILE': 'MONDAY_MOBILE',
	'TUESDAY_MOBILE': 'TUESDAY_MOBILE',
	'WEDNESDAY_MOBILE': 'WEDNESDAY_MOBILE',
	'THURSDAY_MOBILE': 'THURSDAY_MOBILE',
	'FRIDAY_MOBILE': 'FRIDAY_MOBILE',
	'SATURDAY_MOBILE': 'SATURDAY_MOBILE',
	'SUNDAY_MOBILE': 'SUNDAY_MOBILE',
	'DISABLE': 'DISABLE',
	'FEEDBACK_CATEGORY_EVENT_CONFIRM': 'FEEDBACK_CATEGORY_EVENT_CONFIRM',
	'FEEDBACK_TITLE_EVENT_CONFIRM': 'FEEDBACK_TITLE_EVENT_CONFIRM',
	'FEEDBACK_DESCR_EVENT_CONFIRM': 'FEEDBACK_DESCR_EVENT_CONFIRM',
	'AUMENTO_PREZZO_BASE': 'AUMENTO_PREZZO_BASE',
	'DUPLICATE_SELECTED_PERIODS': 'DUPLICATE_SELECTED_PERIODS',
	'PERIODS': 'PERIODS',
	'PERIODS_AND_PRICES': 'PERIODS_AND_PRICES',
	'DUPLICATE_PERIODS_PRICES': 'DUPLICATE_PERIODS_PRICES',
	'PRICE_LIST': 'PRICE_LIST',
	'OVERRIDE_PERIODS': 'OVERRIDE_PERIODS',
	'BASE_PRICE_ALREADY_AVAILABLE': 'BASE_PRICE_ALREADY_AVAILABLE',
	'OVERRIDE': 'OVERRIDE',
	'BETA_VERSION': 'BETA_VERSION',
	'REMEMBER_IN_A_MONTH': 'REMEMBER_IN_A_MONTH',
	'PAST_DATA_NOT_AVAILABLE': 'PAST_DATA_NOT_AVAILABLE',
	'RULE': 'RULE',
	'ONBOARDING_PROFILE_TO_PROFILE': 'ONBOARDING_PROFILE_TO_PROFILE',
	'ONBOARDING_PROFILE_INTRO_TITLE': 'ONBOARDING_PROFILE_INTRO_TITLE',
	'ONBOARDING_PROFILE_INTRO_DESCRIPTION': 'ONBOARDING_PROFILE_INTRO_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_OTHER_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_OTHER_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_D': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_D',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_E': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_E',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_F': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_F',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_D': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_D',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_D': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_D',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_D': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_D',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_DESCRIPTION': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_DESCRIPTION',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_A': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_A',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_B': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_B',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_C': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_C',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_D': 'ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_D',
	'ONBOARDING_PROFILE_END_TITLE': 'ONBOARDING_PROFILE_END_TITLE',
	'ONBOARDING_PROFILE_END_ADVENTURER': 'ONBOARDING_PROFILE_END_ADVENTURER',
	'ONBOARDING_PROFILE_END_GATEKEEPER': 'ONBOARDING_PROFILE_END_GATEKEEPER',
	'ONBOARDING_PROFILE_END_CONQUEROR': 'ONBOARDING_PROFILE_END_CONQUEROR',
	'ONBOARDING_PROFILE_END_STRATEGY_MASTER': 'ONBOARDING_PROFILE_END_STRATEGY_MASTER',
	'ONBOARDING_PROFILE_END_GUARDIAN_OF_STABILITY': 'ONBOARDING_PROFILE_END_GUARDIAN_OF_STABILITY',
	'ONBOARDING_PROFILE_END_BRAND_AMBASSADOR': 'ONBOARDING_PROFILE_END_BRAND_AMBASSADOR',
	'ONBOARDING_PROFILE_END_DYNAMIC_MERCHANT': 'ONBOARDING_PROFILE_END_DYNAMIC_MERCHANT',
	'ONBOARDING_PROFILE_END_PRICES_WARRIOR': 'ONBOARDING_PROFILE_END_PRICES_WARRIOR',
	'ONBOARDING_PROFILE_END_ADVENTURER_DESCRIPTION': 'ONBOARDING_PROFILE_END_ADVENTURER_DESCRIPTION',
	'ONBOARDING_PROFILE_END_GATEKEEPER_DESCRIPTION': 'ONBOARDING_PROFILE_END_GATEKEEPER_DESCRIPTION',
	'ONBOARDING_PROFILE_END_CONQUEROR_DESCRIPTION': 'ONBOARDING_PROFILE_END_CONQUEROR_DESCRIPTION',
	'ONBOARDING_PROFILE_END_STRATEGY_MASTER_DESCRIPTION': 'ONBOARDING_PROFILE_END_STRATEGY_MASTER_DESCRIPTION',
	'ONBOARDING_PROFILE_END_GUARDIAN_OF_STABILITY_DESCRIPTION': 'ONBOARDING_PROFILE_END_GUARDIAN_OF_STABILITY_DESCRIPTION',
	'ONBOARDING_PROFILE_END_BRAND_AMBASSADOR_DESCRIPTION': 'ONBOARDING_PROFILE_END_BRAND_AMBASSADOR_DESCRIPTION',
	'ONBOARDING_PROFILE_END_DYNAMIC_MERCHANT_DESCRIPTION': 'ONBOARDING_PROFILE_END_DYNAMIC_MERCHANT_DESCRIPTION',
	'ONBOARDING_PROFILE_END_PRICES_WARRIOR_DESCRIPTION': 'ONBOARDING_PROFILE_END_PRICES_WARRIOR_DESCRIPTION',
	'DROP_PICKUP': 'DROP_PICKUP',
	'READ_MORE': 'READ_MORE',
	'READ_LESS': 'READ_LESS',
	'ONBOARDING_PROFILE_END_STEP_PROFILE_COMPLETE_TITLE': 'ONBOARDING_PROFILE_END_STEP_PROFILE_COMPLETE_TITLE',
	'ONBOARDING_PROFILE_END_STEP_PROCESSING_TITLE': 'ONBOARDING_PROFILE_END_STEP_PROCESSING_TITLE',
	'ONBOARDING_PROFILE_END_STEP_PROCESSING_DESCRIPTION': 'ONBOARDING_PROFILE_END_STEP_PROCESSING_DESCRIPTION',
	'ONBOARDING_PROFILE_END_STEP_UPLOAD_TITLE': 'ONBOARDING_PROFILE_END_STEP_UPLOAD_TITLE',
	'ONBOARDING_PROFILE_END_STEP_UPLOAD_DESCRIPTION': 'ONBOARDING_PROFILE_END_STEP_UPLOAD_DESCRIPTION',
	'ONBOARDING_PROFILE_END_STEP_READY_TITLE': 'ONBOARDING_PROFILE_END_STEP_READY_TITLE',
	'DOWNLOAD_BASE_PRICES_TEMPLATE_BUTTON_LABEL': 'DOWNLOAD_BASE_PRICES_TEMPLATE_BUTTON_LABEL',
	'UPLOAD_BASE_PRICES_INPUT_TEXT_DRAG_DROP': 'UPLOAD_BASE_PRICES_INPUT_TEXT_DRAG_DROP',
	'UPLOAD_BASE_PRICES_INPUT_TEXT_SEARCH': 'UPLOAD_BASE_PRICES_INPUT_TEXT_SEARCH',
	'UPLOAD_BASE_PRICES_INPUT_TEXT_ON_DRAG': 'UPLOAD_BASE_PRICES_INPUT_TEXT_ON_DRAG',
	'DELETE_BASE_PRICE_PERIOD': 'DELETE_BASE_PRICE_PERIOD',
	'CONFIRM_DELETE_BASE_PRICE_PERIOD': 'CONFIRM_DELETE_BASE_PRICE_PERIOD',
	'FILE_MUST_BE_EXCEL_ERROR_MESSAGE': 'FILE_MUST_BE_EXCEL_ERROR_MESSAGE',
	'FILE_SIZE_EXCEEDED_ERROR_MESSAGE': 'FILE_SIZE_EXCEEDED_ERROR_MESSAGE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_DYNAMIC_PRICING_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_DYNAMIC_PRICING_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_DYNAMIC_PRICING_CONTENT': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_DYNAMIC_PRICING_CONTENT',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_CONTENT': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_CONTENT',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_CONTENT': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_CONTENT',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_ROOM_TYPES_HIERARCHY_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_ROOM_TYPES_HIERARCHY_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_ROOM_TYPES_HIERARCHY_CONTENT': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_ROOM_TYPES_HIERARCHY_CONTENT',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_PROFILE_TITLE': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_PROFILE_TITLE',
	'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_PROFILE_CONTENT': 'ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_PROFILE_CONTENT',
	'BASE_PRICES_UNSAVED_CHANGES_TITLE': 'BASE_PRICES_UNSAVED_CHANGES_TITLE',
	'BASE_PRICES_UNSAVED_CHANGES_LABEL': 'BASE_PRICES_UNSAVED_CHANGES_LABEL',
	'BASE_PRICES_UNSAVED_CHANGES_ACTION_FIX': 'BASE_PRICES_UNSAVED_CHANGES_ACTION_FIX',
	'BASE_PRICES_UNSAVED_CHANGES_ACTION_EXIT': 'BASE_PRICES_UNSAVED_CHANGES_ACTION_EXIT',
	'GENERIC_NEXT': 'GENERIC_NEXT',
	'GENERIC_PREVIOUS': 'GENERIC_PREVIOUS',
	'BASE_PRICES_MIN_DAYS_EXPLAINATION': 'BASE_PRICES_MIN_DAYS_EXPLAINATION',
	'BASE_PRICES_DUPLICATED_PERIOD_AND_PRICE': 'BASE_PRICES_DUPLICATED_PERIOD_AND_PRICE',
	'BASE_PRICES_DUPLICATED_PERIOD_AND_PRICES_MULTIPLE': 'BASE_PRICES_DUPLICATED_PERIOD_AND_PRICES_MULTIPLE',
	'BASE_PRICES_DUPLICATED_PERIOD_MULTIPLE': 'BASE_PRICES_DUPLICATED_PERIOD_MULTIPLE',
	'BASE_PRICES_DUPLICATED_PERIOD': 'BASE_PRICES_DUPLICATED_PERIOD',
	'BASE_PRICES_ADDED': 'BASE_PRICES_ADDED',
	'BASE_PRICES_ADDED_TEXT': 'BASE_PRICES_ADDED_TEXT',
	'TREATMENT': 'TREATMENT',
	'BASE_PRICES_FIX_HOLES_DISABLED': 'BASE_PRICES_FIX_HOLES_DISABLED',
	'BASE_PRICES_FIX_HOLES': 'BASE_PRICES_FIX_HOLES',
	'BASE_PRICES_ERRORS_TOO_SHORT_PERIODS': 'BASE_PRICES_ERRORS_TOO_SHORT_PERIODS',
	'TOO_SHORT_BASE_PRICES_EXPLAINATION': 'TOO_SHORT_BASE_PRICES_EXPLAINATION',
	'COMPETITORS': 'COMPETITORS',
	'BILLING_AND_SUBSCRIPTION': 'BILLING_AND_SUBSCRIPTION',
	'COMPETITORS_LIST': 'COMPETITORS_LIST',
	'SCORE': 'SCORE',
	'RELEVANCE': 'RELEVANCE',
	'COMPETITORS_MAP': 'COMPETITORS_MAP',
	'MARKET_PRICE_TREND': 'MARKET_PRICE_TREND',
	'ADD_COMPETITOR': 'ADD_COMPETITOR',
	'ADD_A_COMPETITOR': 'ADD_A_COMPETITOR',
	'SEARCH_COMPETITOR': 'SEARCH_COMPETITOR',
	'ENTER_COMPETITOR_NAME': 'ENTER_COMPETITOR_NAME',
	'SELECT_COMPETITOR': 'SELECT_COMPETITOR',
	'MIN_MAX_MARKET': 'MIN_MAX_MARKET',
	'ACCOMMODATION_TREND': 'ACCOMMODATION_TREND',
	'MEAN_MARKET_TREND': 'MEAN_MARKET_TREND',
	'ROTATE_DEVICE': 'ROTATE_DEVICE',
	'NO_RESULTS': 'NO_RESULTS',
	'FEEDBACK_CATEGORY_ADD_3_OR_MORE_COMPETITORS': 'FEEDBACK_CATEGORY_ADD_3_OR_MORE_COMPETITORS',
	'FEEDBACK_TITLE_ADD_3_OR_MORE_COMPETITORS': 'FEEDBACK_TITLE_ADD_3_OR_MORE_COMPETITORS',
	'FEEDBACK_DESCR_ADD_3_OR_MORE_COMPETITORS': 'FEEDBACK_DESCR_ADD_3_OR_MORE_COMPETITORS',
	'FEEDBACK_CATEGORY_COMPETITORS_MAP_ZOOM_IN_OR_OUT': 'FEEDBACK_CATEGORY_COMPETITORS_MAP_ZOOM_IN_OR_OUT',
	'FEEDBACK_TITLE_COMPETITORS_MAP_ZOOM_IN_OR_OUT': 'FEEDBACK_TITLE_COMPETITORS_MAP_ZOOM_IN_OR_OUT',
	'FEEDBACK_DESCR_COMPETITORS_MAP_ZOOM_IN_OR_OUT': 'FEEDBACK_DESCR_COMPETITORS_MAP_ZOOM_IN_OR_OUT',
	'FEEDBACK_CATEGORY_COMPETITORS_CHART_INTERACTION': 'FEEDBACK_CATEGORY_COMPETITORS_CHART_INTERACTION',
	'FEEDBACK_TITLE_COMPETITORS_CHART_INTERACTION': 'FEEDBACK_TITLE_COMPETITORS_CHART_INTERACTION',
	'FEEDBACK_DESCR_COMPETITORS_CHART_INTERACTION': 'FEEDBACK_DESCR_COMPETITORS_CHART_INTERACTION',
	'MARKET_INTELLIGENCE': 'MARKET_INTELLIGENCE',
	'BILLING_AND_INVOICES': 'BILLING_AND_INVOICES',
	'BILLING_INFO': 'BILLING_INFO',
	'PAYMENT_METHODS': 'PAYMENT_METHODS',
	'ADDRESS': 'ADDRESS',
	'VAT_ID': 'VAT_ID',
	'BILLING_EMAIL': 'BILLING_EMAIL',
	'SUBSCRIPTIONS': 'SUBSCRIPTIONS',
	'ENDING': 'ENDING',
	'FIRST_NAME': 'FIRST_NAME',
	'LAST_NAME': 'LAST_NAME',
	'CITY': 'CITY',
	'ZIP_CODE': 'ZIP_CODE',
	'COUNTRY': 'COUNTRY',
	'BILLING_CHANGE_PAYMENT_METHOD': 'BILLING_CHANGE_PAYMENT_METHOD',
	'BILLING_CHANGE_METHOD': 'BILLING_CHANGE_METHOD',
	'PHONE': 'PHONE',
	'LIVE_CHAT': 'LIVE_CHAT',
	'BILLING_CHANGE_PAYMENT_METHOD_DESCRIPTION': 'BILLING_CHANGE_PAYMENT_METHOD_DESCRIPTION',
	'BILLING_CHANGE_PAYMENT_METHOD_EMAIL_DESCRIPTION': 'BILLING_CHANGE_PAYMENT_METHOD_EMAIL_DESCRIPTION',
	'BILLING_CHANGE_PAYMENT_METHOD_PHONE_DESCRIPTION': 'BILLING_CHANGE_PAYMENT_METHOD_PHONE_DESCRIPTION',
	'BILLING_CHANGE_PAYMENT_METHOD_LIVE_CHAT_DESCRIPTION': 'BILLING_CHANGE_PAYMENT_METHOD_LIVE_CHAT_DESCRIPTION',
	'EXPIRATION_ABBREVIATED': 'EXPIRATION_ABBREVIATED',
	'PAYMENT_METHOD_AWAITING_FOR_APPROVAL': 'PAYMENT_METHOD_AWAITING_FOR_APPROVAL',
	'DIRECT_DEBIT': 'DIRECT_DEBIT',
	'REQUIRED_VALUE': 'REQUIRED_VALUE',
	'PLAN': 'PLAN',
	'NEXT_PAYMENT': 'NEXT_PAYMENT',
	'UNPAID_INVOICE': 'UNPAID_INVOICE',
	'SUMMARY': 'SUMMARY',
	'DOC_MARKET_INTELLIGENCE_SUMMARY': 'DOC_MARKET_INTELLIGENCE_SUMMARY',
	'HELP': 'HELP',
	'DOC_MARKET_INTELLIGENCE_SECTION_1_TITLE': 'DOC_MARKET_INTELLIGENCE_SECTION_1_TITLE',
	'DOC_MARKET_INTELLIGENCE_SECTION_1_CONTENT': 'DOC_MARKET_INTELLIGENCE_SECTION_1_CONTENT',
	'DOC_MARKET_INTELLIGENCE_SECTION_2_TITLE': 'DOC_MARKET_INTELLIGENCE_SECTION_2_TITLE',
	'DOC_MARKET_INTELLIGENCE_SECTION_2_CONTENT': 'DOC_MARKET_INTELLIGENCE_SECTION_2_CONTENT',
	'DOC_MARKET_INTELLIGENCE_SECTION_3_TITLE': 'DOC_MARKET_INTELLIGENCE_SECTION_3_TITLE',
	'DOC_MARKET_INTELLIGENCE_SECTION_3_CONTENT': 'DOC_MARKET_INTELLIGENCE_SECTION_3_CONTENT',
	'UPDATE_BILLING_INFO_NOTIFICATION_TITLE': 'UPDATE_BILLING_INFO_NOTIFICATION_TITLE',
	'UPDATE_BILLING_INFO_NOTIFICATION_DESCRIPTION': 'UPDATE_BILLING_INFO_NOTIFICATION_DESCRIPTION',
	'UPDATE_BILLING_INFO_NOTIFICATION_TITLE_ERROR': 'UPDATE_BILLING_INFO_NOTIFICATION_TITLE_ERROR',
	'UPDATE_BILLING_INFO_NOTIFICATION_DESCRIPTION_ERROR': 'UPDATE_BILLING_INFO_NOTIFICATION_DESCRIPTION_ERROR',
	'UNPAID': 'UNPAID',
	'PAID': 'PAID',
	'INVOICES': 'INVOICES',
	'INCOMING_INVOICES': 'INCOMING_INVOICES',
	'PENDING': 'PENDING',
	'DATE': 'DATE',
	'AMOUNT': 'AMOUNT',
	'STATUS': 'STATUS',
	'INCOMING': 'INCOMING',
	'SMARTPAYING_UPSELLING': 'SMARTPAYING_UPSELLING',
	'WIRE_TRANSFER': 'WIRE_TRANSFER',
	'NEXT_PAYMENT_LABEL': 'NEXT_PAYMENT_LABEL',
	'UNPAID_INVOICE_LABEL': 'UNPAID_INVOICE_LABEL',
	'AMOUNT_DUE': 'AMOUNT_DUE',
	'SUBSCRIPTION_CHANGES_SCHEDULED': 'SUBSCRIPTION_CHANGES_SCHEDULED',
	'SUBSCRIPTION_PRICE_CHANGE_TEMPLATE': 'SUBSCRIPTION_PRICE_CHANGE_TEMPLATE',
	'TERMINATING': 'TERMINATING',
	'TERMINATING_ON': 'TERMINATING_ON',
	'IN_TRIAL': 'IN_TRIAL',
	'FIRST_PAYMENT': 'FIRST_PAYMENT',
	'SUBSCRIPTION_TRIAL_OUTSIDE_TEXT': 'SUBSCRIPTION_TRIAL_OUTSIDE_TEXT',
	'SUBSCRIPTION_FUTURE_OUTSIDE_TEXT': 'SUBSCRIPTION_FUTURE_OUTSIDE_TEXT',
	'PAUSED': 'PAUSED',
	'PAUSED_ON': 'PAUSED_ON',
	'MULTIPLE_UNPAID_INVOICES': 'MULTIPLE_UNPAID_INVOICES',
	'ONE_TIME_INVOICE_LABEL': 'ONE_TIME_INVOICE_LABEL',
	'INVOICE_MIGRATION_DISCLAIMER': 'INVOICE_MIGRATION_DISCLAIMER',
	'YEAR': 'YEAR',
	'VAT_LABEL': 'VAT_LABEL',
	'FEEDBACK_CATEGORY_PRICE_LIST_SAVE': 'FEEDBACK_CATEGORY_PRICE_LIST_SAVE',
	'FEEDBACK_TITLE_PRICE_LIST_SAVE': 'FEEDBACK_TITLE_PRICE_LIST_SAVE',
	'FEEDBACK_DESCR_PRICE_LIST_SAVE': 'FEEDBACK_DESCR_PRICE_LIST_SAVE',
	'ACADEMY': 'ACADEMY',
	'NO_INVOICES_MESSAGE': 'NO_INVOICES_MESSAGE',
	'COMPETITORS_NOT_SELECTABLE_TEXT': 'COMPETITORS_NOT_SELECTABLE_TEXT',
	'MARKET_TREND_SELECT_ACCOMMODATION_DISABLED': 'MARKET_TREND_SELECT_ACCOMMODATION_DISABLED',
	'BILLING_COMPANY_NAME': 'BILLING_COMPANY_NAME',
	'BILLING_COMPANY_NAME_HINT': 'BILLING_COMPANY_NAME_HINT',
	'BILLING_FISCAL_CODE': 'BILLING_FISCAL_CODE',
	'BILLING_RECIPIENT_CODE': 'BILLING_RECIPIENT_CODE',
	'ORPHAN_NIGHTS': 'ORPHAN_NIGHTS',
	'ORPHAN_NIGHTS_TITLE': 'ORPHAN_NIGHTS_TITLE',
	'ORPHAN_NIGHTS_SELECT_ACCOMMODATION': 'ORPHAN_NIGHTS_SELECT_ACCOMMODATION',
	'ORPHAN_NIGHTS_ACTIVATE': 'ORPHAN_NIGHTS_ACTIVATE',
	'ORPHAN_NIGHTS_ACTIVATE_TOGGLE_YES': 'ORPHAN_NIGHTS_ACTIVATE_TOGGLE_YES',
	'ORPHAN_NIGHTS_ACTIVATE_TOGGLE_NO': 'ORPHAN_NIGHTS_ACTIVATE_TOGGLE_NO',
	'ORPHAN_NIGHTS_MAIN_CARD_TITLE': 'ORPHAN_NIGHTS_MAIN_CARD_TITLE',
	'ORPHAN_NIGHTS_MAIN_CARD_BODY': 'ORPHAN_NIGHTS_MAIN_CARD_BODY',
	'ORPHAN_NIGHTS_CARD_1_TITLE': 'ORPHAN_NIGHTS_CARD_1_TITLE',
	'ORPHAN_NIGHTS_CARD_1_BODY': 'ORPHAN_NIGHTS_CARD_1_BODY',
	'ORPHAN_NIGHTS_CARD_2_TITLE': 'ORPHAN_NIGHTS_CARD_2_TITLE',
	'ORPHAN_NIGHTS_CARD_2_BODY': 'ORPHAN_NIGHTS_CARD_2_BODY',
	'ORPHAN_NIGHTS_CARD_3_TITLE': 'ORPHAN_NIGHTS_CARD_3_TITLE',
	'ORPHAN_NIGHTS_CARD_3_BODY': 'ORPHAN_NIGHTS_CARD_3_BODY',
	'ORPHAN_NIGHTS_NOT_AVAILABLE_TITLE': 'ORPHAN_NIGHTS_NOT_AVAILABLE_TITLE',
	'ORPHAN_NIGHTS_NOT_AVAILABLE_BODY': 'ORPHAN_NIGHTS_NOT_AVAILABLE_BODY',
	'ORPHAN_NIGHTS_CALENDAR_LEGEND': 'ORPHAN_NIGHTS_CALENDAR_LEGEND',
	'ORPHAN_NIGHTS_CALENDAR_PRICE_DETAIL': 'ORPHAN_NIGHTS_CALENDAR_PRICE_DETAIL',
	'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_SUCCESS_TITLE': 'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_SUCCESS_TITLE',
	'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_SUCCESS_DESCRIPTION': 'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_SUCCESS_DESCRIPTION',
	'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_ERROR_TITLE': 'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_ERROR_TITLE',
	'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_ERROR_DESCRIPTION': 'UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_ERROR_DESCRIPTION',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_ERROR': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_ERROR',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_CITY_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_CITY_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_CITY_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_CITY_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_CITY_ERROR': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_CITY_ERROR',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_SEARCH': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_SEARCH',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_SELECT_ACCOMMODATION': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_SELECT_ACCOMMODATION',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_NOT_FOUND': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_ACCOMMODATION_NOT_FOUND',
	'AUTO_ONBOARDING_FIND_PROPERTY_FIND_NO_RESULTS': 'AUTO_ONBOARDING_FIND_PROPERTY_FIND_NO_RESULTS',
	'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_DESCRIPTION': 'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_DESCRIPTION',
	'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_NOT_AVAILABLE': 'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_NOT_AVAILABLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_DESC_1': 'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_DESC_1',
	'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_DESC_2': 'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_DESC_2',
	'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_BACK_BUTTON': 'AUTO_ONBOARDING_FIND_PROPERTY_NO_BOOKING_URL_BACK_BUTTON',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_TYPE_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_TYPE_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_TYPE_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_TYPE_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ROOM_TYPES_COUNT_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ROOM_TYPES_COUNT_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ROOM_TYPES_COUNT_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ROOM_TYPES_COUNT_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_FACILITIES_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_FACILITIES_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_URLS_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_URLS_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_AIRBNB_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_AIRBNB_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_AIRBNB_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_AIRBNB_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_WEBSITE_LABEL': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_WEBSITE_LABEL',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_WEBSITE_PLACEHOLDER': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_WEBSITE_PLACEHOLDER',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_INPUT_HELPER': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_INPUT_HELPER',
	'AUTO_ONBOARDING_FIND_PROPERTY_DOCUMENTATION_PANEL_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_DOCUMENTATION_PANEL_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_DOCUMENTATION_PANEL_DESCRIPTION': 'AUTO_ONBOARDING_FIND_PROPERTY_DOCUMENTATION_PANEL_DESCRIPTION',
	'AUTO_ONBOARDING_FIND_PROPERTY_DOCUMENTATION_PANEL_CONTENT': 'AUTO_ONBOARDING_FIND_PROPERTY_DOCUMENTATION_PANEL_CONTENT',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_FIELDS_WARNING': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_FIELDS_WARNING',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_X_STAR': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_X_STAR',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_X_STARS': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_ACCOMMODATION_RATING_X_STARS',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_TITLE': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_TITLE',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_CONTEXT': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_CONTEXT',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_DESCRIPTION': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_DESCRIPTION',
	'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_CONTENT': 'AUTO_ONBOARDING_FIND_PROPERTY_VERIFY_INFORMATION_DOCUMENTATION_PANEL_CONTENT',
	'SMARTPAYING_MARKETPLACE_AUTOMATION_CONFIGURATION': 'SMARTPAYING_MARKETPLACE_AUTOMATION_CONFIGURATION',
	'SMARTPAYING_MARKETPLACE_ONLY_CONFIGURATION': 'SMARTPAYING_MARKETPLACE_ONLY_CONFIGURATION',
	'SMARTPAYING_MARKETPLACE_ONLY_CONFIGURATION_TEXT': 'SMARTPAYING_MARKETPLACE_ONLY_CONFIGURATION_TEXT',
	'SMARTPAYING_MARKETPLACE_VIA_EMAIL': 'SMARTPAYING_MARKETPLACE_VIA_EMAIL',
	'SMARTPAYING_MARKETPLACE_SEND_TEST': 'SMARTPAYING_MARKETPLACE_SEND_TEST',
	'SMARTPAYING_MARKETPLACE_AFTER_RESERVATION': 'SMARTPAYING_MARKETPLACE_AFTER_RESERVATION',
	'SMARTPAYING_MARKETPLACE_YES': 'SMARTPAYING_MARKETPLACE_YES',
	'SMARTPAYING_MARKETPLACE_NO': 'SMARTPAYING_MARKETPLACE_NO',
	'SMARTPAYING_MARKETPLACE_ENTER_THE_VALUE': 'SMARTPAYING_MARKETPLACE_ENTER_THE_VALUE',
	'SMARTPAYING_MARKETPLACE_DAYS': 'SMARTPAYING_MARKETPLACE_DAYS',
	'SMARTPAYING_MARKETPLACE_BEFORE_CHECKIN': 'SMARTPAYING_MARKETPLACE_BEFORE_CHECKIN',
	'SMARTPAYING_MARKETPLACE_VIA_WHATSAPP': 'SMARTPAYING_MARKETPLACE_VIA_WHATSAPP',
	'SMARTPAYING_MARKETPLACE_HOW_WORK': 'SMARTPAYING_MARKETPLACE_HOW_WORK',
	'SMARTPAYING_MARKETPLACE_NEXT_STEP': 'SMARTPAYING_MARKETPLACE_NEXT_STEP',
	'SMARTPAYING_MARKETPLACE_STEP': 'SMARTPAYING_MARKETPLACE_STEP',
	'SMARTPAYING_MARKETPLACE_OF': 'SMARTPAYING_MARKETPLACE_OF',
	'SMARTPAYING_MARKETPLACE_UPSELLING': 'SMARTPAYING_MARKETPLACE_UPSELLING',
	'SMARTPAYING_MARKETPLACE_CONFIGURE_UPSELLING': 'SMARTPAYING_MARKETPLACE_CONFIGURE_UPSELLING',
	'SMARTPAYING_MARKETPLACE_CONFIGURE_UPSELLING_TEXT': 'SMARTPAYING_MARKETPLACE_CONFIGURE_UPSELLING_TEXT',
	'SMARTPAYING_MARKETPLACE_SEND_EMAIL_TEXT': 'SMARTPAYING_MARKETPLACE_SEND_EMAIL_TEXT',
	'SMARTPAYING_MARKETPLACE_INSERT_EMAIL': 'SMARTPAYING_MARKETPLACE_INSERT_EMAIL',
	'SMARTPAYING_MARKETPLACE_EMAIL': 'SMARTPAYING_MARKETPLACE_EMAIL',
	'SMARTPAYING_MARKETPLACE_CANCEL': 'SMARTPAYING_MARKETPLACE_CANCEL',
	'SMARTPAYING_MARKETPLACE_SEND': 'SMARTPAYING_MARKETPLACE_SEND',
	'SMARTPAYING_MARKETPLACE_HOW_WORK_TEXT': 'SMARTPAYING_MARKETPLACE_HOW_WORK_TEXT',
	'SMARTPAYING_MARKETPLACE_HOW_IT_WORKS': 'SMARTPAYING_MARKETPLACE_HOW_IT_WORKS',
	'SMARTPAYING_MARKETPLACE_MARKETPLACE': 'SMARTPAYING_MARKETPLACE_MARKETPLACE',
	'SMARTPAYING_MARKETPLACE_MARKETPLACE_PLATFORM': 'SMARTPAYING_MARKETPLACE_MARKETPLACE_PLATFORM',
	'SMARTPAYING_MARKETPLACE_EDIT_MARKETPLACE': 'SMARTPAYING_MARKETPLACE_EDIT_MARKETPLACE',
	'SMARTPAYING_MARKETPLACE_MARKETPLACE_PLATFORM_TEXT': 'SMARTPAYING_MARKETPLACE_MARKETPLACE_PLATFORM_TEXT',
	'SMARTPAYING_MARKETPLACE_MARKETPLACE_LINK': 'SMARTPAYING_MARKETPLACE_MARKETPLACE_LINK',
	'SMARTPAYING_MARKETPLACE_MARKETPLACE_LINK_TEXT': 'SMARTPAYING_MARKETPLACE_MARKETPLACE_LINK_TEXT',
	'SMARTPAYING_MARKETPLACE_COPY_LINK': 'SMARTPAYING_MARKETPLACE_COPY_LINK',
	'SMARTPAYING_MARKETPLACE_COPIED_LINK': 'SMARTPAYING_MARKETPLACE_COPIED_LINK',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_INFO': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_INFO',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_INFO_TEXT': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_INFO_TEXT',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_ACTIVATE': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_ACTIVATE',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_ACTIVATE_TEXT': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_ACTIVATE_TEXT',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_INACTIVE': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_INACTIVE',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_ACTIVE': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_ACTIVE',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_DAYS_AFTER': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_DAYS_AFTER',
	'SMARTPAYING_MARKETPLACE_USEFUL_INFORMATION': 'SMARTPAYING_MARKETPLACE_USEFUL_INFORMATION',
	'SMARTPAYING_MARKETPLACE_AUTOMATION': 'SMARTPAYING_MARKETPLACE_AUTOMATION',
	'SMARTPAYING_MARKETPLACE_TEXT_AUTOMATION': 'SMARTPAYING_MARKETPLACE_TEXT_AUTOMATION',
	'SMARTPAYING_MARKETPLACE_SENDING_MODE': 'SMARTPAYING_MARKETPLACE_SENDING_MODE',
	'SMARTPAYING_MARKETPLACE_TEXT_SENDING_MODE': 'SMARTPAYING_MARKETPLACE_TEXT_SENDING_MODE',
	'SMARTPAYING_MARKETPLACE_LAST_MINUTE_BOOKING': 'SMARTPAYING_MARKETPLACE_LAST_MINUTE_BOOKING',
	'SMARTPAYING_MARKETPLACE_LAST_MINUTE_BOOKING_TEXT': 'SMARTPAYING_MARKETPLACE_LAST_MINUTE_BOOKING_TEXT',
	'SMARTPAYING_MARKETPLACE_ACTIVATION': 'SMARTPAYING_MARKETPLACE_ACTIVATION',
	'SMARTPAYING_MARKETPLACE_TEXT_ACTIVATION': 'SMARTPAYING_MARKETPLACE_TEXT_ACTIVATION',
	'SMARTPAYING_MARKETPLACE_AUTOMATED_COMMUNICATIONS_SUMMARY': 'SMARTPAYING_MARKETPLACE_AUTOMATED_COMMUNICATIONS_SUMMARY',
	'SMARTPAYING_MARKETPLACE_WHATSAPP_OPERATION': 'SMARTPAYING_MARKETPLACE_WHATSAPP_OPERATION',
	'SMARTPAYING_MARKETPLACE_WHATSAPP_OPERATION_TEXT': 'SMARTPAYING_MARKETPLACE_WHATSAPP_OPERATION_TEXT',
	'SMARTPAYING_MARKETPLACE_EMAIL_OPERATION': 'SMARTPAYING_MARKETPLACE_EMAIL_OPERATION',
	'SMARTPAYING_MARKETPLACE_EMAIL_OPERATION_TEXT': 'SMARTPAYING_MARKETPLACE_EMAIL_OPERATION_TEXT',
	'SMARTPAYING_PAYMENTS_RESERVATION_PAYMENTS': 'SMARTPAYING_PAYMENTS_RESERVATION_PAYMENTS',
	'SMARTPAYING_PAYMENTS_SEARCH_BY_ID_CUSTOMER': 'SMARTPAYING_PAYMENTS_SEARCH_BY_ID_CUSTOMER',
	'SMARTPAYING_PAYMENTS_ALL_STATUS': 'SMARTPAYING_PAYMENTS_ALL_STATUS',
	'SMARTPAYING_PAYMENTS_RESERVATIONS': 'SMARTPAYING_PAYMENTS_RESERVATIONS',
	'SMARTPAYING_PAYMENTS_FOUND': 'SMARTPAYING_PAYMENTS_FOUND',
	'SMARTPAYING_PAYMENTS_RESET_FILTERS': 'SMARTPAYING_PAYMENTS_RESET_FILTERS',
	'SMARTPAYING_PAYMENTS_RESETTING_THE_FILTERS_WILL_RETURN_YOU_TO_THE_DEFAULT_SETTINGS': 'SMARTPAYING_PAYMENTS_RESETTING_THE_FILTERS_WILL_RETURN_YOU_TO_THE_DEFAULT_SETTINGS',
	'SMARTPAYING_PAYMENTS_EXPORT_DATA': 'SMARTPAYING_PAYMENTS_EXPORT_DATA',
	'SMARTPAYING_PAYMENTS_LAST_30_DAYS': 'SMARTPAYING_PAYMENTS_LAST_30_DAYS',
	'SMARTPAYING_PAYMENTS_SENDS': 'SMARTPAYING_PAYMENTS_SENDS',
	'SMARTPAYING_PAYMENTS_RESERVATION_ID': 'SMARTPAYING_PAYMENTS_RESERVATION_ID',
	'SMARTPAYING_PAYMENTS_CUSTOMER': 'SMARTPAYING_PAYMENTS_CUSTOMER',
	'SMARTPAYING_PAYMENTS_BILL_STATUS': 'SMARTPAYING_PAYMENTS_BILL_STATUS',
	'SMARTPAYING_PAYMENTS_TO_COLLECT': 'SMARTPAYING_PAYMENTS_TO_COLLECT',
	'SMARTPAYING_PAYMENTS_TOTAL_COLLECTED': 'SMARTPAYING_PAYMENTS_TOTAL_COLLECTED',
	'SMARTPAYING_PAYMENTS_ADD_NEW_PAYMENT': 'SMARTPAYING_PAYMENTS_ADD_NEW_PAYMENT',
	'SMARTPAYING_PAYMENTS_PAYMENT_LIST': 'SMARTPAYING_PAYMENTS_PAYMENT_LIST',
	'SMARTPAYING_PAYMENTS_PAYMENT': 'SMARTPAYING_PAYMENTS_PAYMENT',
	'SMARTPAYING_PAYMENTS_OTHER': 'SMARTPAYING_PAYMENTS_OTHER',
	'SMARTPAYING_PAYMENTS_COLLECTED': 'SMARTPAYING_PAYMENTS_COLLECTED',
	'SMARTPAYING_PAYMENTS_PARTIALLY_COLLECTED': 'SMARTPAYING_PAYMENTS_PARTIALLY_COLLECTED',
	'SMARTPAYING_PAYMENTS_REFOUNDED': 'SMARTPAYING_PAYMENTS_REFOUNDED',
	'SMARTPAYING_PAYMENTS_DELETED': 'SMARTPAYING_PAYMENTS_DELETED',
	'SMARTPAYING_PAYMENTS_NOT_AVAILABLE': 'SMARTPAYING_PAYMENTS_NOT_AVAILABLE',
	'SMARTPAYING_PAYMENTS_REFOUND': 'SMARTPAYING_PAYMENTS_REFOUND',
	'SMARTPAYING_PAYMENTS_EDIT': 'SMARTPAYING_PAYMENTS_EDIT',
	'SMARTPAYING_PAYMENTS_DELETE': 'SMARTPAYING_PAYMENTS_DELETE',
	'SMARTPAYING_PAYMENTS_EMAIL': 'SMARTPAYING_PAYMENTS_EMAIL',
	'SMARTPAYING_PAYMENTS_WHATSAPP': 'SMARTPAYING_PAYMENTS_WHATSAPP',
	'SMARTPAYING_PAYMENTS_COPY_LINK': 'SMARTPAYING_PAYMENTS_COPY_LINK',
	'SMARTPAYING_PAYMENTS_NO_RESERVATION_FOUND': 'SMARTPAYING_PAYMENTS_NO_RESERVATION_FOUND',
	'SMARTPAYING_PAYMENTS_LIST_OF_SENDS': 'SMARTPAYING_PAYMENTS_LIST_OF_SENDS',
	'SMARTPAYING_PAYMENTS_ACTION_TYPE': 'SMARTPAYING_PAYMENTS_ACTION_TYPE',
	'SMARTPAYING_PAYMENTS_SENDING_BILL': 'SMARTPAYING_PAYMENTS_SENDING_BILL',
	'SMARTPAYING_PAYMENTS_PAYMENTS': 'SMARTPAYING_PAYMENTS_PAYMENTS',
	'SMARTPAYING_PAYMENTS_PAYMENTS_SENT': 'SMARTPAYING_PAYMENTS_PAYMENTS_SENT',
	'SMARTPAYING_PAYMENTS_MARKETPLACE_AFTER_RESERVATION': 'SMARTPAYING_PAYMENTS_MARKETPLACE_AFTER_RESERVATION',
	'SMARTPAYING_PAYMENTS_MARKETPLACE': 'SMARTPAYING_PAYMENTS_MARKETPLACE',
	'SMARTPAYING_PAYMENTS_MANUAL_SEND': 'SMARTPAYING_PAYMENTS_MANUAL_SEND',
	'SMARTPAYING_PAYMENTS_AUTOMATIC_SEND': 'SMARTPAYING_PAYMENTS_AUTOMATIC_SEND',
	'SMARTPAYING_PAYMENTS_STATUS': 'SMARTPAYING_PAYMENTS_STATUS',
	'SMARTPAYING_PAYMENTS_SENT': 'SMARTPAYING_PAYMENTS_SENT',
	'SMARTPAYING_PAYMENTS_UNSENT': 'SMARTPAYING_PAYMENTS_UNSENT',
	'SMARTPAYING_PAYMENTS_CUSTOMER_DETAILS': 'SMARTPAYING_PAYMENTS_CUSTOMER_DETAILS',
	'SMARTPAYING_PAYMENTS_NATIONALITY': 'SMARTPAYING_PAYMENTS_NATIONALITY',
	'SMARTPAYING_PAYMENTS_MOBILE_PHONE': 'SMARTPAYING_PAYMENTS_MOBILE_PHONE',
	'SMARTPAYING_PAYMENTS_CHANNEL': 'SMARTPAYING_PAYMENTS_CHANNEL',
	'SMARTPAYING_PAYMENTS_PAYMENT_NAME': 'SMARTPAYING_PAYMENTS_PAYMENT_NAME',
	'SMARTPAYING_PAYMENTS_PAYMENT_STATUS': 'SMARTPAYING_PAYMENTS_PAYMENT_STATUS',
	'SMARTPAYING_PAYMENTS_THIRD_PARTS': 'SMARTPAYING_PAYMENTS_THIRD_PARTS',
	'SMARTPAYING_PAYMENTS_NEW_PAYMENT': 'SMARTPAYING_PAYMENTS_NEW_PAYMENT',
	'SMARTPAYING_PAYMENTS_PAYMENT_TYPE': 'SMARTPAYING_PAYMENTS_PAYMENT_TYPE',
	'SMARTPAYING_PAYMENTS_CHOOSE_AN_OPTION': 'SMARTPAYING_PAYMENTS_CHOOSE_AN_OPTION',
	'SMARTPAYING_PAYMENTS_CANCEL': 'SMARTPAYING_PAYMENTS_CANCEL',
	'SMARTPAYING_PAYMENTS_CREATE_PAYMENT': 'SMARTPAYING_PAYMENTS_CREATE_PAYMENT',
	'SMARTPAYING_PAYMENTS_PAYMENT_CREATED': 'SMARTPAYING_PAYMENTS_PAYMENT_CREATED',
	'SMARTPAYING_PAYMENTS_THE_PAYMENT_WAS_SUCCESSFULLY_CREATED': 'SMARTPAYING_PAYMENTS_THE_PAYMENT_WAS_SUCCESSFULLY_CREATED',
	'SMARTPAYING_PAYMENTS_EXTRA': 'SMARTPAYING_PAYMENTS_EXTRA',
	'SMARTPAYING_PAYMENTS_INFORMATIONS': 'SMARTPAYING_PAYMENTS_INFORMATIONS',
	'SMARTPAYING_PAYMENTS_NAME': 'SMARTPAYING_PAYMENTS_NAME',
	'SMARTPAYING_PAYMENTS_ENTER_THE_EXTRA_S_NAME': 'SMARTPAYING_PAYMENTS_ENTER_THE_EXTRA_S_NAME',
	'SMARTPAYING_PAYMENTS_AMOUNT': 'SMARTPAYING_PAYMENTS_AMOUNT',
	'SMARTPAYING_PAYMENTS_CITY_TAX': 'SMARTPAYING_PAYMENTS_CITY_TAX',
	'SMARTPAYING_PAYMENTS_DEPOSIT': 'SMARTPAYING_PAYMENTS_DEPOSIT',
	'SMARTPAYING_PAYMENTS_STAY_AMOUNT': 'SMARTPAYING_PAYMENTS_STAY_AMOUNT',
	'SMARTPAYING_PAYMENTS_TO_VIEW_THE_BILL_STATUS_YOU_MUST_CREATE_THE_FIRST_PAYMENT': 'SMARTPAYING_PAYMENTS_TO_VIEW_THE_BILL_STATUS_YOU_MUST_CREATE_THE_FIRST_PAYMENT',
	'SMARTPAYING_PAYMENTS_ADDING_THE_FIRST_PAYMENT_YOU_WILL_ABLE_TO_SEND_PAYMENT_COMUNICATIONS': 'SMARTPAYING_PAYMENTS_ADDING_THE_FIRST_PAYMENT_YOU_WILL_ABLE_TO_SEND_PAYMENT_COMUNICATIONS',
	'SMARTPAYING_PAYMENTS_ADD_RESERVATION': 'SMARTPAYING_PAYMENTS_ADD_RESERVATION',
	'SMARTPAYING_PAYMENTS_STAY': 'SMARTPAYING_PAYMENTS_STAY',
	'SMARTPAYING_PAYMENTS_CREATE_RESERVATION': 'SMARTPAYING_PAYMENTS_CREATE_RESERVATION',
	'SMARTPAYING_PAYMENTS_NEW_RESERVATION': 'SMARTPAYING_PAYMENTS_NEW_RESERVATION',
	'STRATEGY': 'STRATEGY',
	'STRATEGY_UNDO_CHANGES': 'STRATEGY_UNDO_CHANGES',
	'STRATEGY_RESET_DEFAULT_STRATEGY': 'STRATEGY_RESET_DEFAULT_STRATEGY',
	'STRATEGY_SAVE': 'STRATEGY_SAVE',
	'STRATEGY_PERIOD': 'STRATEGY_PERIOD',
	'STRATEGY_NEW_PERIOD': 'STRATEGY_NEW_PERIOD',
	'STRATEGY_CONFIGURATION': 'STRATEGY_CONFIGURATION',
	'STRATEGY_PRICE_TRENDS': 'STRATEGY_PRICE_TRENDS',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_1_TITLE': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_1_TITLE',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_2_TITLE': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_2_TITLE',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_3_TITLE': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_3_TITLE',
	'STRATEGY_DOCUMENTATION_PANEL_SECTION_4_TITLE': 'STRATEGY_DOCUMENTATION_PANEL_SECTION_4_TITLE',
	'STRATEGY_OVERVIEW_DEFAULT_STRATEGY': 'STRATEGY_OVERVIEW_DEFAULT_STRATEGY',
	'STRATEGY_CONFIGURATION_CUSTOM_PERIODS': 'STRATEGY_CONFIGURATION_CUSTOM_PERIODS',
	'STRATEGY_CONFIGURATION_CUSTOM_PERIODS_HINT': 'STRATEGY_CONFIGURATION_CUSTOM_PERIODS_HINT',
	'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_TITLE': 'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_TITLE',
	'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_SUBTITLE': 'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_SUBTITLE',
	'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_HINT': 'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_HINT',
	'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_CALENDAR_LABEL': 'STRATEGY_CONFIGURATION_ADD_CUSTOM_PERIOD_MODAL_CALENDAR_LABEL',
	'STRATEGY_CONFIGURATION_DELETE_CUSTOM_PERIOD_MODAL_TITLE': 'STRATEGY_CONFIGURATION_DELETE_CUSTOM_PERIOD_MODAL_TITLE',
	'STRATEGY_CONFIGURATION_DELETE_CUSTOM_PERIOD_MODAL_HINT': 'STRATEGY_CONFIGURATION_DELETE_CUSTOM_PERIOD_MODAL_HINT',
	'STRATEGY_CONFIGURATION_RESET_STRATEGY_CHANGES_MODAL_TITLE': 'STRATEGY_CONFIGURATION_RESET_STRATEGY_CHANGES_MODAL_TITLE',
	'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_TITLE': 'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_TITLE',
	'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_HINT': 'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_HINT',
	'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_WITH_SAVE': 'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_WITH_SAVE',
	'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_WITHOUT_SAVE': 'STRATEGY_CONFIGURATION_UNSAVED_CHANGES_MODAL_WITHOUT_SAVE',
	'STRATEGY_CONFIGURATION_MODIFIERS_HINT': 'STRATEGY_CONFIGURATION_MODIFIERS_HINT',
	'STRATEGY_CONFIGURATION_MODIFIERS_FROM': 'STRATEGY_CONFIGURATION_MODIFIERS_FROM',
	'STRATEGY_CONFIGURATION_MODIFIERS_TO': 'STRATEGY_CONFIGURATION_MODIFIERS_TO',
	'STRATEGY_CONFIGURATION_MODIFIERS_STRATEGY_SETTINGS': 'STRATEGY_CONFIGURATION_MODIFIERS_STRATEGY_SETTINGS',
	'STRATEGY_CONFIGURATION_MODIFIERS_STARTING_PRICE_SETTINGS': 'STRATEGY_CONFIGURATION_MODIFIERS_STARTING_PRICE_SETTINGS',
	'STRATEGY_CONFIGURATION_DERIVED_STRATEGY_WARNING_TITLE': 'STRATEGY_CONFIGURATION_DERIVED_STRATEGY_WARNING_TITLE',
	'STRATEGY_CHART_CURRENT_STRATEGY': 'STRATEGY_CHART_CURRENT_STRATEGY',
	'STRATEGY_CHART_EDITED_STRATEGY': 'STRATEGY_CHART_EDITED_STRATEGY',
	'STRATEGY_CHART_STARTING_PRICES': 'STRATEGY_CHART_STARTING_PRICES',
	'STRATEGY_CHART_CLICK_TOOLTIP_EDIT_BASE_STRATEGY': 'STRATEGY_CHART_CLICK_TOOLTIP_EDIT_BASE_STRATEGY',
	'STRATEGY_CHART_CLICK_TOOLTIP_NEW_PERIOD': 'STRATEGY_CHART_CLICK_TOOLTIP_NEW_PERIOD',
	'STRATEGY_CHART_CLICK_TOOLTIP_EDIT_PERIOD': 'STRATEGY_CHART_CLICK_TOOLTIP_EDIT_PERIOD',
	'BASE_PRICES_DUPLICATION_NEGATIVE_VALUES': 'BASE_PRICES_DUPLICATION_NEGATIVE_VALUES',
	'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_DESC_1': 'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_DESC_1',
	'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_DESC_2': 'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_DESC_2',
	'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_POINT_1': 'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_POINT_1',
	'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_POINT_2': 'AUTO_ONBOARDING_ROOMS_MAPPING_INTRO_POINT_2',
	'CONTINUE': 'CONTINUE',
	'YOUR_ACCOMMODATION': 'YOUR_ACCOMMODATION',
	'NEED_HELP': 'NEED_HELP',
	'STEP_X_OF_X': 'STEP_X_OF_X',
	'AUTO_ONBOARDING_PMS_TITLE': 'AUTO_ONBOARDING_PMS_TITLE',
	'AUTO_ONBOARDING_PMS_PMS_INPUT_LABEL': 'AUTO_ONBOARDING_PMS_PMS_INPUT_LABEL',
	'AUTO_ONBOARDING_PMS_PMS_INPUT_PLACEHOLDER': 'AUTO_ONBOARDING_PMS_PMS_INPUT_PLACEHOLDER',
	'AUTO_ONBOARDING_PMS_YOUR_PMS': 'AUTO_ONBOARDING_PMS_YOUR_PMS',
	'AUTO_ONBOARDING_PMS_PMS_ID_LABEL': 'AUTO_ONBOARDING_PMS_PMS_ID_LABEL',
	'AUTO_ONBOARDING_PMS_PMS_ID_PLACEHOLDER': 'AUTO_ONBOARDING_PMS_PMS_ID_PLACEHOLDER',
	'AUTO_ONBOARDING_PMS_WHERE_CAN_I_FIND': 'AUTO_ONBOARDING_PMS_WHERE_CAN_I_FIND',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_SUCCESSFUL_BUTTON': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_SUCCESSFUL_BUTTON',
	'AUTO_ONBOARDING_PMS_SLOW_CONNECTION_LOADER_SUBTITLE': 'AUTO_ONBOARDING_PMS_SLOW_CONNECTION_LOADER_SUBTITLE',
	'AUTO_ONBOARDING_PMS_SLOW_CONNECTION_LOADER_BUTTON': 'AUTO_ONBOARDING_PMS_SLOW_CONNECTION_LOADER_BUTTON',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_TITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_TITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_SUBTITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_SUBTITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_CONTEXT': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_CONTEXT',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_TITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_TITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_DESCRIPTION': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_DESCRIPTION',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_BANNER_TITLE': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_BANNER_TITLE',
	'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_BANNER_DESCRIPTION': 'AUTO_ONBOARDING_PMS_FAST_CONNECTION_ERROR_HELP_SPOT_BANNER_DESCRIPTION',
	'COMPETITORS_NOT_SELECTABLE_TEXT_DELETED_COMPSET': 'COMPETITORS_NOT_SELECTABLE_TEXT_DELETED_COMPSET',
	'COMPETITORS_NOT_SELECTABLE_TEXT_INSUFFICIENT_DATA': 'COMPETITORS_NOT_SELECTABLE_TEXT_INSUFFICIENT_DATA',
	'COMPETITORS_UNDO_ALL': 'COMPETITORS_UNDO_ALL',
	'COMPETITORS_RESET_DESC_1': 'COMPETITORS_RESET_DESC_1',
	'COMPETITORS_RESET_DESC_2': 'COMPETITORS_RESET_DESC_2',
	'COMPETITORS_RESET_TITLE': 'COMPETITORS_RESET_TITLE',
	'COMPETITORS_RESET_SUBTITLE': 'COMPETITORS_RESET_SUBTITLE',
	'SMARTPAYING_PAYMENTS_CUSTOMER_NAME': 'SMARTPAYING_PAYMENTS_CUSTOMER_NAME',
	'SMARTPAYING_PAYMENTS_ENTER_CUSTOMER_NAME': 'SMARTPAYING_PAYMENTS_ENTER_CUSTOMER_NAME',
	'SMARTPAYING_PAYMENTS_LENGTH_OF_STAY': 'SMARTPAYING_PAYMENTS_LENGTH_OF_STAY',
	'SMARTPAYING_PAYMENTS_THIS_INFORMATIONS_ARE_USEFUL_FOR_SENDING_PAYMENTS_COMUNICATIONS': 'SMARTPAYING_PAYMENTS_THIS_INFORMATIONS_ARE_USEFUL_FOR_SENDING_PAYMENTS_COMUNICATIONS',
	'SMARTPAYING_PAYMENTS_STAY_AND_OR_CITY_TAX': 'SMARTPAYING_PAYMENTS_STAY_AND_OR_CITY_TAX',
	'SMARTPAYING_PAYMENTS_INFORMATIONS_WILL_BE_DELETED': 'SMARTPAYING_PAYMENTS_INFORMATIONS_WILL_BE_DELETED',
	'SMARTPAYING_PAYMENTS_RESERVATION_CREATED': 'SMARTPAYING_PAYMENTS_RESERVATION_CREATED',
	'SMARTPAYING_PAYMENTS_THE_RESERVATION_WAS_SUCCESSFULLY_CREATED': 'SMARTPAYING_PAYMENTS_THE_RESERVATION_WAS_SUCCESSFULLY_CREATED',
	'SMARTPAYING_PAYMENTS_ENTER_CUSTOMER_EMAIL': 'SMARTPAYING_PAYMENTS_ENTER_CUSTOMER_EMAIL',
	'SMARTPAYING_PAYMENTS_PREFIX': 'SMARTPAYING_PAYMENTS_PREFIX',
	'SMARTPAYING_PAYMENTS_ENTER_CUSTOMER_PHONE': 'SMARTPAYING_PAYMENTS_ENTER_CUSTOMER_PHONE',
	'SMARTPAYING_PAYMENTS_CITY_TAX_AMOUNT': 'SMARTPAYING_PAYMENTS_CITY_TAX_AMOUNT',
	'SMARTPAYING_PAYMENTS_RESERVATION_DETAILS': 'SMARTPAYING_PAYMENTS_RESERVATION_DETAILS',
	'AUTOONBOARDING_HELP_NOTIFICATION_TITLE': 'AUTOONBOARDING_HELP_NOTIFICATION_TITLE',
	'AUTOONBOARDING_HELP_NOTIFICATION_DESCRIPTION': 'AUTOONBOARDING_HELP_NOTIFICATION_DESCRIPTION',
	'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_DAYS_BEFORE': 'SMARTPAYING_MARKETPLACE_COMMUNICATIONS_DAYS_BEFORE',
	'AUTOONBOARDING_STEPPER_DESCRIPTION': 'AUTOONBOARDING_STEPPER_DESCRIPTION',
	'AUTOONBOARDING_STEPPER_TITLE': 'AUTOONBOARDING_STEPPER_TITLE',
	'AUTOONBOARDING_STEPPER_STEP3': 'AUTOONBOARDING_STEPPER_STEP3',
	'AUTOONBOARDING_STEPPER_BUTTON_PMS': 'AUTOONBOARDING_STEPPER_BUTTON_PMS',
	'SMARTPAYING_PAYMENTS_SEND_PAYMENT': 'SMARTPAYING_PAYMENTS_SEND_PAYMENT',
	'SMARTPAYING_REGISTRY_WILL_BE_UPDATED': 'SMARTPAYING_REGISTRY_WILL_BE_UPDATED',
	'SMARTPAYING_PAYMENT_SENDING_INFORMATION': 'SMARTPAYING_PAYMENT_SENDING_INFORMATION',
	'SMARTPAYING_PAYMENT_TOTAL_SELECTED': 'SMARTPAYING_PAYMENT_TOTAL_SELECTED',
	'SMARTPAYING_PAYMENT_SENDING_TYPE': 'SMARTPAYING_PAYMENT_SENDING_TYPE',
	'SMARTPAYING_PAYMENT_THIS_WILL_BE_AN_EXAMPLE': 'SMARTPAYING_PAYMENT_THIS_WILL_BE_AN_EXAMPLE',
	'SMARTPAYING_SEND_PAYMENT_VIA_WA': 'SMARTPAYING_SEND_PAYMENT_VIA_WA',
	'SMARTPAYING_SEND_PAYMENT_VIA_MAIL': 'SMARTPAYING_SEND_PAYMENT_VIA_MAIL',
	'SMARTPAYING_SEND_PAYMENT_VIA_LINK': 'SMARTPAYING_SEND_PAYMENT_VIA_LINK',
	'SMARTPAYING_SEND_PAYMENT': 'SMARTPAYING_SEND_PAYMENT',
	'SMARTPAYING_PAYMENTS_WA_PREVIEW': 'SMARTPAYING_PAYMENTS_WA_PREVIEW',
	'SMARTPAYING_PAYMENTS_MAIL_PREVIEW': 'SMARTPAYING_PAYMENTS_MAIL_PREVIEW',
	'SMARTPAYING_PAYMENTS_LINK_COPIED_TEXT': 'SMARTPAYING_PAYMENTS_LINK_COPIED_TEXT',
	'SMARTPAYING_PAYMENTS_PAYMENT_SENT': 'SMARTPAYING_PAYMENTS_PAYMENT_SENT',
	'SMARTPAYING_PAYMENTS_PAYMENT_SENT_TEXT': 'SMARTPAYING_PAYMENTS_PAYMENT_SENT_TEXT',
	'SMARTPAYING_SEND_PAYMENT_REFUND': 'SMARTPAYING_SEND_PAYMENT_REFUND',
	'SMARTPAYING_SEND_PAYMENT_REFUND_SEND': 'SMARTPAYING_SEND_PAYMENT_REFUND_SEND',
	'SMARTPAYING_PAYMENTS_REFUND_SENT': 'SMARTPAYING_PAYMENTS_REFUND_SENT',
	'SMARTPAYING_PAYMENTS_REFUND_SENT_TEXT': 'SMARTPAYING_PAYMENTS_REFUND_SENT_TEXT',
	'SMARTPAYING_PAYMENTS_DELETE_RESERVATION': 'SMARTPAYING_PAYMENTS_DELETE_RESERVATION',
	'SMARTPAYING_PAYMENTS_ARE_YOU_SURE_DELETE_RESERVATION': 'SMARTPAYING_PAYMENTS_ARE_YOU_SURE_DELETE_RESERVATION',
	'SMARTPAYING_PAYMENTS_RESERVATION_STILL_VISIBLE': 'SMARTPAYING_PAYMENTS_RESERVATION_STILL_VISIBLE',
	'SMARTPAYING_PAYMENTS_RESERVATION_DELETED': 'SMARTPAYING_PAYMENTS_RESERVATION_DELETED',
	'SMARTPAYING_PAYMENTS_RESERVATION_SUCCESSFULLY_DELETED': 'SMARTPAYING_PAYMENTS_RESERVATION_SUCCESSFULLY_DELETED',
	'SMARTPAYING_PAYMENTS_ARE_YOU_SURE_DELETE_PAYMENT': 'SMARTPAYING_PAYMENTS_ARE_YOU_SURE_DELETE_PAYMENT',
	'SMARTPAYING_PAYMENTS_PAYMENT_NO_LONGER_VISIBLE': 'SMARTPAYING_PAYMENTS_PAYMENT_NO_LONGER_VISIBLE',
	'SMARTPAYING_PAYMENTS_PAYMENT_DELETED': 'SMARTPAYING_PAYMENTS_PAYMENT_DELETED',
	'SMARTPAYING_PAYMENTS_PAYMENT_SUCCESSFULLY_DELETED': 'SMARTPAYING_PAYMENTS_PAYMENT_SUCCESSFULLY_DELETED',
	'SMARTPAYING_PAYMENTS_DELETE_PAYMENT': 'SMARTPAYING_PAYMENTS_DELETE_PAYMENT',
	'SMARTPAYING_PAYMENTS_RESERVATION_DETAIL': 'SMARTPAYING_PAYMENTS_RESERVATION_DETAIL',
	'SMARTPAYING_PAYMENTS_OVERVIEW': 'SMARTPAYING_PAYMENTS_OVERVIEW',
	'SMARTPAYING_PAYMENTS_DETAILS': 'SMARTPAYING_PAYMENTS_DETAILS',
	'SMARTPAYING_MEX_KIND_manualPayment': 'SMARTPAYING_MEX_KIND_manualPayment',
	'SMARTPAYING_MEX_KIND_refundGuest': 'SMARTPAYING_MEX_KIND_refundGuest',
	'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_MANDATORY_ERROR': 'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_MANDATORY_ERROR',
	'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_INVALID_ERROR': 'AUTO_ONBOARDING_FIND_PROPERTY_BOOKING_URL_INVALID_ERROR',
	'AUTO_ONBOARDING_FIND_PROPERTY_WEB_INFO_INPUT_ERROR': 'AUTO_ONBOARDING_FIND_PROPERTY_WEB_INFO_INPUT_ERROR',
	'SMARTPAYING_PAYMENTS_SEND': 'SMARTPAYING_PAYMENTS_SEND',
	'SMARTPAYING_PAYMENTS_PAYMENT_MOMENT': 'SMARTPAYING_PAYMENTS_PAYMENT_MOMENT',
	'SMARTPAYING_MARKETPLACE_UPDATE_SECURITY_SETTINGS': 'SMARTPAYING_MARKETPLACE_UPDATE_SECURITY_SETTINGS',
	'SMARTPAYING_MARKETPLACE_AUTOMATED_COMMUNICATION_ADJUSTMENTS': 'SMARTPAYING_MARKETPLACE_AUTOMATED_COMMUNICATION_ADJUSTMENTS',
	'SMARTPAYING_MARKETPLACE_LEARN_MORE': 'SMARTPAYING_MARKETPLACE_LEARN_MORE',
	'SMARTPAYING_MARKETPLACE_DETAILED_UPDATE_AND_ADJUSTMENTS': 'SMARTPAYING_MARKETPLACE_DETAILED_UPDATE_AND_ADJUSTMENTS',
	'SMARTPAYING_MARKETPLACE_SINGLE_PROPERTY': 'SMARTPAYING_MARKETPLACE_SINGLE_PROPERTY',
	'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_ADDRESSES': 'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_ADDRESSES',
	'SMARTPAYING_MARKETPLACE_EMAIL_AND_SECURITY_SETTINGS_STEPS': 'SMARTPAYING_MARKETPLACE_EMAIL_AND_SECURITY_SETTINGS_STEPS',
	'SMARTPAYING_MARKETPLACE_ADD_EMAIL_DOMAIN': 'SMARTPAYING_MARKETPLACE_ADD_EMAIL_DOMAIN',
	'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_LINKS': 'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_LINKS',
	'SMARTPAYING_MARKETPLACE_MULTIPLE_PROPERTIES': 'SMARTPAYING_MARKETPLACE_MULTIPLE_PROPERTIES',
	'SMARTPAYING_MARKETPLACE_SETTINGS_OVERVIEW': 'SMARTPAYING_MARKETPLACE_SETTINGS_OVERVIEW',
	'SMARTPAYING_MARKETPLACE_SINGLE_PROPERTY_OPTION': 'SMARTPAYING_MARKETPLACE_SINGLE_PROPERTY_OPTION',
	'SMARTPAYING_MARKETPLACE_MULTIPLE_PROPERTIES_OPTION': 'SMARTPAYING_MARKETPLACE_MULTIPLE_PROPERTIES_OPTION',
	'SMARTPAYING_MEX_KIND_paymentSuccess': 'SMARTPAYING_MEX_KIND_paymentSuccess',
	'SMARTPAYING_MEX_KIND_upsellingProposalCheckin': 'SMARTPAYING_MEX_KIND_upsellingProposalCheckin',
	'SMARTPAYING_MEX_KIND_upsellingProposalCreation': 'SMARTPAYING_MEX_KIND_upsellingProposalCreation',
	'SMARTPAYING_MEX_KIND_deposit': 'SMARTPAYING_MEX_KIND_deposit',
	'SMARTPAYING_MEX_KIND_afterCreation': 'SMARTPAYING_MEX_KIND_afterCreation',
	'SMARTPAYING_MEX_KIND_beforeCheckin': 'SMARTPAYING_MEX_KIND_beforeCheckin',
	'SMARTPAYING_MEX_KIND_paymentFail': 'SMARTPAYING_MEX_KIND_paymentFail',
	'BASE_PRICES_SELECT_ROOM_TYPE_PLACEHOLDER': 'BASE_PRICES_SELECT_ROOM_TYPE_PLACEHOLDER',
	'BASE_PRICES_SOURCE_TITLE': 'BASE_PRICES_SOURCE_TITLE',
	'BASE_PRICES_SOURCE_STARTING_PRICES_SUBTITLE': 'BASE_PRICES_SOURCE_STARTING_PRICES_SUBTITLE',
	'BASE_PRICES_SOURCE_STARTING_PRICES_EXPLANATION_1': 'BASE_PRICES_SOURCE_STARTING_PRICES_EXPLANATION_1',
	'BASE_PRICES_SOURCE_STARTING_PRICES_EXPLANATION_2': 'BASE_PRICES_SOURCE_STARTING_PRICES_EXPLANATION_2',
	'BASE_PRICES_SOURCE_STARTING_PRICES': 'BASE_PRICES_SOURCE_STARTING_PRICES',
	'BASE_PRICES_SOURCE_PARENT_ROOM': 'BASE_PRICES_SOURCE_PARENT_ROOM',
	'BASE_PRICES_SOURCE_PARENT_ROOM_PLACEHOLDER': 'BASE_PRICES_SOURCE_PARENT_ROOM_PLACEHOLDER',
	'BASE_PRICES_DERIVATION_TYPE': 'BASE_PRICES_DERIVATION_TYPE',
	'BASE_PRICES_DERIVATION_DYNAMIC': 'BASE_PRICES_DERIVATION_DYNAMIC',
	'BASE_PRICES_DERIVATION_DYNAMIC_EXPLANATION': 'BASE_PRICES_DERIVATION_DYNAMIC_EXPLANATION',
	'BASE_PRICES_DERIVATION_STRICT': 'BASE_PRICES_DERIVATION_STRICT',
	'BASE_PRICES_DERIVATION_STRICT_EXPLANATION': 'BASE_PRICES_DERIVATION_STRICT_EXPLANATION',
	'BASE_PRICES_LIST_DUPLICATE_ELEMENT': 'BASE_PRICES_LIST_DUPLICATE_ELEMENT',
	'SMARTPAYING_MARKETPLACE_MULTIPLE_PROPERTY': 'SMARTPAYING_MARKETPLACE_MULTIPLE_PROPERTY',
	'SMARTPAYING_PAYMENTS_SENT_COMMUNICATIONS': 'SMARTPAYING_PAYMENTS_SENT_COMMUNICATIONS',
	'SMARTPAYING_PAYMENTS_SEE_RESERVATION': 'SMARTPAYING_PAYMENTS_SEE_RESERVATION',
	'SMARTPAYING_MARKETPLACE_EMAIL_DOMAIN_EXPLANATION': 'SMARTPAYING_MARKETPLACE_EMAIL_DOMAIN_EXPLANATION',
	'SMARTPAYING_MARKETPLACE_SPECIFY_LINKS': 'SMARTPAYING_MARKETPLACE_SPECIFY_LINKS',
	'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_LINKS_DOMAIN': 'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_LINKS_DOMAIN',
	'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_ADDRESSES_MULTIPLE': 'SMARTPAYING_MARKETPLACE_SPECIFY_EMAIL_ADDRESSES_MULTIPLE',
	'SMARTPAYING_MARKETPLACE_SINGLE_STRUCTURE': 'SMARTPAYING_MARKETPLACE_SINGLE_STRUCTURE',
	'SMARTPAYING_MARKETPLACE_MULTIPLE_STRUCTURES': 'SMARTPAYING_MARKETPLACE_MULTIPLE_STRUCTURES',
	'SMARTPAYING_MARKETPLACE_SPECIFY_LINKS_MULTIPLE_PROPERTIES': 'SMARTPAYING_MARKETPLACE_SPECIFY_LINKS_MULTIPLE_PROPERTIES',
	'SMARTPAYING_MARKETPLACE_HELP': 'SMARTPAYING_MARKETPLACE_HELP',
	'BASE_PRICES_FIX_MODAL_TITLE': 'BASE_PRICES_FIX_MODAL_TITLE',
	'BASE_PRICES_FIX_MODAL_SUBTITLE': 'BASE_PRICES_FIX_MODAL_SUBTITLE',
	'BASE_PRICES_FIX_MODAL_DESCRIPTION': 'BASE_PRICES_FIX_MODAL_DESCRIPTION',
	'BASE_PRICES_FIX_MODAL_OPTION_EXTEND_BEFORE': 'BASE_PRICES_FIX_MODAL_OPTION_EXTEND_BEFORE',
	'BASE_PRICES_FIX_MODAL_OPTION_EXTEND_AFTER': 'BASE_PRICES_FIX_MODAL_OPTION_EXTEND_AFTER',
	'BASE_PRICES_FIX_MODAL_OPTION_CREATE_NEW': 'BASE_PRICES_FIX_MODAL_OPTION_CREATE_NEW',
	'BASE_PRICES_FIX_MODAL_OPTION_CREATE_NEW_WARNING': 'BASE_PRICES_FIX_MODAL_OPTION_CREATE_NEW_WARNING',
	'BASE_PRICES_FIX_MODAL_OPTION_CREATE_EDIT': 'BASE_PRICES_FIX_MODAL_OPTION_CREATE_EDIT',
	'SMARTPAYING_AUTOMATIONS_AUTOMATIONS': 'SMARTPAYING_AUTOMATIONS_AUTOMATIONS',
	'SMARTPAYING_AUTOMATIONS_ACTIVATE_SERVICES': 'SMARTPAYING_AUTOMATIONS_ACTIVATE_SERVICES',
	'SMARTPAYING_AUTOMATIONS_INFORM_GUESTS': 'SMARTPAYING_AUTOMATIONS_INFORM_GUESTS',
	'SMARTPAYING_AUTOMATIONS_RESERVATION': 'SMARTPAYING_AUTOMATIONS_RESERVATION',
	'SMARTPAYING_AUTOMATIONS_DEPOSITDAYS': 'SMARTPAYING_AUTOMATIONS_DEPOSITDAYS',
	'SMARTPAYING_AUTOMATIONS_AFTERCREATIONDAYS': 'SMARTPAYING_AUTOMATIONS_AFTERCREATIONDAYS',
	'SMARTPAYING_AUTOMATIONS_UPSELLINGPROPOSALCREATION': 'SMARTPAYING_AUTOMATIONS_UPSELLINGPROPOSALCREATION',
	'SMARTPAYING_AUTOMATIONS_PROMOTIONBEFORECHECKIN': 'SMARTPAYING_AUTOMATIONS_PROMOTIONBEFORECHECKIN',
	'SMARTPAYING_AUTOMATIONS_DAYS': 'SMARTPAYING_AUTOMATIONS_DAYS',
	'SMARTPAYING_AUTOMATIONS_DAY': 'SMARTPAYING_AUTOMATIONS_DAY',
	'SMARTPAYING_AUTOMATIONS_WHATSAPP': 'SMARTPAYING_AUTOMATIONS_WHATSAPP',
	'SMARTPAYING_AUTOMATIONS_EMAIL': 'SMARTPAYING_AUTOMATIONS_EMAIL',
	'SMARTPAYING_AUTOMATIONS_AFTER_DAY': 'SMARTPAYING_AUTOMATIONS_AFTER_DAY',
	'SMARTPAYING_AUTOMATIONS_CHECK_IN': 'SMARTPAYING_AUTOMATIONS_CHECK_IN',
	'SMARTPAYING_AUTOMATIONS_CHECK_OUT': 'SMARTPAYING_AUTOMATIONS_CHECK_OUT',
	'SMARTPAYING_AUTOMATIONS_DISCOUNT_CODE': 'SMARTPAYING_AUTOMATIONS_DISCOUNT_CODE',
	'SMARTPAYING_AUTOMATIONS_AFTER': 'SMARTPAYING_AUTOMATIONS_AFTER',
	'SMARTPAYING_AUTOMATIONS_BEFORE': 'SMARTPAYING_AUTOMATIONS_BEFORE',
	'SMARTPAYING_AUTOMATIONS_COMMUNICATION': 'SMARTPAYING_AUTOMATIONS_COMMUNICATION',
	'SMARTPAYING_AUTOMATIONS_COMMUNICATIONS': 'SMARTPAYING_AUTOMATIONS_COMMUNICATIONS',
	'SMARTPAYING_AUTOMATIONS_INACTIVE': 'SMARTPAYING_AUTOMATIONS_INACTIVE',
	'SMARTPAYING_AUTOMATIONS_ACTIVE': 'SMARTPAYING_AUTOMATIONS_ACTIVE',
	'SETTINGS_ACCOMMODATION_TITLE': 'SETTINGS_ACCOMMODATION_TITLE',
	'SETTINGS_GAP_FILLING_TAB_LABEL': 'SETTINGS_GAP_FILLING_TAB_LABEL',
	'SETTINGS_STARTING_PRICES_TAB_LABEL': 'SETTINGS_STARTING_PRICES_TAB_LABEL',
	'SETTINGS_MIN_AND_MAD_TAB_LABEL': 'SETTINGS_MIN_AND_MAD_TAB_LABEL',
	'SMARTPAYING': 'SMARTPAYING',
	'AUTO_ONBOARDING_CONNECT_PROPERTY_TITLE': 'AUTO_ONBOARDING_CONNECT_PROPERTY_TITLE',
	'AUTO_ONBOARDING_CONNECT_PROPERTY_SUBTITLE': 'AUTO_ONBOARDING_CONNECT_PROPERTY_SUBTITLE',
	'AUTO_ONBOARDING_CONNECT_PROPERTY_INPUT_LABEL': 'AUTO_ONBOARDING_CONNECT_PROPERTY_INPUT_LABEL',
	'AUTO_ONBOARDING_CONNECT_PROPERTY_INPUT_PLACEHOLDER': 'AUTO_ONBOARDING_CONNECT_PROPERTY_INPUT_PLACEHOLDER',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_TITLE': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_TITLE',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_SUBTITLE': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_SUBTITLE',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_INPUT_LABEL': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_INPUT_LABEL',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_INPUT_PLACEHOLDER': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_INPUT_PLACEHOLDER',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_CANT_FIND': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_CANT_FIND',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_TITLE': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_TITLE',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTEXT': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTEXT',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_DESCRIPTION': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_DESCRIPTION',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_APALEO': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_APALEO',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_GUESTY': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_GUESTY',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_OCTORATE': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_OCTORATE',
	'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_SMOOBU': 'AUTO_ONBOARDING_INSERT_PROPERTY_ID_DOCUMENTATION_PANEL_CONTENT_SMOOBU',
	'BASE_PRICES_LIST_HOVER_GAP': 'BASE_PRICES_LIST_HOVER_GAP',
	'BASE_PRICES_LIST_HOVER_SHORT_PERIOD': 'BASE_PRICES_LIST_HOVER_SHORT_PERIOD',
	'navbar.items.SETTINGS': 'navbar.items.SETTINGS',
	'navbar.items.GENERAL_SETTINGS': 'navbar.items.GENERAL_SETTINGS',
	'navbar.items.ACCOMMODATION_SETTINGS': 'navbar.items.ACCOMMODATION_SETTINGS',
	'BASE_PRICES_SOURCE_PARENT_ROOM_EXPLANATION_1': 'BASE_PRICES_SOURCE_PARENT_ROOM_EXPLANATION_1',
	'BASE_PRICES_SOURCE_PARENT_ROOM_EXPLANATION_2': 'BASE_PRICES_SOURCE_PARENT_ROOM_EXPLANATION_2',
	'BASE_PRICES_SOURCE_PARENT_ROOM_SUBTITLE': 'BASE_PRICES_SOURCE_PARENT_ROOM_SUBTITLE',
	'BASE_PRICES_SOURCE_SUBTITLE': 'BASE_PRICES_SOURCE_SUBTITLE',
	'SMARTPAYING_AUTOMATIONS_BEFORECHECKOUTDAYS': 'SMARTPAYING_AUTOMATIONS_BEFORECHECKOUTDAYS',
	'SMARTPAYING_AUTOMATIONS_UPSELLINGPROPOSALAFTERCHECKIN': 'SMARTPAYING_AUTOMATIONS_UPSELLINGPROPOSALAFTERCHECKIN',
	'SMARTPAYING_AUTOMATIONS_BEFORECHECKINDAYS': 'SMARTPAYING_AUTOMATIONS_BEFORECHECKINDAYS',
	'SMARTPAYING_AUTOMATIONS_UPSELLINGPROPOSALCHECKIN': 'SMARTPAYING_AUTOMATIONS_UPSELLINGPROPOSALCHECKIN',
	'SMARTPAYING_AUTOMATIONS_DISCOUNTAFTERCHECKOUT': 'SMARTPAYING_AUTOMATIONS_DISCOUNTAFTERCHECKOUT',
	'SMARTPAYING_AUTOMATIONS_RESERVATION_SETTINGS': 'SMARTPAYING_AUTOMATIONS_RESERVATION_SETTINGS',
	'SMARTPAYING_AUTOMATIONS_SENDING_CHANNELS': 'SMARTPAYING_AUTOMATIONS_SENDING_CHANNELS',
	'SMARTPAYING_AUTOMATIONS_VIA_MAIL': 'SMARTPAYING_AUTOMATIONS_VIA_MAIL',
	'SMARTPAYING_AUTOMATIONS_VIA_WHASTAPP': 'SMARTPAYING_AUTOMATIONS_VIA_WHASTAPP',
	'SMARTPAYING_AUTOMATIONS_RESERVATION_TYPE': 'SMARTPAYING_AUTOMATIONS_RESERVATION_TYPE',
	'SMARTPAYING_AUTOMATIONS_RESERVATION_CHANNELS': 'SMARTPAYING_AUTOMATIONS_RESERVATION_CHANNELS',
	'SMARTPAYING_AUTOMATIONS_SENDING_DAYS': 'SMARTPAYING_AUTOMATIONS_SENDING_DAYS',
	'SMARTPAYING_AUTOMATIONS_ALL': 'SMARTPAYING_AUTOMATIONS_ALL',
	'SMARTPAYING_AUTOMATIONS_REFUNDABLE': 'SMARTPAYING_AUTOMATIONS_REFUNDABLE',
	'SMARTPAYING_AUTOMATIONS_NOT_REFUNDABLE': 'SMARTPAYING_AUTOMATIONS_NOT_REFUNDABLE',
	'SMARTPAYING_AUTOMATIONS_DIRECTS': 'SMARTPAYING_AUTOMATIONS_DIRECTS',
	'SMARTPAYING_AUTOMATIONS_BOOKING': 'SMARTPAYING_AUTOMATIONS_BOOKING',
	'SMARTPAYING_AUTOMATIONS_AIRBNB': 'SMARTPAYING_AUTOMATIONS_AIRBNB',
	'SMARTPAYING_AUTOMATIONS_CHOSE_AN_OPTION': 'SMARTPAYING_AUTOMATIONS_CHOSE_AN_OPTION',
	'SMARTPAYING_AUTOMATIONS_SETTINGS': 'SMARTPAYING_AUTOMATIONS_SETTINGS',
	'SMARTPAYING_AUTOMATIONS_LAYOUT_PREVIEW': 'SMARTPAYING_AUTOMATIONS_LAYOUT_PREVIEW',
	'SMARTPAYING_AUTOMATIONS_EMAIL_PREVIEW': 'SMARTPAYING_AUTOMATIONS_EMAIL_PREVIEW',
	'SMARTPAYING_AUTOMATIONS_WHATSAPP_PREVIEW': 'SMARTPAYING_AUTOMATIONS_WHATSAPP_PREVIEW',
	'SMARTPAYING_AUTOMATIONS_PAYMENT_SETTINGS': 'SMARTPAYING_AUTOMATIONS_PAYMENT_SETTINGS',
	'SMARTPAYING_AUTOMATIONS_DEPOSIT_PERCENTAGE': 'SMARTPAYING_AUTOMATIONS_DEPOSIT_PERCENTAGE',
	'SMARTPAYING_AUTOMATIONS_PAYMENT_LINK_VALIDITY_DAYS_AFTER': 'SMARTPAYING_AUTOMATIONS_PAYMENT_LINK_VALIDITY_DAYS_AFTER',
	'SMARTPAYING_AUTOMATIONS_PAYMENT_LINK_VALIDITY_DAYS': 'SMARTPAYING_AUTOMATIONS_PAYMENT_LINK_VALIDITY_DAYS',
	'SMARTPAYING_AUTOMATIONS_ENTER_THE_VALUE': 'SMARTPAYING_AUTOMATIONS_ENTER_THE_VALUE',
	'SMARTPAYING_AUTOMATIONS_ENTER_VALIDITY_DAYS': 'SMARTPAYING_AUTOMATIONS_ENTER_VALIDITY_DAYS',
	'SMARTPAYING_AUTOMATIONS_ENTER_DISCOUNT_CODE': 'SMARTPAYING_AUTOMATIONS_ENTER_DISCOUNT_CODE',
	'SMARTPAYING_AUTOMATIONS_ENTER_SENDING_DAYS': 'SMARTPAYING_AUTOMATIONS_ENTER_SENDING_DAYS',
	'SMARTPAYING_AUTOMATIONS_DISCOUNT_CODE_INFO': 'SMARTPAYING_AUTOMATIONS_DISCOUNT_CODE_INFO',
	'SMARTPAYING_AUTOMATIONS_CHECKIN_CONTEXTUAL': 'SMARTPAYING_AUTOMATIONS_CHECKIN_CONTEXTUAL',
	'SMARTPAYING_AUTOMATIONS_CHECKOUT_CONTEXTUAL': 'SMARTPAYING_AUTOMATIONS_CHECKOUT_CONTEXTUAL',
	'SMARTPAYING_AUTOMATIONS_RESERVATION_CONTEXTUAL': 'SMARTPAYING_AUTOMATIONS_RESERVATION_CONTEXTUAL',
	'AUTOONBOARDING_MAX_OCCUPANCY_LESS_THAN_MIN_ERROR': 'AUTOONBOARDING_MAX_OCCUPANCY_LESS_THAN_MIN_ERROR',
	'AUTOONBOARDING_MIN_OCCUPANCY_GREATER_THAN_MAX_ERROR': 'AUTOONBOARDING_MIN_OCCUPANCY_GREATER_THAN_MAX_ERROR',
	'SMARTPAYING_PAYMENTS_EXPORT_DATA_ERROR': 'SMARTPAYING_PAYMENTS_EXPORT_DATA_ERROR',
	'SMARTPAYING_AUTOMATIONS_AUTOMATIONS_SAVED': 'SMARTPAYING_AUTOMATIONS_AUTOMATIONS_SAVED',
	'STARTING_PRICES_DERIVED_ROOM': 'STARTING_PRICES_DERIVED_ROOM',
	'SMARTPAYING_AUTOMATIONS_NO': 'SMARTPAYING_AUTOMATIONS_NO',
	'SMARTPAYING_AUTOMATIONS_YES': 'SMARTPAYING_AUTOMATIONS_YES',
	'SMARTPAYING_AUTOMATIONS_COMMUNICATION_WILL_BE_SENT': 'SMARTPAYING_AUTOMATIONS_COMMUNICATION_WILL_BE_SENT',
	'SMARTPAYING_PAYMENTS_PAYMENT_OPEN_WA': 'SMARTPAYING_PAYMENTS_PAYMENT_OPEN_WA',
	'SMARTPAYING_PAYMENTS_PAYMENT_OPEN_WA_TEXT': 'SMARTPAYING_PAYMENTS_PAYMENT_OPEN_WA_TEXT',
	'orphan_nights.accommodation_min_value.LABEL': 'orphan_nights.accommodation_min_value.LABEL',
	'orphan_nights.room_type_min_value.LABEL': 'orphan_nights.room_type_min_value.LABEL',
	'BASE_PRICES_DERIVED_ROOM_TYPE_NOT_SELECTABLE': 'BASE_PRICES_DERIVED_ROOM_TYPE_NOT_SELECTABLE',
	'orphan_nights.room_type_accordion.MIN_NIGHT': 'orphan_nights.room_type_accordion.MIN_NIGHT',
	'orphan_nights.room_type_accordion.MIN_NIGHTS': 'orphan_nights.room_type_accordion.MIN_NIGHTS',
	'settings.room_types_accordion.TITLE': 'settings.room_types_accordion.TITLE',
	'auto-onboarding.starting-price-intro.title': 'auto-onboarding.starting-price-intro.title',
	'auto-onboarding.starting-price-intro.description': 'auto-onboarding.starting-price-intro.description',
	'auto-onboarding.starting-price-info-intro-1.title': 'auto-onboarding.starting-price-info-intro-1.title',
	'auto-onboarding.starting-price-info-intro-1.subtitle': 'auto-onboarding.starting-price-info-intro-1.subtitle',
	'auto-onboarding.starting-price-info-intro-1.description': 'auto-onboarding.starting-price-info-intro-1.description',
	'auto-onboarding.starting-price-info-intro-2.title': 'auto-onboarding.starting-price-info-intro-2.title',
	'auto-onboarding.starting-price-info-intro-2.subtitle': 'auto-onboarding.starting-price-info-intro-2.subtitle',
	'auto-onboarding.starting-price-info-intro-2.description': 'auto-onboarding.starting-price-info-intro-2.description',
	'auto-onboarding.starting-price-info-intro-3.title': 'auto-onboarding.starting-price-info-intro-3.title',
	'auto-onboarding.starting-price-info-intro-3.subtitle': 'auto-onboarding.starting-price-info-intro-3.subtitle',
	'auto-onboarding.starting-price-info-intro-3.description': 'auto-onboarding.starting-price-info-intro-3.description',
	'SMARTPAYING_AUTOMATIONS_AFTER_RESERVATION': 'SMARTPAYING_AUTOMATIONS_AFTER_RESERVATION',
	'SMARTPAYING_AUTOMATIONS_BEFORE_CHECKIN': 'SMARTPAYING_AUTOMATIONS_BEFORE_CHECKIN',
	'SMARTPAYING_AUTOMATIONS_AFTER_CHECKIN': 'SMARTPAYING_AUTOMATIONS_AFTER_CHECKIN',
	'SMARTPAYING_AUTOMATIONS_BEFORE_CHECKOUT': 'SMARTPAYING_AUTOMATIONS_BEFORE_CHECKOUT',
	'SMARTPAYING_AUTOMATIONS_AFTER_CHECKOUT': 'SMARTPAYING_AUTOMATIONS_AFTER_CHECKOUT',
	'settings.room_types_list.DERIVED_FROM': 'settings.room_types_list.DERIVED_FROM',
	'SETTINGS.ERROR_WHILE_SAVING_MODAL.TITLE': 'SETTINGS.ERROR_WHILE_SAVING_MODAL.TITLE',
	'SETTINGS.ERROR_WHILE_SAVING_MODAL.DESCRIPTION': 'SETTINGS.ERROR_WHILE_SAVING_MODAL.DESCRIPTION',
	'SETTINGS.DERIVE_STARTING_PRICES.CANNOT_DERIVE': 'SETTINGS.DERIVE_STARTING_PRICES.CANNOT_DERIVE',
	'UNSAVED_CHANGES_MODAL.CLOSE_WITH_SAVE': 'UNSAVED_CHANGES_MODAL.CLOSE_WITH_SAVE',
	'UNSAVED_CHANGES_MODAL.CLOSE_WITHOUT_SAVE': 'UNSAVED_CHANGES_MODAL.CLOSE_WITHOUT_SAVE',
	'UNSAVED_CHANGES_MODAL.TITLE': 'UNSAVED_CHANGES_MODAL.TITLE',
	'UNSAVED_CHANGES_MODAL.HINT_GENERIC': 'UNSAVED_CHANGES_MODAL.HINT_GENERIC',
	'SETTINGS.DERIVE_STARTING_PRICES.CANNOT_DERIVE.OTHER_N': 'SETTINGS.DERIVE_STARTING_PRICES.CANNOT_DERIVE.OTHER_N',
	'auto-onboarding.generic.start_configuring': 'auto-onboarding.generic.start_configuring',
	'auto-onboarding.starting-price-source-selection.title': 'auto-onboarding.starting-price-source-selection.title',
	'auto-onboarding.starting-price-source-selection.description': 'auto-onboarding.starting-price-source-selection.description',
	'auto-onboarding.starting-price-source-selection.data-source-text': 'auto-onboarding.starting-price-source-selection.data-source-text',
	'auto-onboarding.starting-price-source-selection.historical-data-text': 'auto-onboarding.starting-price-source-selection.historical-data-text',
	'auto-onboarding.starting-price-source-selection.historical-data-warning-text': 'auto-onboarding.starting-price-source-selection.historical-data-warning-text',
	'auto-onboarding.starting-price-source-selection.average-selling-price-text': 'auto-onboarding.starting-price-source-selection.average-selling-price-text',
	'SMARTPAYING_AUTOMATIONS_AUTOMATIONS_SAVED_TITLE': 'SMARTPAYING_AUTOMATIONS_AUTOMATIONS_SAVED_TITLE',
	'SMARTPAYING_AUTOMATIONS_ACTIVE_PLURAL': 'SMARTPAYING_AUTOMATIONS_ACTIVE_PLURAL',
	'SMARTPAYING_CUSTOMERS': 'SMARTPAYING_CUSTOMERS',
	'orphan_nights.accommodation_min_value.BUTTON': 'orphan_nights.accommodation_min_value.BUTTON',
	'IMPACT_PREVIEW_NOT_AVAILABLE_TITLE': 'IMPACT_PREVIEW_NOT_AVAILABLE_TITLE',
	'IMPACT_PREVIEW_NOT_AVAILABLE_BODY': 'IMPACT_PREVIEW_NOT_AVAILABLE_BODY',
	'CRMCUSTOMER_PERSONAL-INFO_PHONE': 'CRMCUSTOMER_PERSONAL-INFO_PHONE',
	'CRMCUSTOMER_PERSONAL-INFO_NATIONALITY': 'CRMCUSTOMER_PERSONAL-INFO_NATIONALITY',
	'CRMCUSTOMER_PERSONAL-INFO_MARKETING': 'CRMCUSTOMER_PERSONAL-INFO_MARKETING',
	'CRMCUSTOMER_PROFILING_SEASONALITY': 'CRMCUSTOMER_PROFILING_SEASONALITY',
	'CRMCUSTOMER_PROFILING_RESERVATIONS-CHANNELS': 'CRMCUSTOMER_PROFILING_RESERVATIONS-CHANNELS',
	'CRMCUSTOMER_PROFILING_GROUP-TYPE': 'CRMCUSTOMER_PROFILING_GROUP-TYPE',
	'CRMCUSTOMER_PROFILING_CUSTOMER-TYPE': 'CRMCUSTOMER_PROFILING_CUSTOMER-TYPE',
	'CRMCUSTOMER_PROFILING_TYPE-TRAVELER': 'CRMCUSTOMER_PROFILING_TYPE-TRAVELER',
	'CRMCUSTOMER_PERSONAL-INFO_BIRTH-DATE': 'CRMCUSTOMER_PERSONAL-INFO_BIRTH-DATE',
	'CRMCUSTOMER_PERSONAL-INFO_LANGUAGE': 'CRMCUSTOMER_PERSONAL-INFO_LANGUAGE',
	'CRMCUSTOMER_PERSONAL-INFO_CITY': 'CRMCUSTOMER_PERSONAL-INFO_CITY',
	'CRMCUSTOMER_CUSTOMER-CARD_NEXT-STAY': 'CRMCUSTOMER_CUSTOMER-CARD_NEXT-STAY',
	'CRMCUSTOMER_EDIT-PROFILING_DESCRIPTION': 'CRMCUSTOMER_EDIT-PROFILING_DESCRIPTION',
	'CRMCUSTOMER_COMMUNICATIONS_SENDING': 'CRMCUSTOMER_COMMUNICATIONS_SENDING',
	'CRMCUSTOMER_COMMUNICATIONS_SEND-ON': 'CRMCUSTOMER_COMMUNICATIONS_SEND-ON',
	'CRMCUSTOMER_COMMUNICATIONS_STATE-OPEN': 'CRMCUSTOMER_COMMUNICATIONS_STATE-OPEN',
	'CRMCUSTOMER_COMMUNICATIONS_WHATSAPP': 'CRMCUSTOMER_COMMUNICATIONS_WHATSAPP',
	'CRMCUSTOMER_PROFILING_TAGS': 'CRMCUSTOMER_PROFILING_TAGS',
	'CRMCUSTOMER_PERSONAL-INFO_EMAIL': 'CRMCUSTOMER_PERSONAL-INFO_EMAIL',
	'CRMCUSTOMER_PERSONAL-INFO_LOCATION': 'CRMCUSTOMER_PERSONAL-INFO_LOCATION',
	'CRMCUSTOMER_PERSONAL-INFO_OTHER-INFO': 'CRMCUSTOMER_PERSONAL-INFO_OTHER-INFO',
	'CRMCUSTOMER_PROFILING_ROOM-TYPE': 'CRMCUSTOMER_PROFILING_ROOM-TYPE',
	'CRMCUSTOMER_NOTES_PLACEHOLDER': 'CRMCUSTOMER_NOTES_PLACEHOLDER',
	'CRMCUSTOMER_ACTIONS_ADD-NOTE': 'CRMCUSTOMER_ACTIONS_ADD-NOTE',
	'CRMCUSTOMER_DELETE-NOTE_DESCRIPTION': 'CRMCUSTOMER_DELETE-NOTE_DESCRIPTION',
	'CRMCUSTOMER_NOTES_MOST-RECENT': 'CRMCUSTOMER_NOTES_MOST-RECENT',
	'CRMCUSTOMER_COMMUNICATIONS_STATE-NOT-OPEN': 'CRMCUSTOMER_COMMUNICATIONS_STATE-NOT-OPEN',
	'CRMCUSTOMER_RESERVATIONS-LIST_STAY-CHANNELS': 'CRMCUSTOMER_RESERVATIONS-LIST_STAY-CHANNELS',
	'CRMCUSTOMER_RESERVATIONS-LIST_BOOKED-ON': 'CRMCUSTOMER_RESERVATIONS-LIST_BOOKED-ON',
	'CRMCUSTOMER_RESERVATIONS-LIST_GUESTS': 'CRMCUSTOMER_RESERVATIONS-LIST_GUESTS',
	'CRMCUSTOMER_RESERVATIONS-LIST_AMOUNT': 'CRMCUSTOMER_RESERVATIONS-LIST_AMOUNT',
	'CRMCUSTOMER_RESERVATIONS_DAYS-BEFORE': 'CRMCUSTOMER_RESERVATIONS_DAYS-BEFORE',
	'CRMCUSTOMER_MERGE_TITLE-LEFT': 'CRMCUSTOMER_MERGE_TITLE-LEFT',
	'CRMCUSTOMER_MERGE_SUBTITLE-LEFT': 'CRMCUSTOMER_MERGE_SUBTITLE-LEFT',
	'CRMCUSTOMER_MERGE_TITLE-RIGHT': 'CRMCUSTOMER_MERGE_TITLE-RIGHT',
	'CRMCUSTOMER_MERGE_TITLE-DESCRIPTION': 'CRMCUSTOMER_MERGE_TITLE-DESCRIPTION',
	'CRMCUSTOMER_MERGE_SUBTITLE-DESCRIPTION': 'CRMCUSTOMER_MERGE_SUBTITLE-DESCRIPTION',
	'CRMCUSTOMER_PERSONAL-INFO_NAME': 'CRMCUSTOMER_PERSONAL-INFO_NAME',
	'CRMCUSTOMER_PERSONAL-INFO_COUNTRY': 'CRMCUSTOMER_PERSONAL-INFO_COUNTRY',
	'CRMCUSTOMER_PERSONAL-INFO_SURNAME': 'CRMCUSTOMER_PERSONAL-INFO_SURNAME',
	'CRMCUSTOMER_PERSONAL-INFO_MARKETING-YES': 'CRMCUSTOMER_PERSONAL-INFO_MARKETING-YES',
	'CRMCUSTOMER_PERSONAL-INFO_MARKETING-NO': 'CRMCUSTOMER_PERSONAL-INFO_MARKETING-NO',
	'CRMCUSTOMER_PERSONAL-INFO_REGION': 'CRMCUSTOMER_PERSONAL-INFO_REGION',
	'CRMCUSTOMER_METRICS_STAY-PLURAL': 'CRMCUSTOMER_METRICS_STAY-PLURAL',
	'CRMCUSTOMER_METRICS_STAYS-LABEL': 'CRMCUSTOMER_METRICS_STAYS-LABEL',
	'CRMCUSTOMER_METRICS_NIGHT-PLURAL': 'CRMCUSTOMER_METRICS_NIGHT-PLURAL',
	'CRMCUSTOMER_METRICS_NIGHT-LABEL': 'CRMCUSTOMER_METRICS_NIGHT-LABEL',
	'CRMCUSTOMER_METRICS_SPENT-LABEL': 'CRMCUSTOMER_METRICS_SPENT-LABEL',
	'CRMCUSTOMER_METRICS_DAYS-PLURAL': 'CRMCUSTOMER_METRICS_DAYS-PLURAL',
	'CRMCUSTOMER_METRICS_BOOKING-LABEL': 'CRMCUSTOMER_METRICS_BOOKING-LABEL',
	'CRMCUSTOMER_MERGE_CONTENT-SELECTOR_IDENTIFIED': 'CRMCUSTOMER_MERGE_CONTENT-SELECTOR_IDENTIFIED',
	'CRMCUSTOMER_MERGE_CONTENT-SELECTOR_SEARCH': 'CRMCUSTOMER_MERGE_CONTENT-SELECTOR_SEARCH',
	'Message notification': 'Message notification',
	'CRMCUSTOMER_MERGE-INFORMATION_ALERT': 'CRMCUSTOMER_MERGE-INFORMATION_ALERT',
	'CRMCUSTOMER_PROFILING_CUSTOMER-TYPE_REPETER': 'CRMCUSTOMER_PROFILING_CUSTOMER-TYPE_REPETER',
	'CRMCUSTOMER_PROFILING_CUSTOMER-TYPE_FIRSTIMERS': 'CRMCUSTOMER_PROFILING_CUSTOMER-TYPE_FIRSTIMERS',
	'CRMCUSTOMER_PERSONAL-INFO_MARKETING_ALLOWED': 'CRMCUSTOMER_PERSONAL-INFO_MARKETING_ALLOWED',
	'CRMCUSTOMER_PERSONAL-INFO_MARKETING_NOT-ALLOWED': 'CRMCUSTOMER_PERSONAL-INFO_MARKETING_NOT-ALLOWED',
	'CRMCUSTOMER_ACTIONS_SEE-MORE': 'CRMCUSTOMER_ACTIONS_SEE-MORE',
	'CRMCUSTOMER_PROFILING_SEASONALITY_SPRING': 'CRMCUSTOMER_PROFILING_SEASONALITY_SPRING',
	'CRMCUSTOMER_PROFILING_SEASONALITY_SUMMER': 'CRMCUSTOMER_PROFILING_SEASONALITY_SUMMER',
	'CRMCUSTOMER_PROFILING_SEASONALITY_AUTUMN': 'CRMCUSTOMER_PROFILING_SEASONALITY_AUTUMN',
	'CRMCUSTOMER_PROFILING_SEASONALITY_WINTER': 'CRMCUSTOMER_PROFILING_SEASONALITY_WINTER',
	'CRMCUSTOMER_PROFILING_TYPE-TRAVELER_BUSINESS': 'CRMCUSTOMER_PROFILING_TYPE-TRAVELER_BUSINESS',
	'CRMCUSTOMER_PROFILING_TYPE-TRAVELER_LEISURE': 'CRMCUSTOMER_PROFILING_TYPE-TRAVELER_LEISURE',
	'CRMCUSTOMER_PROFILING_GROUP-TYPE_SINGLE': 'CRMCUSTOMER_PROFILING_GROUP-TYPE_SINGLE',
	'CRMCUSTOMER_PROFILING_GROUP-TYPE_COUPLE': 'CRMCUSTOMER_PROFILING_GROUP-TYPE_COUPLE',
	'CRMCUSTOMER_PROFILING_GROUP-TYPE_FAMILY': 'CRMCUSTOMER_PROFILING_GROUP-TYPE_FAMILY',
	'CRMCUSTOMER_PROFILING_GROUP-TYPE_GROUP': 'CRMCUSTOMER_PROFILING_GROUP-TYPE_GROUP',
	'CRMCUSTOMER_PROFILING_TAGS_PET': 'CRMCUSTOMER_PROFILING_TAGS_PET',
	'CRMCUSTOMER_PROFILING_TAGS_CHILDREN': 'CRMCUSTOMER_PROFILING_TAGS_CHILDREN',
	'CRMCUSTOMER_ACTIONS_ADD-TAG': 'CRMCUSTOMER_ACTIONS_ADD-TAG',
	'CRMCUSTOMER_ADD-TAG_PLACEHOLDER': 'CRMCUSTOMER_ADD-TAG_PLACEHOLDER',
	'CRMCUSTOMER_NOTES': 'CRMCUSTOMER_NOTES',
	'Improvement': 'Improvement',
	'CRMCUSTOMER_ACTIONS_SEE-LESS': 'CRMCUSTOMER_ACTIONS_SEE-LESS',
	'CRMCUSTOMER_PROFILING': 'CRMCUSTOMER_PROFILING',
	'CRMCUSTOMER_COMMUNICATIONS': 'CRMCUSTOMER_COMMUNICATIONS',
	'CRMCUSTOMER_RESERVATIONS': 'CRMCUSTOMER_RESERVATIONS',
	'CRMCUSTOMER_ADD-NOTE-PLACEHOLDER': 'CRMCUSTOMER_ADD-NOTE-PLACEHOLDER',
	'CRMCUSTOMER_ACTIONS_DELETE': 'CRMCUSTOMER_ACTIONS_DELETE',
	'CRMCUSTOMER_ACTIONS_CHANGE-CUSTOMER': 'CRMCUSTOMER_ACTIONS_CHANGE-CUSTOMER',
	'CRMCUSTOMER_MERGE_SEARCH-PLACEHOLDER': 'CRMCUSTOMER_MERGE_SEARCH-PLACEHOLDER',
	'CRMCUSTOMER_CUSTOMER-DETAILS_CATEGORY': 'CRMCUSTOMER_CUSTOMER-DETAILS_CATEGORY',
	'CRMCUSTOMER_EDIT-PERSONAL-INFO_TITLE': 'CRMCUSTOMER_EDIT-PERSONAL-INFO_TITLE',
	'CRMCUSTOMER_ACTIONS_CANCEL': 'CRMCUSTOMER_ACTIONS_CANCEL',
	'CRMCUSTOMER_ACTIONS_SAVE': 'CRMCUSTOMER_ACTIONS_SAVE',
	'CRMCUSTOMER_CUSTOMER-LIST_SEARCH-PLACEHOLDER': 'CRMCUSTOMER_CUSTOMER-LIST_SEARCH-PLACEHOLDER',
	'CRMCUSTOMER_MERGE_TITLE': 'CRMCUSTOMER_MERGE_TITLE',
	'CRMCUSTOMER_ACTIONS_CONTINUES': 'CRMCUSTOMER_ACTIONS_CONTINUES',
	'CRMCUSTOMER_EDIT-PROFILING_TITLE': 'CRMCUSTOMER_EDIT-PROFILING_TITLE',
	'CUSTOMER_PAGE-TITLE': 'CUSTOMER_PAGE-TITLE',
	'CRMCUSTOMER_EDIT-PROFILING_NOTIFICATION-TITLE': 'CRMCUSTOMER_EDIT-PROFILING_NOTIFICATION-TITLE',
	'CRMCUSTOMER_EDIT-PROFILING_NOTIFICATION-SUBTITLE': 'CRMCUSTOMER_EDIT-PROFILING_NOTIFICATION-SUBTITLE',
	'CRMCUSTOMER_DETAILS_PAGE-TITLE': 'CRMCUSTOMER_DETAILS_PAGE-TITLE',
	'CRMCUSTOMER_ACTIONS_BACK': 'CRMCUSTOMER_ACTIONS_BACK',
	'CRMCUSTOMER_ACTIONS_MERGE': 'CRMCUSTOMER_ACTIONS_MERGE',
	'CRMCUSTOMER_NOTES_CATEGORY': 'CRMCUSTOMER_NOTES_CATEGORY',
	'CRMCUSTOMER_ADD-NOTE_TITLE': 'CRMCUSTOMER_ADD-NOTE_TITLE',
	'CRMCUSTOMER_ACTIONS_ADD': 'CRMCUSTOMER_ACTIONS_ADD',
	'CRMCUSTOMER_METRICS_LIFETIME-STAYS_TOOLTIP': 'CRMCUSTOMER_METRICS_LIFETIME-STAYS_TOOLTIP',
	'CRMCUSTOMER_ADD-NOTE_REQUIRED-FIELD': 'CRMCUSTOMER_ADD-NOTE_REQUIRED-FIELD',
	'CRMCUSTOMER_DELETE-NOTE_NOTIFICATION-TITLE': 'CRMCUSTOMER_DELETE-NOTE_NOTIFICATION-TITLE',
	'CRMCUSTOMER_DELETE-NOTE_NOTIFICATION-SUBTITLE': 'CRMCUSTOMER_DELETE-NOTE_NOTIFICATION-SUBTITLE',
	'CRMCUSTOMER_ACTIONS_EDIT': 'CRMCUSTOMER_ACTIONS_EDIT',
	'CRMCUSTOMER_VIEW-NOTES_TITLE': 'CRMCUSTOMER_VIEW-NOTES_TITLE',
	'CRMCUSTOMER_DELETE-NOTE_TITLE': 'CRMCUSTOMER_DELETE-NOTE_TITLE',
	'CRMCUSTOMER_ADD-NOTE_NOTIFICATION-TITLE': 'CRMCUSTOMER_ADD-NOTE_NOTIFICATION-TITLE',
	'CRMCUSTOMER_ADD-NOTE_NOTIFICATION-SUBTITLE': 'CRMCUSTOMER_ADD-NOTE_NOTIFICATION-SUBTITLE',
	'CRMCUSTOMER_EDIT-INFO_NOTIFICATION-TITLE': 'CRMCUSTOMER_EDIT-INFO_NOTIFICATION-TITLE',
	'CRMCUSTOMER_EDIT-INFO_NOTIFICATION-SUBTITLE': 'CRMCUSTOMER_EDIT-INFO_NOTIFICATION-SUBTITLE',
	'CRMCUSTOMER_MERGE_NOTIFICATION-TITLE': 'CRMCUSTOMER_MERGE_NOTIFICATION-TITLE',
	'CRMCUSTOMER_MERGE_NOTIFICATION-SUBTITLE': 'CRMCUSTOMER_MERGE_NOTIFICATION-SUBTITLE',
	'CRMCUSTOMER_CUSTOMER-LIST_CUSTOMERS-FOUND': 'CRMCUSTOMER_CUSTOMER-LIST_CUSTOMERS-FOUND',
	'CRMCUSTOMER_ACTIONS_SEE-ALL': 'CRMCUSTOMER_ACTIONS_SEE-ALL',
	'orphan_nights. explanation.DESC_1': 'orphan_nights. explanation.DESC_1',
	'orphan_nights. explanation.DESC_2': 'orphan_nights. explanation.DESC_2',
	'CRMCUSTOMER_CUSTOMER-LIST_CUSTOMERS-FOUND-PLURAL': 'CRMCUSTOMER_CUSTOMER-LIST_CUSTOMERS-FOUND-PLURAL',
	'SMARTPAYING_PAYMENTS_ALL_ACCOMODATIONS': 'SMARTPAYING_PAYMENTS_ALL_ACCOMODATIONS',
	'CRMCUSTOMER_PERSONAL-INFO_MARKETING_NOT-SAID': 'CRMCUSTOMER_PERSONAL-INFO_MARKETING_NOT-SAID',
	'FEEDBACK.STRATEGY_CHANGE.TITLE': 'FEEDBACK.STRATEGY_CHANGE.TITLE',
	'FEEDBACK.STRATEGY_CHANGE.HEADER': 'FEEDBACK.STRATEGY_CHANGE.HEADER',
	'FEEDBACK.STRATEGY_CHANGE.IMPROVEMENT_1': 'FEEDBACK.STRATEGY_CHANGE.IMPROVEMENT_1',
	'FEEDBACK.STRATEGY_CHANGE.IMPROVEMENT_2': 'FEEDBACK.STRATEGY_CHANGE.IMPROVEMENT_2',
	'FEEDBACK.STRATEGY_CHANGE.IMPROVEMENT_3': 'FEEDBACK.STRATEGY_CHANGE.IMPROVEMENT_3',
	'FEEDBACK.MODAL.CONTENT': 'FEEDBACK.MODAL.CONTENT',
	'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_1': 'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_1',
	'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_2': 'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_2',
	'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_3': 'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_3',
	'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_4': 'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_4',
	'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_OTHER': 'FEEDBACK.MODAL.GENERIC_IMPROVEMENT_OTHER',
	'FEEDBACK.MODAL.GENERIC_SUPER_SAD_EMOJI_TOOLTIP': 'FEEDBACK.MODAL.GENERIC_SUPER_SAD_EMOJI_TOOLTIP',
	'FEEDBACK.MODAL.GENERIC_SAD_EMOJI_TOOLTIP': 'FEEDBACK.MODAL.GENERIC_SAD_EMOJI_TOOLTIP',
	'FEEDBACK.MODAL.GENERIC_NEUTRAL_EMOJI_TOOLTIP': 'FEEDBACK.MODAL.GENERIC_NEUTRAL_EMOJI_TOOLTIP',
	'FEEDBACK.MODAL.GENERIC_HAPPY_EMOJI_TOOLTIP': 'FEEDBACK.MODAL.GENERIC_HAPPY_EMOJI_TOOLTIP',
	'FEEDBACK.MODAL.GENERIC_SUPER_HAPPY_EMOJI_TOOLTIP': 'FEEDBACK.MODAL.GENERIC_SUPER_HAPPY_EMOJI_TOOLTIP',
	'FEEDBACK.MODAL.GENERIC_GO_BACK_EMOJI_TOOLTIP': 'FEEDBACK.MODAL.GENERIC_GO_BACK_EMOJI_TOOLTIP',
	'FEEDBACK.MODAL.GENERIC_COMMENT_PLACEHOLDER': 'FEEDBACK.MODAL.GENERIC_COMMENT_PLACEHOLDER',
	'FEEDBACK.MODAL.GENERIC_SEND_BUTTON': 'FEEDBACK.MODAL.GENERIC_SEND_BUTTON',
	'FEEDBACK.MODAL.IMPROVEMENTS_LIST_TITLE': 'FEEDBACK.MODAL.IMPROVEMENTS_LIST_TITLE',
	'FEEDBACK.MODAL.GENERIC_THANK_YOU_TITLE': 'FEEDBACK.MODAL.GENERIC_THANK_YOU_TITLE',
	'FEEDBACK.MODAL.GENERIC_THANK_YOU_BODY': 'FEEDBACK.MODAL.GENERIC_THANK_YOU_BODY',
	'calendar.draggable_modifiers_controls.CUSTOMIZE_PRICES_BUTTON': 'calendar.draggable_modifiers_controls.CUSTOMIZE_PRICES_BUTTON',
	'calendar.draggable_modifiers_drawer.TITLE': 'calendar.draggable_modifiers_drawer.TITLE',
	'calendar.draggable_modifiers_drawer.SECTION_1_TITLE': 'calendar.draggable_modifiers_drawer.SECTION_1_TITLE',
	'calendar.draggable_modifiers_drawer.PERIODS': 'calendar.draggable_modifiers_drawer.PERIODS',
	'calendar.draggable_modifiers_drawer.ROOM_TYPES': 'calendar.draggable_modifiers_drawer.ROOM_TYPES',
	'calendar.draggable_modifiers_drawer.MISMATCH_CURRENCIES_TITLE': 'calendar.draggable_modifiers_drawer.MISMATCH_CURRENCIES_TITLE',
	'calendar.draggable_modifiers_drawer.MISMATCH_CURRENCIES_DESCRIPTIPON': 'calendar.draggable_modifiers_drawer.MISMATCH_CURRENCIES_DESCRIPTIPON',
	'SALES_MODE_COMPETITOR': 'SALES_MODE_COMPETITOR',
	'CRMCUSTOMER_METRICS_STAY': 'CRMCUSTOMER_METRICS_STAY',
	'CRMCUSTOMER_EDIT-NOTE_TITLE': 'CRMCUSTOMER_EDIT-NOTE_TITLE',
	'CRMCUSTOMER_UPDATE-NOTE_NOTIFICATION-TITLE': 'CRMCUSTOMER_UPDATE-NOTE_NOTIFICATION-TITLE',
	'CRMCUSTOMER_UPDATE-NOTE_NOTIFICATION-SUBTITLE': 'CRMCUSTOMER_UPDATE-NOTE_NOTIFICATION-SUBTITLE',
	'CRMCUSTOMER_METRICS_NIGHT': 'CRMCUSTOMER_METRICS_NIGHT',
	'CRMCUSTOMER_METRICS_DAY': 'CRMCUSTOMER_METRICS_DAY',
	'CRMCUSTOMER_COMMUNICATIONS-PLACEHOLDER': 'CRMCUSTOMER_COMMUNICATIONS-PLACEHOLDER',
	'CRMCUSTOMER_RESERVATIONS-PLACEHOLDER': 'CRMCUSTOMER_RESERVATIONS-PLACEHOLDER',
	'CRMNEWSLETTER_AI-POPUP_TITLE': 'CRMNEWSLETTER_AI-POPUP_TITLE',
	'CRMNEWSLETTER_AI-LOADING': 'CRMNEWSLETTER_AI-LOADING',
	'CRMNEWSLETTER_LAYOUT-EDIT_TITLE-PLACEHOLDER': 'CRMNEWSLETTER_LAYOUT-EDIT_TITLE-PLACEHOLDER',
	'CRMNEWSLETTER_LAYOUT-EDIT_PARAGRAPH-PLACEHOLDER': 'CRMNEWSLETTER_LAYOUT-EDIT_PARAGRAPH-PLACEHOLDER',
	'CRMNEWSLETTER_EDIT-AUDIENCE_SUBTITLE': 'CRMNEWSLETTER_EDIT-AUDIENCE_SUBTITLE',
	'CRMNEWSLETTER_EDIT-OBJECT_SUBTITLE': 'CRMNEWSLETTER_EDIT-OBJECT_SUBTITLE',
	'CRMNEWSLETTER_EDIT-NAME_SUBTITLE': 'CRMNEWSLETTER_EDIT-NAME_SUBTITLE',
	'CRMNEWSLETTER_SENDING-DETAILS_NEWSLETTER-NAME': 'CRMNEWSLETTER_SENDING-DETAILS_NEWSLETTER-NAME',
	'CRMNEWSLETTER_SENDING-DETAILS_TO': 'CRMNEWSLETTER_SENDING-DETAILS_TO',
	'CRMNEWSLETTER_SENDING-DETAILS_OBJECT': 'CRMNEWSLETTER_SENDING-DETAILS_OBJECT',
	'CRMNEWSLETTER_SENDING-DETAILS_WHEN': 'CRMNEWSLETTER_SENDING-DETAILS_WHEN',
	'CRMNEWSLETTER_EDIT_TITLE': 'CRMNEWSLETTER_EDIT_TITLE',
	'CRMNEWSLETTER_EDIT_SUBTITLE': 'CRMNEWSLETTER_EDIT_SUBTITLE',
	'CRMNEWSLETTER_AI-POPUP_PLACEHOLDER': 'CRMNEWSLETTER_AI-POPUP_PLACEHOLDER',
	'CRMNEWSLETTER_SENDING-DETAILS_SCHEDULE-SENDING': 'CRMNEWSLETTER_SENDING-DETAILS_SCHEDULE-SENDING',
	'CRMNEWSLETTER_EDIT-SCHEDULING_NO': 'CRMNEWSLETTER_EDIT-SCHEDULING_NO',
	'CRMNEWSLETTER_EDIT-SCHEDULING_YES': 'CRMNEWSLETTER_EDIT-SCHEDULING_YES',
	'CRMNEWSLETTER_EDIT-SCHEDULING_SUBTITLE': 'CRMNEWSLETTER_EDIT-SCHEDULING_SUBTITLE',
	'CRMNEWSLETTER_LAYOUT-EDIT_DISCOUNT-PLACEHOLDER': 'CRMNEWSLETTER_LAYOUT-EDIT_DISCOUNT-PLACEHOLDER',
	'CRMNEWSLETTER_AI_INFO': 'CRMNEWSLETTER_AI_INFO',
	'CRMNEWSLETTER_SENDING-DETAILS_SENDING': 'CRMNEWSLETTER_SENDING-DETAILS_SENDING',
	'CRMNEWSLETTER_NEWSLETTER-CARD_CUSTOMERS': 'CRMNEWSLETTER_NEWSLETTER-CARD_CUSTOMERS',
	'CRMNEWSLETTER_NEWSLETTER-CARD_OPENING': 'CRMNEWSLETTER_NEWSLETTER-CARD_OPENING',
	'CRMNEWSLETTER_SENDING-DETAILS': 'CRMNEWSLETTER_SENDING-DETAILS',
	'CRMNEWSLETTER_DISCARD-NEWSLETTER_DESCRIPTION': 'CRMNEWSLETTER_DISCARD-NEWSLETTER_DESCRIPTION',
	'CRMNEWSLETTER_DELETE-NEWSLETTER_DESCRIPTION': 'CRMNEWSLETTER_DELETE-NEWSLETTER_DESCRIPTION',
	'CRMNEWSLETTER_DYNAMIC_TITILE': 'CRMNEWSLETTER_DYNAMIC_TITILE',
	'CRMNEWSLETTER_DYNAMIC_SUBTITLE': 'CRMNEWSLETTER_DYNAMIC_SUBTITLE',
	'CRMNEWSLETTER_ACTIONS_BACKGROUND': 'CRMNEWSLETTER_ACTIONS_BACKGROUND',
	'CRMNEWSLETTER_SUGGESTED_INFO': 'CRMNEWSLETTER_SUGGESTED_INFO',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_SUMMARY-TITLE': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_SUMMARY-TITLE',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_PORPUSE-TITLE': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_PORPUSE-TITLE',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_DESCRIPTION-TITLE': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_DESCRIPTION-TITLE',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_AUDIENCE': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_AUDIENCE',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_SEND': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_SEND',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_DISCOUNT': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_DISCOUNT',
	'CRMNEWSLETTER_METRICS_EMAIL': 'CRMNEWSLETTER_METRICS_EMAIL',
	'CRMNEWSLETTER_METRICS_EMAIL-LABEL': 'CRMNEWSLETTER_METRICS_EMAIL-LABEL',
	'CRMNEWSLETTER_METRICS_OPENING': 'CRMNEWSLETTER_METRICS_OPENING',
	'CRMNEWSLETTER_ACTIONS_WRITE': 'CRMNEWSLETTER_ACTIONS_WRITE',
	'CRMNEWSLETTER_SUGGESTED_PLACEHOLDER': 'CRMNEWSLETTER_SUGGESTED_PLACEHOLDER',
	'CRMNEWSLETTER_ACTIONS_GENERATE': 'CRMNEWSLETTER_ACTIONS_GENERATE',
	'CRMNEWSLETTER_ACTIONS_EDIT': 'CRMNEWSLETTER_ACTIONS_EDIT',
	'CRMNEWSLETTER_EDIT-SENDING_INPUT-DAY-LABEL': 'CRMNEWSLETTER_EDIT-SENDING_INPUT-DAY-LABEL',
	'CRMNEWSLETTER_EDIT-SENDING_INPUT-TIME-LABEL': 'CRMNEWSLETTER_EDIT-SENDING_INPUT-TIME-LABEL',
	'CRMNEWSLETTER_NEWSLETTER-CARD_BADGE-TODAY': 'CRMNEWSLETTER_NEWSLETTER-CARD_BADGE-TODAY',
	'CRMNEWSLETTER_ACTIONS_SAVE': 'CRMNEWSLETTER_ACTIONS_SAVE',
	'CRMNEWSLETTER_ACTIONS_IMPROVE': 'CRMNEWSLETTER_ACTIONS_IMPROVE',
	'CRMNEWSLETTER_EDIT-OBJECT_INPUT-PLACEHOLDER': 'CRMNEWSLETTER_EDIT-OBJECT_INPUT-PLACEHOLDER',
	'CRMNEWSLETTER_EDIT-OBJECT_INPUT-HELP': 'CRMNEWSLETTER_EDIT-OBJECT_INPUT-HELP',
	'CRMNEWSLETTER_ACTIONS_DISCARD': 'CRMNEWSLETTER_ACTIONS_DISCARD',
	'CRMNEWSLETTER_ACTIONS_EDIT-LAYOUT': 'CRMNEWSLETTER_ACTIONS_EDIT-LAYOUT',
	'CRMNEWSLETTER_EDIT-NAME_INPUT-PLACEHOLDER': 'CRMNEWSLETTER_EDIT-NAME_INPUT-PLACEHOLDER',
	'CRMNEWSLETTER_EDIT-NAME_INPUT-HELP': 'CRMNEWSLETTER_EDIT-NAME_INPUT-HELP',
	'CRMNEWSLETTER_ACTIONS_CANCEL': 'CRMNEWSLETTER_ACTIONS_CANCEL',
	'CRMNEWSLETTER_LAYOUT-EDIT_TITLE-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_TITLE-BADGE',
	'CRMNEWSLETTER_ACTIONS_UPLOAD': 'CRMNEWSLETTER_ACTIONS_UPLOAD',
	'CRMNEWSLETTER_ACTIONS_PERSONALIZE': 'CRMNEWSLETTER_ACTIONS_PERSONALIZE',
	'CRMNEWSLETTER_LAYOUT-EDIT_HEADER-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_HEADER-BADGE',
	'CRMNEWSLETTER_NEWSLETTER-SENT_SEARCH_PLACEHOLDER': 'CRMNEWSLETTER_NEWSLETTER-SENT_SEARCH_PLACEHOLDER',
	'CRMNEWSLETTER_NEWSLETTER_CATEGORY': 'CRMNEWSLETTER_NEWSLETTER_CATEGORY',
	'CRMNEWSLETTER_NEWSLETTER-SENT_TITLE': 'CRMNEWSLETTER_NEWSLETTER-SENT_TITLE',
	'CRMNEWSLETTER_EDIT-SENDING_INPUT-TIME-PLACEHOLDER': 'CRMNEWSLETTER_EDIT-SENDING_INPUT-TIME-PLACEHOLDER',
	'CRMNEWSLETTER_LAYOUT-EDIT_PARAGRAPH-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_PARAGRAPH-BADGE',
	'CRMNEWSLETTER_NEWSLETTER-BADGE_DISCOUNT-LABEL_YES': 'CRMNEWSLETTER_NEWSLETTER-BADGE_DISCOUNT-LABEL_YES',
	'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_CATEGORY': 'CRMNEWSLETTER_SUGGESTED-NEWSLETTER_CATEGORY',
	'CRMNEWSLETTER_ACTIONS_EDIT-AND-PLAN': 'CRMNEWSLETTER_ACTIONS_EDIT-AND-PLAN',
	'CRMNEWSLETTER_SENDING-DETAILS_PREVIEW': 'CRMNEWSLETTER_SENDING-DETAILS_PREVIEW',
	'CRMNEWSLETTER_NEWSLETTER-DETAILS_TITLE': 'CRMNEWSLETTER_NEWSLETTER-DETAILS_TITLE',
	'CRMNEWSLETTER_ACTIONS_USE': 'CRMNEWSLETTER_ACTIONS_USE',
	'CRMNEWSLETTER_ACTIONS_VIEW': 'CRMNEWSLETTER_ACTIONS_VIEW',
	'CRMNEWSLETTER_LAYOUT-EDIT_IMAGE-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_IMAGE-BADGE',
	'CRMNEWSLETTER_ACTIONS_PREVIEW': 'CRMNEWSLETTER_ACTIONS_PREVIEW',
	'CRMNEWSLETTER_ACTIONS_NEW-REQUEST': 'CRMNEWSLETTER_ACTIONS_NEW-REQUEST',
	'CRMNEWSLETTER_SENT': 'CRMNEWSLETTER_SENT',
	'CRMNEWSLETTER_ACTIONS_VIEW-ALL': 'CRMNEWSLETTER_ACTIONS_VIEW-ALL',
	'CRMNEWSLETTER_LAYOUT-EDIT_DISCOUNT-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_DISCOUNT-BADGE',
	'CRMNEWSLETTER_LAYOUT-EDIT_FOOTER-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_FOOTER-BADGE',
	'CRMNEWSLETTER_PLANNED_PLACEHOLDER': 'CRMNEWSLETTER_PLANNED_PLACEHOLDER',
	'CRMNEWSLETTER_ACTIONS_CREATE-NEWSLETTER': 'CRMNEWSLETTER_ACTIONS_CREATE-NEWSLETTER',
	'CRMNEWSLETTER_DELETE-NEWSLETTER_TITLE': 'CRMNEWSLETTER_DELETE-NEWSLETTER_TITLE',
	'CRMNEWSLETTER_ACTIONS_DELETE': 'CRMNEWSLETTER_ACTIONS_DELETE',
	'CRMNEWSLETTER_LAYOUT-EDIT_DESKTOP': 'CRMNEWSLETTER_LAYOUT-EDIT_DESKTOP',
	'CRMNEWSLETTER_LAYOUT-EDIT_MOBILE': 'CRMNEWSLETTER_LAYOUT-EDIT_MOBILE',
	'CRMNEWSLETTER_ACTIONS_CHOOSE': 'CRMNEWSLETTER_ACTIONS_CHOOSE',
	'CRMNEWSLETTER_ACTIONS_GENERATE-CONTENT': 'CRMNEWSLETTER_ACTIONS_GENERATE-CONTENT',
	'CRMNEWSLETTER_DISCARD-NEWSLETTER_TITLE': 'CRMNEWSLETTER_DISCARD-NEWSLETTER_TITLE',
	'CRMNEWSLETTER_PLANNED': 'CRMNEWSLETTER_PLANNED',
	'CRMNEWSLETTER_SUGGESTED-LATEST': 'CRMNEWSLETTER_SUGGESTED-LATEST',
	'CRMNEWSLETTER_SUGGESTED-DISCARDED': 'CRMNEWSLETTER_SUGGESTED-DISCARDED',
	'CRMNEWSLETTER_PAGE-TITLE_NEWSLETTER': 'CRMNEWSLETTER_PAGE-TITLE_NEWSLETTER',
	'CRMNEWSLETTER_ACTIONS_SETTINGS': 'CRMNEWSLETTER_ACTIONS_SETTINGS',
	'CRMNEWSLETTER_DYNAMIC_GUEST-SURNAME': 'CRMNEWSLETTER_DYNAMIC_GUEST-SURNAME',
	'CRMNEWSLETTER_DYNAMIC_GUEST-SURNAME_DESCRIPTION': 'CRMNEWSLETTER_DYNAMIC_GUEST-SURNAME_DESCRIPTION',
	'CRMNEWSLETTER_NEWSLETTER-CARD_BADGE-TOMORROW': 'CRMNEWSLETTER_NEWSLETTER-CARD_BADGE-TOMORROW',
	'CRMNEWSLETTER_DYNAMIC_GUEST-NAME': 'CRMNEWSLETTER_DYNAMIC_GUEST-NAME',
	'CRMNEWSLETTER_DYNAMIC_GUEST-NAME_DESCRIPTION': 'CRMNEWSLETTER_DYNAMIC_GUEST-NAME_DESCRIPTION',
	'CRMNEWSLETTER_CREATION_PAGE-TITLE': 'CRMNEWSLETTER_CREATION_PAGE-TITLE',
	'CRMNEWSLETTER_PAGE-TITLE_EDIT': 'CRMNEWSLETTER_PAGE-TITLE_EDIT',
	'CRMNEWSLETTER_ACTIONS_SAVE-LATER': 'CRMNEWSLETTER_ACTIONS_SAVE-LATER',
	'CRMNEWSLETTER_ACTIONS_SEND': 'CRMNEWSLETTER_ACTIONS_SEND',
	'CRMNEWSLETTER_SUGGESTED': 'CRMNEWSLETTER_SUGGESTED',
	'CRMNEWSLETTER_LAYOUT-EDIT_LANGUAGE-IT': 'CRMNEWSLETTER_LAYOUT-EDIT_LANGUAGE-IT',
	'CRMNEWSLETTER_LAYOUT-EDIT_LANGUAGE-EN': 'CRMNEWSLETTER_LAYOUT-EDIT_LANGUAGE-EN',
	'CRMNEWSLETTER_LAYOUT-EDIT_LANGUAGE-DE': 'CRMNEWSLETTER_LAYOUT-EDIT_LANGUAGE-DE',
	'CRMNEWSLETTER_DISCARD_NOTIFICATION-TITLE': 'CRMNEWSLETTER_DISCARD_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_DISCARD_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_DISCARD_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_DELETE_NOTIFICATION-TITLE': 'CRMNEWSLETTER_DELETE_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_DELETE_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_DELETE_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_EDIT_NOTIFICATION-TITLE': 'CRMNEWSLETTER_EDIT_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_EDIT_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_EDIT_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_SEND_NOTIFICATION-TITLE': 'CRMNEWSLETTER_SEND_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_SEND_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_SEND_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_SAVE_NOTIFICATION-TITLE': 'CRMNEWSLETTER_SAVE_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_SAVE_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_SAVE_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_ACTIONS_DUPLICATE': 'CRMNEWSLETTER_ACTIONS_DUPLICATE',
	'CRMNEWSLETTER_NEWSLETTER-BADGE_DISCOUNT-LABEL_NO': 'CRMNEWSLETTER_NEWSLETTER-BADGE_DISCOUNT-LABEL_NO',
	'CRM_UPLOAD_PLACEHOLDER': 'CRM_UPLOAD_PLACEHOLDER',
	'CRMNEWSLETTER_LAYOUT-NEWSLETTER_TITLE': 'CRMNEWSLETTER_LAYOUT-NEWSLETTER_TITLE',
	'CRMNEWSLETTER_UPLOAD-NEWSLETTER_TITLE': 'CRMNEWSLETTER_UPLOAD-NEWSLETTER_TITLE',
	'CRMNEWSLETTER_ACTIONS_DELETE-UPLOAD': 'CRMNEWSLETTER_ACTIONS_DELETE-UPLOAD',
	'CRMNEWSLETTER_UPLOAD_NOTIFICATION-TITLE': 'CRMNEWSLETTER_UPLOAD_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_UPLOAD_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_UPLOAD_NOTIFICATION-SUBTITLE',
	'CRM_UPLOAD_PLACEHOLDER-HOVER': 'CRM_UPLOAD_PLACEHOLDER-HOVER',
	'strategy.advanced_documentation_panel.TITLE': 'strategy.advanced_documentation_panel.TITLE',
	'strategy.advanced_documentation_panel.CONTEXT': 'strategy.advanced_documentation_panel.CONTEXT',
	'strategy.advanced_documentation_panel.SUMMARY': 'strategy.advanced_documentation_panel.SUMMARY',
	'strategy.advanced_documentation_panel.CONTENT': 'strategy.advanced_documentation_panel.CONTENT',
	'strategy.period_detail.ADVANCED_SETTINGS_BUTTON': 'strategy.period_detail.ADVANCED_SETTINGS_BUTTON',
	'strategy.advanced_settings.TITLE': 'strategy.advanced_settings.TITLE',
	'strategy.advanced_settings.BACK_TO_STRATEGY': 'strategy.advanced_settings.BACK_TO_STRATEGY',
	'strategy.advanced_settings.DROP_AND_PICKUP': 'strategy.advanced_settings.DROP_AND_PICKUP',
	'strategy.advanced_settings.DAY_OF_THE_WEEK': 'strategy.advanced_settings.DAY_OF_THE_WEEK',
	'strategy.advanced_settings.STARTING_PRICE': 'strategy.advanced_settings.STARTING_PRICE',
	'strategy.advanced_settings.EVENTS_AND_HOLIDAYS': 'strategy.advanced_settings.EVENTS_AND_HOLIDAYS',
	'strategy.advanced_settings.DEFAULT_STRATEGY': 'strategy.advanced_settings.DEFAULT_STRATEGY',
	'strategy.advanced_settings.SUMMARY': 'strategy.advanced_settings.SUMMARY',
	'auto-onboarding.starting-price-generation-loading.description': 'auto-onboarding.starting-price-generation-loading.description',
	'auto-onboarding.starting-price-base-price-map.generate': 'auto-onboarding.starting-price-base-price-map.generate',
	'auto-onboarding.starting-price-base-price-map.reference-room-type': 'auto-onboarding.starting-price-base-price-map.reference-room-type',
	'auto-onboarding.starting-price-base-price-map.average-selling-price': 'auto-onboarding.starting-price-base-price-map.average-selling-price',
	'auto-onboarding.starting-price-base-price-map.left-description': 'auto-onboarding.starting-price-base-price-map.left-description',
	'auto-onboarding.starting-price-base-price-map.average-selling-prices': 'auto-onboarding.starting-price-base-price-map.average-selling-prices',
	'auto-onboarding.starting-price-base-price-map.reference-room-comparison-text': 'auto-onboarding.starting-price-base-price-map.reference-room-comparison-text',
	'auto-onboarding.starting-price-base-price-map.average-selling-price-input-placeholder': 'auto-onboarding.starting-price-base-price-map.average-selling-price-input-placeholder',
	'feedback.competitors_chart_interaction.TITLE': 'feedback.competitors_chart_interaction.TITLE',
	'feedback.competitors_chart_interaction.HEADER': 'feedback.competitors_chart_interaction.HEADER',
	'feedback.competitors_chart_interaction.IMPROVEMENTS_1': 'feedback.competitors_chart_interaction.IMPROVEMENTS_1',
	'feedback.competitors_chart_interaction.IMPROVEMENTS_2': 'feedback.competitors_chart_interaction.IMPROVEMENTS_2',
	'feedback.competitors_map_zoom_in_or_out.TITLE': 'feedback.competitors_map_zoom_in_or_out.TITLE',
	'feedback.competitors_map_zoom_in_or_out.HEADER': 'feedback.competitors_map_zoom_in_or_out.HEADER',
	'feedback.competitors_map_zoom_in_or_out.IMPROVEMENTS_1': 'feedback.competitors_map_zoom_in_or_out.IMPROVEMENTS_1',
	'feedback.competitors_map_zoom_in_or_out.IMPROVEMENTS_2': 'feedback.competitors_map_zoom_in_or_out.IMPROVEMENTS_2',
	'feedback.competitors_map_zoom_in_or_out.IMPROVEMENTS_3': 'feedback.competitors_map_zoom_in_or_out.IMPROVEMENTS_3',
	'AUTOONBOARDING_STEPPER_BUTTON_PRICES': 'AUTOONBOARDING_STEPPER_BUTTON_PRICES',
	'AUTOONBOARDING_STEPPER_STEP4': 'AUTOONBOARDING_STEPPER_STEP4',
	'feedback.bulk_price_customization.TITLE': 'feedback.bulk_price_customization.TITLE',
	'feedback.bulk_price_customization.HEADER': 'feedback.bulk_price_customization.HEADER',
	'feedback.bulk_price_customization.IMPROVEMENTS_1': 'feedback.bulk_price_customization.IMPROVEMENTS_1',
	'feedback.bulk_price_customization.IMPROVEMENTS_2': 'feedback.bulk_price_customization.IMPROVEMENTS_2',
	'feedback.single_price_customization_cell.TITLE': 'feedback.single_price_customization_cell.TITLE',
	'feedback.single_price_customization_cell.HEADER': 'feedback.single_price_customization_cell.HEADER',
	'feedback.single_price_customization_cell.IMPROVEMENTS_1': 'feedback.single_price_customization_cell.IMPROVEMENTS_1',
	'feedback.single_price_customization_cell.IMPROVEMENTS_2': 'feedback.single_price_customization_cell.IMPROVEMENTS_2',
	'feedback.single_price_customization_cell.IMPROVEMENTS_3': 'feedback.single_price_customization_cell.IMPROVEMENTS_3',
	'feedback.single_price_customization_sheet.TITLE': 'feedback.single_price_customization_sheet.TITLE',
	'feedback.single_price_customization_sheet.HEADER': 'feedback.single_price_customization_sheet.HEADER',
	'feedback.single_price_customization_sheet.IMPROVEMENTS_1': 'feedback.single_price_customization_sheet.IMPROVEMENTS_1',
	'feedback.single_price_customization_sheet.IMPROVEMENTS_2': 'feedback.single_price_customization_sheet.IMPROVEMENTS_2',
	'feedback.single_price_customization_sheet.IMPROVEMENTS_3': 'feedback.single_price_customization_sheet.IMPROVEMENTS_3',
	'feedback.event_confirm.TITLE': 'feedback.event_confirm.TITLE',
	'feedback.event_confirm.HEADER': 'feedback.event_confirm.HEADER',
	'feedback.event_confirm.IMPROVEMENTS_1': 'feedback.event_confirm.IMPROVEMENTS_1',
	'feedback.event_confirm.IMPROVEMENTS_2': 'feedback.event_confirm.IMPROVEMENTS_2',
	'feedback.event_confirm.IMPROVEMENTS_3': 'feedback.event_confirm.IMPROVEMENTS_3',
	'feedback.event_confirm.IMPROVEMENTS_4': 'feedback.event_confirm.IMPROVEMENTS_4',
	'feedback.add_3_or_more_competitors.TITLE': 'feedback.add_3_or_more_competitors.TITLE',
	'feedback.add_3_or_more_competitors.HEADER': 'feedback.add_3_or_more_competitors.HEADER',
	'feedback.add_3_or_more_competitors.IMPROVEMENTS_1': 'feedback.add_3_or_more_competitors.IMPROVEMENTS_1',
	'feedback.add_3_or_more_competitors.IMPROVEMENTS_2': 'feedback.add_3_or_more_competitors.IMPROVEMENTS_2',
	'feedback.add_3_or_more_competitors.IMPROVEMENTS_3': 'feedback.add_3_or_more_competitors.IMPROVEMENTS_3',
	'feedback.price_list_save.TITLE': 'feedback.price_list_save.TITLE',
	'feedback.price_list_save.HEADER': 'feedback.price_list_save.HEADER',
	'feedback.price_list_save.IMPROVEMENTS_1': 'feedback.price_list_save.IMPROVEMENTS_1',
	'feedback.price_list_save.IMPROVEMENTS_2': 'feedback.price_list_save.IMPROVEMENTS_2',
	'feedback.price_list_save.IMPROVEMENTS_3': 'feedback.price_list_save.IMPROVEMENTS_3',
	'feedback.price_list_save.IMPROVEMENTS_4': 'feedback.price_list_save.IMPROVEMENTS_4',
	'feedback.price_list_save.IMPROVEMENTS_5': 'feedback.price_list_save.IMPROVEMENTS_5',
	'auto-onboarding.starting-price-base-price-map.generic-room-type': 'auto-onboarding.starting-price-base-price-map.generic-room-type',
	'auto-onboarding.starting-price-base-price-map.base-price-map-generation-failed': 'auto-onboarding.starting-price-base-price-map.base-price-map-generation-failed',
	'auto-onboarding.starting-price-base-price-map.greater-than-one-error': 'auto-onboarding.starting-price-base-price-map.greater-than-one-error',
	'strategy.period_detail_advanced_mode_banner.TITLE': 'strategy.period_detail_advanced_mode_banner.TITLE',
	'strategy.period_detail_advanced_mode_banner.DESC': 'strategy.period_detail_advanced_mode_banner.DESC',
	'strategy.period_detail_advanced_mode_banner.DESC_2': 'strategy.period_detail_advanced_mode_banner.DESC_2',
	'strategy.period_detail_advanced_mode_banner.ADVANCED_BUTTON': 'strategy.period_detail_advanced_mode_banner.ADVANCED_BUTTON',
	'strategy.period_detail_advanced_mode_banner.BASIC_BUTTON': 'strategy.period_detail_advanced_mode_banner.BASIC_BUTTON',
	'strategy.chart_tooltip.ADVANCED': 'strategy.chart_tooltip.ADVANCED',
	'CRMNEWSLETTER_TOOLTIP_CREATED-BY_SMARTCONNECT': 'CRMNEWSLETTER_TOOLTIP_CREATED-BY_SMARTCONNECT',
	'CRMNEWSLETTER_TOOLTIP_CREATED-BY_USER': 'CRMNEWSLETTER_TOOLTIP_CREATED-BY_USER',
	'calendar.events.derived-strategy-event-warning-title': 'calendar.events.derived-strategy-event-warning-title',
	'calendar.events.derived-strategy-event-warning-text': 'calendar.events.derived-strategy-event-warning-text',
	'calendar.events.derived-strategy-event-accommodation-list-warning-text': 'calendar.events.derived-strategy-event-accommodation-list-warning-text',
	'calendar.accommodation.derived-strategy-text': 'calendar.accommodation.derived-strategy-text',
	'CRMNESLETTER_EDIT-OBJECT_TRANSLATION-ITALIAN': 'CRMNESLETTER_EDIT-OBJECT_TRANSLATION-ITALIAN',
	'CRMNESLETTER_EDIT-OBJECT_TRANSLATION-ENGLISH': 'CRMNESLETTER_EDIT-OBJECT_TRANSLATION-ENGLISH',
	'CRMNESLETTER_EDIT-OBJECT_TRANSLATION-GERMAN': 'CRMNESLETTER_EDIT-OBJECT_TRANSLATION-GERMAN',
	'CRMNEWSLETTER_PREVIEW-LAYOUT_INFO': 'CRMNEWSLETTER_PREVIEW-LAYOUT_INFO',
	'CRMNEWSLETTER_PREVIEW-LAYOUT_TITLE': 'CRMNEWSLETTER_PREVIEW-LAYOUT_TITLE',
	'strategy.advanced_event.TITLE_1': 'strategy.advanced_event.TITLE_1',
	'strategy.advanced_event.DESCRIPTION': 'strategy.advanced_event.DESCRIPTION',
	'strategy.advanced_event.SLIDER_TITLE': 'strategy.advanced_event.SLIDER_TITLE',
	'strategy.advanced_event.TITLE_2': 'strategy.advanced_event.TITLE_2',
	'strategy.advanced_event.CHART_EVENT_TICK': 'strategy.advanced_event.CHART_EVENT_TICK',
	'CRMNEWSLETTER_ACTIONS_SAVE-AND-PLAN': 'CRMNEWSLETTER_ACTIONS_SAVE-AND-PLAN',
	'CRMNEWSLETTER_ACTIONS_PLAN': 'CRMNEWSLETTER_ACTIONS_PLAN',
	'CRMNEWSLETTER_NEWSLETTER-BADGE_SENDING-DATE_TOOLTIP': 'CRMNEWSLETTER_NEWSLETTER-BADGE_SENDING-DATE_TOOLTIP',
	'CRMNEWSLETTER_NEWSLETTER-BADGE_CUSTOMERS-TOOLTIP': 'CRMNEWSLETTER_NEWSLETTER-BADGE_CUSTOMERS-TOOLTIP',
	'CRMNEWSLETTER_NEWSLETTER-BADGE_SENDING-ACTIVE_TOOLTIP': 'CRMNEWSLETTER_NEWSLETTER-BADGE_SENDING-ACTIVE_TOOLTIP',
	'CRMNEWSLETTER_NEWSLETTER-BADGE_DISCOUNT-TOOLTIP': 'CRMNEWSLETTER_NEWSLETTER-BADGE_DISCOUNT-TOOLTIP',
	'CRMNEWSLETTER_NEWSLETTER-CARD_OPENING-TOOLTIP': 'CRMNEWSLETTER_NEWSLETTER-CARD_OPENING-TOOLTIP',
	'CRMNEWSLETTER_PREVIEW-LAYOUT_DESKTOP': 'CRMNEWSLETTER_PREVIEW-LAYOUT_DESKTOP',
	'CRMNEWSLETTER_PREVIEW-LAYOUT_MOBILE': 'CRMNEWSLETTER_PREVIEW-LAYOUT_MOBILE',
	'SUPPORT': 'SUPPORT',
	'USER': 'USER',
	'LANGUAGE': 'LANGUAGE',
	'strategy.advanced_dow.TITLE_1': 'strategy.advanced_dow.TITLE_1',
	'strategy.advanced_dow.DESCRIPTION': 'strategy.advanced_dow.DESCRIPTION',
	'strategy.advanced_dow.TITLE_2': 'strategy.advanced_dow.TITLE_2',
	'settings.language-section.title': 'settings.language-section.title',
	'settings.language-section.description': 'settings.language-section.description',
	'strategy.advanced_starting_prices.TITLE_1': 'strategy.advanced_starting_prices.TITLE_1',
	'strategy.advanced_starting_prices.DESCRIPTION': 'strategy.advanced_starting_prices.DESCRIPTION',
	'strategy.advanced_starting_prices.INPUT_LABEL': 'strategy.advanced_starting_prices.INPUT_LABEL',
	'strategy.advanced_starting_prices.TITLE_2': 'strategy.advanced_starting_prices.TITLE_2',
	'strategy.back_to_basic_mode.TITLE': 'strategy.back_to_basic_mode.TITLE',
	'strategy.back_to_basic_mode.DESCRIPTION': 'strategy.back_to_basic_mode.DESCRIPTION',
	'strategy.back_to_basic_mode.CONFIRM': 'strategy.back_to_basic_mode.CONFIRM',
	'strategy.back_to_basic_mode.CANCEL': 'strategy.back_to_basic_mode.CANCEL',
	'strategy.advanced_drop_pickup.TITLE_1': 'strategy.advanced_drop_pickup.TITLE_1',
	'strategy.advanced_drop_pickup.DESCRIPTION': 'strategy.advanced_drop_pickup.DESCRIPTION',
	'strategy.advanced_drop_pickup.TITLE_2': 'strategy.advanced_drop_pickup.TITLE_2',
	'strategy.advanced_drop_pickup_range.LABEL': 'strategy.advanced_drop_pickup_range.LABEL',
	'strategy.advanced_drop_pickup_preview.TITLE': 'strategy.advanced_drop_pickup_preview.TITLE',
	'strategy.advanced_drop_pickup_preview.SEASON_TITLE': 'strategy.advanced_drop_pickup_preview.SEASON_TITLE',
	'strategy.advanced_drop_pickup_preview.SEASON_LOW': 'strategy.advanced_drop_pickup_preview.SEASON_LOW',
	'strategy.advanced_drop_pickup_preview.SEASON_HIGH': 'strategy.advanced_drop_pickup_preview.SEASON_HIGH',
	'strategy.advanced_drop_pickup_preview.X_NAME': 'strategy.advanced_drop_pickup_preview.X_NAME',
	'CRMNEWSLETTER_EDIT-AUDIENCE_TOOLTIP': 'CRMNEWSLETTER_EDIT-AUDIENCE_TOOLTIP',
	'CRMNEWSLETTER_CALENDAR_PAST-PERIOD_TOOLTIP': 'CRMNEWSLETTER_CALENDAR_PAST-PERIOD_TOOLTIP',
	'CRMNEWSLETTER_CALENDAR_CURRENT-PERIOD_TOOLTIP': 'CRMNEWSLETTER_CALENDAR_CURRENT-PERIOD_TOOLTIP',
	'CRMAUDIENCE_CHART-TITLE_CUSTOMERS': 'CRMAUDIENCE_CHART-TITLE_CUSTOMERS',
	'CRMAUDIENCE_CHART-TITLE_AGE': 'CRMAUDIENCE_CHART-TITLE_AGE',
	'CRMAUDIENCE_CHART-TITLE_TRAVELLER': 'CRMAUDIENCE_CHART-TITLE_TRAVELLER',
	'CRMAUDIENCE_CHART-TITLE_COUNTRY': 'CRMAUDIENCE_CHART-TITLE_COUNTRY',
	'CRMAUDIENCE_CHART-PILL_MERGED': 'CRMAUDIENCE_CHART-PILL_MERGED',
	'CRMAUDIENCE_CHART-PILL_UNSUBSCRIBED': 'CRMAUDIENCE_CHART-PILL_UNSUBSCRIBED',
	'CRMAUDIENCE_CHART-PILL_CUSTOMERS': 'CRMAUDIENCE_CHART-PILL_CUSTOMERS',
	'CRMAUDIENCE_CHART-PILL_NEW': 'CRMAUDIENCE_CHART-PILL_NEW',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_SENT': 'CRMAUDIENCE_DETAILS_NEWSLETTER_SENT',
	'CRMNEWSLETTER_ACTIONS_GENERATE-TOOLTIP': 'CRMNEWSLETTER_ACTIONS_GENERATE-TOOLTIP',
	'STAY_DATE_ABBREVIATED': 'STAY_DATE_ABBREVIATED',
	'CREATION_DATE_ABBREVIATED': 'CREATION_DATE_ABBREVIATED',
	'CRMNEWSLETTER_CALENDAR_FUTURE-PERIOD_TOOLTIP': 'CRMNEWSLETTER_CALENDAR_FUTURE-PERIOD_TOOLTIP',
	'business_intelligence.days_difference_warning': 'business_intelligence.days_difference_warning',
	'STD_OCCUPANCY': 'STD_OCCUPANCY',
	'AUTOONBOARDING_STD_OCCUPANCY_PLACEHOLDER': 'AUTOONBOARDING_STD_OCCUPANCY_PLACEHOLDER',
	'dashboard.documentation_panel.title': 'dashboard.documentation_panel.title',
	'dashboard.documentation_panel.context': 'dashboard.documentation_panel.context',
	'dashboard.documentation_panel.description': 'dashboard.documentation_panel.description',
	'dashboard.documentation_panel.content': 'dashboard.documentation_panel.content',
	'dashboard.otb.tooltip_text': 'dashboard.otb.tooltip_text',
	'dashboard.date_picker_filters.WEEK_TO_DATE': 'dashboard.date_picker_filters.WEEK_TO_DATE',
	'dashboard.date_picker_filters.MONTH_TO_DATE': 'dashboard.date_picker_filters.MONTH_TO_DATE',
	'dashboard.date_picker_filters.YEAR_TO_DATE': 'dashboard.date_picker_filters.YEAR_TO_DATE',
	'dashboard.date_picker_filters.LAST_7_DAYS': 'dashboard.date_picker_filters.LAST_7_DAYS',
	'dashboard.date_picker_filters.LAST_30_DAYS': 'dashboard.date_picker_filters.LAST_30_DAYS',
	'dashboard.date_picker_filters.LAST_90_DAYS': 'dashboard.date_picker_filters.LAST_90_DAYS',
	'dashboard.date_picker_filters.LAST_6_MONTHS': 'dashboard.date_picker_filters.LAST_6_MONTHS',
	'dashboard.date_picker_filters.LAST_WINTER': 'dashboard.date_picker_filters.LAST_WINTER',
	'dashboard.date_picker_filters.LAST_SUMMER': 'dashboard.date_picker_filters.LAST_SUMMER',
	'dashboard.date_picker_filters.LAST_FALL': 'dashboard.date_picker_filters.LAST_FALL',
	'dashboard.date_picker_filters.LAST_SPRING': 'dashboard.date_picker_filters.LAST_SPRING',
	'dashboard.date_picker_filters.SINCE_EVER': 'dashboard.date_picker_filters.SINCE_EVER',
	'dashboard.date_picker_filters.SAME_DATES': 'dashboard.date_picker_filters.SAME_DATES',
	'dashboard.date_picker_filters.SAME_DATES_LAST_YEAR': 'dashboard.date_picker_filters.SAME_DATES_LAST_YEAR',
	'SMARTPAYING_MEX_KIND_discountAfterCheckout': 'SMARTPAYING_MEX_KIND_discountAfterCheckout',
	'SMARTPAYING_MEX_KIND_promotionBeforeCheckin': 'SMARTPAYING_MEX_KIND_promotionBeforeCheckin',
	'SMARTPAYING_MEX_KIND_upsellingProposalAfterCheckin': 'SMARTPAYING_MEX_KIND_upsellingProposalAfterCheckin',
	'CRMNEWSLETTER_ACTIONS_PERSONALIZE-TOOLTIP': 'CRMNEWSLETTER_ACTIONS_PERSONALIZE-TOOLTIP',
	'TOGGLE': 'TOGGLE',
	'CRMNEWSLETTER_ACTIONS_TRANSLATE': 'CRMNEWSLETTER_ACTIONS_TRANSLATE',
	'CRMNEWSLETTER_ACTIONS_DEACTIVATE': 'CRMNEWSLETTER_ACTIONS_DEACTIVATE',
	'CRMNEWSLETTER_ACTIONS_ACTIVATE': 'CRMNEWSLETTER_ACTIONS_ACTIVATE',
	'dashboard.save_visualization.TITLE': 'dashboard.save_visualization.TITLE',
	'dashboard.save_visualization.BODY': 'dashboard.save_visualization.BODY',
	'dashboard.filter_card.TITLE': 'dashboard.filter_card.TITLE',
	'dashboard.date_picker_filters.EVER': 'dashboard.date_picker_filters.EVER',
	'AUTOONBOARDING_OCCUPANCY_SETTINGS': 'AUTOONBOARDING_OCCUPANCY_SETTINGS',
	'AUTOONBOARDING_STD_OCCUPANCY_NOT_BETWEEN_MIN_AND_MAX': 'AUTOONBOARDING_STD_OCCUPANCY_NOT_BETWEEN_MIN_AND_MAX',
	'AUTOONBOARDING_STD_OCCUPANCY_ERROR': 'AUTOONBOARDING_STD_OCCUPANCY_ERROR',
	'dashboard.controls.CANT_DELETE_DEFAULT_VIEW_TOOLTIP': 'dashboard.controls.CANT_DELETE_DEFAULT_VIEW_TOOLTIP',
	'dashboard.controls.CANT_OVERRIDE_DEFAULT_VIEW_TOOLTIP': 'dashboard.controls.CANT_OVERRIDE_DEFAULT_VIEW_TOOLTIP',
	'strategy.discover_dialog.TITLE': 'strategy.discover_dialog.TITLE',
	'strategy.discover_dialog.SUBTITLE': 'strategy.discover_dialog.SUBTITLE',
	'strategy.discover_dialog.GO_TO_DOCUMENTATION_BUTTON': 'strategy.discover_dialog.GO_TO_DOCUMENTATION_BUTTON',
	'strategy.discover_dialog.OTHER_VIDEOS': 'strategy.discover_dialog.OTHER_VIDEOS',
	'CRMAUDIENCE_CREATED': 'CRMAUDIENCE_CREATED',
	'CRMAUDIENCE_ACTIONS_NEW-AUDIENCE': 'CRMAUDIENCE_ACTIONS_NEW-AUDIENCE',
	'CRMAUDIENCE_CREATED_PLACEHOLDER': 'CRMAUDIENCE_CREATED_PLACEHOLDER',
	'CRMAUDIENCE_SUGGESTED': 'CRMAUDIENCE_SUGGESTED',
	'CRMAUDIENCE_SUGGESTED_INFO': 'CRMAUDIENCE_SUGGESTED_INFO',
	'CRMAUDIENCE_AUDIENCE-BADGE_NEWSLETTER-TOOLTIP': 'CRMAUDIENCE_AUDIENCE-BADGE_NEWSLETTER-TOOLTIP',
	'CRMAUDIENCE_CREATED-LIST_NAME': 'CRMAUDIENCE_CREATED-LIST_NAME',
	'CRMAUDIENCE_CREATED-LIST_CUSTOMERS': 'CRMAUDIENCE_CREATED-LIST_CUSTOMERS',
	'CRMAUDIENCE_CREATED-LIST_SENDS': 'CRMAUDIENCE_CREATED-LIST_SENDS',
	'CRMAUDIENCE_CREATED-LIST_LAST-MODIFIED': 'CRMAUDIENCE_CREATED-LIST_LAST-MODIFIED',
	'CRMAUDIENCE_DETAILS_CREATED-ON': 'CRMAUDIENCE_DETAILS_CREATED-ON',
	'CRMAUDIENCE_DETAILS_CUSTOMER-NUMBER': 'CRMAUDIENCE_DETAILS_CUSTOMER-NUMBER',
	'CRMAUDIENCE_DETAILS_OVERVIEW': 'CRMAUDIENCE_DETAILS_OVERVIEW',
	'CRMAUDIENCE_DETAILS_NEWSLETTER': 'CRMAUDIENCE_DETAILS_NEWSLETTER',
	'CRMAUDIENCE_DETAILS_CUSTOMERS': 'CRMAUDIENCE_DETAILS_CUSTOMERS',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_PLANNED': 'CRMAUDIENCE_DETAILS_NEWSLETTER_PLANNED',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_SUGGESTED': 'CRMAUDIENCE_DETAILS_NEWSLETTER_SUGGESTED',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_SEND': 'CRMAUDIENCE_DETAILS_NEWSLETTER_SEND',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_NO-SELECTION_PLACEHOLDER': 'CRMAUDIENCE_DETAILS_NEWSLETTER_NO-SELECTION_PLACEHOLDER',
	'CRMAUDIENCE_DETAILS_PAGE-TITLE': 'CRMAUDIENCE_DETAILS_PAGE-TITLE',
	'CRMAUDIENCE_HOVER-CHART_TITLE-AGE': 'CRMAUDIENCE_HOVER-CHART_TITLE-AGE',
	'CRMAUDIENCE_HOVER-CHART_TITLE-TRAVELLER': 'CRMAUDIENCE_HOVER-CHART_TITLE-TRAVELLER',
	'CRMAUDIENCE_HOVER-CHART_TITLE-COUNTRY': 'CRMAUDIENCE_HOVER-CHART_TITLE-COUNTRY',
	'CRMAUDIENCE_DETAILS_SIMILAR-TO': 'CRMAUDIENCE_DETAILS_SIMILAR-TO',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_SEND-PLACEHOLDER': 'CRMAUDIENCE_DETAILS_NEWSLETTER_SEND-PLACEHOLDER',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_SUGGESTED-PLACEHOLDER': 'CRMAUDIENCE_DETAILS_NEWSLETTER_SUGGESTED-PLACEHOLDER',
	'CRMAUDIENCE_DETAILS_NEWSLETTER_PLANNED-PLACEHOLDER': 'CRMAUDIENCE_DETAILS_NEWSLETTER_PLANNED-PLACEHOLDER',
	'CRMAUDIENCE_DETAILS_SIMILAR-TO_PLACEHOLDER': 'CRMAUDIENCE_DETAILS_SIMILAR-TO_PLACEHOLDER',
	'CRMAUDIENCE_AGE-RANGE_1824': 'CRMAUDIENCE_AGE-RANGE_1824',
	'CRMAUDIENCE_AGE-RANGE_2544': 'CRMAUDIENCE_AGE-RANGE_2544',
	'CRMAUDIENCE_AGE-RANGE_4564': 'CRMAUDIENCE_AGE-RANGE_4564',
	'CRMAUDIENCE_AGE-RANGE_65': 'CRMAUDIENCE_AGE-RANGE_65',
	'CRMAUDIENCE_ACTIONS_LINK-TOOLTIP': 'CRMAUDIENCE_ACTIONS_LINK-TOOLTIP',
	'CRMAUDIENCE_ACTIONS_EDIT': 'CRMAUDIENCE_ACTIONS_EDIT',
	'CRMAUDIENCE_ACTIONS_DELETE': 'CRMAUDIENCE_ACTIONS_DELETE',
	'CRMAUDIENCE_ACTIONS_CREATE-NEWSLETTER': 'CRMAUDIENCE_ACTIONS_CREATE-NEWSLETTER',
	'CRMAUDIENCE_CREATION-FILTER-LIST_TITLE': 'CRMAUDIENCE_CREATION-FILTER-LIST_TITLE',
	'CRMAUDIENCE_SAVE-AUDIENCE_DESCRIPTION': 'CRMAUDIENCE_SAVE-AUDIENCE_DESCRIPTION',
	'CRMAUDIENCE_SAVE-AUDIENCE_ALERT': 'CRMAUDIENCE_SAVE-AUDIENCE_ALERT',
	'CRMAUDIENCE_DELETE-AUDIENCE_DESCRIPTION': 'CRMAUDIENCE_DELETE-AUDIENCE_DESCRIPTION',
	'CRMAUDIENCE_DELETE-AUDIENCE-BLOCK_DESCRIPTION': 'CRMAUDIENCE_DELETE-AUDIENCE-BLOCK_DESCRIPTION',
	'CRMNEWSLETTER_BADGE_DRAFT': 'CRMNEWSLETTER_BADGE_DRAFT',
	'CRMAUDIENCE_DELETE-AUDIENCE-BLOCK_LIST-TITLE': 'CRMAUDIENCE_DELETE-AUDIENCE-BLOCK_LIST-TITLE',
	'CRMAUDIENCE_DELETE-AUDIENCE-BLOCK_LIST-PLACEHOLDER': 'CRMAUDIENCE_DELETE-AUDIENCE-BLOCK_LIST-PLACEHOLDER',
	'feedback.dashboard_kpis_compared.TITLE': 'feedback.dashboard_kpis_compared.TITLE',
	'CRMAUDIENCE_SAVE_INPUT-PLACEHOLDER': 'CRMAUDIENCE_SAVE_INPUT-PLACEHOLDER',
	'feedback.dashboard_kpis_compared.HEADER': 'feedback.dashboard_kpis_compared.HEADER',
	'CRMAUDIENCE_ACTIONS_DELETE_TEXT-CONFIRMATION': 'CRMAUDIENCE_ACTIONS_DELETE_TEXT-CONFIRMATION',
	'feedback.dashboard_kpis_compared.IMPROVEMENTS_1': 'feedback.dashboard_kpis_compared.IMPROVEMENTS_1',
	'feedback.dashboard_kpis_compared.IMPROVEMENTS_2': 'feedback.dashboard_kpis_compared.IMPROVEMENTS_2',
	'feedback.dashboard_kpis_compared.IMPROVEMENTS_3': 'feedback.dashboard_kpis_compared.IMPROVEMENTS_3',
	'CRMAUDIENCE_SUGGESTED-AUDIENCE_CATEGORY': 'CRMAUDIENCE_SUGGESTED-AUDIENCE_CATEGORY',
	'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUMMARY': 'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUMMARY',
	'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUMMARY-DESCRIPTION': 'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUMMARY-DESCRIPTION',
	'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUGGESTED-TITLE': 'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUGGESTED-TITLE',
	'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUGGESTED-DESCRIPTION': 'CRMAUDIENCE_SUGGESTED-AUDIENCE_SUGGESTED-DESCRIPTION',
	'CRMAUDIENCE_DELETE_NOTIFICATION-TITLE': 'CRMAUDIENCE_DELETE_NOTIFICATION-TITLE',
	'CRMAUDIENCE_DELETE_NOTIFICATION-SUBTITLE': 'CRMAUDIENCE_DELETE_NOTIFICATION-SUBTITLE',
	'CRMAUDIENCE_ACTIONS_VIEW': 'CRMAUDIENCE_ACTIONS_VIEW',
	'CRMAUDIENCE_ACTIONS_MOVE-TO': 'CRMAUDIENCE_ACTIONS_MOVE-TO',
	'CRMAUDIENCE_CREATE_NOTIFICATION-TITLE': 'CRMAUDIENCE_CREATE_NOTIFICATION-TITLE',
	'CRMAUDIENCE_CREATE_NOTIFICATION-SUBTITLE': 'CRMAUDIENCE_CREATE_NOTIFICATION-SUBTITLE',
	'CRMAUDIENCE_DATA-FILTERS_LAST-6-MONTHS': 'CRMAUDIENCE_DATA-FILTERS_LAST-6-MONTHS',
	'CRMAUDIENCE_SAVE_NOTIFICATION-TITLE': 'CRMAUDIENCE_SAVE_NOTIFICATION-TITLE',
	'CRMAUDIENCE_SAVE_NOTIFICATION-SUBTITLE': 'CRMAUDIENCE_SAVE_NOTIFICATION-SUBTITLE',
	'CRMAUDIENCE_ACTIONS_VIEW-DETAIL': 'CRMAUDIENCE_ACTIONS_VIEW-DETAIL',
	'strategy.discover_dialog.VIDEOS_TO_SHOWCASE': 'strategy.discover_dialog.VIDEOS_TO_SHOWCASE',
	'CRMAUDIENCE_DETAILS_CUSTOMER-NAME': 'CRMAUDIENCE_DETAILS_CUSTOMER-NAME',
	'CRMAUDIENCE_DETAILS_CUSTOMER-COUNTRY': 'CRMAUDIENCE_DETAILS_CUSTOMER-COUNTRY',
	'strategy.documentation_panel.TITLE': 'strategy.documentation_panel.TITLE',
	'strategy.documentation_panel.CONTEXT': 'strategy.documentation_panel.CONTEXT',
	'strategy.documentation_panel.SUMMARY': 'strategy.documentation_panel.SUMMARY',
	'strategy.documentation_panel.CONTENT': 'strategy.documentation_panel.CONTENT',
	'strategy.discover_dialog.GO_TO_ALL_VIDEOS_BUTTON': 'strategy.discover_dialog.GO_TO_ALL_VIDEOS_BUTTON',
	'feedback.strategy_change.TITLE': 'feedback.strategy_change.TITLE',
	'feedback.strategy_change.HEADER': 'feedback.strategy_change.HEADER',
	'feedback.strategy_change.IMPROVEMENTS_1': 'feedback.strategy_change.IMPROVEMENTS_1',
	'feedback.strategy_change.IMPROVEMENTS_2': 'feedback.strategy_change.IMPROVEMENTS_2',
	'feedback.strategy_change.IMPROVEMENTS_3': 'feedback.strategy_change.IMPROVEMENTS_3',
	'dashboard.date_picker_filters.NEXT_7_DAYS': 'dashboard.date_picker_filters.NEXT_7_DAYS',
	'dashboard.date_picker_filters.NEXT_30_DAYS': 'dashboard.date_picker_filters.NEXT_30_DAYS',
	'dashboard.date_picker_filters.NEXT_60_DAYS': 'dashboard.date_picker_filters.NEXT_60_DAYS',
	'dashboard.date_picker_filters.NEXT_90_DAYS': 'dashboard.date_picker_filters.NEXT_90_DAYS',
	'dashboard.date_picker_filters.LAST_60_DAYS': 'dashboard.date_picker_filters.LAST_60_DAYS',
	'dashboard.input.ERROR_INCOMPATIBLE_FILTER': 'dashboard.input.ERROR_INCOMPATIBLE_FILTER',
	'dashboard.date_picker_filters.CURRENT_YEAR': 'dashboard.date_picker_filters.CURRENT_YEAR',
	'CRMAUDIENCE_CREATION_PAGE-TITLE': 'CRMAUDIENCE_CREATION_PAGE-TITLE',
	'CRMAUDIENCE_ACTIONS_CANCEL': 'CRMAUDIENCE_ACTIONS_CANCEL',
	'CRMAUDIENCE_ACTIONS_CREATE-SAVE': 'CRMAUDIENCE_ACTIONS_CREATE-SAVE',
	'CRMAUDIENCE_CREATION_AUDIENCE_OVERVIEW': 'CRMAUDIENCE_CREATION_AUDIENCE_OVERVIEW',
	'CRMAUDIENCE_CREATION_CUSTOMER_SELECTED': 'CRMAUDIENCE_CREATION_CUSTOMER_SELECTED',
	'CRMAUDIENCE_CREATION_AUDIENCE_SIMILAR-TO': 'CRMAUDIENCE_CREATION_AUDIENCE_SIMILAR-TO',
	'CRMAUDIENCE_CREATION_FILTERS': 'CRMAUDIENCE_CREATION_FILTERS',
	'CRMAUDIENCE_CREATION_DESCRIPTION': 'CRMAUDIENCE_CREATION_DESCRIPTION',
	'CRMAUDIENCE_ACTIONS_ADD-FILTER': 'CRMAUDIENCE_ACTIONS_ADD-FILTER',
	'CRMAUDIENCE_CREATION_CUSTOMER-SELECTED_TITLE-SINGULAR': 'CRMAUDIENCE_CREATION_CUSTOMER-SELECTED_TITLE-SINGULAR',
	'CRMAUDIENCE_CREATION_CUSTOMER-SELECTED_TITLE-PLURAL': 'CRMAUDIENCE_CREATION_CUSTOMER-SELECTED_TITLE-PLURAL',
	'CRMAUDIENCE_CREATION_AUDIENCE_SIMILAR-TO_TOOLTIP': 'CRMAUDIENCE_CREATION_AUDIENCE_SIMILAR-TO_TOOLTIP',
	'CRMAUDIENCE_AUDIENCE_CATEGORY': 'CRMAUDIENCE_AUDIENCE_CATEGORY',
	'CRMAUDIENCE_SAVE-AUDIENCE_TITLE': 'CRMAUDIENCE_SAVE-AUDIENCE_TITLE',
	'CRMAUDIENCE_ACTIONS_SAVE': 'CRMAUDIENCE_ACTIONS_SAVE',
	'CRMAUDIENCE_CHART-PILL_REPEATER': 'CRMAUDIENCE_CHART-PILL_REPEATER',
	'CRMAUDIENCE_DATA-FILTERS_LAST-1-YEAR': 'CRMAUDIENCE_DATA-FILTERS_LAST-1-YEAR',
	'CRMAUDIENCE_DATA-FILTERS_THIS-AUDIENCE': 'CRMAUDIENCE_DATA-FILTERS_THIS-AUDIENCE',
	'CRMAUDIENCE_DATA-FILTERS_ALL_CUSTOMERS': 'CRMAUDIENCE_DATA-FILTERS_ALL_CUSTOMERS',
	'CRMAUDIENCE_PAGE-TITLE': 'CRMAUDIENCE_PAGE-TITLE',
	'CRMAUDIENCE_SUGGESTED_EMPTY_STATE': 'CRMAUDIENCE_SUGGESTED_EMPTY_STATE',
	'CRMAUDIENCE_DELETE-AUDIENCE_TITLE': 'CRMAUDIENCE_DELETE-AUDIENCE_TITLE',
	'CMR_AUDIENCE_MALE': 'CMR_AUDIENCE_MALE',
	'CRM_AUDIENCE_FEMALE': 'CRM_AUDIENCE_FEMALE',
	'CRMAUTOMATION_DRAWER_OTA-NOTIFICATION_TITLE': 'CRMAUTOMATION_DRAWER_OTA-NOTIFICATION_TITLE',
	'CRMAUTOMATIO_OTA-MODAL_SUMMARY': 'CRMAUTOMATIO_OTA-MODAL_SUMMARY',
	'CRMAUTOMATION_OTA-MODAL_SUMMARY_TEXT': 'CRMAUTOMATION_OTA-MODAL_SUMMARY_TEXT',
	'CRMAUTOMATIO_OTA-MODAL_INTRO': 'CRMAUTOMATIO_OTA-MODAL_INTRO',
	'CRMAUTOMATIO_OTA-MODAL_INTRO_TEXT': 'CRMAUTOMATIO_OTA-MODAL_INTRO_TEXT',
	'CRMAUTOMATIO_OTA-MODAL_AIRBNB': 'CRMAUTOMATIO_OTA-MODAL_AIRBNB',
	'CRMAUTOMATIO_OTA-MODAL_AIRBNB_TEXT': 'CRMAUTOMATIO_OTA-MODAL_AIRBNB_TEXT',
	'CRMAUTOMATIO_OTA-MODAL_BOOKING': 'CRMAUTOMATIO_OTA-MODAL_BOOKING',
	'CRMAUTOMATIO_OTA-MODAL_BOOKING_TEXT': 'CRMAUTOMATIO_OTA-MODAL_BOOKING_TEXT',
	'CRMAUTOMATIO_OTA-MODAL_BOOKING-VIDEO_TITLE-1': 'CRMAUTOMATIO_OTA-MODAL_BOOKING-VIDEO_TITLE-1',
	'CRMAUTOMATIO_OTA-MODAL_BOOKING-VIDEO_TITLE-2': 'CRMAUTOMATIO_OTA-MODAL_BOOKING-VIDEO_TITLE-2',
	'CRMAUTOMATIO_OTA-MODAL_BOOKING-VIDEO_TITLE-3': 'CRMAUTOMATIO_OTA-MODAL_BOOKING-VIDEO_TITLE-3',
	'CRMAUTOMATIO_OTA-MODAL_AIRBNB-VIDEO_TITLE-1': 'CRMAUTOMATIO_OTA-MODAL_AIRBNB-VIDEO_TITLE-1',
	'CRMAUTOMATIO_OTA-MODAL_SUGGESTION': 'CRMAUTOMATIO_OTA-MODAL_SUGGESTION',
	'CRMAUTOMATIO_OTA-MODAL_SUGGESTION_TEXT': 'CRMAUTOMATIO_OTA-MODAL_SUGGESTION_TEXT',
	'CRMAUTOMATION_OTA-MODAL_CATEGORY': 'CRMAUTOMATION_OTA-MODAL_CATEGORY',
	'CRMAUTOMATION_OTA-MODAL_TITLE': 'CRMAUTOMATION_OTA-MODAL_TITLE',
	'CRMAUDIENCE_TOOLTIP_SIMILAR-TO': 'CRMAUDIENCE_TOOLTIP_SIMILAR-TO',
	'CRMAUDIENCE_DETAILS_CUSTOMERS_PLACEHOLDER': 'CRMAUDIENCE_DETAILS_CUSTOMERS_PLACEHOLDER',
	'CRM_AUDIENCE_OPERATOR_in': 'CRM_AUDIENCE_OPERATOR_in',
	'CRM_AUDIENCE_OPERATOR_not_in': 'CRM_AUDIENCE_OPERATOR_not_in',
	'CRM_AUDIENCE_OPERATOR_is': 'CRM_AUDIENCE_OPERATOR_is',
	'CRM_AUDIENCE_OPERATOR_is_not': 'CRM_AUDIENCE_OPERATOR_is_not',
	'CRM_AUDIENCE_OPERATOR_is_more': 'CRM_AUDIENCE_OPERATOR_is_more',
	'CRM_AUDIENCE_OPERATOR_is_less': 'CRM_AUDIENCE_OPERATOR_is_less',
	'CRM_AUDIENCE_OPERATOR_is_between': 'CRM_AUDIENCE_OPERATOR_is_between',
	'CRM_AUDIENCE_OPERATOR_is_not_between': 'CRM_AUDIENCE_OPERATOR_is_not_between',
	'CRM_AUDIENCE_FIELD_language': 'CRM_AUDIENCE_FIELD_language',
	'CRM_AUDIENCE_FIELD_country': 'CRM_AUDIENCE_FIELD_country',
	'CRM_AUDIENCE_FIELD_nationality': 'CRM_AUDIENCE_FIELD_nationality',
	'CRM_AUDIENCE_FIELD_repeater': 'CRM_AUDIENCE_FIELD_repeater',
	'CRM_AUDIENCE_FIELD_age': 'CRM_AUDIENCE_FIELD_age',
	'CRM_AUDIENCE_FIELD_birthday_in_days': 'CRM_AUDIENCE_FIELD_birthday_in_days',
	'CRM_AUDIENCE_FIELD_gender': 'CRM_AUDIENCE_FIELD_gender',
	'CRM_AUDIENCE_FIELD_last_checkout_day': 'CRM_AUDIENCE_FIELD_last_checkout_day',
	'CRM_AUDIENCE_FIELD_reservation_number': 'CRM_AUDIENCE_FIELD_reservation_number',
	'CRM_AUDIENCE_FIELD____new': 'CRM_AUDIENCE_FIELD____new',
	'CRMAUDIENCE_OTHER': 'CRMAUDIENCE_OTHER',
	'CRMNEWSLETTER_ADD-LINK': 'CRMNEWSLETTER_ADD-LINK',
	'CRMNEWSLETTER_INPUT_ADD-LINK': 'CRMNEWSLETTER_INPUT_ADD-LINK',
	'CRMNEWSLETTER_INPUT-WEB_PLACEHOLDER': 'CRMNEWSLETTER_INPUT-WEB_PLACEHOLDER',
	'CRMNEWSLETTER_INPUT-MAIL_PLACEHOLDER': 'CRMNEWSLETTER_INPUT-MAIL_PLACEHOLDER',
	'CRMNEWSLETTER_INPUT-PHONE_PLACEHOLDER': 'CRMNEWSLETTER_INPUT-PHONE_PLACEHOLDER',
	'CRMNEWSLETTER_ACTIONS_ADD-LINK': 'CRMNEWSLETTER_ACTIONS_ADD-LINK',
	'CRMNEWSLETTER_ACTIONS_EDIT-LINK': 'CRMNEWSLETTER_ACTIONS_EDIT-LINK',
	'CRMNEWSLETTER_ACTIONS_BACKGROUND-COLOR': 'CRMNEWSLETTER_ACTIONS_BACKGROUND-COLOR',
	'CRMNEWSLETTER_ACTIONS_TEXT-COLOR': 'CRMNEWSLETTER_ACTIONS_TEXT-COLOR',
	'CRM_MARKETING': 'CRM_MARKETING',
	'CRMNEWSLETTER_BUTTON-LINK_INPUT_URL': 'CRMNEWSLETTER_BUTTON-LINK_INPUT_URL',
	'CRMNEWSLETTER_BUTTON-LINK_INPUT_PHONE': 'CRMNEWSLETTER_BUTTON-LINK_INPUT_PHONE',
	'CRMNEWSLETTER_BUTTON-LINK_INPUT_EMAIL': 'CRMNEWSLETTER_BUTTON-LINK_INPUT_EMAIL',
	'CRMNEWSLETTER_LAYOUT-EDIT_BUTTON-PLACEHOLDER': 'CRMNEWSLETTER_LAYOUT-EDIT_BUTTON-PLACEHOLDER',
	'CRMAUTOMATION_SENDING-DETAILS_NAME': 'CRMAUTOMATION_SENDING-DETAILS_NAME',
	'CRMAUTOMATION_SENDING-DETAILS_NAME-SUBTITLE': 'CRMAUTOMATION_SENDING-DETAILS_NAME-SUBTITLE',
	'CRMAUTOMATION_SENDING-DETAILS_SENT-TO-SUBTITLE': 'CRMAUTOMATION_SENDING-DETAILS_SENT-TO-SUBTITLE',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT-SUBTITLE': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT-SUBTITLE',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN': 'CRMAUTOMATION_SENDING-DETAILS_WHEN',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN-SUBTITLE': 'CRMAUTOMATION_SENDING-DETAILS_WHEN-SUBTITLE',
	'CRMAUTOMATION_SENDING-DETAILS_SENT-TO': 'CRMAUTOMATION_SENDING-DETAILS_SENT-TO',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT',
	'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT-SUBTITLE': 'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT-SUBTITLE',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT-SUBTITLE': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT-SUBTITLE',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_DEPOSIT': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_DEPOSIT',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY-LINK': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY-LINK',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION-ITALIAN': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION-ITALIAN',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION-ENGLISH': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION-ENGLISH',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION-GERMAN': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT_TRANSLATION-GERMAN',
	'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT': 'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT',
	'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT-YES': 'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT-YES',
	'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT-NO': 'CRMAUTOMATION_SENDING-DETAILS_DISCOUNT-NO',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_EMAIL': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_EMAIL',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_ACTIVE': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_ACTIVE',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_TITLE': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_TITLE',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_WAPP': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_WAPP',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_MOMENT': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_MOMENT',
	'CRMAUTOMATION_SENDING-DETAILS_NAME_INPUT-PLACEHOLDER': 'CRMAUTOMATION_SENDING-DETAILS_NAME_INPUT-PLACEHOLDER',
	'CRMAUTOMATION_SENDING-DETAILS_NAME_INPUT-HELP': 'CRMAUTOMATION_SENDING-DETAILS_NAME_INPUT-HELP',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_RESERVATION-TYPE': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_RESERVATION-TYPE',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_RESERVATION-CHANNEL': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_RESERVATION-CHANNEL',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT_INPUT-PLACEHOLDER': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT_INPUT-PLACEHOLDER',
	'CRMAUTOMATION_SENDING-DETAILS_OBJECT_INPUT-HELP': 'CRMAUTOMATION_SENDING-DETAILS_OBJECT_INPUT-HELP',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_DEPOSIT_INPUT-PLACEHOLDER': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_DEPOSIT_INPUT-PLACEHOLDER',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY_INPUT-PLACEHOLDER': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY_INPUT-PLACEHOLDER',
	'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY_INPUT-HELP': 'CRMAUTOMATION_SENDING-DETAILS_PAYMENT_VALIDITY_INPUT-HELP',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_MOMENT_INPUT-PLACEHOLDER': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_MOMENT_INPUT-PLACEHOLDER',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_EMAIL_INPUT': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_EMAIL_INPUT',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_ACTIVE-YES': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_ACTIVE-YES',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_WAPP_INPUT': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_WAPP_INPUT',
	'CRMAUTOMATION_SENDING-DETAILS_WHEN_ACTIVE-NO': 'CRMAUTOMATION_SENDING-DETAILS_WHEN_ACTIVE-NO',
	'CRMAUTOMATION_EDIT_PAGE-TITLE': 'CRMAUTOMATION_EDIT_PAGE-TITLE',
	'CRMAUTOMATION_CREATE_PAGE-TITLE': 'CRMAUTOMATION_CREATE_PAGE-TITLE',
	'CRMAUTOMATION_DETAILS_WHEN-INACTIVE': 'CRMAUTOMATION_DETAILS_WHEN-INACTIVE',
	'CRMAUTOMATION_DETAILS_WHEN': 'CRMAUTOMATION_DETAILS_WHEN',
	'CRMAUTOMATION_DETAILS_WHEN-IMMEDIATELY': 'CRMAUTOMATION_DETAILS_WHEN-IMMEDIATELY',
	'CRMAUTOMATION_DETAILS_PAYMENT_VALIDITY-LINK': 'CRMAUTOMATION_DETAILS_PAYMENT_VALIDITY-LINK',
	'CRMAUTOMATION_SENDING-DETAILS': 'CRMAUTOMATION_SENDING-DETAILS',
	'CRMAUTOMATION_EDIT-LAYOUT_INFO': 'CRMAUTOMATION_EDIT-LAYOUT_INFO',
	'CRMAUTOMATIONS_ACTIONS_ADD-COMMUNICATION': 'CRMAUTOMATIONS_ACTIONS_ADD-COMMUNICATION',
	'CRMAUTOMATION_EDIT_NOTIFICATION-TITLE': 'CRMAUTOMATION_EDIT_NOTIFICATION-TITLE',
	'CRMAUTOMATION_EDIT_NOTIFICATION-SUBTITLE': 'CRMAUTOMATION_EDIT_NOTIFICATION-SUBTITLE',
	'CRMAUTOMATION_SAVE_NOTIFICATION-TITLE': 'CRMAUTOMATION_SAVE_NOTIFICATION-TITLE',
	'CRMAUTOMATION_SAVE_NOTIFICATION-SUBTITLE': 'CRMAUTOMATION_SAVE_NOTIFICATION-SUBTITLE',
	'CRMAUTOMATION_OTA-MODAL_CONTENT': 'CRMAUTOMATION_OTA-MODAL_CONTENT',
	'CRMAUTOMATION_SENDING-INFO_WHEN_TOOLTIP': 'CRMAUTOMATION_SENDING-INFO_WHEN_TOOLTIP',
	'CRMAUTOMATION_SENDING-INFO_SEND-TO_ACCOMMODATION-TOOLTIP': 'CRMAUTOMATION_SENDING-INFO_SEND-TO_ACCOMMODATION-TOOLTIP',
	'CRMAUTOMATION_SENDING-DETAILS_ACCOMMODATION': 'CRMAUTOMATION_SENDING-DETAILS_ACCOMMODATION',
	'CRM_ALL': 'CRM_ALL',
	'CRMNEWSLETTER_BADGE_NO-CUSTOMERS': 'CRMNEWSLETTER_BADGE_NO-CUSTOMERS',
	'CRMNEWSLETTER_SENDING-DETAILS_SEND-TO_ALERT': 'CRMNEWSLETTER_SENDING-DETAILS_SEND-TO_ALERT',
	'CRM_AUDIENCE_accommodation': 'CRM_AUDIENCE_accommodation',
	'CRM_AUDIENCE_FIELD_accommodation': 'CRM_AUDIENCE_FIELD_accommodation',
	'strategy.base_market_demand.TITLE': 'strategy.base_market_demand.TITLE',
	'strategy.base_market_demand.HINT': 'strategy.base_market_demand.HINT',
	'strategy.base_market_demand.CTA': 'strategy.base_market_demand.CTA',
	'CRMNEWSLETTER_ACTIONS_VIEW-AND-PLAN': 'CRMNEWSLETTER_ACTIONS_VIEW-AND-PLAN',
	'CRMNEWSLETTER_ACTIONS_NOT-NOW': 'CRMNEWSLETTER_ACTIONS_NOT-NOW',
	'CRMNEWSLETTER_TAB_PLANNED': 'CRMNEWSLETTER_TAB_PLANNED',
	'CRMNEWSLETTER_TAB_SENT': 'CRMNEWSLETTER_TAB_SENT',
	'CRMNEWSLETTER_TAB_DRAFT': 'CRMNEWSLETTER_TAB_DRAFT',
	'CRMNEWSLETTER_SUGGESTED_NEW': 'CRMNEWSLETTER_SUGGESTED_NEW',
	'CRMNEWSLETTER_SUGGESTED_ALL': 'CRMNEWSLETTER_SUGGESTED_ALL',
	'strategy.advanced_settings.MARKET_DEMAND': 'strategy.advanced_settings.MARKET_DEMAND',
	'CRMNEWSLETTER_NEWSLETTER-CREATED_ON': 'CRMNEWSLETTER_NEWSLETTER-CREATED_ON',
	'strategy.advanced_market_demand.TITLE_1': 'strategy.advanced_market_demand.TITLE_1',
	'strategy.advanced_market_demand.TITLE_2': 'strategy.advanced_market_demand.TITLE_2',
	'strategy.advanced_market_demand.DESCRIPTION': 'strategy.advanced_market_demand.DESCRIPTION',
	'strategy.advanced_market_demand.INPUT_LABEL': 'strategy.advanced_market_demand.INPUT_LABEL',
	'CRMNEWSLETTER_SUGGESTED-NEW_EMPTY-STATE': 'CRMNEWSLETTER_SUGGESTED-NEW_EMPTY-STATE',
	'CRMNEWSLETTER_SENT_EMPTY-STATE': 'CRMNEWSLETTER_SENT_EMPTY-STATE',
	'CRMNEWSLETTER_DRAFT_EMPTY-STATE': 'CRMNEWSLETTER_DRAFT_EMPTY-STATE',
	'CRM_BADGE_DRAFT': 'CRM_BADGE_DRAFT',
	'CRMNEWSLETTER_LAYOUT_IMAGE': 'CRMNEWSLETTER_LAYOUT_IMAGE',
	'CRMNEWSLETTER_GENERATE_TEXT-GENERATED_TITLE': 'CRMNEWSLETTER_GENERATE_TEXT-GENERATED_TITLE',
	'competitors.list_source.MARKET': 'competitors.list_source.MARKET',
	'competitors.list_source.STRATEGY': 'competitors.list_source.STRATEGY',
	'competitors.strategy_market_notification.TITLE': 'competitors.strategy_market_notification.TITLE',
	'competitors.strategy_market_notification.BODY_1': 'competitors.strategy_market_notification.BODY_1',
	'competitors.strategy_market_edit_list.BUTTON': 'competitors.strategy_market_edit_list.BUTTON',
	'SEE_ALL': 'SEE_ALL',
	'competitors.strategy_market_list.COMPETITORS_COUNT': 'competitors.strategy_market_list.COMPETITORS_COUNT',
	'competitors.strategy_market_edit_list_dialog.TITLE': 'competitors.strategy_market_edit_list_dialog.TITLE',
	'competitors.strategy_market_edit_list_dialog.BODY': 'competitors.strategy_market_edit_list_dialog.BODY',
	'competitors.strategy_market_chart_selector.PRICE': 'competitors.strategy_market_chart_selector.PRICE',
	'competitors.strategy_market_chart_selector.OCCUPANCY': 'competitors.strategy_market_chart_selector.OCCUPANCY',
	'market_analysis.heading.TITLE': 'market_analysis.heading.TITLE',
	'CRM_TRANSLATION_POP-UP_TITLE': 'CRM_TRANSLATION_POP-UP_TITLE',
	'CRM_TRANSLATION_POP-UP_INFO-LANGUAGE': 'CRM_TRANSLATION_POP-UP_INFO-LANGUAGE',
	'CRM_TRANSLATION_POP-UP_INFO': 'CRM_TRANSLATION_POP-UP_INFO',
	'CRM_TRANSLATION_POP-UP_WARNING': 'CRM_TRANSLATION_POP-UP_WARNING',
	'CRM_ACTIONS_APPLY': 'CRM_ACTIONS_APPLY',
	'CRM_ACTIONS_NO': 'CRM_ACTIONS_NO',
	'CRM_LANGUAGE_ENGLISH': 'CRM_LANGUAGE_ENGLISH',
	'CRM_LANGUAGE_ITALIAN': 'CRM_LANGUAGE_ITALIAN',
	'CRM_LANGUAGE_GERMAN': 'CRM_LANGUAGE_GERMAN',
	'CRMNEWSLETTER_CHANGES_NOTIFICATION-TITLE': 'CRMNEWSLETTER_CHANGES_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_CHANGES_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_CHANGES_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_CHANGES_DIALOG_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_CHANGES_DIALOG_NOTIFICATION-SUBTITLE',
	'competitors.strategy_market_notification.BODY_2': 'competitors.strategy_market_notification.BODY_2',
	'CRM_AUDIENCE_FIELD_future_reservations': 'CRM_AUDIENCE_FIELD_future_reservations',
	'CRMNEWSLETTER_PUBLISHED': 'CRMNEWSLETTER_PUBLISHED',
	'CRMNEWSLETTER_ACTIONS_MOVE-TO-DRAFT': 'CRMNEWSLETTER_ACTIONS_MOVE-TO-DRAFT',
	'CRMNEWSLETTER_DRAFT-NEWSLETTER_TITLE': 'CRMNEWSLETTER_DRAFT-NEWSLETTER_TITLE',
	'CRMNEWSLETTER_DRAFT-NEWSLETTER_DESCRIPTION': 'CRMNEWSLETTER_DRAFT-NEWSLETTER_DESCRIPTION',
	'CRMNEWSLETTER_ACTIONS_DRAFT': 'CRMNEWSLETTER_ACTIONS_DRAFT',
	'CRMNEWSLETTER_ACTIONS_PUBLISH': 'CRMNEWSLETTER_ACTIONS_PUBLISH',
	'CRMNEWSLETTER_PUBLISH-NEWSLETTER_TITLE': 'CRMNEWSLETTER_PUBLISH-NEWSLETTER_TITLE',
	'CRMNEWSLETTER_PUBLISH-NEWSLETTER_DESCRIPTION': 'CRMNEWSLETTER_PUBLISH-NEWSLETTER_DESCRIPTION',
	'CRM_TEMPLATE_PERSONALIZED': 'CRM_TEMPLATE_PERSONALIZED',
	'CRMNEWSLETTER_SENDING-DETAILS_TEMPLATE': 'CRMNEWSLETTER_SENDING-DETAILS_TEMPLATE',
	'CRMNEWSLETTER_EDIT-TEMPLATE_SUBTITLE': 'CRMNEWSLETTER_EDIT-TEMPLATE_SUBTITLE',
	'CRMNEWSLETTER_SENDING-DETAILS_SUMMARY': 'CRMNEWSLETTER_SENDING-DETAILS_SUMMARY',
	'CRMNEWSLETTER_EDIT-SUMMARY_SUBTITLE': 'CRMNEWSLETTER_EDIT-SUMMARY_SUBTITLE',
	'CRMNEWSLETTER_EDIT-SUMMARY_INPUT-HELP': 'CRMNEWSLETTER_EDIT-SUMMARY_INPUT-HELP',
	'CRMNEWSLETTER_EDIT-SUMMARY_INPUT-PLACEHOLDER': 'CRMNEWSLETTER_EDIT-SUMMARY_INPUT-PLACEHOLDER',
	'CRMNEWSLETTER_SENDING-DETAILS_DESCRIPTION': 'CRMNEWSLETTER_SENDING-DETAILS_DESCRIPTION',
	'CRMNEWSLETTER_EDIT-DESCRIPTION_SUBTITLE': 'CRMNEWSLETTER_EDIT-DESCRIPTION_SUBTITLE',
	'CRMNEWSLETTER_EDIT-DESCRIPTION_INPUT-HELP': 'CRMNEWSLETTER_EDIT-DESCRIPTION_INPUT-HELP',
	'CRMNEWSLETTER_EDIT-DESCRIPTION_INPUT-PLACEHOLDER': 'CRMNEWSLETTER_EDIT-DESCRIPTION_INPUT-PLACEHOLDER',
	'CRMNEWSLETTER_SENDING-DETAILS_PURPOSE': 'CRMNEWSLETTER_SENDING-DETAILS_PURPOSE',
	'CRMNEWSLETTER_EDIT-PURPOSE_SUBTITLE': 'CRMNEWSLETTER_EDIT-PURPOSE_SUBTITLE',
	'CRMNEWSLETTER_EDIT-PURPOSE_INPUT-HELP': 'CRMNEWSLETTER_EDIT-PURPOSE_INPUT-HELP',
	'CRMNEWSLETTER_EDIT-PURPOSE_INPUT-PLACEHOLDER': 'CRMNEWSLETTER_EDIT-PURPOSE_INPUT-PLACEHOLDER',
	'CRMNEWSLETTER_ACTIONS_SAVE-DRAFT': 'CRMNEWSLETTER_ACTIONS_SAVE-DRAFT',
	'CRMNEWSLETTER_ACTIONS_SAVE-AND-PUBLISH': 'CRMNEWSLETTER_ACTIONS_SAVE-AND-PUBLISH',
	'CRMNEWSLETTER_TEMPLATE-SELECTION_TITLE': 'CRMNEWSLETTER_TEMPLATE-SELECTION_TITLE',
	'CRMNEWSLETTER_TEMPLATE-SELECTION_SUBTITLE': 'CRMNEWSLETTER_TEMPLATE-SELECTION_SUBTITLE',
	'CRMANEWSLETTER_SAVE-TEMPLATE_DESCRIPTION-1': 'CRMANEWSLETTER_SAVE-TEMPLATE_DESCRIPTION-1',
	'CRMANEWSLETTER_SAVE-TEMPLATE_DESCRIPTION-2': 'CRMANEWSLETTER_SAVE-TEMPLATE_DESCRIPTION-2',
	'CRMANEWSLETTER_SAVE-TEMPLATE_DESCRIPTION-3': 'CRMANEWSLETTER_SAVE-TEMPLATE_DESCRIPTION-3',
	'CRMACTIONS_DONT_SAVE': 'CRMACTIONS_DONT_SAVE',
	'CRMACTIONS_SAVE_AS_TEMPLATE': 'CRMACTIONS_SAVE_AS_TEMPLATE',
	'CRMNEWSLETTER_SAVE_TEMPLATE_NOTIFICATION-TITLE': 'CRMNEWSLETTER_SAVE_TEMPLATE_NOTIFICATION-TITLE',
	'CRMNEWSLETTER_SAVE_TEMPLATE_NOTIFICATION-SUBTITLE': 'CRMNEWSLETTER_SAVE_TEMPLATE_NOTIFICATION-SUBTITLE',
	'CRMAUDIENCE_SAVE-AUDIENCE_GIVE-DESCRIPTION': 'CRMAUDIENCE_SAVE-AUDIENCE_GIVE-DESCRIPTION',
	'CRMAUDIENCE_SAVE_INPUT-GIVE-DESCRIPTION-PLACEHOLDER': 'CRMAUDIENCE_SAVE_INPUT-GIVE-DESCRIPTION-PLACEHOLDER',
	'CRMAUDIENCE_SAVE-AUDIENCE_AS-TEMPLATE-TITLE': 'CRMAUDIENCE_SAVE-AUDIENCE_AS-TEMPLATE-TITLE',
	'CRMAUDIENCE_SAVE-AUDIENCE_AS-TEMPLATE-DESCRIPTION': 'CRMAUDIENCE_SAVE-AUDIENCE_AS-TEMPLATE-DESCRIPTION',
	'CRMAUDIENCE_SAVE_TEMPLATE_NOTIFICATION-TITLE': 'CRMAUDIENCE_SAVE_TEMPLATE_NOTIFICATION-TITLE',
	'CRMAUDIENCE_SAVE_TEMPLATE_NOTIFICATION-SUBTITLE': 'CRMAUDIENCE_SAVE_TEMPLATE_NOTIFICATION-SUBTITLE',
	'CRMNEWSLETTER_EDIT-TEMPLATE_LABEL': 'CRMNEWSLETTER_EDIT-TEMPLATE_LABEL',
	'CRMNEWSLETTER_LAYOUT-EDIT_CTA-BADGE': 'CRMNEWSLETTER_LAYOUT-EDIT_CTA-BADGE',
	'CRMNEWSLETTER_ACTIONS_GENERATE_VALUE': 'CRMNEWSLETTER_ACTIONS_GENERATE_VALUE',
	'CRMACTIONS_SAVE-AND-PUBLISH': 'CRMACTIONS_SAVE-AND-PUBLISH',
	'crm.profiling.spring': 'crm.profiling.spring',
	'crm.profiling.summer': 'crm.profiling.summer',
	'crm.profiling.autumn': 'crm.profiling.autumn',
	'crm.profiling.winter': 'crm.profiling.winter',
	'crm.profiling.business': 'crm.profiling.business',
	'crm.profiling.leisure': 'crm.profiling.leisure',
	'crm.profiling.firstimers': 'crm.profiling.firstimers',
	'crm.profiling.repeter': 'crm.profiling.repeter',
	'crm.profiling.single': 'crm.profiling.single',
	'crm.profiling.couple': 'crm.profiling.couple',
	'crm.profiling.family': 'crm.profiling.family',
	'crm.profiling.group': 'crm.profiling.group',
	'pages.marketplace.period': 'pages.marketplace.period',
	'pages.marketplace.period_30': 'pages.marketplace.period_30',
	'pages.marketplace.period_90': 'pages.marketplace.period_90',
	'pages.marketplace.period_all': 'pages.marketplace.period_all',
	'pages.marketplace.title': 'pages.marketplace.title',
	'pages.marketplace.manage_marketplace': 'pages.marketplace.manage_marketplace',
	'pages.marketplace.card_action.manage': 'pages.marketplace.card_action.manage',
	'pages.marketplace.orders.card_title': 'pages.marketplace.orders.card_title',
	'pages.marketplace.orders.hi_hotel': 'pages.marketplace.orders.hi_hotel',
	'pages.marketplace.orders.current_situation_description': 'pages.marketplace.orders.current_situation_description',
	'pages.marketplace.orders.to_be_accepted': 'pages.marketplace.orders.to_be_accepted',
	'pages.marketplace.orders.to_be_delivered': 'pages.marketplace.orders.to_be_delivered',
	'pages.marketplace.orders.no_products_title': 'pages.marketplace.orders.no_products_title',
	'pages.marketplace.orders.no_products_description': 'pages.marketplace.orders.no_products_description',
	'pages.marketplace.orders.got_to_marketplace': 'pages.marketplace.orders.got_to_marketplace',
	'pages.marketplace.link.card_title': 'pages.marketplace.link.card_title',
	'pages.marketplace.link.copy': 'pages.marketplace.link.copy',
	'pages.marketplace.link.description': 'pages.marketplace.link.description',
	'pages.marketplace.visits.card_title': 'pages.marketplace.visits.card_title',
	'pages.marketplace.visits.totals': 'pages.marketplace.visits.totals',
	'pages.marketplace.visits.unique': 'pages.marketplace.visits.unique',
	'pages.marketplace.visits.page_visits': 'pages.marketplace.visits.page_visits',
	'pages.marketplace.visits.chart.total': 'pages.marketplace.visits.chart.total',
	'pages.marketplace.visits.chart.email': 'pages.marketplace.visits.chart.email',
	'pages.marketplace.visits.chart.whatsapp': 'pages.marketplace.visits.chart.whatsapp',
	'pages.marketplace.visits.chart.other': 'pages.marketplace.visits.chart.other',
	'pages.marketplace.visits.chart.other_tooltip': 'pages.marketplace.visits.chart.other_tooltip',
	'pages.marketplace.visits.chart.qrcode': 'pages.marketplace.visits.chart.qrcode',
	'pages.marketplace.communications.card_title': 'pages.marketplace.communications.card_title',
	'pages.marketplace.communications.sends': 'pages.marketplace.communications.sends',
	'pages.marketplace.communications.chart.total': 'pages.marketplace.communications.chart.total',
	'pages.marketplace.communications.chart.email': 'pages.marketplace.communications.chart.email',
	'pages.marketplace.communications.chart.whatsapp': 'pages.marketplace.communications.chart.whatsapp',
	'pages.marketplace.requested_products.card_title': 'pages.marketplace.requested_products.card_title',
	'pages.marketplace.requested_products.total_requests': 'pages.marketplace.requested_products.total_requests',
	'pages.marketplace.requested_products.chart.products': 'pages.marketplace.requested_products.chart.products',
	'pages.marketplace.requested_products.name': 'pages.marketplace.requested_products.name',
	'pages.marketplace.requested_products.requested': 'pages.marketplace.requested_products.requested',
	'pages.marketplace.requested_products.notification_title': 'pages.marketplace.requested_products.notification_title',
	'pages.marketplace.requested_products.notification_text': 'pages.marketplace.requested_products.notification_text',
	'pages.marketplace.requested_products.notification_action': 'pages.marketplace.requested_products.notification_action',
	'pages.marketplace.requested_products.documentation_panel.title': 'pages.marketplace.requested_products.documentation_panel.title',
	'pages.marketplace.requested_products.documentation_panel.category': 'pages.marketplace.requested_products.documentation_panel.category',
	'pages.marketplace.requested_products.documentation_panel.content': 'pages.marketplace.requested_products.documentation_panel.content',
	'pages.marketplace.requested_products.documentation_panel.summary_text': 'pages.marketplace.requested_products.documentation_panel.summary_text',
	'billing.mobile_billing.CONTENT': 'billing.mobile_billing.CONTENT',
	'billing.mobile_invoices.CONTENT': 'billing.mobile_invoices.CONTENT',
	'pages.marketplace.requested_products.unknown_product': 'pages.marketplace.requested_products.unknown_product',
	'strategy.advanced_preview_chart.DEFAULT': 'strategy.advanced_preview_chart.DEFAULT',
	'strategy.advanced_preview_chart.YOUR_SETTING': 'strategy.advanced_preview_chart.YOUR_SETTING',
	'pages.crm.analytics.what_happens.card_title': 'pages.crm.analytics.what_happens.card_title',
	'pages.crm.analytics.what_happens.hi_hotel': 'pages.crm.analytics.what_happens.hi_hotel',
	'pages.crm.analytics.what_happens.great_job': 'pages.crm.analytics.what_happens.great_job',
	'pages.crm.analytics.what_happens.yours': 'pages.crm.analytics.what_happens.yours',
	'pages.crm.analytics.what_happens.open_rate_is': 'pages.crm.analytics.what_happens.open_rate_is',
	'pages.crm.analytics.what_happens.open_rate_tooltip': 'pages.crm.analytics.what_happens.open_rate_tooltip',
	'pages.crm.analytics.what_happens.looking_good': 'pages.crm.analytics.what_happens.looking_good',
	'pages.crm.analytics.what_happens.now': 'pages.crm.analytics.what_happens.now',
	'pages.crm.analytics.what_happens.try_create_newsletter': 'pages.crm.analytics.what_happens.try_create_newsletter',
	'pages.crm.analytics.what_happens.to_reach': 'pages.crm.analytics.what_happens.to_reach',
	'pages.crm.analytics.what_happens.no_mail_sent': 'pages.crm.analytics.what_happens.no_mail_sent',
	'pages.crm.analytics.what_happens.no_mail_sent_description': 'pages.crm.analytics.what_happens.no_mail_sent_description',
	'pages.crm.analytics.what_happens.create': 'pages.crm.analytics.what_happens.create',
	'pages.crm.analytics.what_happens.get_started': 'pages.crm.analytics.what_happens.get_started',
	'pages.crm.analytics.customers.reached': 'pages.crm.analytics.customers.reached',
	'pages.crm.analytics.customers.totals': 'pages.crm.analytics.customers.totals',
	'pages.crm.analytics.customers.card_title': 'pages.crm.analytics.customers.card_title',
	'pages.crm.analytics.title': 'pages.crm.analytics.title',
	'pages.crm.analytics.period': 'pages.crm.analytics.period',
	'pages.crm.analytics.period_3': 'pages.crm.analytics.period_3',
	'pages.crm.analytics.period_6': 'pages.crm.analytics.period_6',
	'pages.crm.analytics.period_all': 'pages.crm.analytics.period_all',
	'pages.crm.analytics.last_newsletter.name': 'pages.crm.analytics.last_newsletter.name',
	'pages.crm.analytics.last_newsletter.sentOn': 'pages.crm.analytics.last_newsletter.sentOn',
	'pages.crm.analytics.last_newsletter.sendings': 'pages.crm.analytics.last_newsletter.sendings',
	'pages.crm.analytics.last_newsletter.delivered': 'pages.crm.analytics.last_newsletter.delivered',
	'pages.crm.analytics.last_newsletter.opened': 'pages.crm.analytics.last_newsletter.opened',
	'pages.crm.analytics.last_newsletter.unsubscribed': 'pages.crm.analytics.last_newsletter.unsubscribed',
	'pages.crm.analytics.last_newsletter.card_title': 'pages.crm.analytics.last_newsletter.card_title',
	'pages.crm.analytics.funnel': 'pages.crm.analytics.funnel',
	'pages.crm.analytics.timeline': 'pages.crm.analytics.timeline',
	'pages.crm.analytics.card_title': 'pages.crm.analytics.card_title',
	'pages.crm.analytics.chart.send': 'pages.crm.analytics.chart.send',
	'pages.crm.analytics.chart.delivered': 'pages.crm.analytics.chart.delivered',
	'pages.crm.analytics.chart.opened': 'pages.crm.analytics.chart.opened',
	'pages.crm.analytics.chart.unsubscribed': 'pages.crm.analytics.chart.unsubscribed',
	'pages.crm.analytics.chart.mails': 'pages.crm.analytics.chart.mails',
	'menu.crm.analytics.title': 'menu.crm.analytics.title',
	'calendar.controls.ACCOMMODATION_DROPDOWN_PLACEHOLDER': 'calendar.controls.ACCOMMODATION_DROPDOWN_PLACEHOLDER',
	'calendar.controls.ACCOMMODATION_DROPDOWN_SEARCH_PLACEHOLDER': 'calendar.controls.ACCOMMODATION_DROPDOWN_SEARCH_PLACEHOLDER',
	'pages.crm.analytics.last_newsletter.empty_state': 'pages.crm.analytics.last_newsletter.empty_state',
	'pages.crm.analytics.customers.chart_tootlip_title': 'pages.crm.analytics.customers.chart_tootlip_title',
	'pages.marketplace.visits.tooltip_title': 'pages.marketplace.visits.tooltip_title',
	'pages.marketplace.communications.tooltip_title': 'pages.marketplace.communications.tooltip_title',
	'pages.crm.analytics.what_happens.we_can_improve': 'pages.crm.analytics.what_happens.we_can_improve',
	'pages.crm.analytics.what_happens.is_below_average': 'pages.crm.analytics.what_happens.is_below_average',
	'pages.crm.analytics.what_happens.keep_up_great_work': 'pages.crm.analytics.what_happens.keep_up_great_work',
	'pages.crm.analytics.what_happens.doing_great': 'pages.crm.analytics.what_happens.doing_great',
	'pages.crm.analytics.what_happens.looking_excellent': 'pages.crm.analytics.what_happens.looking_excellent',
	'pages.crm.analytics.what_happens.keep_up_excellent_work': 'pages.crm.analytics.what_happens.keep_up_excellent_work',
	'pages.crm.analytics.documentation_panel.title': 'pages.crm.analytics.documentation_panel.title',
	'pages.crm.analytics.documentation_panel.category': 'pages.crm.analytics.documentation_panel.category',
	'pages.crm.analytics.documentation_panel.content_bad': 'pages.crm.analytics.documentation_panel.content_bad',
	'pages.crm.analytics.documentation_panel.content_good': 'pages.crm.analytics.documentation_panel.content_good',
	'pages.crm.analytics.documentation_panel.content_excellent': 'pages.crm.analytics.documentation_panel.content_excellent',
	'pages.crm.analytics.documentation_panel.summary_text': 'pages.crm.analytics.documentation_panel.summary_text',
	'strategy.base_preview_chart.TITLE': 'strategy.base_preview_chart.TITLE',
	'make_a_wish_dialog.navigation.TEXT': 'make_a_wish_dialog.navigation.TEXT',
	'make_a_wish_dialog.welcome.SUBTITLE': 'make_a_wish_dialog.welcome.SUBTITLE',
	'make_a_wish_dialog.welcome.TITLE': 'make_a_wish_dialog.welcome.TITLE',
	'make_a_wish_dialog.welcome.DESCRIPTION': 'make_a_wish_dialog.welcome.DESCRIPTION',
	'make_a_wish_dialog.welcome.MAKE_WISH_BUTTON': 'make_a_wish_dialog.welcome.MAKE_WISH_BUTTON',
	'make_a_wish_dialog.welcome.RELEASED_WISHES_TITLE': 'make_a_wish_dialog.welcome.RELEASED_WISHES_TITLE',
	'make_a_wish_dialog.welcome.RELEASED_WISHES_DESCRIPTION': 'make_a_wish_dialog.welcome.RELEASED_WISHES_DESCRIPTION',
	'make_a_wish_dialog.welcome.BUG_REPORT_TITLE': 'make_a_wish_dialog.welcome.BUG_REPORT_TITLE',
	'make_a_wish_dialog.welcome.BUG_REPORT_DESCRIPTION': 'make_a_wish_dialog.welcome.BUG_REPORT_DESCRIPTION',
	'make_a_wish_dialog.form.TITLE': 'make_a_wish_dialog.form.TITLE',
	'make_a_wish_dialog.form.DESCRIPTION': 'make_a_wish_dialog.form.DESCRIPTION',
	'make_a_wish_dialog.form.BUG_REPORT': 'make_a_wish_dialog.form.BUG_REPORT',
	'make_a_wish_dialog.form.BUG_REPORT_TOOLTIP': 'make_a_wish_dialog.form.BUG_REPORT_TOOLTIP',
	'make_a_wish_dialog.form.SUBJECT_INPUT_LABEL': 'make_a_wish_dialog.form.SUBJECT_INPUT_LABEL',
	'make_a_wish_dialog.form.SUBJECT_INPUT_PLACEHOLDER': 'make_a_wish_dialog.form.SUBJECT_INPUT_PLACEHOLDER',
	'make_a_wish_dialog.form.DESCRIBE_INPUT_LABEL': 'make_a_wish_dialog.form.DESCRIBE_INPUT_LABEL',
	'make_a_wish_dialog.form.DESCRIBE_INPUT_PLACEHOLDER': 'make_a_wish_dialog.form.DESCRIBE_INPUT_PLACEHOLDER',
	'make_a_wish_dialog.form.CHECKBOX_INPUT_LABEL': 'make_a_wish_dialog.form.CHECKBOX_INPUT_LABEL',
	'make_a_wish_dialog.form.SEND_WISH_BUTTON': 'make_a_wish_dialog.form.SEND_WISH_BUTTON',
	'make_a_wish_dialog.form.CANCEL_BUTTON': 'make_a_wish_dialog.form.CANCEL_BUTTON',
	'make_a_wish_dialog.wish_sent.SUBTITLE': 'make_a_wish_dialog.wish_sent.SUBTITLE',
	'make_a_wish_dialog.wish_sent.TITLE': 'make_a_wish_dialog.wish_sent.TITLE',
	'make_a_wish_dialog.wish_sent.DESCRIPTION': 'make_a_wish_dialog.wish_sent.DESCRIPTION',
	'make_a_wish_dialog.wish_sent.SEND_ANOTHER_WISH_BUTTON': 'make_a_wish_dialog.wish_sent.SEND_ANOTHER_WISH_BUTTON',
	'pages.crm.analytics.last_newsletter.cta_clicks': 'pages.crm.analytics.last_newsletter.cta_clicks',
	'pages.crm.analytics.customers.documentation_panel.content': 'pages.crm.analytics.customers.documentation_panel.content',
	'pages.crm.analytics.customers.documentation_panel.title': 'pages.crm.analytics.customers.documentation_panel.title',
	'pages.crm.analytics.customers.documentation_panel.category': 'pages.crm.analytics.customers.documentation_panel.category',
	'pages.crm.analytics.customers.documentation_panel.summary_text': 'pages.crm.analytics.customers.documentation_panel.summary_text',
	'pages.crm.analytics.send_rate.documentation_panel.content': 'pages.crm.analytics.send_rate.documentation_panel.content',
	'pages.crm.analytics.send_rate.documentation_panel.title': 'pages.crm.analytics.send_rate.documentation_panel.title',
	'pages.crm.analytics.send_rate.documentation_panel.category': 'pages.crm.analytics.send_rate.documentation_panel.category',
	'pages.crm.analytics.send_rate.documentation_panel.summary_text': 'pages.crm.analytics.send_rate.documentation_panel.summary_text',
	'pages.crm.analytics.charts.documentation_panel.content': 'pages.crm.analytics.charts.documentation_panel.content',
	'pages.crm.analytics.charts.documentation_panel.title': 'pages.crm.analytics.charts.documentation_panel.title',
	'pages.crm.analytics.charts.documentation_panel.category': 'pages.crm.analytics.charts.documentation_panel.category',
	'pages.crm.analytics.charts.documentation_panel.summary_text': 'pages.crm.analytics.charts.documentation_panel.summary_text',
	'pages.crm.analytics.last_newsletter.tooltip_delivered': 'pages.crm.analytics.last_newsletter.tooltip_delivered',
	'pages.crm.analytics.last_newsletter.tooltip_opened': 'pages.crm.analytics.last_newsletter.tooltip_opened',
	'pages.crm.analytics.last_newsletter.tooltip_button_click': 'pages.crm.analytics.last_newsletter.tooltip_button_click',
	'pages.crm.analytics.last_newsletter.tooltip_unsubscribed': 'pages.crm.analytics.last_newsletter.tooltip_unsubscribed',
	'HOW_DOES_IT_WORK': 'HOW_DOES_IT_WORK',
	'CRM_SELECTED': 'CRM_SELECTED',
	'strategy.switch_to_advanced_mode_dialog.TITLE': 'strategy.switch_to_advanced_mode_dialog.TITLE',
	'strategy.switch_to_advanced_mode_dialog.DESCRIPTION': 'strategy.switch_to_advanced_mode_dialog.DESCRIPTION',
	'strategy.switch_to_advanced_mode_dialog.CANCEL': 'strategy.switch_to_advanced_mode_dialog.CANCEL',
	'strategy.switch_to_advanced_mode_dialog.CONFIRM': 'strategy.switch_to_advanced_mode_dialog.CONFIRM',
	'CRMCUSTOMER_MERGE_SUBTITLE-DESCRIPTION_2': 'CRMCUSTOMER_MERGE_SUBTITLE-DESCRIPTION_2',
	'pages.crm.customers.merge.duplicates_detected': 'pages.crm.customers.merge.duplicates_detected',
	'pages.crm.customers.merge.duplicate_detected_single': 'pages.crm.customers.merge.duplicate_detected_single',
	'pages.crm.customers.merge.duplicate_detected_plural': 'pages.crm.customers.merge.duplicate_detected_plural',
	'pages.crm.customers.merge.view': 'pages.crm.customers.merge.view',
	'pages.crm.customers.merge.search_manually': 'pages.crm.customers.merge.search_manually',
	'pages.crm.customers.merge.duplicated_list': 'pages.crm.customers.merge.duplicated_list',
	'pages.crm.customers.merge.table_name': 'pages.crm.customers.merge.table_name',
	'pages.crm.customers.merge.table_surname': 'pages.crm.customers.merge.table_surname',
	'pages.crm.customers.merge.table_email': 'pages.crm.customers.merge.table_email',
	'pages.crm.customers.merge.table_phone': 'pages.crm.customers.merge.table_phone',
	'pages.crm.customers.merge.table_country': 'pages.crm.customers.merge.table_country',
	'pages.crm.customers.merge.table_birth': 'pages.crm.customers.merge.table_birth',
	'pages.crm.customers.merge.merge': 'pages.crm.customers.merge.merge',
	'pages.crm.customers.merge.search_placeholder': 'pages.crm.customers.merge.search_placeholder',
	'strategy.switch_to_advanced_mode_mobiled_dialog.TITLE': 'strategy.switch_to_advanced_mode_mobiled_dialog.TITLE',
	'strategy.switch_to_advanced_mode_mobiled_dialog.DESCRIPTION': 'strategy.switch_to_advanced_mode_mobiled_dialog.DESCRIPTION',
	'pages.crm.customers.merge.view_customer_detail': 'pages.crm.customers.merge.view_customer_detail',
	'pages.crm.customers.merge.duplicate_detected': 'pages.crm.customers.merge.duplicate_detected',
	'strategy.advanced_drop_pickup_preview_slider.TITLE': 'strategy.advanced_drop_pickup_preview_slider.TITLE',
	'strategy.advanced_drop_pickup_preview_slider.PRICE': 'strategy.advanced_drop_pickup_preview_slider.PRICE',
	'strategy.advanced_drop_pickup_preview_chart.DAYS_FROM_CHECKIN': 'strategy.advanced_drop_pickup_preview_chart.DAYS_FROM_CHECKIN',
	'strategy.advanced_drop_pickup_preview_chart.OCCUPANCY': 'strategy.advanced_drop_pickup_preview_chart.OCCUPANCY',
	'strategy.advanced_drop_pickup_preview_chart.PRICE': 'strategy.advanced_drop_pickup_preview_chart.PRICE',
	'strategy.advanced_drop_pickup_preview_chart.CHECKIN_DATE': 'strategy.advanced_drop_pickup_preview_chart.CHECKIN_DATE',
	'feedback.charts_hover.TITLE': 'feedback.charts_hover.TITLE',
	'feedback.charts_hover.HEADER': 'feedback.charts_hover.HEADER',
	'feedback.charts_hover.IMPROVEMENTS_1': 'feedback.charts_hover.IMPROVEMENTS_1',
	'feedback.charts_hover.IMPROVEMENTS_2': 'feedback.charts_hover.IMPROVEMENTS_2',
	'feedback.charts_hover.IMPROVEMENTS_3': 'feedback.charts_hover.IMPROVEMENTS_3',
	'base_prices.how_does_it_work.BUTTON_LABEL': 'base_prices.how_does_it_work.BUTTON_LABEL',
	'base_prices.how_does_it_work.TITLE': 'base_prices.how_does_it_work.TITLE',
	'base_prices.how_does_it_work.SUBTITLE': 'base_prices.how_does_it_work.SUBTITLE',
	'base_prices.how_does_it_work.FIRST_PARAGRAPH': 'base_prices.how_does_it_work.FIRST_PARAGRAPH',
	'base_prices.how_does_it_work.CHART_TITLE': 'base_prices.how_does_it_work.CHART_TITLE',
	'base_prices.how_does_it_work.CHART_X_AXIS_LABEL': 'base_prices.how_does_it_work.CHART_X_AXIS_LABEL',
	'base_prices.how_does_it_work.CHART_Y_AXIS_LABEL': 'base_prices.how_does_it_work.CHART_Y_AXIS_LABEL',
	'base_prices.how_does_it_work.CHART_STARTING_PRICES_LEGEND': 'base_prices.how_does_it_work.CHART_STARTING_PRICES_LEGEND',
	'base_prices.how_does_it_work.CHART_SUGGESTED_PRICES_LEGEND': 'base_prices.how_does_it_work.CHART_SUGGESTED_PRICES_LEGEND',
	'base_prices.how_does_it_work.SECOND_PARAGRAPH': 'base_prices.how_does_it_work.SECOND_PARAGRAPH',
	'base_prices.how_does_it_work.FIRST_FRAME_TITLE': 'base_prices.how_does_it_work.FIRST_FRAME_TITLE',
	'base_prices.how_does_it_work.FIRST_FRAME_DESCRIPTION': 'base_prices.how_does_it_work.FIRST_FRAME_DESCRIPTION',
	'base_prices.how_does_it_work.SECOND_FRAME_TITLE': 'base_prices.how_does_it_work.SECOND_FRAME_TITLE',
	'base_prices.how_does_it_work.SECOND_FRAME_DESCRIPTION': 'base_prices.how_does_it_work.SECOND_FRAME_DESCRIPTION',
	'occupancy.how_does_it_work.TITLE': 'occupancy.how_does_it_work.TITLE',
	'occupancy.how_does_it_work.SUBTITLE': 'occupancy.how_does_it_work.SUBTITLE',
	'occupancy.how_does_it_work.BODY_1': 'occupancy.how_does_it_work.BODY_1',
	'occupancy.how_does_it_work.BODY_2': 'occupancy.how_does_it_work.BODY_2',
	'occupancy.how_does_it_work.BODY_3_TITLE': 'occupancy.how_does_it_work.BODY_3_TITLE',
	'occupancy.how_does_it_work.BODY_3_BODY_1': 'occupancy.how_does_it_work.BODY_3_BODY_1',
	'occupancy.how_does_it_work.BODY_3_BODY_2': 'occupancy.how_does_it_work.BODY_3_BODY_2',
	'feedback.advanced_strategy_change.TITLE': 'feedback.advanced_strategy_change.TITLE',
	'feedback.advanced_strategy_change.HEADER': 'feedback.advanced_strategy_change.HEADER',
	'feedback.advanced_strategy_change.IMPROVEMENTS_1': 'feedback.advanced_strategy_change.IMPROVEMENTS_1',
	'feedback.advanced_strategy_change.IMPROVEMENTS_2': 'feedback.advanced_strategy_change.IMPROVEMENTS_2',
	'feedback.advanced_strategy_change.IMPROVEMENTS_3': 'feedback.advanced_strategy_change.IMPROVEMENTS_3',
	'CRM_AUDIENCE_FIELD_tag_id': 'CRM_AUDIENCE_FIELD_tag_id',
	'CRM_AUDIENCE_FIELD_avg_nights': 'CRM_AUDIENCE_FIELD_avg_nights',
	'CRM_AUDIENCE_FIELD_avg_price': 'CRM_AUDIENCE_FIELD_avg_price',
	'CRM_AUDIENCE_FIELD_avg_booking_window': 'CRM_AUDIENCE_FIELD_avg_booking_window',
	'calendar.how-doest-it-work.PRICE_PENDING_INTG_LIMIT': 'calendar.how-doest-it-work.PRICE_PENDING_INTG_LIMIT',
	'calendar.how-doest-it-work.PRICE_SENT_TO': 'calendar.how-doest-it-work.PRICE_SENT_TO',
	'calendar.how-doest-it-work.WAITING_TO_SEND': 'calendar.how-doest-it-work.WAITING_TO_SEND',
	'calendar.how-doest-it-work.SENT': 'calendar.how-doest-it-work.SENT',
	'calendar.how-doest-it-work.LAST_PRICE_SEND': 'calendar.how-doest-it-work.LAST_PRICE_SEND',
	'calendar.how-doest-it-work.SOME_PMS_OR_CHANNEL_MANAGERS': 'calendar.how-doest-it-work.SOME_PMS_OR_CHANNEL_MANAGERS',
	'CHANNEL_MANAGER': 'CHANNEL_MANAGER',
	'calendar.how-does-it-work_badge.SENT': 'calendar.how-does-it-work_badge.SENT',
	'calendar.how-does-it-work_badge.WAITING': 'calendar.how-does-it-work_badge.WAITING',
	'calendar.how-does-it-work_badge_tooltip.SENT': 'calendar.how-does-it-work_badge_tooltip.SENT',
	'calendar.how-does-it-work_badge_tooltip.WAITING': 'calendar.how-does-it-work_badge_tooltip.WAITING',
	'calendar.how-does-it-work_dialog.TITLE': 'calendar.how-does-it-work_dialog.TITLE',
	'calendar.how-does-it-work_dialog.CONTEXT': 'calendar.how-does-it-work_dialog.CONTEXT',
	'calendar.how-does-it-work_dialog.PRICE_SENT': 'calendar.how-does-it-work_dialog.PRICE_SENT',
	'calendar.how-does-it-work_dialog.PRICE_SENDING': 'calendar.how-does-it-work_dialog.PRICE_SENDING',
	'calendar.how-does-it-work_dialog_progress.PRICE_SENT': 'calendar.how-does-it-work_dialog_progress.PRICE_SENT',
	'calendar.how-does-it-work_dialog_progress.PRICE_WAITING': 'calendar.how-does-it-work_dialog_progress.PRICE_WAITING',
	'calendar.how-does-it-work_dialog_progress.LIMIT_REACHED': 'calendar.how-does-it-work_dialog_progress.LIMIT_REACHED',
	'calendar.how-does-it-work_dialog_documentation.CONTENT': 'calendar.how-does-it-work_dialog_documentation.CONTENT',
	'calendar.how-does-it-work_faq_success.CONTENT': 'calendar.how-does-it-work_faq_success.CONTENT',
	'calendar.how-does-it-work_faq_waiting.CONTENT': 'calendar.how-does-it-work_faq_waiting.CONTENT',
	'strategy.market_correction.VALUE_LABELS': 'strategy.market_correction.VALUE_LABELS',
	'CHANNEL_MANAGERS': 'CHANNEL_MANAGERS',
	'calendar.how-does-it-work_badge.PAUSED': 'calendar.how-does-it-work_badge.PAUSED',
	'market_correction.how_does_it_work.SUBTITLE': 'market_correction.how_does_it_work.SUBTITLE',
	'market_correction.how_does_it_work.TITLE': 'market_correction.how_does_it_work.TITLE',
	'calendar.how-does-it-work_dialog.PRICE_PAUSED': 'calendar.how-does-it-work_dialog.PRICE_PAUSED',
	'CRMNEWSLETTER_ACTIONS_SAVE-AND-SEND': 'CRMNEWSLETTER_ACTIONS_SAVE-AND-SEND',
	'calendar.how-does-it-work_badge_tooltip.PAUSED': 'calendar.how-does-it-work_badge_tooltip.PAUSED',
	'market_correction.how_does_it_work.SUMMARY': 'market_correction.how_does_it_work.SUMMARY',
	'calendar.how-does-it-work_dialog_progress.PAUSED': 'calendar.how-does-it-work_dialog_progress.PAUSED',
	'calendar.how-doest-it-work.PAUSED': 'calendar.how-doest-it-work.PAUSED',
	'calendar.how-doest-it-work.PRICE_PAUSED': 'calendar.how-doest-it-work.PRICE_PAUSED',
	'market_correction.how_does_it_work.CONTENT': 'market_correction.how_does_it_work.CONTENT',
	'market_correction.how_does_it_work.BOX_1': 'market_correction.how_does_it_work.BOX_1',
	'market_correction.how_does_it_work.BOX_2': 'market_correction.how_does_it_work.BOX_2',
	'calendar.how-doest-it-work.PRICE_SENT_SUCCESS': 'calendar.how-doest-it-work.PRICE_SENT_SUCCESS',
	'market_correction.how_does_it_work.CHART_SECTION_TITLE': 'market_correction.how_does_it_work.CHART_SECTION_TITLE',
	'market_correction.how_does_it_work.CHART_SECTION_CHART_X_AXIS_LABEL': 'market_correction.how_does_it_work.CHART_SECTION_CHART_X_AXIS_LABEL',
	'market_correction.how_does_it_work.CHART_SECTION_DESCRIPTION': 'market_correction.how_does_it_work.CHART_SECTION_DESCRIPTION',
	'market_correction.how_does_it_work.CHART_SECTION_SWITCH_ITEM_YOUR_OCCUPANCY': 'market_correction.how_does_it_work.CHART_SECTION_SWITCH_ITEM_YOUR_OCCUPANCY',
	'market_correction.how_does_it_work.CHART_SECTION_SWITCH_ITEM_INFLUENCE': 'market_correction.how_does_it_work.CHART_SECTION_SWITCH_ITEM_INFLUENCE',
	'market_correction.how_does_it_work.CHART_SECTION_MARKET_OCCUPANCY_INFLUENCE': 'market_correction.how_does_it_work.CHART_SECTION_MARKET_OCCUPANCY_INFLUENCE',
	'market_correction.how_does_it_work.CHART_SECTION_LOW_INFLUENCE': 'market_correction.how_does_it_work.CHART_SECTION_LOW_INFLUENCE',
	'market_correction.how_does_it_work.CHART_SECTION_HIGH_INFLUENCE': 'market_correction.how_does_it_work.CHART_SECTION_HIGH_INFLUENCE',
	'market_correction.how_does_it_work.CHART_SECTION_CONTROL_EXPLANATION': 'market_correction.how_does_it_work.CHART_SECTION_CONTROL_EXPLANATION',
	'market_correction.how_does_it_work.CHART_SECTION_YOUR_OCCUPANCY': 'market_correction.how_does_it_work.CHART_SECTION_YOUR_OCCUPANCY',
	'settings.tab.MINIMUM_STAY': 'settings.tab.MINIMUM_STAY',
	'settings.minimum_stay_period.DEFAULT': 'settings.minimum_stay_period.DEFAULT',
	'settings.minimum_stay_add_period.DESCRIPTION': 'settings.minimum_stay_add_period.DESCRIPTION',
	'settings.minimum_stay_add_period.CTA': 'settings.minimum_stay_add_period.CTA',
	'settings.minimum_stay_overview.PLACEHOLDER': 'settings.minimum_stay_overview.PLACEHOLDER',
	'settings.minimum_stay_period.MIN_NIGHTS': 'settings.minimum_stay_period.MIN_NIGHTS',
	'settings.minimum_stay_period.MIN_NIGHT': 'settings.minimum_stay_period.MIN_NIGHT',
	'settings.minimum_stay_period_rolling.TOOLTIP': 'settings.minimum_stay_period_rolling.TOOLTIP',
	'settings.minimum_stay_period_gap_filling.TOOLTIP': 'settings.minimum_stay_period_gap_filling.TOOLTIP',
	'settings.minimum_stay_period_list.TITLE': 'settings.minimum_stay_period_list.TITLE',
	'settings.minimum_stay_edit_period.TITLE': 'settings.minimum_stay_edit_period.TITLE',
	'settings.minimum_stay_edit_default_period.TITLE': 'settings.minimum_stay_edit_default_period.TITLE',
	'settings.minimum_stay_edit_period.CONTEXT': 'settings.minimum_stay_edit_period.CONTEXT',
	'ACCOMMODATION': 'ACCOMMODATION',
	'settings.minimum_stay_edit_add_tab.MIN_STAY': 'settings.minimum_stay_edit_add_tab.MIN_STAY',
	'settings.minimum_stay_edit_add_tab.DYNAMIC_RULES': 'settings.minimum_stay_edit_add_tab.DYNAMIC_RULES',
	'settings.minimum_stay_edit_add_tab.GAP_FILLING': 'settings.minimum_stay_edit_add_tab.GAP_FILLING',
	'settings.minimum_stay_edit_add_tab_min_nights.INPUT': 'settings.minimum_stay_edit_add_tab_min_nights.INPUT',
	'settings.minimum_stay_edit_add_tab_min_nights_dow.TITLE': 'settings.minimum_stay_edit_add_tab_min_nights_dow.TITLE',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.DAY_BEFORE_CHECKIN': 'settings.minimum_stay_edit_add_tab_dynamic_rules.DAY_BEFORE_CHECKIN',
	'FROM': 'FROM',
	'TO': 'TO',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.MIN_STAY': 'settings.minimum_stay_edit_add_tab_dynamic_rules.MIN_STAY',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.CONFIRM': 'settings.minimum_stay_edit_add_tab_dynamic_rules.CONFIRM',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.DELETE': 'settings.minimum_stay_edit_add_tab_dynamic_rules.DELETE',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.ADD_RULE': 'settings.minimum_stay_edit_add_tab_dynamic_rules.ADD_RULE',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.OVERVIEW_PERIOD': 'settings.minimum_stay_edit_add_tab_dynamic_rules.OVERVIEW_PERIOD',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.OVERVIEW_NIGHTS': 'settings.minimum_stay_edit_add_tab_dynamic_rules.OVERVIEW_NIGHTS',
	'settings.minimum_stay_edit_add_tab_gap_filling.TOGGLE_LABEL': 'settings.minimum_stay_edit_add_tab_gap_filling.TOGGLE_LABEL',
	'settings.minimum_stay_edit_add_tab_gap_filling.INPUT': 'settings.minimum_stay_edit_add_tab_gap_filling.INPUT',
	'settings.minimum_stay_period_delete_dialog.TITLE': 'settings.minimum_stay_period_delete_dialog.TITLE',
	'settings.minimum_stay_period_delete_dialog.CONTENT': 'settings.minimum_stay_period_delete_dialog.CONTENT',
	'settings.minimum_stay_period_not_available.TITLE': 'settings.minimum_stay_period_not_available.TITLE',
	'settings.minimum_stay_period_not_available.CONTENT': 'settings.minimum_stay_period_not_available.CONTENT',
	'settings.minimum_stay_period_not_available.CTA': 'settings.minimum_stay_period_not_available.CTA',
	'settings.minimum_stay_period_not_available_dialog.TITLE': 'settings.minimum_stay_period_not_available_dialog.TITLE',
	'settings.minimum_stay_period_not_available_dialog.CONTENT': 'settings.minimum_stay_period_not_available_dialog.CONTENT',
	'settings.minimum_stay_add_dialog.TITLE': 'settings.minimum_stay_add_dialog.TITLE',
	'settings.minimum_stay_add_dialog.CONTEXT': 'settings.minimum_stay_add_dialog.CONTEXT',
	'strategy.advanced_drop_pickup.CUSTOM_RULES_DIALOG_TITLE': 'strategy.advanced_drop_pickup.CUSTOM_RULES_DIALOG_TITLE',
	'strategy.advanced_drop_pickup.MANAGE_CUSTOM_RULES': 'strategy.advanced_drop_pickup.MANAGE_CUSTOM_RULES',
	'strategy.advanced_drop_pickup.CUSTOM_RULES': 'strategy.advanced_drop_pickup.CUSTOM_RULES',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.INVALID_RANGE': 'settings.minimum_stay_edit_add_tab_dynamic_rules.INVALID_RANGE',
	'settings.minimum_stay_edit_add_tab_dynamic_rules.PERIOD_OVERLAP': 'settings.minimum_stay_edit_add_tab_dynamic_rules.PERIOD_OVERLAP',
	'strategy.advanced_drop_pickup.CUSTOM_RULES_DIALOG_EXPLANATION': 'strategy.advanced_drop_pickup.CUSTOM_RULES_DIALOG_EXPLANATION',
	'strategy.advanced_drop_pickup.ADD_DAYS_RULE': 'strategy.advanced_drop_pickup.ADD_DAYS_RULE',
	'strategy.advanced_drop_pickup.ADD_OCCUPANCY_RULE': 'strategy.advanced_drop_pickup.ADD_OCCUPANCY_RULE',
	'strategy.advanced_drop_pickup.DAYS': 'strategy.advanced_drop_pickup.DAYS',
	'strategy.advanced_drop_pickup.RULES': 'strategy.advanced_drop_pickup.RULES',
	'strategy.advanced_drop_pickup.DAYS_BEFORE_CHECK_IN': 'strategy.advanced_drop_pickup.DAYS_BEFORE_CHECK_IN',
	'strategy.advanced_drop_pickup.FROM': 'strategy.advanced_drop_pickup.FROM',
	'strategy.advanced_drop_pickup.TO': 'strategy.advanced_drop_pickup.TO',
	'strategy.advanced_drop_pickup.OCCUPANCY': 'strategy.advanced_drop_pickup.OCCUPANCY',
	'strategy.advanced_drop_pickup.VARIATION': 'strategy.advanced_drop_pickup.VARIATION',
	'strategy.advanced_drop_pickup.ADD_OR_SELECT_DAYS_RULE': 'strategy.advanced_drop_pickup.ADD_OR_SELECT_DAYS_RULE',
	'strategy.advanced_drop_pickup.ADD_FIRST_DAYS_RULE': 'strategy.advanced_drop_pickup.ADD_FIRST_DAYS_RULE',
	'strategy.advanced_drop_pickup.DUPLICATE_ON_HIGH_SEASON': 'strategy.advanced_drop_pickup.DUPLICATE_ON_HIGH_SEASON',
	'strategy.advanced_drop_pickup.DUPLICATE_ON_LOW_SEASON': 'strategy.advanced_drop_pickup.DUPLICATE_ON_LOW_SEASON',
	'strategy.advanced_drop_pickup.CONFIRM_ERROR': 'strategy.advanced_drop_pickup.CONFIRM_ERROR',
	'DEFAULT': 'DEFAULT',
	'market_correction.how_does_it_work.CHART_SECTION_CHART_LINE_YOUR_PRICE_LABEL': 'market_correction.how_does_it_work.CHART_SECTION_CHART_LINE_YOUR_PRICE_LABEL',
	'settings.minimum_stay_save.TITLE': 'settings.minimum_stay_save.TITLE',
	'settings.minimum_stay_save.DESCRIPTION': 'settings.minimum_stay_save.DESCRIPTION',
	'login.lost_customer_toast.TITLE': 'login.lost_customer_toast.TITLE',
	'login.lost_customer_toast.CONTENT': 'login.lost_customer_toast.CONTENT',
	'strategy.advanced_drop_pickup.CUSTOM_RULE_CHART_TOOLTIP': 'strategy.advanced_drop_pickup.CUSTOM_RULE_CHART_TOOLTIP',
	'CRM_AUDIENCE_FIELD_price': 'CRM_AUDIENCE_FIELD_price',
	'CRM_AUDIENCE_FIELD_nights': 'CRM_AUDIENCE_FIELD_nights',
	'CRM_AUDIENCE_FIELD_avg_nights_percentile': 'CRM_AUDIENCE_FIELD_avg_nights_percentile',
	'CRM_AUDIENCE_FIELD_avg_expense_percentile': 'CRM_AUDIENCE_FIELD_avg_expense_percentile',
	'settings.minimum_stay_room_types_list.NON_PRICE_SENDING_ROOM_TYPE_NOT_SELECTABLE': 'settings.minimum_stay_room_types_list.NON_PRICE_SENDING_ROOM_TYPE_NOT_SELECTABLE',
	'settings.minimum_stay_period_request_dialog.CONTENT': 'settings.minimum_stay_period_request_dialog.CONTENT',
	'settings.minimum_stay_period_request_dialog.TITLE': 'settings.minimum_stay_period_request_dialog.TITLE',
	'settings.minimum_stay_reset_default.TOOLTIP': 'settings.minimum_stay_reset_default.TOOLTIP',
	'settings.minimum_stay_reset_to_accommodation_default.TOOLTIP': 'settings.minimum_stay_reset_to_accommodation_default.TOOLTIP',
	'settings.minimum_stay_inherited_settings.TOOLTIP': 'settings.minimum_stay_inherited_settings.TOOLTIP',
	'CRMNEWSLETTER_SENDING-DETAILS_SENT-TO-SUBTITLE': 'CRMNEWSLETTER_SENDING-DETAILS_SENT-TO-SUBTITLE',
	'feedback.min_stay_change.HEADER': 'feedback.min_stay_change.HEADER',
	'feedback.min_stay_change.TITLE': 'feedback.min_stay_change.TITLE',
	'feedback.min_stay_change.IMPROVEMENTS_1': 'feedback.min_stay_change.IMPROVEMENTS_1',
	'feedback.min_stay_change.IMPROVEMENTS_2': 'feedback.min_stay_change.IMPROVEMENTS_2',
	'feedback.min_stay_change.IMPROVEMENTS_3': 'feedback.min_stay_change.IMPROVEMENTS_3',
	'NOT_PROVIDED': 'NOT_PROVIDED',
	'CRM_AUDIENCE_FIELD_channel': 'CRM_AUDIENCE_FIELD_channel',
	'CRM_AUDIENCE_FIELD_policy': 'CRM_AUDIENCE_FIELD_policy',
	'pages.crm.communications.warning': 'pages.crm.communications.warning',
	'pages.crm.communications.warning_communication_inactive_without_ps': 'pages.crm.communications.warning_communication_inactive_without_ps',
	'pages.crm.communications.filter_dialog_title': 'pages.crm.communications.filter_dialog_title',
	'pages.crm.communications.customize_using_filters': 'pages.crm.communications.customize_using_filters',
	'pages.crm.communications.save_filters': 'pages.crm.communications.save_filters',
	'SETTINGS.DERIVE_STARTING_PRICES.ROOM_TYPE_ALREADY_DERIVED': 'SETTINGS.DERIVE_STARTING_PRICES.ROOM_TYPE_ALREADY_DERIVED',
	'pages.crm.analytics.requested_products.empty_state': 'pages.crm.analytics.requested_products.empty_state',
	'pages.marketplace.requested_products.nov_2024_warning_title': 'pages.marketplace.requested_products.nov_2024_warning_title',
	'pages.marketplace.requested_products.nov_2024_warning_text': 'pages.marketplace.requested_products.nov_2024_warning_text',
	'feedback.drop_and_pickup_rules_confirmed.TITLE': 'feedback.drop_and_pickup_rules_confirmed.TITLE',
	'feedback.drop_and_pickup_rules_confirmed.HEADER': 'feedback.drop_and_pickup_rules_confirmed.HEADER',
	'feedback.drop_and_pickup_rules_confirmed.IMPROVEMENTS_1': 'feedback.drop_and_pickup_rules_confirmed.IMPROVEMENTS_1',
	'feedback.drop_and_pickup_rules_confirmed.IMPROVEMENTS_2': 'feedback.drop_and_pickup_rules_confirmed.IMPROVEMENTS_2',
	'feedback.drop_and_pickup_rules_confirmed.IMPROVEMENTS_3': 'feedback.drop_and_pickup_rules_confirmed.IMPROVEMENTS_3',
	'CRMAUTOMATION_SENDING-DETAILS_SENT-TO-AUDIENCE': 'CRMAUTOMATION_SENDING-DETAILS_SENT-TO-AUDIENCE',
	'pages.crm.communications.customize_without_filters': 'pages.crm.communications.customize_without_filters',
	'CRM_AUDIENCE_FIELD_stay_between_dates': 'CRM_AUDIENCE_FIELD_stay_between_dates',

} as const

export type TranslationKey = typeof TranslationKeys[keyof typeof TranslationKeys];