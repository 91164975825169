import { formatISO } from 'date-fns'
import { forOwn } from 'lodash'

// generic
const isIsoDateString = (value: any): boolean => {
	return (
		value &&
		typeof value === 'string' &&
		/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?/.test(value)
	)
}

export const generateDateAsIfClientIsInUTCTimezone = (date = new Date()) => {
	const today = date

	const day = today.getDate()
	const month = today.getMonth()
	const year = today.getFullYear()

	return new Date(Date.UTC(year, month, day))
}

// from backend

export const stringDateFromBackend = (value: string) => {
	const [year, month, day, hours, minutes, seconds] = value.split(/[-T:.Z]/).map(Number)

	return new Date(year, month - 1, day, hours, minutes, seconds)
}

export const stringDateToStartOfDay = (value: string) => {
	const [year, month, day] = value.split(/[-]/).map(Number)

	return new Date(year, month - 1, day, 0, 0, 0)
}

export const parseDatesFromBackend = (body: any) => {
	const objectType = typeof body

	if ([null, undefined].includes(body) || ['number', 'boolean'].includes(objectType)) return body

	for (const key of Object.keys(body)) {
		const value = body[key]
		if (isIsoDateString(value)) {
			body[key] = stringDateFromBackend(value)
			//body[key] = utilDate.utcToCurrentTimezone(new Date(value))
		} else if (typeof value === 'object') parseDatesFromBackend(value)
	}
}

// to backend (if necessary)
/* export const dateToStringForBackend = (date: Date) => {
	return formatISO(date, { representation: 'date' })
}

export const parseDatesToBackend = (body: any) => {
	const objectType = typeof body

	if ([null, undefined].includes(body) || ['number', 'boolean'].includes(objectType)) return body

	for (const key of Object.keys(body)) {
		const value = body[key]
		if (value instanceof Date) {
			body[key] = dateToStringForBackend(generateDateAsIfClientIsInUTCTimezone(value))
			//body[key] = utilDate.utcToCurrentTimezone(new Date(value))
		} else if (typeof value === 'object') parseDatesFromBackend(value)
	}
}
 */
