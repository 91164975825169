import { AxiosInstance, AxiosResponse, default as axios } from 'axios'
import { CardTypes } from '../constants/cardTypes'
import { Environments } from '../constants/environments'
import { LoadingIds } from '../constants/loadingIds'
import { StorageKeys } from '../constants/storageKeys'
import { ISpResponseWrapper } from '../submodules/sharedTypes/common/ISpResponseWrapper'
import { NetworkCommunicationType } from '../submodules/sharedTypes/common/NetworkCommunicationType'
import { RequestType } from '../submodules/sharedTypes/common/RequestTypes'
import { WSMessageEvent } from '../submodules/sharedTypes/common/WebSocket'
import { additionalHttpHeaders } from '../submodules/sharedTypes/communication/additionalParams/AdditionalHttpHeaders'
import { AdditionalParams } from '../submodules/sharedTypes/communication/additionalParams/AdditionalParams'
import { tokenRefreshRetryTime } from './../config/ConnectionSettings'
import { ISocketHandler } from './../networkListeners/socketListenersMap'
import { useLoadingStore } from './../store/loading'
import { useNotificationsStore } from './../store/notifications'
import { INetworkObject } from './../submodules/sharedTypes/common/INetworkObject'
import { RestNetworkObject } from './../submodules/sharedTypes/common/RestNetworkObject'
import { NewTokenNetworkObject } from './../submodules/sharedTypes/communication/auth/NewTokenNetworkObject'
import { NewTokenResponse } from './../submodules/sharedTypes/communication/auth/NewTokenResponse'
import { UtilSales } from './UtilSales'
import { utilWebSocket } from './UtilWebSocket'
import { utilBadPayment } from './utilBadPayment'
import { utilLogout } from './utilLogout'
import { utilTrialPeriod } from './utilTrialPeriod'
import { TranslationKeys } from '~/i18n/TranslationKeys'

class UtilNetwork {
	private readonly httpClient: AxiosInstance

	private apiBaseUrl: string | undefined = undefined

	// these are used when we need to refresh a token
	private pendingCallsCounter = 0
	private tokenPromise: Promise<void> | undefined

	public constructor(apiBaseUrl?: string) {
		this.httpClient = axios.create()
		this.httpClient.interceptors.response.use((res) => {
			console.log('hello', res)
			parseDatesFromBackend(res.data)
			return res
		})
		this.apiBaseUrl = apiBaseUrl
	}

	public simpleNonAuthorizedRequest<T, K>(
		request: INetworkObject<T>,
		callBack: (res: K) => void,
		onError: (res: K) => void
	) {
		return this._restRequest(request as RestNetworkObject<T>, callBack, onError)
	}

	public simpleRequest<T, K>(
		request: INetworkObject<T>,
		callBack?: (res: K) => void,
		loadingId: LoadingIds = '' as LoadingIds,
		customSessionToken?: string
	): Promise<K> | undefined {
		if (request.communicationType === NetworkCommunicationType.WEB_SOCKET) {
			utilWebSocket.sendEvent(request.getConnectionString() as WSMessageEvent, request.getParams(), loadingId)
		} else {
			if (callBack == undefined) {
				throw new Error('no callback was set for this request!')
			}
			return this._restRequest(request as RestNetworkObject<T>, callBack, undefined, loadingId, customSessionToken)
		}
	}

	public async genericGetRequest<T>(url: string): Promise<T> {
		const { data } = await this.httpClient.get(url, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem(StorageKeys.SessionToken)}`,
			},
		})
		return data
	}

	public registerServerInteractions(map: Map<WSMessageEvent, ISocketHandler>) {
		utilWebSocket.setMessageHandlers(map)
	}

	private _restRequest<T, K>(
		request: RestNetworkObject<T>,
		callBack: (res: K) => void,
		onError?: (res: K) => void,
		loadingId?: LoadingIds,
		customSessionToken?: string
	) {
		return new Promise<K>(async (resolve) => {
			if (loadingId != undefined) {
				useLoadingStore().addLoading(loadingId)
			}

			if (this.tokenPromise != undefined) {
				await this.enqueueForToken()
			}

			log(['Request type: ', request.getRequestType().toUpperCase(), ' to: ', request.getConnectionString()], 'Rest')

			const req = this.getAxiosCall(request, customSessionToken)

			req
				.then((res: AxiosResponse<ISpResponseWrapper<K>>) => {
					// on success:
					const data = res.data.data

					const environment = useRuntimeConfig().public.ENVIRONMENT
					if (environment === Environments.Development || environment === Environments.Staging) {
						log(['Response from: ', request.getConnectionString(), ' with data: ', data], 'Rest')
					}

					callBack(data)
					if (loadingId != undefined) {
						useLoadingStore().removeLoading(loadingId)
					}
					resolve(data)
				})
				.catch((err: any) => {
					if (onError != undefined) {
						onError(err)
						return
					}
					// on error:
					let statusCode,
						code = undefined
					try {
						statusCode = err.response.data.statusCode
						code = err.response.data.code
					} catch {
						log(['Response error: ', request.getConnectionString(), ' with data: ', err], 'Rest')
					}
					const isTokenInvalid =
						(statusCode == 400 && code == 'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED') ||
						(statusCode == 401 && code == 'FST_JWT_AUTHORIZATION_TOKEN_EXPIRED') ||
						(statusCode == 400 && code == 'FAST_JWT_MALFORMED')

					if (isTokenInvalid) {
						this.handleExpiredToken().then(async () => {
							const value = await this.simpleRequest(request, (res: K) => {})!
							resolve(value)
						})
					} else {
						const notificationStore = useNotificationsStore()
						notificationStore.addNotification({
							canClose: false,
							cardType: CardTypes.ERROR,
							title: TranslationKeys.SOMETHING_WENT_WRONG,
							message: TranslationKeys.SOMETHING_WENT_WRONG_TEXT,
						})
					}
				})
		})
	}

	private getUrl<T>(request: INetworkObject<T>) {
		const baseUrl = this.apiBaseUrl || useRuntimeConfig().public.API_BASE_URL
		return baseUrl + request.getConnectionString()
	}

	/**
	 * Creates an axios call using an INetworkObject<T>
	 */
	private getAxiosCall<T>(request: RestNetworkObject<T>, customSessionToken?: string) {
		const requestType = request.getRequestType()
		const url = this.getUrl(request)
		const config = this.getConfig(request, customSessionToken)

		// get and delete have a different params order in axios, so we need to call it separately
		if (requestType === RequestType.GET || requestType === RequestType.DELETE) {
			// the following is ignored as we are accessing axios method's in an unconventional way, and as a result
			// TS is not sure what we are doing...
			// @ts-ignore
			return this.httpClient[request.getRequestType()](url, config)
		}
		// the following is ignored as we are accessing axios method's in an unconventional way, and as a result
		// TS is not sure what we are doing...
		// @ts-ignore
		return this.httpClient[request.getRequestType()](url, JSON.stringify(request.getParams()), config)
	}

	/**
	 * get the axios configuration
	 */
	private getConfig<T>(request: RestNetworkObject<T>, customSessionToken?: string) {
		return {
			params: this.getUrlParams(request),
			headers: {
				Authorization: `Bearer ${customSessionToken || localStorage.getItem(StorageKeys.SessionToken)}`,
				'Content-Type': 'application/json; charset=utf8',
				[additionalHttpHeaders[AdditionalParams.SalesMode]]: UtilSales.isSalesMode.value,
			},
		}
	}

	/**
	 * get the url params if needed
	 */
	private getUrlParams<T>(request: RestNetworkObject<T>) {
		return request.getRequestType() === RequestType.GET ? request.getParams() : {}
	}

	async handleExpiredToken() {
		utilWebSocket.invalidateToken()
		if (this.tokenPromise == undefined) {
			this.updateToken()
		}
		await this.enqueueForToken()
		utilWebSocket.confirmToken()
	}

	private updateToken() {
		this.tokenPromise = new Promise(async (confirmToken) => {
			while ((await this.tryGetRefreshToken()) === false) {
				console.warn('token refresh failed!')
				// wait delay the next refresh token refresh
				await new Promise((resolve) => {
					setTimeout(() => {
						resolve(true)
					}, tokenRefreshRetryTime)
				})
			}

			// resolve this.tokenPromise
			confirmToken()
		})
	}

	/**
	 * a function to try and get a new token using the refresh token.
	 * IMPORTANT: in case the refresh token is not valid two side-effects are triggered:
	 *  - token & refresh token are removed from the session
	 *  - logout is enforced
	 * @returns a promise containing the boolean value concerning the success of the operation
	 */
	private tryGetRefreshToken(): Promise<boolean> {
		return new Promise((resolve) => {
			const refreshToken = localStorage.getItem(StorageKeys.RefreshToken) || ''
			if (refreshToken === '') {
				utilLogout.logout()
				resolve(true)
			}

			const request = this.getAxiosCall(
				new NewTokenNetworkObject({
					refreshToken,
				})
			)

			request
				.then((res: AxiosResponse<ISpResponseWrapper<NewTokenResponse>>) => {
					const data = res.data.data as NewTokenResponse

					localStorage.setItem(StorageKeys.SessionToken, data.tokenData.token)
					localStorage.setItem(StorageKeys.RefreshToken, data.tokenData.refreshToken)

					utilTrialPeriod.handlePeriod(data.subscriptionsData?.trialPeriodExpirationDate)
					utilBadPayment.handlePaymentStatus(
						data.subscriptionsData?.paymentStatus,
						data.subscriptionsData?.accountLockDate
					)
					resolve(true)
				})
				.catch((err: any) => {
					const { statusCode, code } = err.response.data

					const refreshTokenExpired = statusCode === 401 && code === 'FAST_JWT_EXPIRED'

					if (refreshTokenExpired) {
						utilLogout.logout()
						document.location.reload()
						resolve(true)
					}

					resolve(false)
				})
		})
	}

	private async enqueueForToken() {
		const promise = this.tokenPromise

		// with no promise, don't wait.
		if (promise == undefined) {
			return
		}

		// add to queue, await for token, remove from queue
		this.pendingCallsCounter++
		await promise
		this.pendingCallsCounter--

		// if the queue is empty, remove the promise
		if (this.pendingCallsCounter == 0) {
			this.tokenPromise = undefined
		}
	}
}

export const utilNetwork = new UtilNetwork()
export { UtilNetwork as UtilNetworkClass }
