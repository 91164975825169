import { DayOfTheWeek } from '@dev.smartpricing/sp-shared-network-freemium-types'
import {
	addDays,
	addWeeks,
	differenceInCalendarDays,
	differenceInDays,
	eachDayOfInterval,
	formatISO,
	getWeek,
	subDays,
} from 'date-fns'
import _, { cloneDeep, isEqual } from 'lodash'
import { defineStore } from 'pinia'
import { v7 } from 'uuid'
import { Range } from '~/submodules/sharedTypes/common/Range'
import { DEFAULT_STRATEGY_ADVANCED_PARAMS } from '../config/Strategy'
import { FeedbackId } from '../constants/FeedbackId'
import { LoadingIds } from '../constants/loadingIds'
import { StrategyActionTypes, StrategyTrackingTypes } from '../constants/tracking/strategiesTracking'
import { TrackingMessages } from '../constants/trackingMessages'
import { Feature } from '../submodules/sharedTypes/common/Feature'
import { PricePreview, PricePreviewAggregation } from '../submodules/sharedTypes/common/Price'
import { PriceSeries } from '../submodules/sharedTypes/common/PriceSeries'
import { GetAccommodationPricesPredictionPreviewNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/strategy/GetAccommodationPricesPredictionPreviewNetworkObject'
import { GetAdvancedExpertUserParamsMappingNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/strategy/GetAdvancedExpertUserParamsMappingNetworkObject'
import { GetAdvancedExpertUserParamsMappingResponse } from '../submodules/sharedTypes/communication/accommodations/id/strategy/GetAdvancedExpertUserParamsMappingResponse'
import { UpdateAccommodationStrategyNetworkObject } from '../submodules/sharedTypes/communication/accommodations/id/strategy/UpdateAccommodationStrategyNetworkObject'
import { GetHowDoesItWorkMarketCorrectionResponse } from '../submodules/sharedTypes/communication/how-does-it-work/market-correction/GetHowDoesItWorkMarketCorrectionResponse'
import { DropPickupRuleWithErrors, Strategy, StrategyChartLineDataPoint, StrategyPeriod } from '../types/Strategy'
import { utilDate } from '../utils/utilDate'
import {
	DefaultStrategySettings,
	Seasonality,
	StrategySettings,
	StrategyType,
} from './../submodules/sharedTypes/common/Strategy'
import { GetAccommodationStrategyNetworkObject } from './../submodules/sharedTypes/communication/accommodations/id/strategy/GetAccommodationStrategyNetworkObject'
import { GetAccommodationStrategyResponse } from './../submodules/sharedTypes/communication/accommodations/id/strategy/GetAccommodationStrategyResponse'
import { utilNetwork } from './../utils/UtilNetwork'
import { useAccommodationsStore } from './accommodations'
import { useFeedbackStore } from './feedback'

const enum PricePredictionType {
	Starting = 'starting',
}

const enum PricePredictionFormattedType {
	Current = 'current',
	CurrentPreview = 'current_preview',
	Published = 'published',
	PublishedPreview = 'published_preview',
	Starting = 'starting',
}

type BasePricePreviewAggregation = {
	id: number
	prices: (Omit<PricePreview, 'suggestedPrice' | 'publishedPrice'> & {
		price: number
	})[]
}

type StrategiesState = {
	currentStrategy: Strategy
	editableStrategy: Strategy
	previewedStrategy: Strategy | undefined

	selectedPeriodIndex: string | null | undefined

	pricePredictions: Map<StrategyType, PricePreviewAggregation[]> &
		Map<PricePredictionType, BasePricePreviewAggregation[]>
	pricePredictionsFormatted: Record<PricePredictionFormattedType, Record<number, StrategyChartLineDataPoint[]>>

	isLoadingPricePredictions: boolean
	isLoadingStrategy: boolean

	newPeriodStartDate: Date
	newPeriodEndDate: Date

	activeChartSeries: PriceSeries[]

	isAdvancedMode: boolean
	isEditingCustomRules: boolean

	defaultStrategySettings?: DefaultStrategySettings

	advancedModeParamsMapping?: StrategySettings

	howDoesItWorkMarketCorrection: Record<string, number[]>
}

const defaultStrategy: Strategy = {
	accommodationId: 0,
	periods: [],
	baseStrategy: {
		averagePrice: 0,
		priceDecrease: -10,
		priceIncrease: 10,
		isAdvancedExpertUserEnabled: false,
		drop: DEFAULT_STRATEGY_ADVANCED_PARAMS.drop,
		pickup: DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup,
		eventsAndHolidaysImpact: DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
		dayOfTheWeekMap: DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap,
		expertUserAveragePrice: DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices,
		advancedExpertUserAveragePrice: DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices,
		isMarketDemandEnabled: DEFAULT_STRATEGY_ADVANCED_PARAMS.marketDemandEnabled,
		marketDemand: DEFAULT_STRATEGY_ADVANCED_PARAMS.marketDemand,
		rules: {
			[Seasonality.Low]: [],
			[Seasonality.High]: [],
		},
	},
}

const defaultPricePredictions = new Map()
const defaultPricePredictionsFormatted = {
	[PricePredictionFormattedType.Current]: {},
	[PricePredictionFormattedType.CurrentPreview]: {},
	[PricePredictionFormattedType.Published]: {},
	[PricePredictionFormattedType.PublishedPreview]: {},
	[PricePredictionFormattedType.Starting]: {},
}

export const defaultActiveChartSeries = [
	PriceSeries.SuggestedPrices,
	PriceSeries.SuggestedPricesPreview,
	PriceSeries.PublishedPricesPreview,
]

export const useStrategiesStore = defineStore('strategies', {
	state: (): StrategiesState => ({
		currentStrategy: _.cloneDeep(defaultStrategy),
		editableStrategy: _.cloneDeep(defaultStrategy),
		previewedStrategy: undefined,

		selectedPeriodIndex: undefined,

		pricePredictions: defaultPricePredictions,
		pricePredictionsFormatted: defaultPricePredictionsFormatted,

		isLoadingPricePredictions: false,
		isLoadingStrategy: false,

		newPeriodStartDate: new Date(),
		newPeriodEndDate: addWeeks(new Date(), 1),

		activeChartSeries: defaultActiveChartSeries,

		isAdvancedMode: false,
		isEditingCustomRules: false,

		defaultStrategySettings: undefined,

		advancedModeParamsMapping: undefined,

		howDoesItWorkMarketCorrection: {},
	}),
	actions: {
		resetCurrentStrategyToBasic() {
			if (this.selectedPeriodIndex === null) {
				this.editableStrategy.baseStrategy.averagePrice = defaultStrategy.baseStrategy.averagePrice
				this.editableStrategy.baseStrategy.expertUserAveragePrice = defaultStrategy.baseStrategy.expertUserAveragePrice
				this.editableStrategy.baseStrategy.priceIncrease = defaultStrategy.baseStrategy.priceIncrease
				this.editableStrategy.baseStrategy.priceDecrease = defaultStrategy.baseStrategy.priceDecrease
				this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled = false
				this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice =
					defaultStrategy.baseStrategy.advancedExpertUserAveragePrice
				this.editableStrategy.baseStrategy.drop = defaultStrategy.baseStrategy.drop
				this.editableStrategy.baseStrategy.pickup = defaultStrategy.baseStrategy.pickup
				this.editableStrategy.baseStrategy.eventsAndHolidaysImpact =
					defaultStrategy.baseStrategy.eventsAndHolidaysImpact
				// @ts-ignore
				this.editableStrategy.baseStrategy.dayOfTheWeekMap = {
					[Seasonality.Low]: {
						[DayOfTheWeek.Monday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday]) * 100
						),
						[DayOfTheWeek.Tuesday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday]) * 100
						),
						[DayOfTheWeek.Wednesday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday]) * 100
						),
						[DayOfTheWeek.Thursday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday]) * 100
						),

						[DayOfTheWeek.Friday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday]) * 100
						),
						[DayOfTheWeek.Saturday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday]) * 100
						),

						[DayOfTheWeek.Sunday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday]) * 100
						),
					},
					[Seasonality.High]: {
						[DayOfTheWeek.Monday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday]) * 100
						),
						[DayOfTheWeek.Tuesday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday]) * 100
						),
						[DayOfTheWeek.Wednesday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday]) * 100
						),
						[DayOfTheWeek.Thursday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday]) * 100
						),
						[DayOfTheWeek.Friday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday]) * 100
						),
						[DayOfTheWeek.Saturday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday]) * 100
						),
						[DayOfTheWeek.Sunday]: Math.round(
							(this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday]) * 100
						),
					},
				}

				this.editableStrategy.baseStrategy.rules = {
					[Seasonality.Low]: [],
					[Seasonality.High]: [],
				}
			} else {
				const isDefaultStrategyBasicMode = !this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled
				const customPeriod = this.editableStrategy.periods.find((period) => period.id === this.selectedPeriodIndex)

				if (!customPeriod) {
					return
				}

				customPeriod.settings.averagePrice = isDefaultStrategyBasicMode
					? (this.editableStrategy.baseStrategy.expertUserAveragePrice ??
						DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices)
					: defaultStrategy.baseStrategy.averagePrice
				customPeriod.settings.expertUserAveragePrice = isDefaultStrategyBasicMode
					? (this.editableStrategy.baseStrategy.expertUserAveragePrice ??
						DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices)
					: defaultStrategy.baseStrategy.expertUserAveragePrice
				customPeriod.settings.priceIncrease = isDefaultStrategyBasicMode
					? this.editableStrategy.baseStrategy.priceIncrease
					: defaultStrategy.baseStrategy.priceIncrease
				customPeriod.settings.priceDecrease = isDefaultStrategyBasicMode
					? this.editableStrategy.baseStrategy.priceDecrease
					: defaultStrategy.baseStrategy.priceDecrease
				customPeriod.settings.isAdvancedExpertUserEnabled = false
				customPeriod.settings.advancedExpertUserAveragePrice =
					defaultStrategy.baseStrategy.advancedExpertUserAveragePrice
				customPeriod.settings.drop = this.editableStrategy.baseStrategy.drop ?? defaultStrategy.baseStrategy.drop
				customPeriod.settings.pickup = this.editableStrategy.baseStrategy.pickup ?? defaultStrategy.baseStrategy.pickup
				customPeriod.settings.eventsAndHolidaysImpact =
					this.editableStrategy.baseStrategy.eventsAndHolidaysImpact ??
					defaultStrategy.baseStrategy.eventsAndHolidaysImpact
				// @ts-ignore
				customPeriod.settings.dayOfTheWeekMap = {
					[Seasonality.Low]: {
						[DayOfTheWeek.Monday]: Math.round(
							this.editableStrategy?.baseStrategy?.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] * 100
						),
						[DayOfTheWeek.Tuesday]: Math.round(
							this.editableStrategy?.baseStrategy?.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] * 100
						),
						[DayOfTheWeek.Wednesday]: Math.round(
							this.editableStrategy?.baseStrategy?.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] * 100
						),
						[DayOfTheWeek.Thursday]: Math.round(
							this.editableStrategy?.baseStrategy?.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] * 100
						),

						[DayOfTheWeek.Friday]: Math.round(
							this.editableStrategy?.baseStrategy?.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] * 100
						),
						[DayOfTheWeek.Saturday]: Math.round(
							this.editableStrategy?.baseStrategy?.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] * 100
						),
						[DayOfTheWeek.Sunday]: Math.round(
							this.editableStrategy.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy.baseStrategy.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] * 100
						),
					},
					[Seasonality.High]: {
						[DayOfTheWeek.Monday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] * 100
						),
						[DayOfTheWeek.Tuesday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] * 100
						),
						[DayOfTheWeek.Wednesday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] * 100
						),
						[DayOfTheWeek.Thursday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] * 100
						),
						[DayOfTheWeek.Friday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] * 100
						),
						[DayOfTheWeek.Saturday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] * 100
						),
						[DayOfTheWeek.Sunday]: Math.round(
							this.editableStrategy?.baseStrategy.dayOfTheWeekMap
								? this.editableStrategy?.baseStrategy.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] / 100
								: this.defaultStrategySettings!.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] * 100
						),
					},
				}
				customPeriod.settings.rules = {
					[Seasonality.Low]: [],
					[Seasonality.High]: [],
				}
			}
		},

		getStrategy(accommodationId: number) {
			this.currentStrategy.accommodationId = accommodationId
			this.editableStrategy.accommodationId = accommodationId

			this.isLoadingStrategy = true

			useLoadingStore().addLoading(LoadingIds.STRATEGY_LOAD)
			utilNetwork.simpleRequest(
				new GetAccommodationStrategyNetworkObject({ accommodationId }),
				undefined,
				LoadingIds.STRATEGY_LOAD
			)
		},
		getStrategyPricePredictions() {
			if (this.isEditingCustomRules === true) {
				return
			}
			// Before updating the currently previewed strategy to the changes
			// we made to the edited strategy, we check for edits and track them
			// specifically for the base strategy and each period.
			const uniqueTrackingId = crypto.randomUUID()

			if (!isEqual(this.editableStrategy.baseStrategy, this.previewedStrategy?.baseStrategy)) {
				const isAdvanced = this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled
				let trackingBody: { [key in string]: any } = {
					change_type: StrategyActionTypes.Preview,
					strategy_type: StrategyTrackingTypes.Base,
					selected_days: 500,
					uuid: uniqueTrackingId,
					str_mode: isAdvanced ? 'advanced' : 'basic',
				}

				if (isAdvanced) {
					trackingBody = {
						...trackingBody,
						aeu_starting_price: this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice,
						aeu_price_decrease_low_season: this.editableStrategy?.baseStrategy?.drop?.low
							? this.editableStrategy.baseStrategy.drop.low / -10
							: undefined,
						aeu_price_decrease_high_season: this.editableStrategy?.baseStrategy?.drop?.high
							? this.editableStrategy.baseStrategy.drop.high / -10
							: undefined,
						aeu_price_increase_low_season: this.editableStrategy?.baseStrategy?.pickup?.low
							? this.editableStrategy.baseStrategy.pickup.low / 10
							: undefined,
						aeu_price_increase_high_season: this.editableStrategy?.baseStrategy?.pickup?.high
							? this.editableStrategy.baseStrategy.pickup.high / 10
							: undefined,
						aeu_events: this.editableStrategy.baseStrategy.eventsAndHolidaysImpact / 10,
						aeu_dow_low_season: Object.values(this.editableStrategy?.baseStrategy?.dayOfTheWeekMap?.low ?? {}),
						aeu_dow_high_season: Object.values(this.editableStrategy?.baseStrategy?.dayOfTheWeekMap?.high ?? {}),
					}
				} else {
					trackingBody = {
						...trackingBody,
						price_decrease: this.editableStrategy.baseStrategy.priceDecrease / 10,
						price_increase: this.editableStrategy.baseStrategy.priceIncrease / 10,
						avg_price: this.editableStrategy.baseStrategy.averagePrice,
					}
				}

				utilTracking.track(TrackingMessages.STRATEGY_CHANGE, trackingBody)
			}

			this.editableStrategy.periods.forEach((period) => {
				const samePeriodInPreviousStrategy = this.previewedStrategy?.periods.find(
					(editablePeriod) => editablePeriod.id === period.id
				)

				if (!samePeriodInPreviousStrategy || !isEqual(samePeriodInPreviousStrategy, period)) {
					const isPeriodAdvanced = period.settings.isAdvancedExpertUserEnabled
					let trackingBody: { [key in string]: any } = {
						change_type: StrategyActionTypes.Preview,
						strategy_type: StrategyTrackingTypes.Period,
						selected_days: differenceInCalendarDays(period.dateRange.to, period.dateRange.from),
						uuid: uniqueTrackingId,
						str_mode: isPeriodAdvanced ? 'advanced' : 'basic',
					}

					if (isPeriodAdvanced) {
						trackingBody = {
							...trackingBody,
							aeu_starting_price: period.settings.advancedExpertUserAveragePrice,
							aeu_price_decrease_low_season: period?.settings?.drop?.low ? period.settings.drop.low / -10 : undefined,
							aeu_price_decrease_high_season: period?.settings?.drop?.high
								? period.settings.drop.high / -10
								: undefined,
							aeu_price_increase_low_season: period?.settings?.pickup?.low
								? period.settings.pickup.low / 10
								: undefined,
							aeu_price_increase_high_season: period?.settings?.pickup?.high
								? period.settings.pickup.high / 10
								: undefined,
							aeu_events: period.settings.eventsAndHolidaysImpact / 10,
							aeu_dow_low_season: Object.values(period.settings?.dayOfTheWeekMap?.low ?? {}),
							aeu_dow_high_season: Object.values(period.settings?.dayOfTheWeekMap?.high ?? {}),
						}
					} else {
						trackingBody = {
							...trackingBody,
							price_decrease: period.settings.priceDecrease / 10,
							price_increase: period.settings.priceIncrease / 10,
							avg_price: period.settings.averagePrice,
						}
					}

					utilTracking.track(TrackingMessages.STRATEGY_CHANGE, trackingBody)
				}
			})

			// Check also for removed periods
			this.previewedStrategy?.periods.forEach((period) => {
				const samePeriodInCurrentStrategy = this.editableStrategy.periods.find(
					(previewedPeriod) => previewedPeriod.id === period.id
				)

				if (!samePeriodInCurrentStrategy) {
					utilTracking.track(TrackingMessages.STRATEGY_CHANGE, {
						change_type: StrategyActionTypes.Preview,
						strategy_type: StrategyTrackingTypes.Period,
						selected_days: differenceInCalendarDays(period.dateRange.to, period.dateRange.from),
						uuid: uniqueTrackingId,
					})
				}
			})

			this.isLoadingPricePredictions = true
			this.previewedStrategy = _.cloneDeep(this.editableStrategy)

			utilNetwork.simpleRequest(
				new GetAccommodationPricesPredictionPreviewNetworkObject({
					strategy: {
						...this.editableStrategy,
						periods: this.editableStrategy.periods.map((period) => {
							return {
								...period,
								settings: {
									priceIncrease: period.settings.priceIncrease / 10,
									priceDecrease: period.settings.priceDecrease / 10,
									advancedExpertUserAveragePrice:
										(period.settings.advancedExpertUserAveragePrice ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
									isMarketDemandEnabled: period.settings.isMarketDemandEnabled,
									marketDemand: period.settings.marketDemand,
									averagePrice:
										((period.settings.isAdvancedExpertUserEnabled
											? period.settings.advancedExpertUserAveragePrice
											: period.settings.expertUserAveragePrice) ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) /
										100,
									expertUserAveragePrice:
										(period.settings.expertUserAveragePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
									isAdvancedExpertUserEnabled: period.settings.isAdvancedExpertUserEnabled,
									drop: {
										[Seasonality.Low]:
											Math.abs(period.settings?.drop?.low || DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low) / 10,
										[Seasonality.High]:
											Math.abs(period.settings?.drop?.high || DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high) / 10,
									},
									pickup: {
										[Seasonality.Low]:
											Math.abs(period.settings?.pickup?.low || DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low) / 10,
										[Seasonality.High]:
											Math.abs(period.settings?.pickup?.high || DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high) / 10,
									},
									eventsAndHolidaysImpact: parseFloat(
										utilNumber
											.remapValue(
												period.settings.eventsAndHolidaysImpact ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
												[0, 20],
												[0, 2]
											)
											.toFixed(1)
									),
									dayOfTheWeekMap: {
										[Seasonality.Low]: {
											[DayOfTheWeek.Monday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Monday] ??
													period.settings.dayOfTheWeekMap?.low[0] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[0]) / 100,
											[DayOfTheWeek.Tuesday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Tuesday] ??
													period.settings.dayOfTheWeekMap?.low[1] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[1]) / 100,
											[DayOfTheWeek.Wednesday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Wednesday] ??
													period.settings.dayOfTheWeekMap?.low[2] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[2]) / 100,
											[DayOfTheWeek.Thursday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Thursday] ??
													period.settings.dayOfTheWeekMap?.low[3] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[3]) / 100,
											[DayOfTheWeek.Friday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Friday] ??
													period.settings.dayOfTheWeekMap?.low[4] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[4]) / 100,
											[DayOfTheWeek.Saturday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Saturday] ??
													period.settings.dayOfTheWeekMap?.low[4] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[5]) / 100,
											[DayOfTheWeek.Sunday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Sunday] ??
													period.settings.dayOfTheWeekMap?.low[5] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[5]) / 100,
										},
										[Seasonality.High]: {
											[DayOfTheWeek.Monday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Monday] ??
													period.settings.dayOfTheWeekMap?.low[0] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[0]) / 100,
											[DayOfTheWeek.Tuesday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ??
													period.settings.dayOfTheWeekMap?.low[1] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[1]) / 100,
											[DayOfTheWeek.Wednesday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ??
													period.settings.dayOfTheWeekMap?.low[2] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[2]) / 100,
											[DayOfTheWeek.Thursday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ??
													period.settings.dayOfTheWeekMap?.low[3] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[3]) / 100,
											[DayOfTheWeek.Friday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ??
													period.settings.dayOfTheWeekMap?.low[4] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[4]) / 100,
											[DayOfTheWeek.Saturday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ??
													period.settings.dayOfTheWeekMap?.low[4] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[5]) / 100,
											[DayOfTheWeek.Sunday]:
												(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ??
													period.settings.dayOfTheWeekMap?.low[5] ??
													DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[5]) / 100,
										},
									},
									rules: {
										[Seasonality.Low]: period.settings.rules[Seasonality.Low].map((rule) => ({
											...rule,
											bookDaysInterval: {
												...rule.bookDaysInterval,
												occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
													from: interval.from / 100,
													to: interval.to / 100,
													expectedChange: {
														type: interval.expectedChange.type,
														value:
															interval.expectedChange.type === 'percentage'
																? interval.expectedChange.value / 100
																: interval.expectedChange.value,
													},
												})),
											},
										})),
										[Seasonality.High]: period.settings.rules[Seasonality.High].map((rule) => ({
											...rule,
											bookDaysInterval: {
												...rule.bookDaysInterval,
												occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
													from: interval.from / 100,
													to: interval.to / 100,
													expectedChange: {
														type: interval.expectedChange.type,
														value:
															interval.expectedChange.type === 'percentage'
																? interval.expectedChange.value / 100
																: interval.expectedChange.value,
													},
												})),
											},
										})),
									},
								},
							}
						}),
						baseStrategy: {
							priceIncrease: this.editableStrategy.baseStrategy.priceIncrease / 10,
							priceDecrease: this.editableStrategy.baseStrategy.priceDecrease / 10,
							averagePrice:
								((this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled
									? this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice
									: this.editableStrategy.baseStrategy.expertUserAveragePrice) ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
							isAdvancedExpertUserEnabled: this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled,
							isMarketDemandEnabled: this.editableStrategy.baseStrategy.isMarketDemandEnabled,
							marketDemand: this.editableStrategy.baseStrategy.marketDemand,
							advancedExpertUserAveragePrice:
								(this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
							expertUserAveragePrice:
								(this.editableStrategy.baseStrategy.expertUserAveragePrice ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
							drop: {
								[Seasonality.Low]:
									Math.abs(
										this.editableStrategy?.baseStrategy?.drop?.low ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low
									) / 10,
								[Seasonality.High]:
									Math.abs(
										this.editableStrategy.baseStrategy.drop?.high ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high
									) / 10,
							},
							pickup: {
								[Seasonality.Low]:
									(this.editableStrategy.baseStrategy?.pickup?.low ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low) / 10,
								[Seasonality.High]:
									(this.editableStrategy.baseStrategy.pickup?.high ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high) /
									10,
							},
							eventsAndHolidaysImpact: parseFloat(
								utilNumber
									.remapValue(
										this.editableStrategy.baseStrategy.eventsAndHolidaysImpact ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
										[0, 20],
										[0, 2]
									)
									.toFixed(1)
							),
							dayOfTheWeekMap: {
								[Seasonality.Low]: {
									[DayOfTheWeek.Monday]:
										(this.editableStrategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low]?.[DayOfTheWeek.Monday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday]) / 100,
									[DayOfTheWeek.Tuesday]:
										(this.editableStrategy.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Tuesday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday]) / 100,
									[DayOfTheWeek.Wednesday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Wednesday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday]) / 100,
									[DayOfTheWeek.Thursday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Thursday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday]) / 100,

									[DayOfTheWeek.Friday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Friday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday]) / 100,
									[DayOfTheWeek.Saturday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Saturday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday]) / 100,

									[DayOfTheWeek.Sunday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Sunday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday]) / 100,
								},
								[Seasonality.High]: {
									[DayOfTheWeek.Monday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Monday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday]) / 100,
									[DayOfTheWeek.Tuesday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday]) / 100,
									[DayOfTheWeek.Wednesday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday]) / 100,
									[DayOfTheWeek.Thursday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday]) / 100,
									[DayOfTheWeek.Friday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday]) / 100,
									[DayOfTheWeek.Saturday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday]) / 100,
									[DayOfTheWeek.Sunday]:
										(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ??
											this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] ??
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday]) / 100,
								},
							},
							rules: {
								[Seasonality.Low]: this.editableStrategy.baseStrategy.rules[Seasonality.Low].map((rule) => ({
									...rule,
									bookDaysInterval: {
										...rule.bookDaysInterval,
										occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
											from: interval.from / 100,
											to: interval.to / 100,
											expectedChange: {
												type: interval.expectedChange.type,
												value:
													interval.expectedChange.type === 'percentage'
														? interval.expectedChange.value / 100
														: interval.expectedChange.value,
											},
										})),
									},
								})),
								[Seasonality.High]: this.editableStrategy.baseStrategy.rules[Seasonality.High].map((rule) => ({
									...rule,
									bookDaysInterval: {
										...rule.bookDaysInterval,
										occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
											from: interval.from / 100,
											to: interval.to / 100,
											expectedChange: {
												type: interval.expectedChange.type,
												value:
													interval.expectedChange.type === 'percentage'
														? interval.expectedChange.value / 100
														: interval.expectedChange.value,
											},
										})),
									},
								})),
							},
						},
					},
				})
			)
		},
		setStrategy(res: GetAccommodationStrategyResponse) {
			if (!res.isStrategyLoading) {
				this.isLoadingStrategy = false
			}

			const oldSelectedPeriodIndex =
				this.selectedPeriodIndex === null
					? null
					: this.editableStrategy.periods.findIndex((period) => period.id === this.selectedPeriodIndex)
			if (res.strategy.accommodationId === this.currentStrategy.accommodationId) {
				this.defaultStrategySettings = res.defaultStrategySettings

				const uniquePeriods = res.strategy.periods.map((period) => {
					return {
						id: crypto.randomUUID(),
						dateRange: period.dateRange,
						settings: period.settings,
					}
				})

				this.currentStrategy = {
					accommodationId: res.strategy.accommodationId,
					baseStrategy: {
						averagePrice: Math.round(res.strategy.baseStrategy.averagePrice * 100),
						priceDecrease: res.strategy.baseStrategy.priceDecrease * 10,
						priceIncrease: res.strategy.baseStrategy.priceIncrease * 10,
						isMarketDemandEnabled: res.strategy.baseStrategy.isMarketDemandEnabled,
						marketDemand: res.strategy.baseStrategy.marketDemand,
						advancedExpertUserAveragePrice: res.strategy.baseStrategy.isAdvancedExpertUserEnabled
							? Math.round(
									(res.strategy.baseStrategy.advancedExpertUserAveragePrice ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
								)
							: Math.round(
									(res.strategy.baseStrategy.expertUserAveragePrice ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
								),
						expertUserAveragePrice: Math.round(
							(res.strategy.baseStrategy.expertUserAveragePrice ??
								res.strategy.baseStrategy.averagePrice ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
						),
						isAdvancedExpertUserEnabled: res.strategy.baseStrategy.isAdvancedExpertUserEnabled,
						drop: {
							[Seasonality.Low]: res.strategy.baseStrategy.isAdvancedExpertUserEnabled
								? ((res.strategy?.baseStrategy?.drop && res.strategy?.baseStrategy?.drop[Seasonality.Low] * 10) ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low)
								: res.strategy.baseStrategy.priceDecrease * -10,
							[Seasonality.High]: res.strategy.baseStrategy.isAdvancedExpertUserEnabled
								? ((res.strategy?.baseStrategy?.drop && res.strategy?.baseStrategy?.drop[Seasonality.High] * 10) ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high)
								: res.strategy.baseStrategy.priceDecrease * -10,
						},
						pickup: {
							[Seasonality.Low]: res.strategy.baseStrategy.isAdvancedExpertUserEnabled
								? ((res.strategy?.baseStrategy?.pickup && res.strategy?.baseStrategy?.pickup[Seasonality.Low] * 10) ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low)
								: res.strategy.baseStrategy.priceIncrease * 10,
							[Seasonality.High]: res.strategy.baseStrategy.isAdvancedExpertUserEnabled
								? ((res.strategy?.baseStrategy?.pickup && res.strategy?.baseStrategy?.pickup[Seasonality.High] * 10) ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high)
								: res.strategy.baseStrategy.priceIncrease * 10,
						},
						eventsAndHolidaysImpact:
							res.strategy.baseStrategy?.eventsAndHolidaysImpact !== undefined
								? utilNumber.remapValue(res.strategy.baseStrategy?.eventsAndHolidaysImpact, [0, 2], [0, 20])
								: DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
						dayOfTheWeekMap: {
							[Seasonality.Low]: {
								[DayOfTheWeek.Monday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Monday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday]) * 100
								),
								[DayOfTheWeek.Tuesday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Tuesday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday]) * 100
								),
								[DayOfTheWeek.Wednesday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Wednesday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday]) * 100
								),
								[DayOfTheWeek.Thursday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Thursday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday]) * 100
								),
								[DayOfTheWeek.Friday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Friday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday]) * 100
								),
								[DayOfTheWeek.Saturday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Saturday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday]) * 100
								),
								[DayOfTheWeek.Sunday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Sunday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday]) * 100
								),
							},
							[Seasonality.High]: {
								[DayOfTheWeek.Monday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Monday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday]) * 100
								),
								[DayOfTheWeek.Tuesday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday]) * 100
								),
								[DayOfTheWeek.Wednesday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday]) * 100
								),
								[DayOfTheWeek.Thursday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday]) * 100
								),
								[DayOfTheWeek.Friday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday]) * 100
								),
								[DayOfTheWeek.Saturday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday]) * 100
								),
								[DayOfTheWeek.Sunday]: Math.round(
									(res.strategy?.baseStrategy?.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ??
										res.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday]) * 100
								),
							},
						},
						rules: {
							[Seasonality.Low]: res.strategy.baseStrategy.rules[Seasonality.Low].map((rule) => {
								return {
									id: rule.id,
									bookDaysInterval: {
										...rule.bookDaysInterval,
										fromError: false,
										fromDirty: false,
										toError: false,
										toDirty: false,
										occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => {
											return {
												id: v7(),
												from: Math.round(interval.from * 100),
												to: Math.round(interval.to * 100),
												fromError: false,
												fromDirty: false,
												toError: false,
												toDirty: false,
												expectedChange: {
													type: interval.expectedChange.type,
													value:
														interval.expectedChange.type === 'percentage'
															? Math.round(interval.expectedChange.value * 100)
															: interval.expectedChange.value,
												},
											}
										}),
									},
								}
							}),
							[Seasonality.High]: res.strategy.baseStrategy.rules[Seasonality.High].map((rule) => {
								return {
									id: rule.id,
									bookDaysInterval: {
										...rule.bookDaysInterval,
										fromError: false,
										fromDirty: false,
										toError: false,
										toDirty: false,
										occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => {
											return {
												id: v7(),
												from: Math.round(interval.from * 100),
												to: Math.round(interval.to * 100),
												fromError: false,
												fromDirty: false,
												toError: false,
												toDirty: false,
												expectedChange: {
													type: interval.expectedChange.type,
													value:
														interval.expectedChange.type === 'percentage'
															? Math.round(interval.expectedChange.value * 100)
															: interval.expectedChange.value,
												},
											}
										}),
									},
								}
							}),
						},
					},
					periods: uniquePeriods.map((period) => {
						return {
							id: period.id,
							dateRange: period.dateRange,
							settings: {
								averagePrice: Math.round(
									(period.settings.averagePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
								),
								priceDecrease: period.settings.priceDecrease * 10,
								priceIncrease: period.settings.priceIncrease * 10,
								isAdvancedExpertUserEnabled: period.settings.isAdvancedExpertUserEnabled,
								advancedExpertUserAveragePrice: period.settings.isAdvancedExpertUserEnabled
									? Math.round(
											(period.settings.advancedExpertUserAveragePrice ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
										)
									: Math.round(
											(period.settings.expertUserAveragePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
										),
								expertUserAveragePrice: Math.round(
									(period.settings.expertUserAveragePrice ??
										period.settings.averagePrice ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
								),
								isMarketDemandEnabled: period.settings.isMarketDemandEnabled,
								marketDemand: period.settings.marketDemand,
								drop: {
									[Seasonality.Low]: period.settings.isAdvancedExpertUserEnabled
										? (period.settings.drop && period.settings.drop[Seasonality.Low] * 10) ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low
										: period.settings.priceDecrease * -10,
									[Seasonality.High]: period.settings.isAdvancedExpertUserEnabled
										? (period.settings.drop && period.settings.drop[Seasonality.High] * 10) ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high
										: period.settings.priceDecrease * -10,
								},
								pickup: {
									[Seasonality.Low]: period.settings.isAdvancedExpertUserEnabled
										? (period.settings.pickup && period.settings.pickup[Seasonality.Low] * 10) ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low
										: period.settings.priceIncrease * 10,
									[Seasonality.High]: period.settings.isAdvancedExpertUserEnabled
										? (period.settings.pickup && period.settings.pickup[Seasonality.High] * 10) ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high
										: period.settings.priceIncrease * 10,
								},
								eventsAndHolidaysImpact: period.settings?.eventsAndHolidaysImpact
									? utilNumber.remapValue(period.settings?.eventsAndHolidaysImpact, [0, 2], [0, 20])
									: DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
								startingPrices: period.settings.startingPrices,
								dayOfTheWeekMap: {
									[Seasonality.Low]: {
										[DayOfTheWeek.Monday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Monday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday]) * 100
										),
										[DayOfTheWeek.Tuesday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Tuesday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday]) * 100
										),
										[DayOfTheWeek.Wednesday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Wednesday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday]) * 100
										),
										[DayOfTheWeek.Thursday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Thursday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday]) * 100
										),
										[DayOfTheWeek.Friday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Friday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday]) * 100
										),
										[DayOfTheWeek.Saturday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Saturday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday]) * 100
										),
										[DayOfTheWeek.Sunday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Sunday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday]) * 100
										),
									},
									[Seasonality.High]: {
										[DayOfTheWeek.Monday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Monday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday]) * 100
										),
										[DayOfTheWeek.Tuesday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday]) * 100
										),
										[DayOfTheWeek.Wednesday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday]) *
												100
										),
										[DayOfTheWeek.Thursday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday]) * 100
										),
										[DayOfTheWeek.Friday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday]) * 100
										),
										[DayOfTheWeek.Saturday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday]) * 100
										),
										[DayOfTheWeek.Sunday]: Math.round(
											(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ??
												this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] ??
												DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday]) * 100
										),
									},
								},
								rules: {
									[Seasonality.Low]: period.settings.rules[Seasonality.Low].map((rule) => {
										return {
											id: rule.id,
											bookDaysInterval: {
												...rule.bookDaysInterval,
												occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => {
													return {
														id: rule.id,
														bookDaysInterval: {
															...rule.bookDaysInterval,
															fromError: false,
															fromDirty: false,
															toError: false,
															toDirty: false,
															occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => {
																return {
																	id: v7(),
																	from: Math.round(interval.from * 100),
																	to: Math.round(interval.to * 100),
																	fromError: false,
																	fromDirty: false,
																	toError: false,
																	toDirty: false,
																	expectedChange: {
																		type: interval.expectedChange.type,
																		value:
																			interval.expectedChange.type === 'percentage'
																				? Math.round(interval.expectedChange.value * 100)
																				: interval.expectedChange.value,
																	},
																}
															}),
														},
													}
												}),
											},
										}
									}),
									[Seasonality.High]: period.settings.rules[Seasonality.High].map((rule) => {
										return {
											id: rule.id,
											bookDaysInterval: {
												...rule.bookDaysInterval,
												fromError: false,
												fromDirty: false,
												toError: false,
												toDirty: false,
												occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => {
													return {
														id: v7(),
														from: Math.round(interval.from * 100),
														to: Math.round(interval.to * 100),
														fromError: false,
														fromDirty: false,
														toError: false,
														toDirty: false,
														expectedChange: {
															type: interval.expectedChange.type,
															value:
																interval.expectedChange.type === 'percentage'
																	? Math.round(interval.expectedChange.value * 100)
																	: interval.expectedChange.value,
														},
													}
												}),
											},
										}
									}),
								},
							},
						}
					}),
				}

				const isColumnLayout = useWindowSize().width.value < 1280

				if (oldSelectedPeriodIndex === null) {
					this.selectedPeriodIndex = null
				} else {
					this.selectedPeriodIndex =
						oldSelectedPeriodIndex > -1
							? this.currentStrategy.periods[oldSelectedPeriodIndex]?.id
							: isColumnLayout
								? null
								: undefined
				}

				this.editableStrategy = this.previewedStrategy = _.cloneDeep(this.currentStrategy)

				this.editableStrategy.periods = this.editableStrategy.periods.filter(
					(period) => !utilDate.isBeforeToday(period.dateRange.to)
				)

				this.setNewPeriodDefaultDate()
			}
		},
		setStrategiesPricePredictions(res: GetPricesPredictionResponse) {
			this.isLoadingStrategy = false
			this.isLoadingPricePredictions = false

			if (res.accommodationId === this.editableStrategy.accommodationId) {
				this.pricePredictions.set(res.strategyType, res.roomTypePrices)
				this.previewedStrategy = _.cloneDeep(this.editableStrategy)

				const daysLimitForPriceSeries = addDays(new Date(), 500)

				res.roomTypePrices.forEach((roomTypePricesPrediction) => {
					if (res.strategyType === StrategyType.Current) {
						const currentPrices: StrategyChartLineDataPoint[] = []
						const publishedPrices: StrategyChartLineDataPoint[] = []

						roomTypePricesPrediction.prices.forEach((pricePrediction) => {
							if (differenceInDays(pricePrediction.date, daysLimitForPriceSeries) <= 0) {
								currentPrices.push({
									time: pricePrediction.date,
									value: pricePrediction.suggestedPrice,
								})
								publishedPrices.push({
									time: pricePrediction.date,
									value: pricePrediction.publishedPrice,
								})
							}
						})

						this.pricePredictionsFormatted[PricePredictionFormattedType.Current][roomTypePricesPrediction.id] =
							currentPrices
						this.pricePredictionsFormatted[PricePredictionFormattedType.Published][roomTypePricesPrediction.id] =
							publishedPrices
					}
					if (res.strategyType === StrategyType.Preview) {
						const currentPricesPreview: StrategyChartLineDataPoint[] = []
						const publishedPricesPreview: StrategyChartLineDataPoint[] = []

						roomTypePricesPrediction.prices.forEach((pricePrediction) => {
							currentPricesPreview.push({
								time: pricePrediction.date,
								value: pricePrediction.suggestedPrice,
							})
							publishedPricesPreview.push({
								time: pricePrediction.date,
								value: pricePrediction.publishedPrice,
							})
						})

						this.pricePredictionsFormatted[PricePredictionFormattedType.CurrentPreview][roomTypePricesPrediction.id] =
							currentPricesPreview
						this.pricePredictionsFormatted[PricePredictionFormattedType.PublishedPreview][roomTypePricesPrediction.id] =
							publishedPricesPreview
					}
				})
			}
		},
		setStartingPrices(res: GetAccommodationBasePricesResponse) {
			const currentDate = new Date()
			const daysLimitForPriceSeries = addDays(currentDate, 500)
			const formattedRoomTypes: BasePricePreviewAggregation[] = []

			res.roomTypes.forEach((roomTypeWithPrices) => {
				const pricesPrediction: { date: Date; price: number }[] = []

				roomTypeWithPrices.basePrices.forEach((pricesWithDateRange) => {
					const datesInDateRange = eachDayOfInterval({
						start: pricesWithDateRange.dateRange.from,
						end: pricesWithDateRange.dateRange.to,
					})

					datesInDateRange.forEach((date) => {
						if (
							differenceInCalendarDays(date, currentDate) >= 0 &&
							differenceInCalendarDays(date, daysLimitForPriceSeries) <= 0
						) {
							pricesPrediction.push({
								date: date,
								price: pricesWithDateRange.price,
							})
						}
					})
				})

				formattedRoomTypes.push({
					id: roomTypeWithPrices.id,
					prices: pricesPrediction,
				})
			})

			this.pricePredictions.set(PricePredictionType.Starting, formattedRoomTypes)

			formattedRoomTypes.forEach((roomTypePricesPrediction) => {
				this.pricePredictionsFormatted[PricePredictionType.Starting][roomTypePricesPrediction.id] =
					roomTypePricesPrediction.prices.map((pricePrediction) => {
						return {
							time: pricePrediction.date,
							value: pricePrediction.price,
						}
					})
			})
		},
		setActiveChartSeries(activeChartSeries: PriceSeries[]) {
			this.activeChartSeries = activeChartSeries
		},
		setHowDoesItWorkMarketCorrection(res: GetHowDoesItWorkMarketCorrectionResponse) {
			this.howDoesItWorkMarketCorrection = res.marketCorrection.reduce((acc: Record<string, number[]>, item) => {
				acc[`${Math.round(item.accommodationOccupancy * 100)}-${item.influence}`] = item.correction
				return acc
			}, {})
		},
		addEditablePeriod(dateRange: Range<Date>) {
			const periodId = crypto.randomUUID()

			this.editableStrategy.periods.push({
				id: periodId,
				dateRange: dateRange,
				settings: cloneDeep(this.editableStrategy.baseStrategy),
			})
			this.selectedPeriodIndex = periodId
		},
		removeEditablePeriod(id: string) {
			this.selectedPeriodIndex = undefined
			const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === id)

			if (periodIndex > -1) {
				this.editableStrategy.periods.splice(periodIndex, 1)
			}

			this.setNewPeriodDefaultDate()
		},
		updateEditablePeriod(id: string, dateRange: Range<Date>) {
			const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === id)

			if (periodIndex > -1) {
				this.editableStrategy.periods[periodIndex].dateRange = dateRange
			}
		},
		updateSelectedPeriod(periodId: string | null | undefined) {
			const isColumnLayout = useWindowSize().width.value < 1280

			if (this.selectedPeriodIndex === periodId && !isColumnLayout) {
				this.selectedPeriodIndex = undefined
			} else {
				this.selectedPeriodIndex = periodId
			}
		},
		undoStrategyChanges() {
			this.setIsAdvancedMode(false)
			this.pricePredictions.delete(StrategyType.Preview)

			const uniqueTrackingId = crypto.randomUUID()

			if (!isEqual(this.currentStrategy.baseStrategy, this.editableStrategy.baseStrategy)) {
				const isAdvanced = this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled
				let trackingData: { [key: string]: any } = {
					change_type: StrategyActionTypes.Preview,
					strategy_type: StrategyTrackingTypes.Base,
					selected_days: 500,
					uuid: uniqueTrackingId,
					str_mode: isAdvanced ? 'advanced' : 'basic',
				}

				if (isAdvanced) {
					trackingData = {
						...trackingData,
						aeu_price_decrease_low_season: this.editableStrategy?.baseStrategy?.drop?.low
							? this.editableStrategy.baseStrategy.drop.low / -10
							: undefined,
						aeu_price_decrease_high_season: this.editableStrategy?.baseStrategy?.drop?.high
							? this.editableStrategy.baseStrategy.drop.high / -10
							: undefined,
						aeu_price_increase_low_season: this.editableStrategy?.baseStrategy?.pickup?.low
							? this.editableStrategy.baseStrategy.pickup.low / 10
							: undefined,
						aeu_price_increase_high_season: this.editableStrategy?.baseStrategy?.pickup?.high
							? this.editableStrategy.baseStrategy.pickup.high / 10
							: undefined,
						aeu_events: this.editableStrategy.baseStrategy.eventsAndHolidaysImpact,
						aeu_dow_low_season: Object.values(this.editableStrategy?.baseStrategy?.dayOfTheWeekMap?.low ?? {}),
						aeu_dow_high_season: Object.values(this.editableStrategy?.baseStrategy?.dayOfTheWeekMap?.high ?? {}),
						aeu_starting_prices: this.editableStrategy.baseStrategy.averagePrice,
					}
				} else {
					trackingData = {
						...trackingData,
						price_decrease: this.editableStrategy.baseStrategy.priceDecrease / 10,
						price_increase: this.editableStrategy.baseStrategy.priceIncrease / 10,
						avg_price: this.editableStrategy.baseStrategy.averagePrice,
					}
				}

				utilTracking.track(TrackingMessages.STRATEGY_CHANGE, trackingData)
			}

			this.currentStrategy.periods
				.filter((period) => {
					!utilDate.isBeforeToday(period.dateRange.to)
				})
				.forEach((period) => {
					const samePeriodInPreviousStrategy = this.editableStrategy.periods.find(
						(editablePeriod) => editablePeriod.id === period.id
					)

					if (!samePeriodInPreviousStrategy || !isEqual(samePeriodInPreviousStrategy, period)) {
						const isPeriodAdvanced = period.settings.isAdvancedExpertUserEnabled
						let trackingData: { [key: string]: any } = {
							change_type: StrategyActionTypes.Preview,
							strategy_type: StrategyTrackingTypes.Period,
							selected_days: differenceInCalendarDays(period.dateRange.to, period.dateRange.from),
							uuid: uniqueTrackingId,
							str_mode: isPeriodAdvanced ? 'advanced' : 'basic',
						}

						if (isPeriodAdvanced) {
							trackingData = {
								...trackingData,
								aeu_price_decrease_low_season: period.settings?.drop?.low ? period.settings.drop.low / -10 : undefined,
								aeu_price_decrease_high_season: period.settings?.drop?.high
									? period.settings.drop.high / -10
									: undefined,
								aeu_price_increase_low_season: period.settings?.pickup?.low
									? period.settings.pickup.low / 10
									: undefined,
								aeu_price_increase_high_season: period.settings?.pickup?.high
									? period.settings.pickup.high / 10
									: undefined,
								aeu_events: period.settings.eventsAndHolidaysImpact,
								aeu_dow_low_season: Object.values(period.settings.dayOfTheWeekMap?.low ?? {}),
								aeu_dow_high_season: Object.values(period.settings.dayOfTheWeekMap?.high ?? {}),
								aeu_starting_prices: period.settings.averagePrice,
							}
						} else {
							trackingData = {
								...trackingData,
								price_decrease: period.settings.priceDecrease / 10,
								price_increase: period.settings.priceIncrease / 10,
								avg_price: period.settings.averagePrice,
							}
						}

						utilTracking.track(TrackingMessages.STRATEGY_CHANGE, trackingData)
					}
				})

			// Check also for removed periods
			this.editableStrategy.periods
				.filter((period) => {
					!utilDate.isBeforeToday(period.dateRange.to)
				})
				.forEach((period) => {
					const samePeriodInCurrentStrategy = this.currentStrategy.periods.find(
						(previewedPeriod) => previewedPeriod.id === period.id
					)

					if (!samePeriodInCurrentStrategy) {
						utilTracking.track(TrackingMessages.STRATEGY_CHANGE, {
							change_type: StrategyActionTypes.Preview,
							strategy_type: StrategyTrackingTypes.Period,
							selected_days: differenceInCalendarDays(period.dateRange.to, period.dateRange.from),
							uuid: uniqueTrackingId,
						})
					}
				})

			const filterdCurrentStrategy = {
				...this.currentStrategy,
				periods: this.currentStrategy.periods.filter((period) => !utilDate.isBeforeToday(period.dateRange.to)),
			}

			this.editableStrategy = _.cloneDeep(filterdCurrentStrategy)
			this.previewedStrategy = _.cloneDeep(filterdCurrentStrategy)
			this.selectedPeriodIndex = undefined
		},
		saveStrategyChanges() {
			this.isLoadingStrategy = true

			this.pricePredictions.delete(StrategyType.Current)
			this.pricePredictions.delete(StrategyType.Preview)

			const isBaseStrategyStatusChanged = !isEqual(
				this.currentStrategy.baseStrategy.isAdvancedExpertUserEnabled,
				this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled
			)

			const newStrategy = {
				...this.editableStrategy,
				baseStrategy: {
					priceIncrease: this.editableStrategy.baseStrategy.priceIncrease / 10,
					priceDecrease: this.editableStrategy.baseStrategy.priceDecrease / 10,
					averagePrice:
						((this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled
							? this.editableStrategy.baseStrategy.expertUserAveragePrice
							: this.editableStrategy.baseStrategy.expertUserAveragePrice) ??
							DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
					isAdvancedExpertUserEnabled: this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled,
					isMarketDemandEnabled: this.editableStrategy.baseStrategy.isMarketDemandEnabled,
					marketDemand: this.editableStrategy.baseStrategy.marketDemand,
					advancedExpertUserAveragePrice:
						(this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice ??
							DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
					expertUserAveragePrice:
						(this.editableStrategy.baseStrategy.expertUserAveragePrice ??
							DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
					drop: {
						[Seasonality.Low]:
							Math.abs(this.editableStrategy?.baseStrategy?.drop?.low ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low) /
							10,
						[Seasonality.High]:
							Math.abs(this.editableStrategy.baseStrategy.drop?.high ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high) /
							10,
					},
					pickup: {
						[Seasonality.Low]:
							(this.editableStrategy.baseStrategy?.pickup?.low ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low) / 10,
						[Seasonality.High]:
							(this.editableStrategy.baseStrategy.pickup?.high ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high) / 10,
					},
					eventsAndHolidaysImpact: utilNumber.remapValue(
						this.editableStrategy.baseStrategy.eventsAndHolidaysImpact ??
							DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
						[0, 20],
						[0, 2]
					),

					dayOfTheWeekMap: {
						[Seasonality.Low]: {
							[DayOfTheWeek.Monday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Monday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Monday]) / 100,
							[DayOfTheWeek.Tuesday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Tuesday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Tuesday]) / 100,
							[DayOfTheWeek.Wednesday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Wednesday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Wednesday]) / 100,
							[DayOfTheWeek.Thursday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Thursday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Thursday]) / 100,
							[DayOfTheWeek.Friday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Friday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Friday]) / 100,
							[DayOfTheWeek.Saturday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Saturday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Saturday]) / 100,
							[DayOfTheWeek.Sunday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Sunday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.Low][DayOfTheWeek.Sunday]) / 100,
						},
						[Seasonality.High]: {
							[DayOfTheWeek.Monday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday]) / 100,
							[DayOfTheWeek.Tuesday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday]) / 100,
							[DayOfTheWeek.Wednesday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday]) / 100,
							[DayOfTheWeek.Thursday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday]) / 100,
							[DayOfTheWeek.Friday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday]) / 100,
							[DayOfTheWeek.Saturday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday]) / 100,
							[DayOfTheWeek.Sunday]:
								(this.editableStrategy.baseStrategy.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ??
									this.defaultStrategySettings?.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday]) / 100,
						},
					},

					rules: {
						[Seasonality.Low]: this.editableStrategy.baseStrategy.rules[Seasonality.Low].map((rule) => ({
							...rule,
							bookDaysInterval: {
								...rule.bookDaysInterval,
								occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
									from: interval.from / 100,
									to: interval.to / 100,
									expectedChange: {
										type: interval.expectedChange.type,
										value:
											interval.expectedChange.type === 'percentage'
												? interval.expectedChange.value / 100
												: interval.expectedChange.value,
									},
								})),
							},
						})),
						[Seasonality.High]: this.editableStrategy.baseStrategy.rules[Seasonality.High].map((rule) => ({
							...rule,
							bookDaysInterval: {
								...rule.bookDaysInterval,
								occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
									from: interval.from / 100,
									to: interval.to / 100,
									expectedChange: {
										type: interval.expectedChange.type,
										value:
											interval.expectedChange.type === 'percentage'
												? interval.expectedChange.value / 100
												: interval.expectedChange.value,
									},
								})),
							},
						})),
					},
				},
				periods: this.editableStrategy.periods.map((period) => {
					return {
						...period,
						settings: {
							priceIncrease: period.settings.priceIncrease / 10,
							priceDecrease: period.settings.priceDecrease / 10,
							advancedExpertUserAveragePrice:
								(period.settings.advancedExpertUserAveragePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) /
								100,
							isMarketDemandEnabled: period.settings.isMarketDemandEnabled,
							marketDemand: period.settings.marketDemand,
							averagePrice:
								((period.settings.isAdvancedExpertUserEnabled
									? period.settings.advancedExpertUserAveragePrice
									: period.settings.expertUserAveragePrice) ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
							expertUserAveragePrice:
								(period.settings.expertUserAveragePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) / 100,
							isAdvancedExpertUserEnabled: period.settings.isAdvancedExpertUserEnabled,
							drop: {
								[Seasonality.Low]:
									Math.abs(period.settings?.drop?.low || DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low) / 10,
								[Seasonality.High]:
									Math.abs(period.settings?.drop?.high || DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high) / 10,
							},
							pickup: {
								[Seasonality.Low]:
									Math.abs(period.settings?.pickup?.low || DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low) / 10,
								[Seasonality.High]:
									Math.abs(period.settings?.pickup?.high || DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high) / 10,
							},
							eventsAndHolidaysImpact: parseFloat(
								utilNumber
									.remapValue(
										period.settings.eventsAndHolidaysImpact ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact,
										[0, 20],
										[0, 2]
									)
									.toFixed(1)
							),
							dayOfTheWeekMap: {
								[Seasonality.Low]: {
									[DayOfTheWeek.Monday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Monday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Monday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Monday]) / 100,
									[DayOfTheWeek.Tuesday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Tuesday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Tuesday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Tuesday]) / 100,
									[DayOfTheWeek.Wednesday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Wednesday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Wednesday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Wednesday]) / 100,
									[DayOfTheWeek.Thursday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Thursday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Thursday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Thursday]) / 100,
									[DayOfTheWeek.Friday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Friday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Friday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Friday]) / 100,
									[DayOfTheWeek.Saturday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Saturday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Saturday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Saturday]) / 100,
									[DayOfTheWeek.Sunday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.Low][DayOfTheWeek.Sunday] ||
											period.settings.dayOfTheWeekMap?.low[DayOfTheWeek.Sunday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Sunday]) / 100,
								},
								[Seasonality.High]: {
									[DayOfTheWeek.Monday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Monday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Monday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Monday]) / 100,
									[DayOfTheWeek.Tuesday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Tuesday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Tuesday]) / 100,
									[DayOfTheWeek.Wednesday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Wednesday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Wednesday]) / 100,
									[DayOfTheWeek.Thursday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Thursday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Thursday]) / 100,
									[DayOfTheWeek.Friday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Friday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Friday]) / 100,
									[DayOfTheWeek.Saturday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Saturday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Saturday]) / 100,
									[DayOfTheWeek.Sunday]:
										(period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ||
											period.settings.dayOfTheWeekMap?.[Seasonality.High][DayOfTheWeek.Sunday] ||
											DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap[Seasonality.High][DayOfTheWeek.Sunday]) / 100,
								},
							},

							rules: {
								[Seasonality.Low]: period.settings.rules[Seasonality.Low].map((rule) => ({
									...rule,
									bookDaysInterval: {
										...rule.bookDaysInterval,
										occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
											from: interval.from / 100,
											to: interval.to / 100,
											expectedChange: {
												type: interval.expectedChange.type,
												value:
													interval.expectedChange.type === 'percentage'
														? interval.expectedChange.value / 100
														: interval.expectedChange.value,
											},
										})),
									},
								})),
								[Seasonality.High]: period.settings.rules[Seasonality.High].map((rule) => ({
									...rule,
									bookDaysInterval: {
										...rule.bookDaysInterval,
										occupancyInterval: rule.bookDaysInterval.occupancyInterval.map((interval) => ({
											from: interval.from / 100,
											to: interval.to / 100,
											expectedChange: {
												type: interval.expectedChange.type,
												value:
													interval.expectedChange.type === 'percentage'
														? interval.expectedChange.value / 100
														: interval.expectedChange.value,
											},
										})),
									},
								})),
							},
						},
					}
				}),
			}

			const uniqueTrackingId = crypto.randomUUID()
			let isSavingAdvancedPeriod = false
			if (!isEqual(this.editableStrategy.baseStrategy, this.currentStrategy.baseStrategy)) {
				const isAdvanced = newStrategy.baseStrategy.isAdvancedExpertUserEnabled
				isSavingAdvancedPeriod = isAdvanced
				let trackingData: { [key: string]: any } = {
					change_type: StrategyActionTypes.Confirm,
					strategy_type: StrategyTrackingTypes.Base,
					selected_days: 500,
					uuid: uniqueTrackingId,
					str_mode: isAdvanced ? 'advanced' : 'basic',
				}

				if (
					this.editableStrategy.baseStrategy.isMarketDemandEnabled !==
					this.currentStrategy.baseStrategy.isMarketDemandEnabled
				) {
					trackingData = {
						...trackingData,
						is_market_correction_enabled: this.editableStrategy.baseStrategy.isMarketDemandEnabled,
					}
				}
				if (
					(this.editableStrategy.baseStrategy.isMarketDemandEnabled !==
						this.currentStrategy.baseStrategy.isMarketDemandEnabled &&
						this.editableStrategy.baseStrategy.isMarketDemandEnabled) ||
					this.editableStrategy.baseStrategy.marketDemand !== this.currentStrategy.baseStrategy.marketDemand
				) {
					trackingData = {
						...trackingData,
						market_correction_level: this.editableStrategy.baseStrategy.marketDemand,
					}
				}

				if (isAdvanced) {
					trackingData = {
						...trackingData,
						aeu_starting_prices: this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice,
						aeu_price_decrease_low_season: this.editableStrategy.baseStrategy?.drop?.low
							? this.editableStrategy.baseStrategy.drop.low / -10
							: undefined,
						aeu_price_decrease_high_season: this.editableStrategy.baseStrategy.drop?.high
							? this.editableStrategy.baseStrategy.drop.high / -10
							: undefined,
						aeu_price_increase_low_season: this.editableStrategy.baseStrategy?.pickup?.low
							? this.editableStrategy.baseStrategy?.pickup?.low / 10
							: undefined,
						aeu_price_increase_high_season: this.editableStrategy.baseStrategy?.pickup?.high
							? this.editableStrategy.baseStrategy.pickup.high / 10
							: undefined,
						aeu_events: this.editableStrategy.baseStrategy.eventsAndHolidaysImpact / 10,
						aeu_dow_low_season: Object.values(this.editableStrategy.baseStrategy?.dayOfTheWeekMap?.low ?? {}),
						aeu_dow_high_season: Object.values(this.editableStrategy?.baseStrategy?.dayOfTheWeekMap?.high ?? {}),
						custom_rules: isEqual(this.editableStrategy.baseStrategy.rules, this.currentStrategy.baseStrategy.rules)
							? false
							: true,
					}
				} else {
					trackingData = {
						...trackingData,
						price_decrease: this.editableStrategy.baseStrategy.priceDecrease / 10,
						price_increase: this.editableStrategy.baseStrategy.priceIncrease / 10,
						avg_price: this.editableStrategy.baseStrategy.averagePrice,
					}
				}

				utilTracking.track(TrackingMessages.STRATEGY_CONFIRM, trackingData)
			}

			this.editableStrategy.periods.forEach((period) => {
				const samePeriodInPreviousStrategy = this.currentStrategy.periods.find(
					(editablePeriod) => editablePeriod.id === period.id
				)

				if (!samePeriodInPreviousStrategy || !isEqual(samePeriodInPreviousStrategy, period)) {
					const isPeriodAdvanced = period.settings.isAdvancedExpertUserEnabled
					isSavingAdvancedPeriod = isSavingAdvancedPeriod || isPeriodAdvanced
					let trackingData: { [key: string]: any } = {
						change_type: StrategyActionTypes.Confirm,
						strategy_type: StrategyTrackingTypes.Period,
						selected_days: differenceInCalendarDays(period.dateRange.to, period.dateRange.from),
						uuid: uniqueTrackingId,
						str_mode: isPeriodAdvanced ? 'advanced' : 'basic',
					}

					if (period.settings.isMarketDemandEnabled !== samePeriodInPreviousStrategy?.settings.isMarketDemandEnabled) {
						trackingData = {
							...trackingData,
							is_market_correction_enabled: period.settings.isMarketDemandEnabled,
						}
					}
					if (
						(period.settings.isMarketDemandEnabled !== samePeriodInPreviousStrategy?.settings.isMarketDemandEnabled &&
							period.settings.isMarketDemandEnabled) ||
						period.settings.marketDemand !== samePeriodInPreviousStrategy?.settings.marketDemand
					) {
						trackingData = {
							...trackingData,
							market_correction_level: period.settings.marketDemand,
						}
					}

					if (isPeriodAdvanced) {
						trackingData = {
							...trackingData,
							aeu_starting_prices: period.settings.averagePrice,
							aeu_price_decrease_low_season: period.settings?.drop?.low ? period.settings.drop.low / -10 : undefined,
							aeu_price_decrease_high_season: period.settings?.drop?.high ? period.settings.drop.high / -10 : undefined,
							aeu_price_increase_low_season: period.settings?.pickup?.low ? period.settings.pickup.low / 10 : undefined,
							aeu_price_increase_high_season: period.settings?.pickup?.high
								? period.settings.pickup.high / 10
								: undefined,
							aeu_events: period.settings.eventsAndHolidaysImpact / 10,
							aeu_dow_low_season: Object.values(period.settings.dayOfTheWeekMap?.low ?? {}),
							aeu_dow_high_season: Object.values(period.settings.dayOfTheWeekMap?.high ?? {}),
							custom_rules: isEqual(period.settings.rules, samePeriodInPreviousStrategy?.settings.rules) ? false : true,
						}
					} else {
						trackingData = {
							...trackingData,
							price_decrease: period.settings.priceDecrease / 10,
							price_increase: period.settings.priceIncrease / 10,
							avg_price: period.settings.averagePrice,
						}
					}

					utilTracking.track(TrackingMessages.STRATEGY_CONFIRM, trackingData)
				}
			})

			// Check also for removed periods
			this.currentStrategy.periods.forEach((period) => {
				const samePeriodInCurrentStrategy = this.editableStrategy.periods.find(
					(previewedPeriod) => previewedPeriod.id === period.id
				)

				if (!samePeriodInCurrentStrategy) {
					utilTracking.track(TrackingMessages.STRATEGY_CONFIRM, {
						change_type: StrategyActionTypes.Confirm,
						strategy_type: StrategyTrackingTypes.Period,
						selected_days: differenceInCalendarDays(period.dateRange.to, period.dateRange.from),
						uuid: uniqueTrackingId,
					})
				}
			})

			utilNetwork.simpleRequest(
				new UpdateAccommodationStrategyNetworkObject({
					removeModifiers: false,
					strategy: newStrategy as Strategy,
				})
			)

			if (isSavingAdvancedPeriod) {
				useFeedbackStore().requestFeedback(FeedbackId.StrategyAdvancedChange)
			} else {
				useFeedbackStore().requestFeedback(FeedbackId.StrategyChange)
			}
		},
		resetStrategyChanges() {
			this.isLoadingStrategy = true

			this.pricePredictions.delete(StrategyType.Current)
			this.pricePredictions.delete(StrategyType.Preview)

			const mappedDefaultStrategy = {
				...defaultStrategy,
				baseStrategy: {
					priceIncrease: defaultStrategy.baseStrategy.priceIncrease / 10,
					priceDecrease: defaultStrategy.baseStrategy.priceDecrease / 10,
					averagePrice: defaultStrategy.baseStrategy.averagePrice,
					isAdvancedExpertUserEnabled: false,
				},
			}

			utilNetwork.simpleRequest(
				new UpdateAccommodationStrategyNetworkObject({
					removeModifiers: false,
					strategy: {
						...mappedDefaultStrategy,
						accommodationId: this.editableStrategy.accommodationId,
					},
				})
			)

			utilTracking.track(TrackingMessages.STRATEGY_CONFIRM, {
				price_decrease: mappedDefaultStrategy.baseStrategy.priceDecrease,
				price_increase: mappedDefaultStrategy.baseStrategy.priceIncrease,
				avg_price: mappedDefaultStrategy.baseStrategy.averagePrice,
				change_type: StrategyActionTypes.Reset,
				strategy_type: StrategyTrackingTypes.Base,
				selected_days: 500,
				uuid: crypto.randomUUID(),
			})

			this.isAdvancedMode = false
			this.selectedPeriodIndex = undefined
		},
		setCurrentPeriodSettings(settings: StrategySettings) {
			if (this.selectedPeriodIndex == null) {
				this.editableStrategy.baseStrategy = {
					...settings,
					expertUserAveragePrice: settings.averagePrice,
				}
			} else {
				const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === this.selectedPeriodIndex)

				if (periodIndex > -1) {
					this.editableStrategy.periods[periodIndex].settings = {
						...settings,
						expertUserAveragePrice: settings.averagePrice,
					}
				}
			}
		},
		setCurrentPeriodAdvancedSettings(settings: Partial<StrategySettings>, selectedPeriodIndex?: string) {
			const periodId = selectedPeriodIndex != undefined ? selectedPeriodIndex : this.selectedPeriodIndex

			if (periodId === null) {
				this.editableStrategy.baseStrategy = {
					...this.editableStrategy.baseStrategy,
					...settings,
					isAdvancedExpertUserEnabled: true,
				}
			} else if (periodId) {
				const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === periodId)
				if (periodIndex > -1) {
					this.editableStrategy.periods[periodIndex].settings = {
						...this.editableStrategy.periods[periodIndex].settings,
						...settings,
						isAdvancedExpertUserEnabled: true,
					}
				}
			}
		},

		updateNewPeriodStartDate(date: Date) {
			this.newPeriodStartDate = date
		},
		updateNewPeriodEndDate(date: Date) {
			this.newPeriodEndDate = date
		},

		setNewPeriodDefaultDate() {
			const periods = this.editableStrategy.periods.flatMap((period) => period.dateRange)

			this.newPeriodStartDate = new Date()
			this.newPeriodEndDate = addDays(new Date(), 7)

			periods.forEach((period) => {
				const isNewperiodStartDateOverlappingPeriod = utilDate.dateInRange(this.newPeriodStartDate, period)
				let isNewPeriodEndDateOverlappingPeriod =
					utilDate.dateInRange(this.newPeriodEndDate, period) ||
					(utilDate.isBefore(this.newPeriodStartDate, period.from) &&
						utilDate.isAfter(this.newPeriodEndDate, period.to))

				if (isNewperiodStartDateOverlappingPeriod) {
					this.newPeriodStartDate = addDays(period.to, 1)
					this.newPeriodEndDate = addDays(this.newPeriodStartDate, 7)

					isNewPeriodEndDateOverlappingPeriod = utilDate.dateInRange(this.newPeriodEndDate, period)
				}

				if (isNewPeriodEndDateOverlappingPeriod) {
					this.newPeriodEndDate = subDays(period.from, 1)
				}
			})
		},

		setIsAdvancedMode(isAdvancedMode: boolean) {
			if (this.isAdvancedExpertUserAvailable) {
				this.isAdvancedMode = isAdvancedMode
			}
		},

		getAdvancedModeParamsMapping() {
			this.advancedModeParamsMapping = undefined

			utilNetwork.simpleRequest(
				new GetAdvancedExpertUserParamsMappingNetworkObject({
					accommodationId: this.currentStrategy.accommodationId,
					strategy: {
						averagePrice: this.currentPeriodSettings.averagePrice / 100,
						priceDecrease: this.currentPeriodSettings.priceDecrease / 10,
						priceIncrease: this.currentPeriodSettings.priceIncrease / 10,
					},
				}),
				undefined,
				LoadingIds.STRATEGY_ADVANCED_EXPERT_USER_PARAMS_MAPPING
			)
		},

		setAdvancedModeParamsMapping(mapping: GetAdvancedExpertUserParamsMappingResponse) {
			this.advancedModeParamsMapping = mapping.strategy
		},

		setCurrentPeriodadvancedMappedSettings() {
			if (this.isAdvancedMode) {
				const isBaseStrategySelected = this.selectedPeriodIndex === null
				const isBaseStrategyAlreadyAdvanced = this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled

				if (isBaseStrategySelected && !isBaseStrategyAlreadyAdvanced) {
					this.editableStrategy.baseStrategy.advancedExpertUserAveragePrice =
						(this.advancedModeParamsMapping?.averagePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
					this.editableStrategy.baseStrategy.drop!.low =
						(this.advancedModeParamsMapping?.drop[0] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low) * 10
					this.editableStrategy.baseStrategy.drop!.high =
						(this.advancedModeParamsMapping?.drop[2] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high) * 10
					this.editableStrategy.baseStrategy.pickup!.low =
						(this.advancedModeParamsMapping?.pickup[0] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low) * 10
					this.editableStrategy.baseStrategy.pickup!.high =
						(this.advancedModeParamsMapping?.pickup[2] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high) * 10
					this.editableStrategy.baseStrategy.eventsAndHolidaysImpact = Math.round(
						(this.advancedModeParamsMapping?.eventsAndHolidaysImpact ??
							DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact) * 10
					)
					this.editableStrategy.baseStrategy.dayOfTheWeekMap = {
						[Seasonality.Low]: {
							[DayOfTheWeek.Monday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Monday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Monday]) * 100
							),
							[DayOfTheWeek.Tuesday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Tuesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Tuesday]) * 100
							),
							[DayOfTheWeek.Wednesday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Wednesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Wednesday]) * 100
							),
							[DayOfTheWeek.Thursday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Thursday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Thursday]) * 100
							),
							[DayOfTheWeek.Friday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Friday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Friday]) * 100
							),
							[DayOfTheWeek.Saturday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Saturday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Saturday]) * 100
							),
							[DayOfTheWeek.Sunday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Sunday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Sunday]) * 100
							),
						},
						[Seasonality.High]: {
							[DayOfTheWeek.Monday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Monday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Monday]) * 100
							),
							[DayOfTheWeek.Tuesday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Tuesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Tuesday]) * 100
							),
							[DayOfTheWeek.Wednesday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Wednesday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Wednesday]) * 100
							),
							[DayOfTheWeek.Thursday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Thursday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Thursday]) * 100
							),
							[DayOfTheWeek.Friday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Friday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Friday]) * 100
							),
							[DayOfTheWeek.Saturday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Saturday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Saturday]) * 100
							),
							[DayOfTheWeek.Sunday]: Math.round(
								(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Sunday] ??
									DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Sunday]) * 100
							),
						},
					}
					this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled = true

					this.editableStrategy.baseStrategy.rules = {
						[Seasonality.Low]: [],
						[Seasonality.High]: [],
					}
				} else {
					const period = this.editableStrategy.periods.find((period) => period.id === this.selectedPeriodIndex)
					const previewPeriod = this.previewedStrategy!.periods.find((period) => period.id === this.selectedPeriodIndex)
					const isPeriodAlreadyAdvanced = previewPeriod?.settings.isAdvancedExpertUserEnabled

					if (!isPeriodAlreadyAdvanced) {
						period!.settings.advancedExpertUserAveragePrice = period!.settings.expertUserAveragePrice
						period!.settings.drop!.low = Math.abs(period!.settings.priceDecrease)
						period!.settings.drop!.high = Math.abs(period!.settings.priceDecrease)
						period!.settings.pickup!.low = period!.settings.priceIncrease
						period!.settings.pickup!.high = period!.settings.priceIncrease

						period!.settings.advancedExpertUserAveragePrice =
							(this.advancedModeParamsMapping?.averagePrice ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.startingPrices) * 100
						period!.settings.drop[0] =
							(this.advancedModeParamsMapping?.drop[0] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.low) * 10
						period!.settings.drop[2] =
							(this.advancedModeParamsMapping?.drop[2] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.drop.high) * 10
						period!.settings.pickup[0] =
							(this.advancedModeParamsMapping?.pickup[0] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.low) * 10
						period!.settings.pickup[2] =
							(this.advancedModeParamsMapping?.pickup[2] ?? DEFAULT_STRATEGY_ADVANCED_PARAMS.pickup.high) * 10
						period!.settings.eventsAndHolidaysImpact = Math.round(
							(this.advancedModeParamsMapping?.eventsAndHolidaysImpact ??
								DEFAULT_STRATEGY_ADVANCED_PARAMS.eventsAndHolidaysImpact) * 10
						)
						period!.settings.dayOfTheWeekMap = {
							[Seasonality.Low]: {
								[DayOfTheWeek.Monday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Monday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Monday]) * 100
								),
								[DayOfTheWeek.Tuesday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Tuesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Tuesday]) * 100
								),
								[DayOfTheWeek.Wednesday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Wednesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Wednesday]) * 100
								),
								[DayOfTheWeek.Thursday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Thursday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Thursday]) * 100
								),
								[DayOfTheWeek.Friday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Friday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Friday]) * 100
								),
								[DayOfTheWeek.Saturday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Saturday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Saturday]) * 100
								),
								[DayOfTheWeek.Sunday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.low[DayOfTheWeek.Sunday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.low[DayOfTheWeek.Sunday]) * 100
								),
							},
							[Seasonality.High]: {
								[DayOfTheWeek.Monday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Monday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Monday]) * 100
								),
								[DayOfTheWeek.Tuesday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Tuesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Tuesday]) * 100
								),
								[DayOfTheWeek.Wednesday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Wednesday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Wednesday]) * 100
								),
								[DayOfTheWeek.Thursday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Thursday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Thursday]) * 100
								),
								[DayOfTheWeek.Friday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Friday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Friday]) * 100
								),
								[DayOfTheWeek.Saturday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Saturday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Saturday]) * 100
								),
								[DayOfTheWeek.Sunday]: Math.round(
									(this.advancedModeParamsMapping?.dayOfTheWeekMap?.high[DayOfTheWeek.Sunday] ??
										DEFAULT_STRATEGY_ADVANCED_PARAMS.dayOfTheWeekMap.high[DayOfTheWeek.Sunday]) * 100
								),
							},
						}

						period!.settings.isAdvancedExpertUserEnabled = true

						period!.settings.rules = {
							[Seasonality.Low]: [],
							[Seasonality.High]: [],
						}
					}
				}
			}
		},

		setCurrentPeriodCustomDropPickupRules(rules: Record<Seasonality, DropPickupRuleWithErrors[]>) {
			if (this.selectedPeriodIndex == null) {
				this.editableStrategy.baseStrategy.rules[Seasonality.Low] = structuredClone(toRaw(rules[Seasonality.Low]))
				this.editableStrategy.baseStrategy.rules[Seasonality.High] = structuredClone(toRaw(rules[Seasonality.High]))
			} else {
				const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === this.selectedPeriodIndex)

				this.editableStrategy.periods[periodIndex].settings.rules[Seasonality.Low] = structuredClone(
					toRaw(rules[Seasonality.Low])
				)
				this.editableStrategy.periods[periodIndex].settings.rules[Seasonality.High] = structuredClone(
					toRaw(rules[Seasonality.High])
				)
			}
		},
	},
	getters: {
		isAdvancedExpertUserAvailable(): boolean {
			return (
				useUserStore().getEnabledFeatures.includes(Feature.AdvancedExpertUser) &&
				useAccommodationsStore().accommodations.find(
					(accommodation) => accommodation.id === this.currentStrategy.accommodationId
				)?.pipelineVersion === 2
			)
		},

		getCurrentSeasonality(): Seasonality {
			const currentPeriod = this.selectedPeriodIndex
				? this.editableStrategy.periods.find((period) => period.id === this.selectedPeriodIndex)?.dateRange
				: null

			// for base strategy, we return the current week seasonality
			if (!currentPeriod) {
				return this.defaultStrategySettings?.weekSeasonalityMap[getWeek(new Date())] ?? Seasonality.Low
			}

			const weekFrom = getWeek(currentPeriod.from)
			const seasonalityFrom = this.defaultStrategySettings?.weekSeasonalityMap[weekFrom] ?? Seasonality.Low
			const weekTo = getWeek(currentPeriod.to)
			const seasonalityTo = this.defaultStrategySettings?.weekSeasonalityMap[weekTo] ?? Seasonality.Low

			return seasonalityFrom === seasonalityTo
				? seasonalityFrom
				: (this.defaultStrategySettings?.weekSeasonalityMap[getWeek(new Date())] ?? Seasonality.Low)
		},

		isCurrentPeriodAllInASeasonality(): boolean {
			const currentPeriod = this.selectedPeriodIndex
				? this.editableStrategy.periods.find((period) => period.id === this.selectedPeriodIndex)?.dateRange
				: null

			// for base strategy, we return the current week seasonality
			if (!currentPeriod) {
				return false
			}

			const weekFrom = getWeek(currentPeriod.from)
			const seasonalityFrom = this.defaultStrategySettings?.weekSeasonalityMap[weekFrom] ?? Seasonality.Low
			const weekTo = getWeek(currentPeriod.to)
			const seasonalityTo = this.defaultStrategySettings?.weekSeasonalityMap[weekTo] ?? Seasonality.Low

			return seasonalityFrom === seasonalityTo
		},

		selectedSettings(): any {
			if (this.selectedPeriodIndex == null) {
				return this.editableStrategy.baseStrategy
			} else {
				const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === this.selectedPeriodIndex)
				return this.editableStrategy.periods[periodIndex]!.settings
			}
		},
		isDefaultStrategyChanged(): boolean {
			const { accommodationId: defaultStrategyAccommodationId, ...defaultStrategyConfiguartion } = defaultStrategy
			const { accommodationId: customStrategyAccommodationId, ...customStrategyConfiguration } = this.currentStrategy

			if (!_.isEqual(defaultStrategyConfiguartion, customStrategyConfiguration)) {
				return true
			}
			return false
		},
		isDefaultAdvancedStrategyChanged() {
			const { accommodationId: defaultStrategyAccommodationId, ...defaultStrategyConfiguartion } = {
				dayOfTheWeekMap: this.editableStrategy.baseStrategy.dayOfTheWeekMap,
				eventsAndHolidaysImpact: this.editableStrategy.baseStrategy.eventsAndHolidaysImpact,
				drop: this.editableStrategy.baseStrategy.drop,
				pickup: this.editableStrategy.baseStrategy.pickup,
				expertUserAveragePrice: this.editableStrategy.baseStrategy.expertUserAveragePrice,
				rules: this.editableStrategy.baseStrategy.rules,
			}
			const { accommodationId: customStrategyAccommodationId, ...customStrategyConfiguration } = {
				dayOfTheWeekMap: this.currentStrategy.baseStrategy.dayOfTheWeekMap,
				eventsAndHolidaysImpact: this.currentStrategy.baseStrategy.eventsAndHolidaysImpact,
				drop: this.currentStrategy.baseStrategy.drop,
				pickup: this.currentStrategy.baseStrategy.pickup,
				expertUserAveragePrice: this.currentStrategy.baseStrategy.expertUserAveragePrice,
				rules: this.currentStrategy.baseStrategy.rules,
			}

			if (!_.isEqual(defaultStrategyConfiguartion, customStrategyConfiguration)) {
				return true
			}
			return false
		},

		isSelectedAdvancedStrategyChanged() {
			return (periodId: string) => {
				const editablePeriod = this.editableStrategy.periods.find((period) => period.id === periodId)
				const currentPeriod = this.currentStrategy.periods.find((period) => period.id === periodId)

				const { accommodationId: defaultStrategyAccommodationId, ...defaultStrategyConfiguartion } = {
					dayOfTheWeekMap: editablePeriod?.settings.dayOfTheWeekMap,
					eventsAndHolidaysImpact: editablePeriod?.settings.eventsAndHolidaysImpact,
					drop: editablePeriod?.settings.drop,
					pickup: editablePeriod?.settings.pickup,
					expertUserAveragePrice: editablePeriod?.settings.expertUserAveragePrice,
					isAdvancedExpertUserEnabled: editablePeriod?.settings.isAdvancedExpertUserEnabled,
					rules: editablePeriod?.settings.rules,
				}
				const { accommodationId: customStrategyAccommodationId, ...customStrategyConfiguration } = {
					dayOfTheWeekMap: currentPeriod?.settings.dayOfTheWeekMap,
					eventsAndHolidaysImpact: currentPeriod?.settings.eventsAndHolidaysImpact,
					drop: currentPeriod?.settings.drop,
					pickup: currentPeriod?.settings.pickup,
					expertUserAveragePrice: currentPeriod?.settings.expertUserAveragePrice,
					rules: currentPeriod?.settings.rules,
				}

				if (!_.isEqual(defaultStrategyConfiguartion, customStrategyConfiguration)) {
					return true
				}
				return false
			}
		},

		isEditableStrategyChanged(): boolean {
			if (this.isEditingCustomRules) {
				return false
			}

			// we need to remove past periods also from current strategy, to prevent the comparison to be always true
			const filteredCurrentStrategy = {
				...this.currentStrategy,
				periods: this.currentStrategy.periods.filter((period) => !utilDate.isBeforeToday(period.dateRange.to)),
			}

			if (!_.isEqual(filteredCurrentStrategy, this.editableStrategy)) {
				return true
			}
			return false
		},
		isPreviewedStrategyChanged(): boolean {
			if (
				!this.isEditingCustomRules &&
				(this.previewedStrategy === undefined || !_.isEqual(this.editableStrategy, this.previewedStrategy))
			) {
				return true
			}
			return false
		},
		isDerivedStrategy(): boolean {
			const accommodationsStore = useAccommodationsStore()
			const currentAccommodation = accommodationsStore.getAccommodationById(this.currentStrategy.accommodationId)

			return !!currentAccommodation?.parentStrategyAccommodationId
		},
		derivedStrategyParentAccommodation(): Accommodation | undefined {
			const accommodationsStore = useAccommodationsStore()
			const currentAccommodation = accommodationsStore.getAccommodationById(this.currentStrategy.accommodationId)

			if (currentAccommodation?.parentStrategyAccommodationId) {
				return accommodationsStore.getAccommodationById(currentAccommodation?.parentStrategyAccommodationId)
			}

			return undefined
		},
		currentPeriodSettings(): StrategySettings {
			if (this.selectedPeriodIndex == null) {
				return this.editableStrategy.baseStrategy
			} else {
				const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === this.selectedPeriodIndex)
				return this.editableStrategy.periods[periodIndex].settings
			}
		},
		currentPeriodDateRange(): Range<Date> {
			if (this.selectedPeriodIndex == null) {
				return { from: this.newPeriodStartDate, to: this.newPeriodEndDate }
			} else {
				const periodIndex = this.editableStrategy.periods.findIndex((period) => period.id === this.selectedPeriodIndex)
				return this.editableStrategy.periods[periodIndex].dateRange
			}
		},
		isWaitingDefaultPricePredictions(): boolean {
			return (
				this.isLoadingStrategy ||
				this.isLoadingPricePredictions ||
				(!this.isPreviewedStrategyChanged &&
					(!this.pricePredictions.has(StrategyType.Current) ||
						!this.pricePredictions.has(PricePredictionType.Starting)))
			)
		},
		isWaitingPreviewPricePredictions(): boolean {
			return (
				this.isLoadingStrategy ||
				this.isLoadingPricePredictions ||
				(this.isPreviewedStrategyChanged && !this.pricePredictions.has(StrategyType.Preview))
			)
		},
		strategyPeriodsFromLongest(): StrategyPeriod[] {
			return _.cloneDeep(this.editableStrategy).periods.sort((a, b) => {
				return (
					differenceInCalendarDays(b.dateRange.to, b.dateRange.from) -
					differenceInCalendarDays(a.dateRange.to, a.dateRange.from)
				)
			})
		},
		strategyPeriodsFromShortest(): StrategyPeriod[] {
			return _.cloneDeep(this.editableStrategy).periods.sort((a, b) => {
				return (
					differenceInCalendarDays(a.dateRange.to, a.dateRange.from) -
					differenceInCalendarDays(b.dateRange.to, b.dateRange.from)
				)
			})
		},
		isPeriodAdvanced(): (periodIndex: string) => boolean {
			return (periodIndex: string) => {
				if (periodIndex === null) {
					return this.editableStrategy.baseStrategy.isAdvancedExpertUserEnabled && this.isAdvancedExpertUserAvailable
				}
				return (
					this.editableStrategy.periods.find((period) => period.id === periodIndex)?.settings
						.isAdvancedExpertUserEnabled && this.isAdvancedExpertUserAvailable
				)
			}
		},
	},
})
