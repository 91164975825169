import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import { GetAccommodationsPricesRequest } from './GetAccommodationsPricesRequest';

export class GetAccommodationsPricesNetworkObject extends WebSocketNetworkObject<GetAccommodationsPricesRequest> {
  constructor(params: GetAccommodationsPricesRequest) {
    super(params, WSMessageEvent.GetAccommodationsPrices);
  }
}
