import { defineStore } from 'pinia'
import { utilTracking } from '~/utils/utilTracking'
import { FeatureEvent } from '~~/submodules/feedback/types/FeatureEvent'
import { FeedbackId } from '../constants/FeedbackId'
import { StorageKeys } from '../constants/storageKeys'
import { TrackingMessages } from '../constants/trackingMessages'
import { FeedbackHandler } from './../../submodules/feedback/index'
import { UserFeatureEvent } from './../../submodules/feedback/types/UserFeatureEvent'
import { UserFeedback } from './../../submodules/feedback/types/UserFeedback'
import { IS_DEV_MODE, IS_PROD } from './../constants/index'

type ModalsState = {
	activeFeedback?: FeatureEvent
	initiated: Boolean
	handler?: FeedbackHandler
}

export const useFeedbackStore = defineStore('👍 feedback store', {
	state: (): ModalsState => ({ activeFeedback: undefined, initiated: false, handler: undefined }),
	actions: {
		init() {
			if (!this.initiated && !IS_DEV_MODE()) {
				this.handler = new FeedbackHandler(
					useRuntimeConfig().public.FEEDBACK_PROJECT,
					localStorage.getItem(StorageKeys.SessionToken) || '',
					useRuntimeConfig().public.FEEDBACK_URL,
					this.setActiveFeedback
				)
				this.initiated = true
			}
		},
		stop() {
			if (this.initiated && this.handler) {
				this.handler!.kill()
				delete this.handler
				this.initiated = false
			}
		},
		getFeedbackEventId(feedbackId: FeedbackId): number {
			return this.feedbacksIdMap[feedbackId]
		},
		setActiveFeedback(activeFeedback: UserFeatureEvent): void {
			this.activeFeedback = activeFeedback.event

			utilTracking.track(TrackingMessages.FEATURE_FEEDBACK_SHOW, {
				feature_name: this.activeFeedback.eventKey,
			})
		},
		giveFeedback(feedback: UserFeedback): void {
			if (this.handler != undefined) {
				this.handler.onTrack(feedback)
			}
		},
		rejectFeedback(): void {
			const id = this.activeFeedback?.id
			if (id != undefined && this.handler != undefined) {
				this.handler.onReject(id)
			}
		},
		requestFeedback(eventId: FeedbackId): void {
			const feedbackEventId = this.getFeedbackEventId(eventId)
			if (this.handler != undefined) {
				this.handler.onManualTrigger(feedbackEventId)
			}
		},
	},
	getters: {
		feedbacksIdMap(): Record<FeedbackId, number> {
			return {
				[FeedbackId.StrategyChange]: 1,
				[FeedbackId.BulkPriceCustomization]: 2,
				[FeedbackId.EventConfirm]: 6,
				[FeedbackId.Add3OrMoreCompetitors]: 7,
				[FeedbackId.CompetitorsMapZoomInOrOut]: 8,
				[FeedbackId.CompetitorsChartInteraction]: 9,
				[FeedbackId.PriceListSave]: 10,
				[FeedbackId.DashboardKPISCompared]: IS_PROD() ? 13 : 11,
				[FeedbackId.ChartsHover]: IS_PROD() ? 52 : 21,
				[FeedbackId.StrategyAdvancedChange]: IS_PROD() ? 53 : 21,
				[FeedbackId.MinStayChange]: IS_PROD() ? 55 : 23,
				[FeedbackId.DropPickupCustomRule]: IS_PROD() ? 54 : 24,
			}
		},
	},
})
