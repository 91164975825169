import { compareAsc, isSameDay } from 'date-fns'
import { defineStore } from 'pinia'
import { Event } from '~/submodules/sharedTypes/common/Event'
import { Price } from '../submodules/sharedTypes/common/Price'
import { GetAccommodationDateDetailsBookingOccupanciesResponse } from '../submodules/sharedTypes/communication/accommodations/id/date-details/GetAccommodationDateDetailsBookingOccupanciesResponse'
import { GetAccommodationDateDetailsStatsResponse } from '../submodules/sharedTypes/communication/accommodations/id/date-details/GetAccommodationDateDetailsStatsResponse'
import { GetAccommodationsPricesResponse } from '../submodules/sharedTypes/communication/accommodations/prices/GetAccommodationsPricesResponse'
import { GetDateDetailsPriceComputationHistoryResponse } from '../submodules/sharedTypes/communication/common/GetDateDetailsPriceComputationHistoryResponse'
import { GetRoomTypeDateDetailsBookingOccupanciesResponse } from '../submodules/sharedTypes/communication/room-types/id/date-details/GetRoomTypeDateDetailsBookingOccupanciesResponse'
import { GetRoomTypeDateDetailsPriceResponse } from '../submodules/sharedTypes/communication/room-types/id/date-details/GetRoomTypeDateDetailsPriceResponse'
import { GetRoomTypeDateDetailsStatsResponse } from '../submodules/sharedTypes/communication/room-types/id/date-details/GetRoomTypeDateDetailsStatsResponse'
import { IChartDataPoint } from '../types/IChartDataPoint'
import { INamedArray } from '../types/INamedArray'
import { Currency } from './../submodules/sharedTypes/common/Currency'
import { HistoricalComputedPrice } from './../submodules/sharedTypes/common/HistoricalComputedPrice'
import { BookingDateOccupancy } from './../submodules/sharedTypes/common/Occupancy'
import { Reservation } from './../submodules/sharedTypes/common/Reservation'
import { GetAccommodationDateDetailsNetworkObject } from './../submodules/sharedTypes/communication/accommodations/id/date-details/GetAccommodationDateDetailsNetworkObject'
import { GetRoomTypeBookingEventsResponse } from './../submodules/sharedTypes/communication/room-types/id/booking-events/GetRoomTypeBookingEventsResponse'
import { GetRoomTypeDateDetailsEventsResponse } from './../submodules/sharedTypes/communication/room-types/id/date-details/GetRoomTypeDateDetailsEventsResponse'
import { GetRoomTypeDateDetailsNetworkObject } from './../submodules/sharedTypes/communication/room-types/id/date-details/GetRoomTypeDateDetailsNetworkObject'
import { utilNetwork } from './../utils/UtilNetwork'
import { TranslationKeys } from '~/i18n/TranslationKeys'

type DateDetailsState = {
	accommodationId: number
	roomTypeId: number
	date: Date
	currentPrice: Price | undefined
	occupancy: number
	currency: Currency
	occupancyLastYear: number
	adr: number
	adrLastYear: number
	historicalComputedPrices: HistoricalComputedPrice[]
	canceledReservations: Reservation[]
	confirmedReservations: Reservation[]
	occupancies: BookingDateOccupancy[]
	events: Omit<Event, 'eventPeriod' | 'eventDetails'>[]
}

export const useDateDetailsStore = defineStore('📊 date details', {
	state: (): DateDetailsState => ({
		accommodationId: 0,
		roomTypeId: 0,
		currency: {
			code: 'EUR',
		},
		date: new Date(),
		currentPrice: undefined,
		occupancy: 0,
		occupancyLastYear: 0,
		adr: 0,
		adrLastYear: 0,
		historicalComputedPrices: [],
		canceledReservations: [],
		confirmedReservations: [],
		occupancies: [],
		events: [],
	}),
	actions: {
		setDetailsRequirements(accommodationId: number, roomType: number, date: Date, price?: Price) {
			this.accommodationId = accommodationId
			this.roomTypeId = roomType
			this.date = date
			this.currentPrice = {
				...price,
				history: price?.history ?? [],
			}
			this.currency = useAccommodationsStore().accommodations.find((el) => el.id === accommodationId)?.currency || {
				code: 'EUR',
			}

			if (roomType == 0) {
				utilNetwork.simpleRequest(
					new GetAccommodationDateDetailsNetworkObject({
						accommodationId,
						date,
					})
				)
			} else {
				utilNetwork.simpleRequest(
					new GetRoomTypeDateDetailsNetworkObject({
						roomTypeId: roomType,
						date,
					})
				)
			}
		},
		updateDetailsRequirements(response: GetAccommodationsPricesResponse) {
			const isPriceDetailSet = this.currentPrice?.id !== undefined
			const matchingPrice = response.accommodations
				.find((el) => el.id == this.currentPrice?.accommodationId)
				?.prices?.find((el) => el.id === this.currentPrice?.id)

			if (isPriceDetailSet && matchingPrice != undefined && !matchingPrice.isLoading) {
				this.setDetailsRequirements(
					this.currentPrice!.accommodationId,
					this.currentPrice!.roomTypeId,
					this.currentPrice!.date,
					matchingPrice
				)
			}
		},
		setAccommodationDateDetails(response: GetAccommodationDateDetailsStatsResponse) {
			if (isSameDay(response.date, this.date) && response.accommodationId === this.accommodationId) {
				this.adr = response.stats.adr
				this.adrLastYear = response.statsSdly.adr
				this.occupancy = response.stats.filledRooms
				this.occupancyLastYear = response.statsSdly.filledRooms
			}
		},
		setRoomTypeReservations(response: GetRoomTypeBookingEventsResponse) {
			if (isSameDay(response.date, this.date) && response.roomTypeId === this.roomTypeId) {
				this.canceledReservations = response.canceledReservations
				this.confirmedReservations = response.confirmedReservations
			}
		},

		setRoomTypeDateDetails(response: GetRoomTypeDateDetailsStatsResponse) {
			if (isSameDay(response.date, this.date) && response.roomTypeId === this.roomTypeId) {
				this.adr = response.stats.adr
				this.adrLastYear = response.statsSdly.adr
				this.occupancy = response.stats.filledRooms
				this.occupancyLastYear = response.statsSdly.filledRooms
			}
		},
		setRoomTypeEvents(response: GetRoomTypeDateDetailsEventsResponse) {
			if (isSameDay(response.date, this.date) && response.roomTypeId === this.roomTypeId) {
				// @ts-ignore
				this.events = response.events
			}
		},
		setRoomTypePriceDateDetailsResponse(response: GetRoomTypeDateDetailsPriceResponse) {
			if (isSameDay(response.date, this.date) && response.roomTypeId === this.roomTypeId) {
				this.currentPrice = {
					id: response.price.id,
					date: response.price.date,
					isLoading: response.price.isLoading,
					isClosingDate: response.price.isClosingDate,
					publishedPrice: response.price.publishedPrice,
					predictedPrice: response.price.predictedPrice,
					modifiers: response.price.modifiers,
					activeModifiers: response.price.activeModifiers,
					startingPrice: response.price.startingPrice,
					roomTypeId: response.price.roomTypeId,
					isRoomTypeVirtual: response.price.isRoomTypeVirtual,
					isRoomTypeIndexed: response.price.isRoomTypeIndexed,
					indexedPrice: response.price.indexedPrice,
					history: response.price.history,
					accommodationId: this.currentPrice?.accommodationId || '',
				}
			}
		},
		setAccommodationOccupancies(response: GetAccommodationDateDetailsBookingOccupanciesResponse) {
			if (response.accommodationId === this.accommodationId && isSameDay(this.date, response.date)) {
				this.occupancies = response.bookingOccupancies
			}
		},
		setRoomTypeOccupancies(response: GetRoomTypeDateDetailsBookingOccupanciesResponse) {
			if (response.roomTypeId === this.roomTypeId && isSameDay(this.date, response.date)) {
				this.occupancies = response.bookingOccupancies
			}
		},
		setComputedPrices(response: GetDateDetailsPriceComputationHistoryResponse) {
			if (isSameDay(response.date, this.date)) {
				this.historicalComputedPrices = response.priceComputationHistory.sort((prev, next) =>
					compareAsc(prev.date, next.date)
				)
			}
		},
		reset() {
			this.accommodationId = 0
			this.roomTypeId = 0
			this.date = new Date()
			this.currentPrice = undefined
			this.occupancy = 0
			this.occupancyLastYear = 0
			this.adr = 0
			this.adrLastYear = 0
			this.historicalComputedPrices = []
			this.canceledReservations = []
			this.confirmedReservations = []
			this.occupancies = []
		},
	},
	getters: {
		getPickupData(): INamedArray<IChartDataPoint<number, Date>>[] {
			const confirmedEntries = this.confirmedReservations.map((entry) => ({ x: entry.bookedAt, y: entry.amount }))
			const canceledEntries = this.canceledReservations.map((entry) => ({
				x: entry.canceledAt!,
				y: entry.amount,
			}))

			return [
				{
					entries: confirmedEntries,
					name: TranslationKeys.RESERVATIONS,
				},
				{
					entries: canceledEntries,
					name: TranslationKeys.CANCELLED_F_MULTIPLE,
				},
			]
		},
		getHystoricalPricesData(): INamedArray<IChartDataPoint<number, Date>> {
			return {
				name: TranslationKeys.PRICE,
				entries: this.historicalComputedPrices.map((el) => ({
					y: el.price,
					x: el.date,
				})),
			}
		},
		getOccupancyData(): INamedArray<IChartDataPoint<number, Date>> {
			return {
				name: TranslationKeys.OCCUPANCY,
				entries: this.occupancies.map((el) => ({
					y: el.occupancy,
					x: el.date,
				})),
			}
		},
	},
})
