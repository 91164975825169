import addDays from 'date-fns/addDays'
import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'
import { Event, EventPreviewType, EventType } from '~/submodules/sharedTypes/common/Event'
import { eventsImpactsSave } from '~~/src/tracking/eventsTracking'
import { calendarConfig } from '../config/CalendarConfig'
import { FeedbackId } from '../constants/FeedbackId'
import { CardTypes } from '../constants/cardTypes'
import { LoadingIds } from '../constants/loadingIds'
import { computationPaddingConfig } from '../constants/priceComputation/computationPadding'
import { TrackingMessages } from '../constants/trackingMessages'
import { RoomTypeEventImpactPreviewData } from '../submodules/sharedTypes/communication/events/preview/GetEventImpactPreviewResponse'
import { ToggleEventVisibilityNetworkObject } from '../submodules/sharedTypes/communication/events/toggle-visibility/ToggleEventVisibilityNetworkObject'
import { ToggleEventVisibilityResponse } from '../submodules/sharedTypes/communication/events/toggle-visibility/ToggleEventVisibilityResponse'
import { utilNetwork } from '../utils/UtilNetwork'
import { utilTracking } from '../utils/utilTracking'
import { EventDetails, RepetitionType } from './../submodules/sharedTypes/common/Event'
import { GetAccommodationsEventsNetworkObject } from './../submodules/sharedTypes/communication/accommodations/events/GetAccommodationsEventsNetworkObject'
import { GetAccommodationsEventsResponse } from './../submodules/sharedTypes/communication/accommodations/events/GetAccommodationsEventsResponse'
import { CreateEventNetworkObject } from './../submodules/sharedTypes/communication/events/create/CreateEventNetworkObject'
import { CreateEventResponse } from './../submodules/sharedTypes/communication/events/create/CreateEventResponse'
import { GetEventDetailsNetworkObject } from './../submodules/sharedTypes/communication/events/details/GetEventDetailsNetworkObject'
import { GetEventDetailsRequest } from './../submodules/sharedTypes/communication/events/details/GetEventDetailsRequest'
import { GetEventDetailsResponse } from './../submodules/sharedTypes/communication/events/details/GetEventDetailsResponse'
import { GetEventImpactPreviewNetworkObject } from './../submodules/sharedTypes/communication/events/preview/GetEventImpactPreviewNetworkObject'
import { GetEventImpactPreviewResponse } from './../submodules/sharedTypes/communication/events/preview/GetEventImpactPreviewResponse'
import { RemoveEventNetworkObject } from './../submodules/sharedTypes/communication/events/remove/RemoveEventNetworkObject'
import { RemoveEventResponse } from './../submodules/sharedTypes/communication/events/remove/RemoveEventResponse'
import { UpdateEventNetworkObject } from './../submodules/sharedTypes/communication/events/update/UpdateEventNetworkObject'
import { UpdateEventResponse } from './../submodules/sharedTypes/communication/events/update/UpdateEventResponse'
import { useAccommodationsStore } from './accommodations'
import { useBannerStore } from './banners'
import { useFeedbackStore } from './feedback'
import { useLoadingStore } from './loading'
import { useNotificationsStore } from './notifications'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

type EventsState = {
	eventsByAccommodation: Map<number, Event[]>
	startDate: Date
	editableEvent: Event
	currentEventDetails?: EventDetails
	editableEventDetails: EventDetails
	selectedAccommodations: number[]
	eventInteractionAccommodationId: number
	currentPreview: Map<string, RoomTypeEventImpactPreviewData[]>
	eventPreview: Map<string, RoomTypeEventImpactPreviewData[]>
	eventWithModifiersPreview: Map<string, RoomTypeEventImpactPreviewData[]>
	savePendingEvents: number
}

// @ts-ignore
const emtpyEvent: Event = {
	label: {
		it: '',
		de: '',
		en: '',
	},
}

const emptyEventDetails: EventDetails = {
	// @ts-ignore
	eventDates: undefined,
	impacts: [],
	repetitionType: RepetitionType.NoRepeat,
}

export const useEventsStore = defineStore('🏨 events', {
	state: (): EventsState => ({
		eventsByAccommodation: new Map(),
		startDate: new Date(),
		editableEvent: cloneDeep(emtpyEvent),
		currentEventDetails: undefined,
		editableEventDetails: cloneDeep(emptyEventDetails),
		selectedAccommodations: [],
		eventInteractionAccommodationId: -1,
		currentPreview: new Map(),
		eventPreview: new Map(),
		eventWithModifiersPreview: new Map(),
		savePendingEvents: 0,
	}),
	actions: {
		setEvents(res: GetAccommodationsEventsResponse): void {
			res.accommodations.map((val) => {
				this.eventsByAccommodation.set(val.id, val.events)
			})
		},
		setEventDetails(res: GetEventDetailsResponse): void {
			this.currentEventDetails = cloneDeep(res.eventDetails)
			this.editableEventDetails = cloneDeep(res.eventDetails)
		},
		setStartDate(date: Date) {
			this.startDate = date
		},
		clearEvents() {
			this.eventsByAccommodation.clear()
		},
		resetEditableEvent() {
			// @ts-ignore
			this.editableEvent = cloneDeep(emtpyEvent)
			// @ts-ignore
			this.editableEventDetails = cloneDeep(emptyEventDetails)
			this.selectedAccommodations = []
		},
		toggleCurrentEventVisibility() {
			const isVisible = this.editableEvent.isUserDisabled!

			utilNetwork.simpleRequest(
				new ToggleEventVisibilityNetworkObject({
					accommodationId: this.editableEvent.accommodationId!,
					eventId: this.editableEvent.id!,
					eventType: this.editableEvent.eventType,
					// any date of the event is fine, so we just use the first one
					eventDate: this.editableEvent.eventPeriod.from,
					isVisible,
				})
			)

			utilTracking.track(isVisible ? TrackingMessages.EVENT_RESTORE : TrackingMessages.EVENT_DISABLE, {
				event_type: this.editableEvent.eventType,
				event_originator: 'System',
			})
		},
		deleteCurrentEvent() {
			utilNetwork.simpleRequest(
				new RemoveEventNetworkObject({
					accommodationId: this.editableEvent.accommodationId!,
					eventId: this.editableEvent.id!,
					eventType: this.editableEvent.eventType,
					// any date of the event is fine, so we just use the first one
					eventDate: this.editableEvent.eventPeriod.from,
				})
			)
		},
		toggleCurrentEventVisibilityConfirmed(res: ToggleEventVisibilityResponse) {
			const event = this.eventsByAccommodation.get(res.accommodationId)!.find((el) => el.id == res.eventId)!
			this.triggerLoadingEventsBanner(event)

			// const newEvents = (
			//     this.eventsByAccommodation.get(res.accommodationId) || []
			// )?.filter((el) => el.id !== res.eventId)
			// this.eventsByAccommodation.set(res.accommodationId, newEvents)
		},
		removeEventConfirmed(res: RemoveEventResponse) {
			const event = this.eventsByAccommodation.get(res.accommodationId)!.find((el) => el.id == res.eventId)!
			this.triggerLoadingEventsBanner(event)

			// const newEvents = (
			//     this.eventsByAccommodation.get(res.accommodationId) || []
			// )?.filter((el) => el.id !== res.eventId)
			// this.eventsByAccommodation.set(res.accommodationId, newEvents)
		},
		async requestEvents() {
			this.clearEvents()
			const loadingStore = useLoadingStore()
			await loadingStore.getPromiseDependency(LoadingIds.ACCOMMODATIONS)

			const accommodations = useAccommodationsStore().accommodationsToShow.length
				? useAccommodationsStore().accommodationsToShow
				: useAccommodationsStore().accommodations.map((element) => element.id)

			const request = new GetAccommodationsEventsNetworkObject({
				accommodationIds: accommodations,
				dateRange: {
					from: this.startDate,
					to: addDays(this.startDate, calendarConfig.maxDays - 1),
				},
			})

			utilNetwork.simpleRequest(request, undefined, LoadingIds.EVENTS)
		},
		resetPreviews() {
			this.currentPreview = new Map()
			this.eventPreview = new Map()
			this.eventWithModifiersPreview = new Map()
		},
		async requestPreview() {
			this.resetPreviews()
			const eventDetails = cloneDeep(this.editableEventDetails)
			if (eventDetails.impacts.length == 0) {
				eventDetails.impacts = [
					{
						date: this.editableEventDetails.eventDates.from,
						impact: 0,
					},
				]
			}

			utilNetwork.simpleRequest(
				new GetEventImpactPreviewNetworkObject({
					accommodationIds: this.selectedAccommodations,
					event: this.editableEvent,
					eventDetails,
				})
			)
		},
		previewReceived(res: GetEventImpactPreviewResponse) {
			const accommodationIds = res.accommodationIds
			accommodationIds.forEach((accommodationId) => {
				switch (res.eventPreviewType) {
					case EventPreviewType.Current: {
						this.currentPreview.set(accommodationId, res.roomTypes)
						break
					}
					case EventPreviewType.Preview: {
						this.eventPreview.set(accommodationId, res.roomTypes)
						break
					}
					case EventPreviewType.PreviewWithModifiers: {
						this.eventWithModifiersPreview.set(accommodationId, res.roomTypes)
						break
					}
				}
			})
		},
		saveEvent(removeModifiers: boolean) {
			eventsImpactsSave(this.editableEvent, this.editableEventDetails, this.currentEventDetails)

			useNotificationsStore().addNotification({
				canClose: true,
				cardType: CardTypes.SUCCESS,
				title:
					this.editableEvent.eventType == EventType.Event ? TranslationKeys.EVENT_SENT : TranslationKeys.FESTIVITY_SENT,
				message:
					this.editableEvent.eventType == EventType.Event
						? TranslationKeys.EVENT_SENT_TEXT
						: TranslationKeys.FESTIVITY_SENT_TEXT,
				// TODO: replace once we get multilang going
				messageReplacements: [this.editableEvent.label.en],
			})

			this.selectedAccommodations.forEach((accommodationId) => {
				if (this.editableEvent.id != undefined) {
					utilNetwork.simpleRequest(
						new UpdateEventNetworkObject({
							id: this.editableEvent.id,
							event: { ...this.editableEvent, accommodationId },
							eventDetails: this.editableEventDetails,
							removeModifiers,
						})
					)
				} else {
					utilNetwork.simpleRequest(
						new CreateEventNetworkObject({
							event: { ...this.editableEvent, accommodationId },
							eventDetails: this.editableEventDetails,
							removeModifiers,
						})
					)
				}
			})

			useFeedbackStore().requestFeedback(FeedbackId.EventConfirm)
		},
		confirmSavedEvent(res: CreateEventResponse) {
			this.triggerLoadingEventsBanner(res.event)
		},
		confirmUpdatedEvent(res: UpdateEventResponse) {
			// const events = this.eventsByAccommodation.get(
			//     res.event.accommodationId
			// )!
			// const eventIndex = events.findIndex((el) => el.id == res.id)

			// events[eventIndex] = res.event
			// this.eventsByAccommodation.set(res.event.accommodationId, events)
			this.triggerLoadingEventsBanner(res.event)
		},
		async requestEventDetails(params: GetEventDetailsRequest) {
			utilNetwork.simpleRequest(new GetEventDetailsNetworkObject(params))
		},
		triggerLoadingEventsBanner(event: Event) {
			this.savePendingEvents++
			const eventType = event.eventType
			let title: TranslationKey
			let message: TranslationKey

			// TODO: handle multilang
			let titleReplacements =
				this.savePendingEvents < 2
					? [event.label.it as TranslationKey]
					: [this.savePendingEvents.toString() as TranslationKey]

			if (eventType === EventType.Event) {
				if (this.savePendingEvents < 2) {
					title = TranslationKeys.EVENT_LOADING
					message = TranslationKeys.EVENT_LOADING_TEXT
				} else {
					;(title = TranslationKeys.MULTIPLE_EVENTS_LOADING), (message = TranslationKeys.MULTIPLE_EVENTS_LOADING_TEXT)
				}
			} else {
				if (this.savePendingEvents < 2) {
					title = TranslationKeys.FESTIVITY_LOADING
					message = TranslationKeys.FESTIVITY_LOADING_TEXT
				} else {
					title = TranslationKeys.MULTIPLE_FESTIVITIES_LOADING
					message = TranslationKeys.MULTIPLE_FESTIVITIES_LOADING_TEXT
				}
			}

			useBannerStore().addUniqueBanner(
				{
					id: eventType == EventType.Event ? 'event_counter' : 'holiday_counter',
					canClose: true,
					cardType: CardTypes.INFO,
					title,
					titleReplacements,
					message,
				},
				true
			)
		},
	},
	getters: {
		getCurrentPreviewByAccommodationId() {
			return (id: string) => {
				return this.currentPreview.get(id) || []
			}
		},
		getEventPreviewByAccommodationId() {
			return (id: string) => {
				return this.eventPreview.get(id) || []
			}
		},
		getEventWithModifiersPreviewByAccommodationId() {
			return (id: string) => {
				return this.eventWithModifiersPreview.get(id) || []
			}
		},
		getEventsByAccommodationId() {
			return (id: number) => {
				// if (
				//     useRuntimeConfig().public.ENVIRONMENT ===
				//     Environments.Development
				// ) {
				//     return getMockEventsForDate(this.startDate, id)
				// }
				return this.eventsByAccommodation.get(id) || []
			}
		},
		getTimePadding(): number {
			const accommodationStore = useAccommodationsStore()
			let accommodations =
				this.editableEvent.id == undefined ? this.selectedAccommodations : [this.eventInteractionAccommodationId]

			const computationOffsets = accommodations.map((acc) =>
				(accommodationStore.getAccommodationById(acc)?.roomTypes || []).map((el) => el.priceComputationMaxOffset)
			)

			return computationOffsets
				.reduce((prev, next) => [...prev, ...next], [])
				.reduce((prev, next) => Math.min(prev, next), computationPaddingConfig.minComputationPadding)
		},
		getEditableEventHasAlteredWeight(): boolean {
			const accommodationStore = useAccommodationsStore()
			const weightsAlterators = this.selectedAccommodations.map(
				(el) => accommodationStore.getAccommodationById(el)?.eventsWeightMultiplier
			)

			return weightsAlterators.filter((el) => el != undefined && el != 1).length > 0
		},
	},
})
